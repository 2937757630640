import { useEffect, useState } from "react";
import { AISpecialty, AITranslationElement } from "../../../shared/types";
import { fbFirestore } from "../../firebase";

interface useAITranslationProps {
  useGpToAi?: boolean;
  useSpToAi?: boolean;
  useSpLabelList?: boolean;
}

interface AITranslationTable {
  [aiLabel: string]: AITranslationElement;
}

interface Translation {
  [input: string]: string;
}

type LabelList = {
  [specialty in AISpecialty]: string[];
};

//Create instance to reuse if already fetched
let tableInstance: AITranslationTable = {};

const useAITranslation = ({
  useGpToAi,
  useSpToAi,
  useSpLabelList,
}: useAITranslationProps) => {
  const [gpLabelToAiLabel, setGpLabelToAiLabel] = useState<Translation>({});
  const [spLabelToAiLabel, setSpLabelToAiLabel] = useState<Translation>({});
  const [spLabelList, setSpLabelList] = useState<LabelList>({
    Dermatology: [],
    Otolaryngology: [],
  });
  const [table, setTable] = useState<AITranslationTable>(tableInstance);

  useEffect(() => {
    fetchAITranslationTable().then((newTable) => {
      //Set state to the fetched table
      setTable(newTable);

      //Initialize dictionaries
      if (useGpToAi) initGpToAi(newTable);
      if (useSpToAi) initSpToAi(newTable);
      if (useSpLabelList) initSpLabelList(newTable);
    });
  }, []);

  //Fetch if instance doesn't exist yet
  const fetchAITranslationTable = (): Promise<AITranslationTable> => {
    if (isEmpty(tableInstance)) {
      return fbFirestore
        .collection("ai_translation")
        .get()
        .then((snapshots) => {
          tableInstance = {};
          snapshots.docs.forEach((doc) => {
            const element = doc.data() as AITranslationElement;
            const aiLabel = doc.id;
            tableInstance[aiLabel] = element;
          });
          return tableInstance;
        });
    } else {
      return Promise.resolve(tableInstance);
    }
  };

  const initGpToAi = (newTable: AITranslationTable): void => {
    if (Object.keys(newTable).length > 0) {
      const newMapping: Translation = {};
      Object.entries(newTable).forEach((entry) => {
        gpLabelToAiLabel[entry[1].gpLabel] = entry[0];
      });
      setGpLabelToAiLabel(newMapping);
    }
  };

  const initSpToAi = (newTable: AITranslationTable): void => {
    if (Object.keys(newTable).length > 0) {
      const newMapping: Translation = {};
      Object.entries(newTable).forEach((entry) => {
        newMapping[entry[1].spLabel] = entry[0];
      });
      setSpLabelToAiLabel(newMapping);
    }
  };

  const initSpLabelList = (newTable: AITranslationTable): void => {
    if (Object.keys(newTable).length > 0) {
      const newList: LabelList = {
        Dermatology: [],
        Otolaryngology: [],
      };
      newList["Otolaryngology"] = Object.values(newTable)
        .filter((element) => {
          return element.specialty === "Otolaryngology";
        })
        .map((element) => {
          return element.spLabel;
        })
        .sort((a, b) => {
          return a > b ? 1 : -1;
        });

      newList["Dermatology"] = Object.values(newTable)
        .filter((element) => {
          return element.specialty === "Dermatology";
        })
        .map((element) => {
          return element.spLabel;
        })
        .sort((a, b) => {
          return a > b ? 1 : -1;
        });

      setSpLabelList(newList);
    }
  };

  const gpToAi = (
    gpLabel: string,
    returnOriginalIfNotFound?: boolean,
  ): string => {
    if (isEmpty(gpLabelToAiLabel)) {
      console.warn(
        "GP to AI lookup not initialized. Call initGpToAi to initialize.",
      );
      return gpLabel;
    }
    if (gpLabelToAiLabel[gpLabel] !== undefined) {
      return gpLabelToAiLabel[gpLabel];
    }
    return returnOriginalIfNotFound ? gpLabel : "";
  };

  const spToAi = (
    spLabel: string,
    returnOriginalIfNotFound?: boolean,
  ): string => {
    if (isEmpty(spLabelToAiLabel)) {
      console.warn(
        "SP to AI lookup not initialized. Call initSpToAi to initialize.",
      );
      return spLabel;
    }
    if (spLabelToAiLabel[spLabel] !== undefined) {
      return spLabelToAiLabel[spLabel];
    }
    return returnOriginalIfNotFound ? spLabel : "";
  };

  const aiToGp = (
    aiLabel: string,
    returnOriginalIfNotFound?: boolean,
  ): string => {
    if (isEmpty(table)) {
      console.warn(
        "AI to GP lookup not initialized. The table is not initialized yet.",
      );
      return aiLabel;
    }
    if (table[aiLabel] !== undefined) {
      return table[aiLabel].gpLabel;
    }
    return returnOriginalIfNotFound ? aiLabel : "";
  };

  const aiToSp = (
    aiLabel: string,
    returnOriginalIfNotFound?: boolean,
  ): string => {
    if (isEmpty(table)) {
      console.warn(
        "AI to SP lookup not initialized. The table is not initialized yet.",
      );
      return aiLabel;
    }
    if (table[aiLabel] !== undefined) {
      return table[aiLabel].spLabel;
    }
    return returnOriginalIfNotFound ? aiLabel : "";
  };

  const checkAILabelExists = (aiLabel: string): boolean => {
    return table[aiLabel] !== undefined;
  };

  const isEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
  };

  return {
    gpToAi,
    spToAi,
    aiToGp,
    aiToSp,
    checkAILabelExists,
    spLabelList,
  };
};

export default useAITranslation;
