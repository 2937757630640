import {
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import AVAGalleryController from "./AVAGalleryController";
interface AVAGalleryButtonProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  unselectAll: () => void;
}

const AVAGalleryButton: FC<AVAGalleryButtonProps> = ({
  selectedMedia,
  unselectAll,
}) => {
  const {
    enableAVAUpload,
    avaGalleryState,
    avaGalleryError,
    isDisabled,
    setAVAGalleryState,
    uploadFilesToAVAHandler,
    avaGalleryLoading,
    showSelectError,
  } = AVAGalleryController({ selectedMedia, unselectAll });

  const isMobileSize = useMobileSizes();

  if (!enableAVAUpload) return null;

  return (
    <>
      <Grid>
        <Button
          onClick={uploadFilesToAVAHandler}
          variant="outlined"
          color="primary"
          disabled={isDisabled}
        >
          <span style={{ display: "inline-flex", alignItems: "baseline" }}>
            {isMobileSize ? "" : "Upload to"}
            <img
              src="/Ava_logo.svg"
              alt="Icon"
              style={
                isMobileSize
                  ? {
                      height: "1.2em",
                      fontSize: "1em",
                      marginTop: "5.25px",
                      marginBottom: "5.25px",
                    }
                  : {
                      height: "1em",
                      fontSize: "1em",
                      marginLeft: "0.35em",
                    }
              }
            />
          </span>
        </Button>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={avaGalleryState}
          setState={setAVAGalleryState}
          errorMessage={avaGalleryError}
          successMessage="Files uploaded successfully."
          loadingMessage={avaGalleryLoading}
          useSnackbar
        />

        {showSelectError && (
          <MuiAlert severity="warning">
            Select at least one image to upload to Ava.
          </MuiAlert>
        )}
      </Grid>
    </>
  );
};

export default AVAGalleryButton;
