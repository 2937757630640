import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import { FC } from "react";

interface InterpretationsViewProps {
  billAudiogram: boolean;
  setBillAudiogram: (doBill: boolean) => void;
  billAudiogramGranted: boolean;
  billSinusEndoscopy: boolean;
  setBillSinusEndoscopy: (doBill: boolean) => void;
  billSinusEndoscopyGranted: boolean;
}

const InterpretationsView: FC<InterpretationsViewProps> = ({
  billAudiogram,
  setBillAudiogram,
  billAudiogramGranted,
  billSinusEndoscopy,
  setBillSinusEndoscopy,
  billSinusEndoscopyGranted,
}: InterpretationsViewProps) => {
  return (
    <Grid container spacing={1}>
      {billAudiogramGranted && (
        <Grid size={{ xs: 12 }}>
          <FormControlLabel
            label={"Audiogram"}
            checked={billAudiogram}
            onChange={(_, checked) => {
              setBillAudiogram(checked);
            }}
            control={<Checkbox color="primary" disableRipple />}
          />
        </Grid>
      )}
      {billSinusEndoscopyGranted && (
        <Grid size={{ xs: 12 }}>
          <FormControlLabel
            label={"Sinus Endoscopy"}
            checked={billSinusEndoscopy}
            onChange={(_, checked) => {
              setBillSinusEndoscopy(checked);
            }}
            control={<Checkbox color="primary" disableRipple />}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default InterpretationsView;
