import { Grid2 as Grid, Paper, Theme, Typography } from "@mui/material";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
}));

export const ExplanationPanel: FC = () => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.container} variant="outlined">
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <span className={classes.title}>
            <Typography variant="h6" color="primary">
              Standard Rejection Explanations
            </Typography>
          </span>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Typography variant="subtitle1" color="primary">
            Status <br />
          </Typography>
          <Typography variant="caption" color="primary">
            <b>&ldquo;failed&rdquo;</b>: email failed to send due to incorrect
            email address <br />
            <b>&ldquo;refused&rdquo;</b>: Rejected by AHS, some frequent error
            codes below
            <br />
          </Typography>

          <Typography variant="subtitle1" color="primary">
            Error Codes <br />
          </Typography>
          <Typography variant="caption" color="primary">
            <b>63A</b>: Schedule Of Benefits Payment for your claim was reduced
            or refused in accordance with the applicable benefits schedule.
            <br />
            <b>63B</b>: Maximum Number Of Calls Payment was reduced as the
            maximum number of calls for the HSC was reached
            <br />
          </Typography>
        </Grid>

        <Grid size={{ xs: 6 }}>
          <Typography variant="caption" color="primary">
            <b>67A</b>: Previous payment for this service was refused
            <br />
            <b> RVRSL</b>: Reversal. This is a reversal of a previously assessed
            item.
            <br />
            <b> 05BB</b>: Invalid/Blank Unique Lifetime Identifier. This claim
            was refused as the ULI is: (a) blank or (b) invalid or (c) not valid
            for the service recipient.
            <br />
            <b>05A</b>: Invalid Personal Health Number. The PHN is invalid or
            blank
            <br />
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
