import {
  ErrorHandlerOptions,
  ProcessState,
} from "@alethea-medical/alethea-components";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import analyticsLogs from "../../../analyticsLogs";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";

interface AveiroRetryFormProps {
  visible: boolean;
  activityId: string;
  itemCode: string | undefined;
  refetchPayments: () => void;
  setProcessState: (state: ProcessState) => void;
  processState: ProcessState;
  errorHandler: (options: ErrorHandlerOptions) => void;
}

const AveiroRetryForm: FC<AveiroRetryFormProps> = ({
  visible,
  activityId,
  itemCode,
  refetchPayments,
  processState,
  setProcessState,
  errorHandler,
}) => {
  const submitOatPayment = fbFunctions.httpsCallable("aveiro-submitOatPayment");

  const handleRetry = () => {
    if (itemCode === undefined) return;

    setProcessState(ProcessState.running);
    return submitOatPayment({ itemCode: itemCode, activityId: activityId })
      .then((result) => {
        if (result.data.ok) {
          // Show success page
          logAnalyticsEvent(analyticsLogs.aveiro.paymentSubmit.success);
          setProcessState(ProcessState.success);
        } else {
          errorHandler({
            error: new Error(result.data.error),
            userMessage: "Could not submit payment. Reason",
            analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail,
          });
        }
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error retrying payment",
          analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail,
        });
      })
      .finally(() => {
        // Refetch payments after retrying
        refetchPayments();
      });
  };

  return (
    <Collapse in={visible}>
      <Grid container spacing={1} justifyContent="center">
        <Grid size={{ xs: 12 }}>
          <Alert severity="warning">
            A payment was previously attempted for this item, but the status of
            this payment is unknown. Please retry it by pressing
            &ldquo;Retry&rdquo;. This will NOT result in a double billing.{" "}
          </Alert>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Button
            onClick={handleRetry}
            variant="contained"
            color="secondary"
            disabled={processState === ProcessState.running}
            fullWidth
          >
            Retry
          </Button>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default AveiroRetryForm;
