import { FormListItem } from "./FormsSelect";

export function sortForms(formList: FormListItem[]): void {
  formList.sort(
    (
      a: { name: string; uid: string; favoritedStatus: string },
      b: { name: string; uid: string; favoritedStatus: string },
    ) => {
      return (
        a.favoritedStatus.localeCompare(b.favoritedStatus) ||
        a.name.localeCompare(b.name)
      );
    },
  );
}
