import { useContext } from "react";
import { SpecialistTemplates } from "../../../../shared/types";
import analyticsLogs from "../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../firebase";
import { InputContext } from "../SecureMessaging/Utilities/InputProvider";

/**Summary
 * Handles interacting with the input context to fill in input text field values
 * and econsult outcome values
 */

const SpecialistTemplatesInputController = (): {
  templateInsertHandler: (template: SpecialistTemplates.Item) => void;
} => {
  const inputContext = useContext(InputContext);

  const templateInsertHandler = (template: SpecialistTemplates.Item) => {
    logAnalyticsEvent(analyticsLogs.specialistTemplates.insertOption, {
      name: template.name,
    });

    //If this component is not in an input context, then these functions just do nothing
    let spacer = "";
    //Only add space if text exists in textbox, and the last character isn't an endline character
    if (
      inputContext.value.length > 0 &&
      inputContext.value[inputContext.value.length - 1] !== "\n"
    )
      spacer = " ";
    inputContext.setValue(`${inputContext.value}${spacer}${template.text}`);
    if (template.outcome !== undefined)
      inputContext.setOutcome(template.outcome);
    if (template.diagnosis !== undefined)
      inputContext.setDiagnosis(template.diagnosis);
  };

  return {
    templateInsertHandler,
  };
};

export default SpecialistTemplatesInputController;
