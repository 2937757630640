import Grid from "@mui/material/Grid2";
import { FC, useEffect } from "react";
import {
  Control,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { TextDropWrapper } from "src/components/useTextDrop";
import { Activity, PatientInfo } from "../../../../../shared/types";
import { TypedFormDateTextField } from "../../../../components/FormInputFields/FormDateTextField";
import { TypedFormTextField } from "../../../../components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "../../../../components/FormInputFields/PhoneInput";
import { ConsultEmail } from "./ConsultEmail";
import { ConsultEmailPatientCheckbox } from "./ConsultEmailPatientCheckbox";
import ConsultOOPInput from "./ConsultOOPInput";
import { ConsultPHN } from "./ConsultPHN";
import ConsultPatientProvinceDropdown from "./ConsultPatientProvinceDropdown";

// TODO rewrite formatting with MaskedInput/Maskito

interface ConsultPatientInfoFormProps {
  control: Control<Activity.ConsultFormFields>;
  trigger: UseFormTrigger<Activity.ConsultFormFields>;
  watch: UseFormWatch<Activity.ConsultFormFields>;
  setValue: UseFormSetValue<Activity.ConsultFormFields>;
  disabled: boolean;
  onSelectPatient: (patientInfo: PatientInfo) => void;
  serviceProvince?: string;
}

// Given a date of birth, calculate the age of the patient
function calculateAge(dob: string) {
  const birthday = new Date(dob);
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
}

// Given a 9 digit PHN with any non-digit characters in it, return a string in XXXXXXXXX format
function formatPhn(phn: string) {
  phn = phn.replace(/\D/g, "");
  return phn;
}

// Given a date in YYYYMMDD format (with whatever non-digit characters in it), return a string in YYYY/MM/DD format
function formatDate(date: string) {
  date = date.replace(/\D/g, "");
  return date.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
}

// Given a 10 digit phone number with any non-digit characters in it, return a string in (XXX) XXX-XXXX format
function formatPhone(phone: string) {
  phone = phone.replace(/\D/g, "");
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

const ConsultPatientInfoForm: FC<ConsultPatientInfoFormProps> = ({
  control,
  trigger,
  watch,
  setValue,
  disabled,
  onSelectPatient,
  serviceProvince,
}) => {
  const dateOfBirth = watch("patientInfo.dateOfBirth");

  // Update Age when date of birth changes
  useEffect(() => {
    const age = calculateAge(dateOfBirth);

    if (dateOfBirth === "") setValue("patientInfo.age", "");
    else if (!isNaN(age) && age >= 0)
      setValue("patientInfo.age", age.toString());
    else setValue("patientInfo.age", "Invalid Date of Birth");
  }, [dateOfBirth, setValue]);

  const handleDropText = (
    fieldName: keyof Activity.ConsultFormFields["patientInfo"],
    text: string,
  ) => {
    if (fieldName === "dateOfBirth") {
      text = formatDate(text);
    } else if (fieldName === "phone") {
      text = formatPhone(text);
    } else if (fieldName === "phn") {
      text = formatPhn(text);
    }
    setValue(`patientInfo.${fieldName}`, text, { shouldValidate: true });
    trigger(`patientInfo.${fieldName}`);
  };

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 4, sm: 2 }}>
        <ConsultPatientProvinceDropdown
          control={control}
          disabled={disabled}
          serviceProvince={serviceProvince}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 10 }}>
        <TextDropWrapper onDropText={(text) => handleDropText("phn", text)}>
          <ConsultPHN
            control={control}
            trigger={trigger}
            disabled={disabled}
            onSelectPatient={onSelectPatient}
          />
        </TextDropWrapper>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextDropWrapper
          onDropText={(text) => handleDropText("firstName", text)}
        >
          <TypedFormTextField
            name="patientInfo.firstName"
            control={control}
            label="Patient First Name"
            rules={{
              required: {
                value: true,
                message: "Patient first name is required",
              },
            }}
          />
        </TextDropWrapper>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextDropWrapper
          onDropText={(text) => handleDropText("lastName", text)}
        >
          <TypedFormTextField
            name="patientInfo.lastName"
            control={control}
            label="Patient Last Name"
            rules={{
              required: {
                value: true,
                message: "Patient last name is required",
              },
            }}
            disabled={disabled}
          />
        </TextDropWrapper>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextDropWrapper onDropText={(text) => handleDropText("email", text)}>
          <ConsultEmail
            control={control}
            trigger={trigger}
            disabled={disabled}
          />
        </TextDropWrapper>
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <ConsultEmailPatientCheckbox control={control} disabled={disabled} />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <TextDropWrapper onDropText={(text) => handleDropText("phone", text)}>
          <TypedPhoneInput
            name="patientInfo.phone"
            control={control}
            label="Patient Phone Number"
          />
        </TextDropWrapper>
      </Grid>
      <Grid container size={{ xs: 12 }} spacing={1}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TextDropWrapper
            onDropText={(text) => handleDropText("dateOfBirth", text)}
          >
            <TypedFormDateTextField
              name="patientInfo.dateOfBirth"
              control={control}
              label="Patient Date of Birth"
              rules={{
                required: {
                  value: true,
                  message: "Patient date of birth is required",
                },
              }}
              disabled={disabled}
            />
          </TextDropWrapper>
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <TypedFormTextField
            name="patientInfo.age"
            control={control}
            label="Patient Age"
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ConsultOOPInput control={control} disabled={disabled} />
      </Grid>
    </Grid>
  );
};

export default ConsultPatientInfoForm;
export { calculateAge };
