import React from "react";
import ResetPassword from "src/components/ResetPassword";
import { buildRoute } from "src/routes/internalHelpers";
import { Login } from "src/views/Auth/components";
import Signup from "src/views/Pages/Signup";
import authChildrenRoutes, {
  AuthRouteNames,
  AuthChildRouteName,
  AUTH_ROUTE,
} from "./authRoutesData";

/**
 * Elements to display for each route
 */
const authRouteElements: {
  [key in AuthChildRouteName]: React.ReactElement;
} = {
  [AuthRouteNames.login]: <Login />,
  [AuthRouteNames.signup]: <Signup />,
  [AuthRouteNames.resetPassword]: <ResetPassword />,
};

/**
 * Creates the route
 */
const authRoute = buildRoute(
  authChildrenRoutes,
  authRouteElements,
  AuthRouteNames,
  AUTH_ROUTE,
);

export default authRoute;
