import {
  Divider,
  Grid2 as Grid,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { format, subMonths } from "date-fns";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../palette";
import { formatDollars } from "../billingFunctions";

type sectionProps = {
  name: string;
  label: string;
  communications: number;
  econsults: number;
  commEarnings: number;
  econsultsEarnings: number;
};

const useStyles = makeStyles()((theme: Theme) => ({
  total: {
    color: palette.orange,
    textAlign: "center",
  },
  monthHeader: {
    color: palette.darkGreen,
    textAlign: "center",
  },
  subtitle: {
    padding: theme.spacing(1),
  },
}));

const TotalPanel: FC<sectionProps> = (props: sectionProps) => {
  const { classes } = useStyles();

  const renderTitle = (inputName: string) => {
    const currentDate = new Date();
    const currMonthName = format(currentDate, "MMMM");
    const prevMonthName = format(subMonths(currentDate, 1), "MMMM");
    if (inputName == "current") {
      return (
        <Typography variant="h5" className={classes.monthHeader}>
          {currMonthName}
        </Typography>
      );
    }
    if (inputName == "past") {
      return (
        <Typography variant="h5" className={classes.monthHeader}>
          {prevMonthName}
        </Typography>
      );
    }
    if (inputName == "ytd") {
      return (
        <Typography variant="h5" className={classes.monthHeader}>
          YTD
        </Typography>
      );
    }
    return null;
  };

  return (
    <Paper variant="outlined">
      <Grid container justifyContent="space-evenly">
        <Grid size={{ xs: 12 }}>{renderTitle(props.name)}</Grid>
        <Grid size={{ xs: 12 }}>
          <Divider />
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Typography variant="h4" className={classes.total}>
            Total <br /> $
            {formatDollars(props.econsultsEarnings + props.commEarnings)}
          </Typography>
        </Grid>
        <Grid size={{ xs: 8 }}>
          <Typography variant="subtitle1" color="primary">
            {props.econsults} <b>Consults</b> earning $
            {formatDollars(props.econsultsEarnings)} <br />
          </Typography>
          <Typography variant="subtitle1" color="primary">
            {props.communications} <b>Comunications</b> earning $
            {formatDollars(props.commEarnings)}
            <br />
          </Typography>
        </Grid>

        <Grid size={{ xs: 12 }}>
          <Typography
            variant="subtitle2"
            color="primary"
            className={classes.subtitle}
          >
            {props.label}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default TotalPanel;
