import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIconOutlined from "@mui/icons-material/EditOutlined";
import Button from "@mui/material/Button";
import { ReactElement, useState } from "react";

type UseEditButtonsReturn = {
  EditButton: ReactElement;
  isEditing: boolean;
};
interface UseEditButtonsProps {
  unsavedChanges: boolean;
  alwaysEnableEditing?: boolean;
}
const useEditButtons: (props: UseEditButtonsProps) => UseEditButtonsReturn = ({
  unsavedChanges,
  alwaysEnableEditing = false,
}) => {
  const [isEditing, setIsEditing] = useState(alwaysEnableEditing);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  return {
    EditButton: (
      <Button
        onClick={handleEdit}
        disabled={isEditing && unsavedChanges}
        startIcon={
          isEditing ? (
            <ArrowBackIcon color="primary" />
          ) : (
            <EditIconOutlined color="primary" />
          )
        }
      >
        {isEditing ? "Done" : "Edit"}
      </Button>
    ),
    isEditing,
  };
};

export default useEditButtons;
