import {
  PaperModal,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import SendIcon from "@mui/icons-material/Send";
import { Grid2 as Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FC, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { InviteCodes } from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import analyticsLogs from "../../../analyticsLogs";
import { TypedEmailInput } from "../../../components/FormInputFields/EmailInput";
import { TypedFormTextArea } from "../../../components/FormInputFields/FormTextArea";
import { TypedFormTextField } from "../../../components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "../../../components/FormInputFields/PhoneInput";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";
import useCms from "../../../utils/useCms";
import InformationBox from "../Signup/Helpers/Container/InformationBox";

const useStyles = makeStyles()(() => ({
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

function generateDefaultPersonalMessage(
  defaultMessage: string | undefined,
  inviteeFirstName: string,
  inviteeLastName: string,
  inviterFirstName: string,
  inviterLastName: string,
) {
  let message = defaultMessage ?? "";

  // Replace all by setting global flag. replaceAll is not supported in all browsers
  message = message.replace(/\$\{inviteeFirstName\}/g, inviteeFirstName);
  message = message.replace(/\$\{inviteeLastName\}/g, inviteeLastName);
  message = message.replace(/\$\{inviterFirstName\}/g, inviterFirstName);
  message = message.replace(/\$\{inviterLastName\}/g, inviterLastName);
  const lines = message.split("\\n");

  return lines.join("\n");
}

const defaultValues: InviteCodes.InvitedUserFormInfo = {
  firstName: "",
  lastName: "",
  email: "",
  fax: "",
  clinicName: "",
  city: "",
  personalMessage: generateDefaultPersonalMessage("", "", "", "", ""),
};

interface InviteAColleagueProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const InviteAColleague: FC<InviteAColleagueProps> = ({ show, setShow }) => {
  const { classes } = useStyles();

  const authContext = useContext(AuthContext);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { dirtyFields, isDirty },
  } = useForm<InviteCodes.InvitedUserFormInfo>({
    mode: "onTouched",
    defaultValues,
  });
  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
    errorHandler,
  } = useProcessState({ logAnalyticsEvent });
  const sendInvite = fbFunctions.httpsCallable("invite-inviteAColleague");
  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const content = useCms({ cmsId: "inviteAColleague" });

  useEffect(() => {
    if (dirtyFields["personalMessage"] !== true && content.isLoaded())
      setValue(
        "personalMessage",
        generateDefaultPersonalMessage(
          content.getValue<string>("defaultPersonalMessage"),
          firstName,
          lastName,
          authContext.profile?.firstName ?? "",
          authContext.profile?.lastName ?? "",
        ),
      );
  }, [firstName, lastName, authContext.profile, content]);

  useEffect(() => {
    if (processState === ProcessState.success && isDirty)
      setProcessState(ProcessState.idle);
  }, [isDirty]);

  const handleClose = () => {
    if (isDirty) {
      if (
        window.confirm(
          "Are you sure you want to exit? Your changes will not be saved.",
        )
      ) {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  const onSubmit = (data: InviteCodes.InvitedUserFormInfo) => {
    setProcessState(ProcessState.running);
    if (data.email === "" && data.fax === "") {
      setProcessErrorMessage("You must provide either an email or fax number.");
      setProcessState(ProcessState.error);
      return;
    }

    sendInvite(data)
      .then(() => {
        logAnalyticsEvent(analyticsLogs.inviteAColleauge.invite.success);
        setProcessState(ProcessState.success);
        resetForm();
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error sending invite",
          analyticsLog: analyticsLogs.inviteAColleauge.invite.fail,
        });
      });
  };

  const resetForm = () => {
    reset(defaultValues);
  };

  const onError = () => {
    setProcessErrorMessage("Check form for errors.");
    setProcessState(ProcessState.error);
  };

  const isDisabled = () => {
    return processState === ProcessState.running;
  };

  return (
    <PaperModal
      show={show}
      setShow={handleClose}
      containerClassName={classes.modal}
      flexWidth
      flexHeight
      enablePadding
    >
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <fieldset disabled={isDisabled()}>
          <Grid container spacing={1}>
            <Grid size={{ xs: 12 }}>
              <Typography variant="h5">Invite a Colleague</Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedFormTextField
                name="firstName"
                control={control}
                label="First Name *"
                disabled={isDisabled()}
                rules={{
                  required: { value: true, message: "First name is required." },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedFormTextField
                name="lastName"
                control={control}
                label="Last Name *"
                disabled={isDisabled()}
                rules={{
                  required: { value: true, message: "Last name is required." },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <InformationBox>
                Provide an email or a fax number of the Family Physician, NP, or
                Specialist to send the invite to. At least one is required.
              </InformationBox>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedEmailInput
                name="email"
                control={control}
                label="Email"
                disabled={isDisabled()}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedPhoneInput
                name="fax"
                control={control}
                label="Fax"
                disabled={isDisabled()}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedFormTextField
                name="clinicName"
                control={control}
                label="Clinic Name"
                disabled={isDisabled()}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TypedFormTextField
                name="city"
                control={control}
                label="City"
                disabled={isDisabled()}
                rules={{
                  required: { value: true, message: "City is required." },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <InformationBox>
                Your personal message will be sent to your colleague. You can
                edit this message below:
              </InformationBox>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <TypedFormTextArea
                name="personalMessage"
                label="Personal Message"
                control={control}
                initRows={3}
                disabled={isDisabled()}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <ProcessStatus
                state={processState}
                setState={setProcessState}
                errorMessage={processErrorMessage}
                successMessage={"Invite sent."}
              />
            </Grid>
            <Grid>
              <Button
                type="submit"
                color="primary"
                disabled={isDisabled()}
                startIcon={<SendIcon color="primary" />}
              >
                Invite
              </Button>
            </Grid>
          </Grid>
        </fieldset>
      </form>
    </PaperModal>
  );
};

export default InviteAColleague;
