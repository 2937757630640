import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { HeadCell, Order, SortableKeys } from "../types";

interface BillingTableHeadProps {
  headCells: HeadCell[];
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: SortableKeys,
  ) => void;
  order: Order;
  orderBy: string;
}

const useStyles = makeStyles()((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  sortingBy: {
    color: theme.palette.primary.main,
  },
  notSortingBy: {
    color: theme.palette.grey[600],
  },
}));

const BillingTableHead: FC<BillingTableHeadProps> = ({
  headCells,
  order,
  orderBy,
  onRequestSort,
}) => {
  const { classes } = useStyles();

  const createSortHandler =
    (property: SortableKeys) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={"normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {orderBy === headCell.id ? (
                <>
                  <span className={classes.sortingBy}>{headCell.label}</span>
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                </>
              ) : (
                <>
                  <span className={classes.notSortingBy}>{headCell.label}</span>
                </>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
export default BillingTableHead;
