import { Theme } from "@mui/material/styles";

const sharedStyles = (
  theme: Theme,
): {
  boxBorderColor: { borderColor: string };
  boxPadding: { padding: string };
} => {
  return {
    boxBorderColor: {
      borderColor: theme.palette.grey[300],
    },
    boxPadding: {
      padding: theme.spacing(1),
    },
  };
};

export default sharedStyles;
