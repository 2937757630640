import { Activity } from "../../../../../shared/types";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import AddLinks from "../../../../components/AddLinks";
import { formatTimeLong } from "../../../../models/formatTime";

import { FC } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  userActionText: {
    textAlign: "center",
    fontSize: "0.9em",
  },
  messageText: {
    zIndex: 100,
    position: "relative",
  },
}));

interface UserActionMessageProps {
  message: Activity.Message;
  sentByUser: Activity.ActivityUser | undefined;
  nameColor: string;
}

const UserActionMessage: FC<UserActionMessageProps> = ({
  message,
  sentByUser,
  nameColor,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.userActionText}>
      <Grid size={{ xs: 12 }}>
        <Typography style={{ color: nameColor }}>
          {sentByUser?.firstName ?? ""} {sentByUser?.lastName ?? ""}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="subtitle1">
          {formatTimeLong(message.sentAt.toDate())}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }} className={cx(classes.messageText)}>
        <Typography variant="subtitle1">
          <AddLinks text={message.message} />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default UserActionMessage;
