import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { useContext, useEffect, useState } from "react";
import {
  Activity,
  Specialist,
  UserProfile,
} from "../../../../../../shared/types";
import { AuthContext } from "../../../../../AuthProvider";
import analyticsLogs from "../../../../../analyticsLogs";
import useAITranslation from "../../../../../components/useAITranslation";
import { logAnalyticsEvent } from "../../../../../firebase";
import { ActivityContext } from "../../Inbox/Contexts/ActivityProvider";
import { SpecialistContext } from "../../Utilities/SpecialistProvider";
import {
  ImageAnnotationItem,
  ImageAnnotationSelectedDict,
  getImagesToLabel,
  shouldShowImageAnnotation,
  submitLabels,
} from "./EconsultImageAnnotationModel";

type EconsultImageAnnotationControllerReturn = {
  imagesToLabel: ImageAnnotationItem[];
  specialty: string;
  expandAnnotation: boolean;
  showAnnotation: boolean;
  confirmAIPredictionsHandler: (items: ImageAnnotationSelectedDict) => void;
  labelImagesHandler: (
    items: ImageAnnotationSelectedDict,
    labels: string[],
  ) => void;
  ignoreImagesHandler: (items: ImageAnnotationSelectedDict) => void;
  startLabellingHandler: () => void;
  stopLabellingHandler: () => void;
  annotationProcess: ProcessState;
  annotationError: string;
};

const EconsultImageAnnotationController =
  (): EconsultImageAnnotationControllerReturn => {
    const authContext = useContext(AuthContext);
    const specialistContext = useContext(SpecialistContext);
    const activityContext = useContext(ActivityContext);

    const [expandAnnotation, setExpandAnnotation] = useState(false);
    const [showAnnotation, setShowAnnotation] = useState(false);

    const [imagesToLabel, setImagesToLabel] = useState<ImageAnnotationItem[]>(
      [],
    );

    const { processState, setProcessState, processErrorMessage, errorHandler } =
      useProcessState({ logAnalyticsEvent });

    const { checkAILabelExists } = useAITranslation({});

    const initialLoad = (
      activity: Activity.Activity,
      econsult: Activity.Econsult,
      specialist: Specialist.Profile,
      profile: UserProfile,
    ) => {
      shouldShowImageAnnotation(
        activity,
        econsult,
        specialist,
        profile,
        authContext.uid,
      ).then((allow) => {
        if (allow) {
          return getImagesToLabel(econsult).then((newImages) => {
            setImagesToLabel(newImages);
            if (newImages.length > 0) setShowAnnotation(true);
          });
        } else {
          setShowAnnotation(false);
        }
      });
    };

    const confirmAIPredictionsHandler = (
      items: ImageAnnotationSelectedDict,
    ) => {
      // Apply AI predictions to the selected images
      const newImagesToLabel = [...imagesToLabel];
      Object.keys(items).forEach((id) => {
        const idx = newImagesToLabel.findIndex((item) => item.id === id);
        if (idx !== -1)
          newImagesToLabel[idx].doctorLabels =
            newImagesToLabel[idx].aiPrediction;
      });
      setImagesToLabel(newImagesToLabel);
    };

    const labelImagesHandler = (
      items: ImageAnnotationSelectedDict,
      labels: string[],
    ) => {
      // Apply labels to the selected images
      const newImagesToLabel = [...imagesToLabel];
      Object.keys(items).forEach((id) => {
        const idx = newImagesToLabel.findIndex((item) => item.id === id);
        if (idx !== -1) newImagesToLabel[idx].doctorLabels = labels;
      });
      setImagesToLabel(newImagesToLabel);
    };

    const ignoreImagesHandler = (items: ImageAnnotationSelectedDict) => {
      // Apply Exclude to the selected images
      labelImagesHandler(items, ["Exclude"]);
    };

    const startLabellingHandler = () => {
      setExpandAnnotation(true);
    };

    const stopLabellingHandler = () => {
      setExpandAnnotation(false);

      setProcessState(ProcessState.running);
      submitLabels(activityContext.econsult, imagesToLabel, checkAILabelExists)
        .then((econsultLabels) => {
          return activityContext.updateEconsult({
            imageLabels: econsultLabels,
          });
        })
        .then(() => {
          setProcessState(ProcessState.idle);
          logAnalyticsEvent(analyticsLogs.imageAnnotation.econsult.save);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error saving labels",
            analyticsLog: analyticsLogs.imageAnnotation.econsult.saveFail,
          });
        });
    };
    useEffect(() => {
      //Make sure things are initialized, then load
      if (
        specialistContext.specialist !== undefined &&
        authContext.profile !== undefined
      ) {
        if (imagesToLabel.length === 0) {
          initialLoad(
            activityContext.sharedActivity,
            activityContext.econsult,
            specialistContext.specialist,
            authContext.profile,
          );
        }
      }
    }, [
      activityContext.sharedActivity,
      activityContext.econsult,
      specialistContext.specialist,
      authContext.profile,
    ]);

    return {
      imagesToLabel,
      specialty: activityContext.econsult.specialty,
      expandAnnotation,
      showAnnotation,
      confirmAIPredictionsHandler,
      labelImagesHandler,
      ignoreImagesHandler,
      startLabellingHandler,
      stopLabellingHandler,
      annotationProcess: processState,
      annotationError: processErrorMessage,
    };
  };

export default EconsultImageAnnotationController;
