import Typography from "@mui/material/Typography";

import { Activity } from "../../../../../shared/types";
import CopyToClipboard from "./CopyToClipboard";
import EconsultCard from "./EconsultCard";

import { FC } from "react";
import { calculateAge } from "../../Consult/PatientInfoForm/ConsultPatientInfoForm";

interface EconsultPatientProps {
  econsult: Activity.Econsult;
}

const EconsultPatient: FC<EconsultPatientProps> = ({ econsult }) => {
  return (
    <EconsultCard title="Patient">
      <CopyToClipboard
        formattedText={
          `${econsult.patientFirstName} ${econsult.patientLastName}\n` +
          `PHN: ${econsult.phn}\n` +
          `${econsult.oop === false ? "" : `Out of Province\n`}` +
          (econsult.patientEmail === ""
            ? ""
            : `Email: ${econsult.patientEmail}\n`) +
          (econsult.patientPhone === ""
            ? ""
            : `Phone: ${econsult.patientPhone}\n`) +
          (econsult.patientDateOfBirth !== "" &&
          econsult.patientDateOfBirth !== undefined
            ? `Date of Birth: ${econsult.patientDateOfBirth}`
            : "")
        }
      />

      <Typography>
        {econsult.patientFirstName} {econsult.patientLastName}
      </Typography>
      <Typography>PHN: {econsult.phn}</Typography>
      {econsult.oop !== false && <Typography>Out of Province</Typography>}
      {econsult.patientEmail !== "" && (
        <Typography>Email: {econsult.patientEmail}</Typography>
      )}
      {econsult.patientPhone !== "" && (
        <Typography>Phone: {econsult.patientPhone}</Typography>
      )}
      {/* Check if undefined, since old econsults won't have it. */}
      {econsult.patientDateOfBirth !== "" &&
        econsult.patientDateOfBirth !== undefined && (
          <Typography>Date of Birth: {econsult.patientDateOfBirth}</Typography>
        )}
      {econsult.patientDateOfBirth !== "" &&
        econsult.patientDateOfBirth !== undefined && (
          <Typography>
            Age: {calculateAge(econsult.patientDateOfBirth)}
          </Typography>
        )}
    </EconsultCard>
  );
};

export default EconsultPatient;
