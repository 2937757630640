import { PaperModal } from "@alethea-medical/alethea-components";
import React from "react";

import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  modal: {
    padding: theme.spacing(8, 2, 4, 2),
  },
}));

interface AgreementModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  children: React.ReactNode;
}

const AgreementModal: React.FC<AgreementModalProps> = ({
  show,
  setShow,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <>
      <PaperModal
        className={classes.modal}
        show={show}
        setShow={setShow}
        enablePadding
      >
        {children}
      </PaperModal>
    </>
  );
};

export default AgreementModal;
