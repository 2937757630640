import AttachmentIcon from "@mui/icons-material/Attachment";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { FC } from "react";
import MessageListItem from "../../../components/MessageListItem";
import MessageSubheader from "./MessageSubheader";
import { PatientMessage } from "./types";

import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

interface PatientMessageListItemProps {
  message: PatientMessage;
  selected: boolean;
  setSelected: (id: string, checked: boolean) => void;
  openMessage: (id: string) => void;
}

const PatientMessageListItem: FC<PatientMessageListItemProps> = ({
  message,
  selected,
  setSelected,
  openMessage,
}) => {
  const { classes } = useStyles();
  return (
    <MessageListItem
      selected={selected}
      setSelected={setSelected}
      openMessage={openMessage}
      id={message.id}
      read={message.read}
      primary={message.subject}
      secondary={<MessageSubheader message={message} />}
    >
      <>
        {message?.messageMediaURIs?.length > 0 && (
          <AttachmentIcon className={classes.icon} color="primary" />
        )}
        {!message.read && (
          <FiberManualRecordIcon className={classes.icon} color="secondary" />
        )}
      </>
    </MessageListItem>
  );
};

export default PatientMessageListItem;
