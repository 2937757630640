import { MutableRefObject, useEffect, useRef, useState } from "react";

type BlockDragControllerReturn = {
  mouseDownHandler: () => void;
  isDragging: boolean;
};
interface BlockDragControllerProps {
  /** Ref for the index containing the current index of the drag */
  currentDragIndexRef: MutableRefObject<number>;
  /** Ref for the second index used when dragging using the block option */
  currentDragEndIndexRef?: MutableRefObject<number>;
  /** Optionally provide a ref for the starting index if the value needs to be exposed */
  startingIndexRef?: MutableRefObject<number>;
  /** Pixels per index. ie dragging x pixels results in y change in index */
  pxPerIndex: number;
  /** Handler when drag is finished. Drag reverse is true if start index > end index */
  dragFinishedHandler: (dragReverse?: boolean) => void;
  /** Don't allow dragging above this index */
  maxDragIndex?: number;
  /** Don't allow dragging below this index */
  minDragIndex?: number;
  dragContainerRef: MutableRefObject<HTMLDivElement | null>;
}

const BlockDragController = ({
  currentDragIndexRef,
  currentDragEndIndexRef,
  startingIndexRef,
  pxPerIndex,
  dragFinishedHandler,
  maxDragIndex,
  minDragIndex,
  dragContainerRef,
}: BlockDragControllerProps): BlockDragControllerReturn => {
  const indexOnStartDrag = startingIndexRef ?? useRef<number>(0);
  const [mouseY, setMouseY] = useState<number>(0);
  const [mouseYOnDrag, setMouseYOnDrag] = useState<number | undefined>(
    undefined,
  );
  const [scrollYOnDrag, setScrollYOnDrag] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  const mouseDownHandler = () => {
    // Initialize the drag start position with the current position
    indexOnStartDrag.current = currentDragIndexRef.current;

    // Initialize our scroll position
    if (dragContainerRef.current !== null)
      setScrollYOnDrag(dragContainerRef.current.scrollTop);

    setIsDragging(true);
  };

  const onDragFinish = () => {
    setIsDragging(false);
    setMouseYOnDrag(undefined);
    dragFinishedHandler(currentDragIndexRef.current < indexOnStartDrag.current);
  };

  const applyMinAndMaxIdx = (idx: number): number => {
    if (maxDragIndex !== undefined) {
      idx = Math.min(maxDragIndex, idx);
    }
    if (minDragIndex !== undefined) {
      idx = Math.max(minDragIndex, idx);
    }
    return idx;
  };

  useEffect(() => {
    //Only listen to mouse events after user has clicked on a time slot to improve performance
    if (isDragging) {
      const handleWindowMouseMove = (event: MouseEvent) => {
        let scrollDiff = 0;
        if (dragContainerRef.current !== null) {
          // Calculate scroll difference from where we started dragging to where
          // we are now to account for scrolling
          scrollDiff = dragContainerRef.current.scrollTop - scrollYOnDrag;
        }

        // When mouse moves, update mouse y position.
        setMouseY(event.clientY + scrollDiff);

        // Also if we haven't set mouseYOnMouseDown, then set it to the current value.
        // This will set it to the y value when the mouse is pressed
        if (mouseYOnDrag === undefined) {
          setMouseYOnDrag(event.clientY);
        }
      };

      if (dragContainerRef.current !== null) {
        dragContainerRef.current.addEventListener(
          "mousemove",
          handleWindowMouseMove,
        );

        return () => {
          if (dragContainerRef.current !== null) {
            dragContainerRef.current.removeEventListener(
              "mousemove",
              handleWindowMouseMove,
            );
          }
        };
      }
    }
  }, [isDragging, mouseYOnDrag]);

  useEffect(() => {
    //Only listen to mouse events after user has clicked on a time slot to improve performance
    if (isDragging) {
      const handleWindowMouseUp = () => {
        onDragFinish();
      };
      window.addEventListener("mouseup", handleWindowMouseUp);

      return () => {
        window.removeEventListener("mouseup", handleWindowMouseUp);
      };
    }
  }, [isDragging]);

  useEffect(() => {
    if (isDragging && mouseYOnDrag !== undefined) {
      // Calculate how many indexes were moved based on how far the mouse is away from the original position
      let idx =
        Math.floor((mouseY - mouseYOnDrag) / pxPerIndex) +
        indexOnStartDrag.current;
      // Clamp the index between the allowed min and max (if provided)
      idx = applyMinAndMaxIdx(idx);

      // If the index hasn't changed, then return
      const startDelta = idx - currentDragIndexRef.current;
      if (startDelta === 0) return;

      // currentDragEndIndexRef is defined if we are moving the whole block instead of just one edge
      if (currentDragEndIndexRef !== undefined) {
        // Determine the new end idx based on how far the start index moved
        let endIdx = currentDragEndIndexRef.current + startDelta;
        endIdx = applyMinAndMaxIdx(endIdx);

        // Find how far the end index has moved
        const endDelta = endIdx - currentDragEndIndexRef.current;

        // Apply the smaller delta between endDelta and startDelta to guarantee we don't move past the allowed min and max
        let deltaToUse: number;
        if (endDelta < startDelta) {
          deltaToUse = endDelta;
        } else {
          deltaToUse = startDelta;
        }
        idx = currentDragIndexRef.current + deltaToUse;
        endIdx = currentDragEndIndexRef.current + deltaToUse;

        currentDragEndIndexRef.current = endIdx;
      }
      currentDragIndexRef.current = idx;
    }
  }, [isDragging, mouseYOnDrag, mouseY]);

  return {
    mouseDownHandler,
    isDragging,
  };
};

export default BlockDragController;
