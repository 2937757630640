import { useScreenSize } from "@alethea-medical/alethea-components";
import { FC } from "react";
import SpecialistTemplatesView from "./SpecialistTemplatesView";

const EditSpecialistTemplatesPage: FC = () => {
  const { heightMinusAppBar } = useScreenSize({});
  return (
    <SpecialistTemplatesView
      height={heightMinusAppBar}
      alwaysEnableEditing={true}
    />
  );
};

export default EditSpecialistTemplatesPage;
