import { useCallback, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

interface useQueryParamRoutingProps {
  paramName: string;
}

/** Utility hook to add or remove a parameter from query parameters without affecting other parameters
 * Ex: https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345
 * useQueryParamRouting({ paramName: "draftId" })
 * Call addOrRemoveFromQueryParams(09876) results in https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345&draftId=09876
 * Call addOrRemoveFromQueryParams(undefined) results in https://entid-dev-9c711.web.app/dashboard/consult?jwt=12345
 */
const useQueryParamRouting = ({ paramName }: useQueryParamRoutingProps) => {
  const [, setSearchparams] = useSearchParams();
  // Need to use location as a workaround. For some reason when a query param
  // is removed but the navigation is blocked, it still shows up as undefined.
  // useLocation for some reason gets the most recent value of searchParams
  const { search } = useLocation();

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    undefined,
  );

  const browserSearchParam =
    new URLSearchParams(search).get(paramName) || undefined;
  if (browserSearchParam !== currentValue) {
    setCurrentValue(browserSearchParam);
  }

  /**
   * Adds or removes the provided parameter as paramName to the query parameters (https://<route>>/?<other params>&<paramName>=<param>)
   * @param paramValue Parameter to add. If undefined the parameter will be removed
   */
  const addOrRemoveFromQueryParams = (paramValue: string | undefined) => {
    setCurrentValue(paramValue);
    setSearchparams((params) => {
      if (paramValue === undefined) {
        params.delete(paramName);
        return params;
      }
      params.append(paramName, paramValue);
      return params;
    });
  };

  return {
    addOrRemoveFromQueryParams,
    currentValue,
  };
};

export default useQueryParamRouting;
