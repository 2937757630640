import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(8),
    flexGrow: 1,
    padding: theme.spacing(0, 2),
  },
}));

type ColumnViewContainerProps = {
  children: React.ReactNode;
};

const ColumnViewContainer: React.FunctionComponent<
  ColumnViewContainerProps
> = ({ children }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid size={{ xs: 12, sm: 10, md: 8, lg: 4 }}>{children}</Grid>
      </Grid>
    </div>
  );
};

export default ColumnViewContainer;
