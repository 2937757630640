import AddIcon from "@mui/icons-material/Add";
import { FC, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  mouseListener: {
    width: "100%",
    height: "100%",
  },
  add: {
    border: "1px solid #ddd",
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
}));

interface TimeRowAddProps {
  index: number;
  mouseDownHandler: (index: number) => void;
  hideButton: boolean;
}

const TimeRowAdd: FC<TimeRowAddProps> = ({
  index,
  mouseDownHandler,
  hideButton,
}) => {
  const { classes } = useStyles();
  const [hover, setHover] = useState(false);

  // Use callback to reduce number of recreations of this function (since there are a lot of TimeRowAdd components)
  const onMouseEnter = useCallback(() => {
    setHover(true);
  }, [setHover]);

  const onMouseLeave = useCallback(() => {
    setHover(false);
  }, [setHover]);

  const onMouseDown = useCallback(() => {
    mouseDownHandler(index);
  }, [index, mouseDownHandler]);

  return (
    <div
      className={classes.mouseListener}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
    >
      {hover && !hideButton && <AddIcon className={classes.add} />}
    </div>
  );
};

export default TimeRowAdd;
