import { projectEnv } from "../../../firebase";

/** Clover API Key - This key is public so it is OK to expose in the frontend/github */
export const cloverApiKey =
  projectEnv === "prod"
    ? "a5126d0a1328dde5cfe7a7c0eee4370b"
    : "55c6fbf294da44dcf194e1839f8783ae";
/** Clover SDK URL - Load this script to access the Clover SDK */
export const cloverSdkUrl =
  projectEnv === "prod"
    ? "https://checkout.clover.com/sdk.js"
    : "https://checkout.sandbox.dev.clover.com/sdk.js";
