import { FC } from "react";
import { Control } from "react-hook-form";
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";
import ResponseTime from "./ResponseTime";
import {
  SpecialistSelectItem,
  SpecialtySubsiteSpecialistSelectControlType,
  noSpecialistUid,
} from "./SpecialtySubsiteSpecialistSelectController";

interface SpecialistSelectProps {
  control: Control<SpecialtySubsiteSpecialistSelectControlType>;
  specialists: SpecialistSelectItem[];
  disabled: boolean;
  loading?: boolean;
}

const SpecialistSelect: FC<SpecialistSelectProps> = ({
  control,
  specialists,
  disabled,
  loading,
}) => {
  return (
    <TypedFormSelect
      name="specialist"
      control={control}
      options={specialists}
      label={"Specialist"}
      rules={{
        required: { value: true, message: "A specialist is required" },
        validate: {
          isSpecialistSelected: (specialist: SpecialistSelectItem) => {
            return specialist.uid === noSpecialistUid
              ? "A specialist is required"
              : undefined;
          },
          isOnVacation: (specialist: SpecialistSelectItem) => {
            return specialist.labelV2.onVacation
              ? "Specialist is on vacation, please save draft and wait for return or select another specialist"
              : undefined;
          }, // return message if true, undefined if false
        },
      }}
      disableWhenOne={
        specialists.length === 1 && specialists[0].uid === noSpecialistUid
      }
      getOptionLabel={(specialist: SpecialistSelectItem) => {
        if (loading) {
          return "Loading Specialists";
        }
        // return html
        return (
          <>
            {specialist.labelV2.name}
            {specialist.labelV2.location && (
              <i>{" - " + specialist.labelV2.location}</i>
            )}
          </>
        );
      }}
      getOptionLabelSecondary={(specialist: SpecialistSelectItem) => {
        if (loading) {
          return "";
        }
        return (
          <>
            <span>
              {specialist.labelV2.title ?? ""}
              {specialist.labelV2.title &&
                specialist.labelV2.credentials &&
                " | "}
              {specialist.labelV2.credentials ?? ""}
            </span>
            {specialist.labelV2.onVacation && (
              <>
                <br />
                <span>
                  On Vacation{" "}
                  {specialist.labelV2.vacationEndDateMili &&
                    " - Return date: " +
                      new Date(
                        parseInt(specialist.labelV2.vacationEndDateMili),
                      ).toDateString()}
                </span>
              </>
            )}
          </>
        );
        // convert end date to date then to string
      }}
      getOptionValue={(specialist: SpecialistSelectItem) => {
        return specialist;
      }}
      getEndAdornment={(specialist: SpecialistSelectItem) =>
        ResponseTime({
          responseTimeMillis: specialist.avgResponseTime,
          fontSize: "small",
        })
      }
      disabled={disabled}
    />
  );
};

export default SpecialistSelect;
