import { GridSize } from "@mui/material";
import { FormHelperText } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { Controller } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import palette from "../../../palette";
import RoundedButton from "../../RoundedButton";
import { ControlledInputProps } from "../types";

const useStyles = makeStyles()((theme: Theme) => ({
  buttonUnselected: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
      backgroundColor: theme.palette.grey[400],
    },
  },
  buttonSelected: {
    backgroundColor: palette.orange,
  },
}));

interface FormRoundedRadioButtonsProps extends ControlledInputProps {
  options: string[];
  breakpoints?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize };
  displayOption?: (option: string) => string;
  onClick?: (option: string) => void;
}

const FormRoundedRadioButtons = ({
  name,
  control,
  defaultValue,
  options,
  disabled,
  errorTextPadding,
  breakpoints = {},
  displayOption,
  onClick,
  rules,
}: FormRoundedRadioButtonsProps) => {
  const { classes, cx } = useStyles();
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue !== undefined ? defaultValue : false}
      rules={rules}
      render={({ field, fieldState }) => {
        const handleClickOption = (optionSelected: string) => {
          field.onChange(optionSelected);
        };

        return (
          <Grid container spacing={2}>
            {options.map((option, index) => (
              <Grid
                key={`rounded_radio_button_${option}_${index}`}
                size={{
                  ...breakpoints,
                  xs:
                    breakpoints?.xs ??
                    (Math.floor(12 / options.length) as GridSize),
                }}
              >
                <RoundedButton
                  className={cx({
                    [classes.buttonSelected]: option === field.value,
                    [classes.buttonUnselected]: option !== field.value,
                  })}
                  disabled={disabled}
                  onClick={() => {
                    handleClickOption(option);
                    onClick?.(option);
                  }}
                  fullWidth
                >
                  {displayOption ? displayOption(option) : option}
                </RoundedButton>
              </Grid>
            ))}
            <Grid size={{ xs: 12 }}>
              <FormHelperText error>
                {errorTextPadding
                  ? fieldState.error?.message
                    ? fieldState.error.message
                    : " "
                  : fieldState.error?.message}
              </FormHelperText>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default FormRoundedRadioButtons;
