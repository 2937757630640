import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";

interface FormDatePickerProps extends ControlledInputProps {
  rules?: any;
  label?: string;
  labelAsPlaceHolder?: boolean;
  errors?: any;
  required?: boolean;
  noLabel?: boolean;
}

const FormDatePicker = (props: FormDatePickerProps) => {
  const {
    name,
    control,
    defaultValue,
    rules,
    label,
    labelAsPlaceHolder,
    noLabel,
    ...rest
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ field }) => (
        <DatePicker
          label={
            !noLabel
              ? labelAsPlaceHolder && label
                ? label
                : "Select a date"
              : ""
          }
          openTo="year"
          open={open}
          onOpen={() => setOpen(true)} //Open dialog whenever the input field is clicked
          onClose={() => setOpen(false)}
          format="yyyy-MM-dd"
          value={field.value}
          onChange={field.onChange}
          {...rest}
        />
      )}
    />
  );
};

export default FormDatePicker;
