/**
 * Returns error message for phone number input field if invalid
 * @param phone Phone number to validate
 * @returns Undefined if valid or empty, error message if invalid
 */
const isValidPhoneInput = (phone: string): string | undefined => {
  return isPhoneNumber(phone) || phone === ""
    ? undefined
    : "Phone number is invalid";
};

/**
 * Validates a phone number
 * @param phoneNumber Phone number to validate
 * @returns true if phone number matches either (xxx) xxx-xxxx or xxxxxxxxxx format
 */
export function isPhoneNumber(phoneNumber: string): boolean {
  // Remove all non-numeric characters for raw number validation
  const numericOnly = phoneNumber.replace(/\D/g, "");

  // Check for either formatted version or exactly 10 digits
  return (
    /^\(\d{3}\) \d{3}-\d{4}$/.test(phoneNumber) || /^\d{10}$/.test(numericOnly)
  );
}

export default isValidPhoneInput;
