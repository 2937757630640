import { ProcessStatus } from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import { Control, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";
import SpecialistSelect from "./SpecialistSelect";
import SpecialtySubsiteSpecialistSelectController, {
  SpecialtySubsiteSpecialistSelectControlType,
} from "./SpecialtySubsiteSpecialistSelectController";
import SubsiteSelect from "./SubsiteSelect";

interface SpecialtySubsiteSpecialistSelectProps {
  control: Control<SpecialtySubsiteSpecialistSelectControlType>;
  setValue: UseFormSetValue<SpecialtySubsiteSpecialistSelectControlType>;
  trigger: UseFormTrigger<SpecialtySubsiteSpecialistSelectControlType>;
  isDisabled?: boolean;
}

const SpecialtySubsiteSpecialistSelect = ({
  control,
  setValue,
  trigger,
  isDisabled = false,
}: SpecialtySubsiteSpecialistSelectProps) => {
  const {
    routeTree,
    specialists,
    subsites,
    processState,
    processErrorMessage,
    isRunning,
  } = SpecialtySubsiteSpecialistSelectController({
    control,
    setValue,
    trigger,
  });

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <TypedFormSelect
          name="specialty"
          control={control}
          label="Specialty"
          options={routeTree ? Object.keys(routeTree).sort() : []}
          disabled={isDisabled || isRunning()}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <SubsiteSelect
          control={control}
          subsites={subsites}
          disabled={isDisabled || isRunning()}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <SpecialistSelect
          control={control}
          specialists={specialists}
          disabled={isDisabled || isRunning()}
          loading={isRunning()}
        />
      </Grid>
    </Grid>
  );
};

export default SpecialtySubsiteSpecialistSelect;
