import Alert from "@mui/material/Alert";
import { FC, useContext } from "react";
import { Control, useWatch } from "react-hook-form";
import { Activity } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";

interface ConsultPatientProvinceDropdownProps {
  control: Control<Activity.ConsultFormFields>;
  disabled: boolean;
  serviceProvince?: string;
}

const ConsultPatientProvinceDropdown: FC<
  ConsultPatientProvinceDropdownProps
> = ({ control, disabled, serviceProvince }) => {
  const authContext = useContext(AuthContext);
  const locationIdx = useWatch({
    control,
    name: "locationIdx",
    defaultValue: 0,
  });
  const patientProvince = useWatch({
    control,
    name: "patientProvince",
    defaultValue:
      authContext?.profile?.locations[locationIdx]?.province ?? "AB",
  });

  const isPatientQCAndBillingAB = () => {
    return (
      patientProvince === "QC" &&
      (serviceProvince === "AB" ||
        authContext?.profile?.locations[locationIdx]?.province === "AB")
    );
  };

  return (
    <>
      <TypedFormSelect
        control={control}
        name="patientProvince"
        disabled={disabled}
        options={[
          "AB",
          "BC",
          "MB",
          "NB",
          "NL",
          "NT",
          "NS",
          "NU",
          "ON",
          "PE",
          "QC",
          "SK",
          "YT",
        ]}
        rules={{ required: { value: true, message: "Province is required." } }}
      />
      {isPatientQCAndBillingAB() && (
        <Alert severity="warning">
          Patients from Quebec cannot be billed for.
        </Alert>
      )}
    </>
  );
};

export default ConsultPatientProvinceDropdown;
