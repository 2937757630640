import { SignupInfo } from "@alethea-medical/aletheamd-types";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import HelpModal from "../../../../../components/HelpModal";
import sharedStyles from "../../../../../components/shared/sharedStyles";

const useStyles = makeStyles()(() => ({
  ...sharedStyles(),
}));

interface InviteCodeBoxProps {
  control: Control<SignupInfo.SignupInfo>;
  disabled?: boolean;
}

const InviteCodeBox: FC<InviteCodeBoxProps> = ({ control, disabled }) => {
  const { classes } = useStyles();

  const formatInput = (value: string): string => {
    return value.toUpperCase();
  };

  return (
    <>
      <HelpModal
        buttonText="Referral Code"
        helpText={[
          "If you received an invite from a colleague, enter their referral code here.",
        ]}
      />
      <Controller
        name={"inviteCode"}
        control={control}
        render={({ field }) => (
          <TextField
            fullWidth
            {...field}
            onChange={(e) => {
              e.target.value = formatInput(e.target.value);
              field.onChange(e);
            }}
            disabled={disabled}
            className={classes.canDisable}
            variant="outlined"
            InputLabelProps={{
              disableAnimation: true,
            }}
            onSubmit={() => {
              // Remove leading & trailing empty spaces
              field.value = field.value ? field.value.trim() : "";
            }}
          />
        )}
      />
    </>
  );
};

export default InviteCodeBox;
