export function checkBritishColombiaPHN(phn: string): boolean {
  //remove leading zero's
  phn = phn.replace(/\b0+/g, "");

  //change string to number array
  const digitArray = Array.from(
    String(phn)
      .split("")
      .map((phn) => {
        return Number(phn);
      }),
  );

  //first number is always supposed to be 9 for BC PHN
  if (digitArray[0] == 9) {
    //BC PHN is 10 digits long where the last number is the checksum
    if (digitArray.length != 10) {
      return false;
    } else {
      let sum = 0;
      //multiply each number by its weight given its position in the array and insert modded value in place
      for (let i = 1; i < 9; i++) {
        sum += applyWeight(digitArray[i], i) % 11;
      }
      const checkSum = 11 - (sum % 11);

      if (checkSum == digitArray[9]) {
        return true;
      } else {
        return false;
      }
    }
  }

  return false;
}
function applyWeight(num: number, index: number) {
  //index has associated weights that we need to multiply number by
  switch (index) {
    case 1:
      return num * 2;
    case 2:
      return num * 4;
    case 3:
      return num * 8;
    case 4:
      return num * 5;
    case 5:
      return num * 10;
    case 6:
      return num * 9;
    case 7:
      return num * 7;
    case 8:
      return num * 3;
    default:
      return -1;
  }
}
export default checkBritishColombiaPHN;
