import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import MessagingGallery from "../../../components/Gallery/Views/MessagingGallery";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    margin: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(1, 2),
  },
}));

interface AttachmentViewerProps {
  messageMediaURIs: string[];
}

const AttachmentViewer: FC<AttachmentViewerProps> = ({ messageMediaURIs }) => {
  const { classes } = useStyles();

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container className={classes.root} spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Typography component="h1" variant="h5" color="primary">
              Attachments
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <MessagingGallery referralMediaURIs={messageMediaURIs} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AttachmentViewer;
