import { Button, Collapse, Grid2 as Grid, GridSize } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { FC, useCallback } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../palette";
import { UserMediaMetadataSelectedDict } from "../Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../Models/GalleryModel";
import ConfirmDeleteButton from "./ConfirmDeleteButton";
import GalleryGrid from "./GalleryGrid";
import GalleryRenderer from "./GalleryRenderer";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    backgroundColor: palette.lightGreenLight,
  },
  imageContainer: {
    maxHeight: "200px",
    overflowY: "auto",
  },
}));

interface SelectedMediaViewerProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  select: (item: UserMediaMetadataItem) => void;
  unselect: (id: string) => void;
  unselectAll: () => void;
  /** Delete selected media handler. If not provided, delete button won't show */
  deleteSelectedMedia?: () => void;
  /** Multiply grid size to increase size in smaller components. Default 1 */
  gridSizeMultiplier?: 1 | 2;
}

const SelectedMediaViewer: FC<SelectedMediaViewerProps> = ({
  selectedMedia,
  select,
  unselect,
  unselectAll,
  deleteSelectedMedia,
  gridSizeMultiplier = 1,
}) => {
  const { classes } = useStyles();

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        selected={selectedMedia[photo.id] !== undefined}
        enableSelect={true}
        editing={false}
        select={select}
        unselect={unselect}
      />
    ),
    [selectedMedia],
  );

  const getGridSize = (size: number): GridSize => {
    return (size * gridSizeMultiplier) as GridSize;
  };

  return (
    <>
      <Collapse in={Object.keys(selectedMedia).length > 0}>
        <Grid
          container
          className={classes.container}
          alignItems="center"
          spacing={1}
        >
          <Grid>
            <Button variant="outlined" color="primary" onClick={unselectAll}>
              Unselect All
            </Button>
          </Grid>
          {deleteSelectedMedia !== undefined && (
            <Grid>
              <ConfirmDeleteButton
                numToDelete={Object.keys(selectedMedia).length}
                deleteHandler={deleteSelectedMedia}
              />
            </Grid>
          )}
          <Grid size={{ xs: 12 }} className={classes.imageContainer}>
            <GalleryGrid
              photos={Object.values(selectedMedia)}
              galleryRenderer={galleryRenderer}
              breakpoints={{
                xs: getGridSize(3),
                sm: getGridSize(2),
                md: getGridSize(1),
                lg: getGridSize(1),
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default SelectedMediaViewer;
