import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { FC } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "tss-react/mui";
import { FileType } from "../../../shared/types";

const useStyles = makeStyles()(() => ({
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: "16px",
  },
  thumb: {
    display: "inline-flex",
    border: "none",
    marginBottom: "8px",
    marginRight: "8px",
    width: "100px",
    height: "100px",
    padding: "4px",
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: "0",
    overflow: "hidden",
    position: "relative",
  },
  thumbImage: {
    display: "block",
    width: "auto",
    height: "100%",
  },
  thumbVideo: {
    display: "block",
    width: "auto",
    height: " 100%",
  },
  thumbImgRemoveButton: {
    cursor: "pointer",
    position: "absolute",
    left: "0px",
    top: "0px",
    color: "white",
  },
  circleInner: {
    fill: "white",
    position: "absolute",
  },
}));

interface ThumbnailFile {
  fileType: FileType;
  src: string;
  filename: string;
}

interface ThumbnailFileDict {
  [key: string]: ThumbnailFile;
}

interface ThumbnailGridProps {
  files: ThumbnailFileDict;
  handleRemove: (key: string) => void;
}

const ThumbnailGrid: FC<ThumbnailGridProps> = ({ files, handleRemove }) => {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.thumbsContainer}>
        {Object.keys(files).map((key) => {
          const file = files[key];
          if (file.fileType === "image" || file.fileType === "video") {
            return (
              <div className={classes.thumb} key={"thumbnail_" + file.src}>
                <div className={classes.thumbInner}>
                  {file.fileType === "image" && (
                    <img
                      src={file.src}
                      className={classes.thumbImage}
                      alt={`Image ${file.filename}`}
                    />
                  )}
                  {file.fileType === "video" && (
                    <ReactPlayer
                      className={classes.thumbVideo}
                      url={file.src}
                      width="100%"
                      height="100%"
                    />
                  )}

                  <div
                    className={classes.thumbImgRemoveButton}
                    onClick={() => {
                      handleRemove(key);
                    }}
                  >
                    <svg width="25px" height="25px">
                      {/* Circle */}
                      <circle
                        className={classes.circleInner}
                        cx="12.5"
                        cy="12.2"
                        r="9"
                      />
                      <HighlightOffIcon color="primary" />
                    </svg>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};

export default ThumbnailGrid;
