import {
  PaperModal,
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import usePermissions from "../../../../../../components/usePermissions";
import InterpretationsController from "../../Interpretations/InterpretationsController";
import InterpretationsView from "../../Interpretations/InterpretationsView";
import PrevInterpretationsView from "../../Interpretations/PrevInterpretationsView";
import InputContextOutcomesSetter from "../InputContextOutcomesSetter";
import OutcomesController from "../OutcomesController";
import OutcomesInput from "../OutcomesInput";

const useStyles = makeStyles()(() => ({
  header: {
    fontSize: "1.3em",
  },
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

export const defaultOutcome = "Not applicable";

export const outcomes = [
  "No treatment required",
  "Patient to see Specialist",
  "Management or treatment suggested",
  "Treatment suggested prior to Specialist visit",
  "Further investigations suggested",
  "Refer to different specialty",
  "Unknown",
  defaultOutcome,
];

interface EconsultOutcomesModalProps {
  activityId: string;
  showOutcomesMenu: boolean;
  setShowOutcomesMenu: (show: boolean) => void;
}

/**
 * Modal to show econsult outcomes
 */
const EconsultOutcomesModal: FC<EconsultOutcomesModalProps> = ({
  activityId,
  showOutcomesMenu,
  setShowOutcomesMenu,
}) => {
  const { classes } = useStyles();

  const {
    selectedOutcome,
    setSelectedOutcome,
    selectedDiagnosis,
    setSelectedDiagnosis,
    handleSubmitOutcomes,
    processState,
    processErrorMessage,
  } = OutcomesController({});

  InputContextOutcomesSetter({
    setSelectedOutcome,
    setSelectedDiagnosis,
    setShowOutcomesMenu,
  });

  const { granted: billAudiogramGranted } = usePermissions({
    resourceKey: resourceKeys.billAudiogram,
  });
  const { granted: billSinusEndoscopyGranted } = usePermissions({
    resourceKey: resourceKeys.billSinusEndoscopy,
  });

  const interpController = InterpretationsController({ activityId });

  const onSubmit = () => {
    let closeModal = true;
    let waitToClose = false;
    Promise.resolve()
      .then(() => {
        if (
          interpController.billAudiogram ||
          interpController.billSinusEndoscopy
        ) {
          return interpController.bill().then((success) => {
            if (success) {
              // On success, wait 1 second before closing
              closeModal = closeModal && true;
              waitToClose = true;
            } else {
              // On error, don't close
              closeModal = false;
            }
          });
        }
        return Promise.resolve();
      })
      .then(() => {
        return handleSubmitOutcomes().then((success) => {
          if (success) {
            // On success, close modal
            closeModal = closeModal && true;
          } else {
            // On error, don't close
            closeModal = false;
          }
        });
      })
      .then(() => {
        if (closeModal) {
          if (waitToClose) {
            setTimeout(() => {
              setShowOutcomesMenu(false);
            }, 1000);
          } else {
            setShowOutcomesMenu(false);
          }
        }
      });
  };

  const isDisabled = (): boolean => {
    // interpretations are running/success or outcomes are running = disable
    return (
      interpController.processState === ProcessState.running ||
      interpController.processState === ProcessState.success ||
      processState === ProcessState.running
    );
  };

  return (
    <PaperModal
      className={classes.modal}
      show={showOutcomesMenu}
      setShow={setShowOutcomesMenu}
      flexWidth
      flexHeight
      enablePadding
    >
      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Typography className={classes.header}>
            Please confirm the eConsult outcome and diagnosis are correct
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <OutcomesInput
            outcomes={outcomes}
            selectedOutcome={selectedOutcome}
            setSelectedOutcome={setSelectedOutcome}
            selectedDiagnosis={selectedDiagnosis}
            setSelectedDiagnosis={setSelectedDiagnosis}
            handleSubmitOutcomes={handleSubmitOutcomes}
          />
        </Grid>
        {(billAudiogramGranted || billSinusEndoscopyGranted) && (
          <Grid size={{ xs: 12 }}>
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Typography>
                  Did you complete any Interpretations with this Consult?
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InterpretationsView
                  {...interpController}
                  billAudiogramGranted={billAudiogramGranted}
                  billSinusEndoscopyGranted={billSinusEndoscopyGranted}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid size={{ xs: 12 }}>
          <PrevInterpretationsView />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <ProcessStatus
            state={interpController.processState}
            errorMessage={interpController.processErrorMessage}
            successMessage="Interpretations billed."
            loadingMessage="Billing Interpretations..."
          />
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            successMessage="Outcomes saved."
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              setShowOutcomesMenu(false);
            }}
            disabled={isDisabled()}
          >
            Skip
          </Button>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            disabled={isDisabled()}
          >
            Save and Close
          </Button>
        </Grid>
      </Grid>
    </PaperModal>
  );
};

export default EconsultOutcomesModal;
