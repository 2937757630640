import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import Badge from "@mui/material/Badge";
import { SxProps } from "@mui/system";
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { Fcm } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { fbFirestore } from "../../../../firebase";

type UnreadNotificationsCounterProps = {
  children?: ReactNode;
  sx?: SxProps;
};

const UnreadNotificationsCounter: FC<UnreadNotificationsCounterProps> = ({
  children,
  sx,
}) => {
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.uid !== "") {
      return fbFirestore
        .collection(dbNames.notifications)
        .doc(authContext.uid)
        .onSnapshot((snapshot) => {
          if (!snapshot.exists) return;
          const notificationsProfile = snapshot.data() as
            | Fcm.UserNotificationProfile
            | undefined;
          if (!notificationsProfile) return;
          if (typeof notificationsProfile.numUnread !== "number") return;
          setUnreadNotifications(Math.max(notificationsProfile.numUnread, 0));
        });
    }
  }, [authContext.uid]);

  return (
    <Badge
      badgeContent={unreadNotifications}
      color="error"
      max={99}
      overlap="rectangular"
      sx={sx}
    >
      {children}
    </Badge>
  );
};

export default UnreadNotificationsCounter;
