import React, { useEffect, useState } from "react";
import { Activity } from "../../../../../../shared/types";
import useEnableOutcomes from "./useEnableOutcomes";

interface OutcomesContainerProps {
  resourceKey: string;
  activity: Activity.Activity;
  showOutcomesButton: boolean;
  setShowOutcomesButton: (show: boolean) => void;
  children: React.ReactNode;
}

/**
 * Does not render controller and outcomes if user does not have access to outcomes to save memory
 */
const OutcomesContainer: React.FC<OutcomesContainerProps> = ({
  resourceKey,
  activity,
  showOutcomesButton,
  setShowOutcomesButton,
  children,
}) => {
  const [enable, setEnable] = useState(false);

  useEnableOutcomes({ setEnable, activity, resourceKey });

  useEffect(() => {
    setShowOutcomesButton(showOutcomesButton || enable);
  }, [enable]);

  if (enable) {
    return <>{children}</>;
  }
  return null;
};

export default OutcomesContainer;
