import { Activity } from "@alethea-medical/aletheamd-types";
import { PermPhoneMsg, QuestionAnswer } from "@mui/icons-material";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import palette from "../../../../../palette";
import { ActivityItem } from "../../types";

interface LabelContainerProps {
  activity: ActivityItem<Activity.UserActivity>;
  read: boolean;
}

const LabelContainer: FC<LabelContainerProps> = ({ activity, read }) => {
  const color = read ? palette.darkGreen : palette.orange;
  return (
    <Grid container>
      {activity.sharedActivity.activityType === "econsult" && (
        <QuestionAnswer sx={{ color }} />
      )}
      {activity.sharedActivity.activityType === "phoneConsult" && (
        <PermPhoneMsg sx={{ color }} />
      )}
    </Grid>
  );
};

export default LabelContainer;
