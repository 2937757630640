import Typography from "@mui/material/Typography";
import { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ServiceItem } from "../../../../db/Service";
import useConsultFormStyles from "../useConsultFormStyles";
import ServiceCard from "./ServiceCard";

interface ServiceSearchResultsProps {
  searchResults: ServiceItem[];
  noResults: boolean;
  selectService: (serviceId: string) => void;
}

const ServiceSearchResults: FC<ServiceSearchResultsProps> = ({
  searchResults,
  noResults,
  selectService,
}) => {
  const { classes } = useConsultFormStyles();

  //TODO: What do we do in case of rendering error for a service? Log it?
  return (
    <div>
      {searchResults.length === 0 && noResults && (
        <div className={classes.spacing}>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ textAlign: "center" }}
          >
            No services found.
          </Typography>
        </div>
      )}
      {searchResults.length > 0 && (
        <>
          {searchResults.map((serviceItem) => (
            <div className={classes.spacing} key={serviceItem.id}>
              <ErrorBoundary fallback={<></>}>
                <ServiceCard
                  serviceId={serviceItem.id}
                  service={serviceItem.service}
                  selectService={selectService}
                  showStartConsultButton
                />
              </ErrorBoundary>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default ServiceSearchResults;
