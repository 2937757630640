import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import { FC, useCallback, useState } from "react";
import { makeStyles } from "tss-react/mui";
import GalleryController from "../../../components/Gallery/Controllers/GalleryController";
import MediaViewerController from "../../../components/Gallery/Controllers/MediaViewerController";
import SelectGalleryController from "../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../components/Gallery/Models/GalleryModel";
import GalleryControls from "../../../components/Gallery/Views/GalleryControls";
import GalleryRenderer from "../../../components/Gallery/Views/GalleryRenderer";
import GallerySearchBar from "../../../components/Gallery/Views/GallerySearchBar";
import GalleryTimestampBins from "../../../components/Gallery/Views/GalleryTimestampBins";
import MediaViewer from "../../../components/Gallery/Views/MediaViewer/MediaViewer";
import SelectedMediaViewer from "../../../components/Gallery/Views/SelectedMediaViewer";
import AVAGalleryButton from "./AVAGalleryIntegration/AVAGalleryButton";
import UploadFilesModal from "./UploadFilesModal";

const useStyles = makeStyles()((theme: Theme) => ({
  item: {
    marginBottom: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(2),
  },
  hidden: {
    display: "none",
  },
}));

const GalleryView: FC = () => {
  const { classes, cx } = useStyles();

  const {
    // data
    binnedMediaItems,
    // handlers
    loadMoreHandler,
    refreshHandler,
    fetchNewHandler,
    removeMediaItemsHandler,
    modifyItemHandler,
    // states
    searchProps,
    galleryLoadState,
    galleryLoadError,
  } = GalleryController();

  const {
    // data
    selectedMedia,
    // handlers
    selectHandler,
    unselectHandler,
    unselectAllHandler,
    deleteSelectedMediaHandler,
    // states
    editing,
    setEditing,
    selectState,
    selectError,
  } = SelectGalleryController({ removeMediaItems: removeMediaItemsHandler });

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        selected={selectedMedia[photo.id] !== undefined}
        enableSelect={editing}
        editing={editing}
        select={selectHandler}
        unselect={unselectHandler}
        handleOpenMedia={openMediaItemHandler}
      />
    ),
    [selectedMedia, editing],
  );

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <HeightLayout
        height={heightMinusAppBar}
        className={cx(
          {
            [classes.hidden]: openMediaItem !== undefined,
          },
          classes.container,
        )}
      >
        <HeightLayoutChild flexDriver className={classes.item}>
          <GalleryControls
            refresh={refreshHandler}
            editing={editing}
            setEditing={setEditing}
            setShowUploadModal={setShowUploadModal}
          >
            <AVAGalleryButton
              selectedMedia={selectedMedia}
              unselectAll={unselectAllHandler}
            />
          </GalleryControls>
          <ProcessStatus
            state={galleryLoadState}
            errorMessage={galleryLoadError}
          />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.item}>
          <GallerySearchBar searchProps={searchProps} />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.item}>
          <SelectedMediaViewer
            selectedMedia={selectedMedia}
            select={selectHandler}
            unselect={unselectHandler}
            unselectAll={unselectAllHandler}
            deleteSelectedMedia={deleteSelectedMediaHandler}
          />
          <ProcessStatus
            state={selectState}
            errorMessage={selectError}
            successMessage="Files deleted successfully."
          />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven allowOverflowY className={classes.item}>
          <GalleryTimestampBins
            binnedMediaItems={binnedMediaItems}
            galleryRenderer={galleryRenderer}
          />
        </HeightLayoutChild>
        {!searchProps.enableSearch && (
          <HeightLayoutChild flexDriver className={classes.item}>
            <Button
              onClick={loadMoreHandler}
              variant="outlined"
              color="primary"
            >
              Load More
            </Button>
          </HeightLayoutChild>
        )}
      </HeightLayout>
      {openMediaItem !== undefined && (
        <MediaViewer
          height={heightMinusAppBar}
          mediaItem={openMediaItem}
          closeMediaItem={closeMediaItemHandler}
          modifyItemHandler={modifyItemHandler}
          removeMediaItems={removeMediaItemsHandler}
        />
      )}
      <UploadFilesModal
        show={showUploadModal}
        setShow={setShowUploadModal}
        uploadFinishedHandler={fetchNewHandler}
      />
    </>
  );
};

export default GalleryView;
