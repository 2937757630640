import { FC } from "react";
import { Control } from "react-hook-form";
import { Activity } from "../../../../shared/types";
import { TypedLocationDropdown } from "../../../components/LocationDropdown";

interface ConsultLocationSelectorProps {
  control: Control<Activity.ConsultFormFields>;
  onSelectLocation: (locationIdx: number) => void;
  disabled: boolean;
  setValue?: (name: keyof Activity.ConsultFormFields, value: any) => void;
}

const ConsultLocationSelector: FC<ConsultLocationSelectorProps> = ({
  control,
  onSelectLocation,
  disabled,
  setValue,
}) => {
  return (
    <TypedLocationDropdown
      name="locationIdx"
      label="Referring Clinic"
      control={control}
      disabled={disabled}
      onSelectLocation={onSelectLocation}
      setValue={setValue}
    />
  );
};

export default ConsultLocationSelector;
