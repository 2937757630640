import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import { DatePicker } from "@mui/x-date-pickers";
import { addMonths, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { FC } from "react";

interface MonthPickerProps {
  fromDate: Date;
  setMonth: (from: Date, to: Date) => void;
  label?: string;
}

const MonthPicker: FC<MonthPickerProps> = ({ fromDate, setMonth, label }) => {
  const handleChangeDate = (date: Date | null) => {
    if (date === null) {
      return;
    }
    const from = startOfMonth(date);
    const to = endOfMonth(date);
    setMonth(from, to);
  };

  return (
    <>
      <IconButton
        onClick={() => {
          handleChangeDate(subMonths(fromDate, 1));
        }}
        size="large"
      >
        <ChevronLeft />
      </IconButton>

      <DatePicker
        views={["month", "year"]}
        label={label ? label : "Month"}
        value={fromDate}
        onChange={handleChangeDate}
      />
      <IconButton
        onClick={() => {
          handleChangeDate(addMonths(fromDate, 1));
        }}
        size="large"
      >
        <ChevronRight />
      </IconButton>
    </>
  );
};

export default MonthPicker;
