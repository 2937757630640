//https://www.npmjs.com/package/react-process-string
import Linkify from "linkify-react";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  link: {
    wordBreak: "break-all", //Break links wherever to stop width of parent div expanding
  },
}));

interface AddLinksProps {
  text: string;
}

const AddLinks: FC<AddLinksProps> = ({ text }) => {
  const { classes } = useStyles();
  const linkProps = {
    onClick: (event: any) => {
      if (
        !window.confirm(`Open this link in a new tab?\n${event.target.href}`)
      ) {
        event.preventDefault();
      }
    },
  };
  return (
    <Linkify
      options={{
        className: classes.link,
        attributes: linkProps,
        target: "_blank",
        rel: "noopener noreferrer",
      }}
    >
      {text}
    </Linkify>
  );
};

export default AddLinks;
