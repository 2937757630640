import {
  HeightLayout,
  HeightLayoutChild,
  ProcessState,
  ProcessStatus,
  thinScrollbar,
} from "@alethea-medical/alethea-components";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import DraftsListController from "./Controllers/DraftsListController";
import DraftSearchBar from "./DraftSearchBar";
import DraftsToolbar from "./DraftsToolbar";
import DraftListItem from "./ListItem/DraftListItem";

const sortDraftEntriesByCreated = (
  a: [string, Activity.ConsultDraft],
  b: [string, Activity.ConsultDraft],
) => {
  if (a[1].created < b[1].created) return 1;
  else if (a[1].created > b[1].created) return -1;
  else return 0;
};

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  box: {
    borderColor: theme.palette.grey[300],
  },
  threadSidebar: {
    padding: 0,
  },
  threadListItem: {
    padding: 0,
  },
  threadList: {
    padding: 0,
  },
  hidden: {
    display: "none",
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
  },
  inboxControlsBar: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  tabSwitcher: {
    paddingLeft: theme.spacing(1),
  },
  centerListItem: {
    justifyContent: "center",
  },
  noSavedDrafts: {
    textAlign: "center",
  },
}));

interface DraftsListViewProps {
  height: number;
  approvalsList?: boolean;
}

const DraftsListView: FC<DraftsListViewProps> = ({ height, approvalsList }) => {
  const { classes } = useStyles();

  const {
    drafts,
    searchDrafts,
    selectedItems,
    allSelected,
    selectAllHandler,
    listItemSelectHandler,
    openDraftHandler,
    openApprovalHandler,
    discardHandler,
    enableSearch,
    clearSearch,
    runSearch,
    processState,
    processErrorMessage,
    setProcessState,
  } = DraftsListController(
    approvalsList ? "drafts_pending_approval" : "user_drafts",
  );

  return (
    <List className={classes.threadSidebar}>
      <HeightLayout height={height}>
        <HeightLayoutChild flexDriver>
          <ListItem divider>
            <DraftSearchBar
              enableSearch={enableSearch}
              runSearch={runSearch}
              clearSearch={clearSearch}
              placeholderText={"Search by Subject"}
            />
          </ListItem>
          <ProcessStatus
            state={processState}
            setState={setProcessState}
            errorMessage={processErrorMessage}
            useSnackbar={true}
          />
          <ListItem className={classes.toolbar} divider>
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid>
                <Checkbox
                  checked={allSelected}
                  onChange={(e) => {
                    selectAllHandler(e.target.checked);
                  }}
                />
              </Grid>
              <Grid>
                <DraftsToolbar
                  atLeastOneSelected={selectedItems.length > 0}
                  moreThanOneSelected={selectedItems.length > 1}
                  discard={discardHandler}
                />
              </Grid>
            </Grid>
          </ListItem>
        </HeightLayoutChild>
        <HeightLayoutChild
          flexDriven
          allowOverflowY
          className={classes.thinScrollbar}
        >
          {Object.keys(drafts).length == 0 &&
            processState != ProcessState.running && (
              <ListItem className={classes.centerListItem}>
                <Typography className={classes.noSavedDrafts}>
                  You have no{" "}
                  {approvalsList ? "pending approvals" : "saved drafts"}
                </Typography>
              </ListItem>
            )}
          {Object.entries(enableSearch ? searchDrafts : drafts)
            .sort(sortDraftEntriesByCreated)
            .map(([draftId, draft]) => (
              <DraftListItem
                key={`draft_list_item_${draftId}`}
                draft={draft}
                draftId={draftId}
                openDraft={
                  approvalsList ? openApprovalHandler : openDraftHandler
                }
                selected={selectedItems.includes(draftId)}
                setSelected={listItemSelectHandler}
              />
            ))}
        </HeightLayoutChild>
      </HeightLayout>
    </List>
  );
};

export default DraftsListView;
