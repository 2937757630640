import {
  ErrorHandlerOptions,
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { aletheaMDCrypto } from "@alethea-medical/aletheamd-crypto";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { Activity } from "@alethea-medical/aletheamd-types";
import { GridSize } from "@mui/material/Grid2";
import firebase from "firebase/compat/app";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "src/AuthProvider";
import analyticsLogs from "src/analyticsLogs";
import usePermissions from "src/components/usePermissions";
import useQueryParamRouting from "src/components/useQueryParamRouting/useQueryParamRouting";
import { fbFirestore, logAnalyticsEvent } from "src/firebase";
import devConsole from "src/models/devConsole";
import {
  ActivityDict,
  ActivityItem,
} from "src/views/Pages/SecureMessaging/types";
import InboxModel from "../Models/InboxModel";
import ListItemSelectController from "./ListItemSelectController";

const loadMoreAmount = 10;

type InboxControllerReturn<MetadataType> = {
  // State
  sortedActivities: ActivityItem<MetadataType>[];
  sortedEconsults: (Activity.Econsult | null)[];
  activities: ActivityDict<MetadataType>;
  updateActivitiesInState: (
    newActivities: {
      id: string;
      sharedActivity?: Activity.Activity;
      metadataActivity?: MetadataType;
    }[],
  ) => void;
  removeActivitiesFromState: (activityIds: string[]) => void;
  loadMoreHandler: () => void;
  disableLoadMoreLoading: boolean;
  disableLoadMoreEndOfResults: boolean;
  calculateGridSize: (selectedOptions: string[]) => Record<string, GridSize>;
  refillActivities: (loadCount: number) => void;

  // Permissions
  permissions: ViewPermissions;

  // Select
  searchParams: { params: string[]; status: string };
  updateSearchParams: (params: string[], status: string) => void;
  selectedActivities: string[];
  allSelected: boolean;
  selectAllHandler: (selectAll: boolean) => void;
  activitySelectHandler: (id: string, checked: boolean) => void;
  unselectAllHandler: () => void;

  // Search
  enableSearch: boolean;
  runSearch: (
    params: string[],
    status: string,
    initialSearch?: boolean,
    searchFilters?: string[],
  ) => void;
  clearSearch: () => void;
  fetchSubjectLineFilters: () => Promise<string[]>;
  saveSubjectLineFilters: (searchFilters: string[]) => Promise<void>;

  //  Process state
  processState: ProcessState;
  setProcessState: (state: ProcessState) => void;
  processErrorMessage: string;
  errorHandler: (options: ErrorHandlerOptions) => void;
  isActivityOpen: boolean;
  openActivityHandler: (activityId: string) => void;
};

interface InboxControllerProps<MetadataType> {
  inboxModel: InboxModel;
  metadataFieldToSortBy: keyof MetadataType;
  folder?: Activity.UserActivityFolder;
  statuses?: string[];
  setShowTabs?: (show: boolean) => void;
}

interface ViewPermissions {
  hasEditStatusPermissions: boolean;
}

function InboxController<MetadataType>({
  inboxModel,
  metadataFieldToSortBy,
  statuses,
  folder,
  setShowTabs,
}: InboxControllerProps<MetadataType>): InboxControllerReturn<MetadataType> {
  const authContext = useContext(AuthContext);

  const [disableLoadMore, setDisableLoadMore] = useState<boolean>(false);

  // List
  const [newActivityQueue, setNewActivityQueue] = useState<{
    items: ActivityItem<MetadataType>[];
    dontUpdateOldest?: boolean;
  }>({ items: [] });
  const [activities, setActivities] = useState<ActivityDict<MetadataType>>({});
  const [mostRecentFetchTime, setMostRecentFetchTime] =
    useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
  const [oldestActivityTime, setOldestActivityTime] =
    useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());

  // #region Select Activities
  const {
    selectedItems: selectedActivities,
    allSelected,
    selectAllHandler,
    listItemSelectHandler: activitySelectHandler,
    unselectAllHandler,
  } = ListItemSelectController({ listItemDict: activities });
  // #endregion Select Activities

  const [enableSearch, setEnableSearch] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<{
    params: string[];
    status: string;
  }>({ params: [], status: "" });

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });
  const permissions: ViewPermissions = {
    hasEditStatusPermissions: hasEditStatusPermissions,
  };

  // Calculates grid Size based on the selected options
  // Returns a Record of column to its GridSize
  const calculateGridSize = (
    selectedOptions: string[],
  ): Record<string, GridSize> => {
    const maxGridSize = 11 - (hasEditStatusPermissions ? 2 : 0);
    const baseGridSize = Math.floor(maxGridSize / selectedOptions.length);
    let extraSpaces = maxGridSize % selectedOptions.length;
    const gridSizes = selectedOptions.map((option) => ({
      option,
      size: baseGridSize,
    }));
    // Also calculate grid size for the status column, were users that share a clinic can see the status of the econsult
    if (hasEditStatusPermissions) {
      gridSizes.push({
        option: "Status",
        size: 2,
      });
    }
    const priorityOrder = [
      "Consultant Name",
      "Referrer Name",
      "Patient Name",
      "Subsite",
      "Specialty",
      "PHN",
    ];

    for (const priority of priorityOrder) {
      const gridItem = gridSizes.find((item) => item.option === priority);
      if (gridItem && extraSpaces > 0) {
        gridItem.size += 1;
        extraSpaces -= 1;
      }
    }

    return gridSizes.reduce(
      (acc, item) => {
        acc[item.option] = item.size as GridSize;
        return acc;
      },
      {} as Record<string, GridSize>,
    );
  };

  //#region Handling new activities
  // Handle new activities coming in and add to activities dict. Function when activities updates
  const newActivityQueueHandler = (
    newItems: ActivityItem<MetadataType>[],
    dontUpdateOldest?: boolean,
  ) => {
    if (newItems.length > 0) {
      // Update activities dictionary
      const newActivities = { ...activities };

      newItems.forEach((item) => {
        if (!dontUpdateOldest) {
          const timestamp = item.metadataActivity[
            metadataFieldToSortBy
          ] as unknown as firebase.firestore.Timestamp;
          if (timestamp < oldestActivityTime) setOldestActivityTime(timestamp);
        }

        devConsole.log(
          `${
            newActivities[item.id] !== undefined ? "Update" : "New"
          } activity: ${item.id}. ${
            item.sharedActivity.recentMessage.readBy.includes(authContext.uid)
              ? "Read"
              : "Unread"
          }`,
        );
        newActivities[item.id] = item;
      });
      setActivities(newActivities);

      //Update last fetched time, so that we only fetch new messages later than this time
      //This reduces the number of calls to this function
      setMostRecentFetchTime(firebase.firestore.Timestamp.now());
    }
  };

  // When activities are added to the queue, add them to the activity dictionary
  // A queue is used instead of adding directly to the dictionary, so that any time new activities are received, it will always use the most up to date version of the dictionary
  // This avoid stupid react bugs like using an out-dated version of activities because setActivities was called after the original function call
  useEffect(() => {
    newActivityQueueHandler(
      newActivityQueue.items,
      newActivityQueue.dontUpdateOldest,
    );
  }, [newActivityQueue]);

  /**
   * Parse new user activities coming in as firestore snapshot, fetch activity document, and add to activities dict
   * @param docs Documents from snapshot
   */
  const newUserActivitiesSnapshotHandler = (
    docs:
      | firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
      | firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>[],
  ): Promise<void> => {
    return Promise.all(
      docs.map((metadataDoc) => {
        // Foreach user activity, fetch the shared activity document
        return fbFirestore
          .collection(dbNames.activities)
          .doc(metadataDoc.id)
          .get()
          .then((sharedActivityDoc) => {
            return {
              id: metadataDoc.id,
              sharedActivity: sharedActivityDoc.data() as Activity.Activity,
              metadataActivity: metadataDoc.data() as MetadataType,
            };
          });
      }),
    ).then((result) => {
      setNewActivityQueue({ items: result });
    });
  };
  //#endregion

  //#region Loading activities
  const loadActivities = (
    fetchEarlierThan: firebase.firestore.Timestamp,
    fetchOverdue?: boolean,
    searchParams?: { params: string[]; status: string },
    loadCount: number = loadMoreAmount,
  ) => {
    setProcessState(ProcessState.running);
    setDisableLoadMore(true);

    return inboxModel
      .loadActivities(fetchEarlierThan, {
        statuses,
        folder,
        amountToLoad: loadCount,
        fetchOverdue,
        searchParams,
      })
      .then(({ results, didReturnResults }) => {
        if (!didReturnResults) setDisableLoadMore(true);

        return newUserActivitiesSnapshotHandler(results).then(() => {
          setProcessState(ProcessState.idle);
          return didReturnResults;
        });
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error loading messages",
        });
        return false;
      })
      .then((didReturnResults) => {
        setDisableLoadMore(!didReturnResults);
      });
  };

  const refillActivities = (loadCount: number) => {
    loadActivities(
      firebase.firestore.Timestamp.now(),
      true,
      searchParams,
      loadCount,
    );
  };

  //Load more activities, with timestamp less than the oldest message we currently have
  const loadMoreHandler = async () => {
    const subjectLineFilters = await fetchSubjectLineFilters();
    if (enableSearch) {
      runSearch(
        searchParams.params,
        searchParams.status,
        false,
        subjectLineFilters,
        folder,
      );
    } else {
      loadActivities(oldestActivityTime);
    }
  };

  // Reload activities when tab changes (or on first load)
  useEffect(() => {
    resetActivities();
    loadActivities(firebase.firestore.Timestamp.now(), true, searchParams);
  }, [folder, JSON.stringify(statuses)]);

  // Listen for new messages and put into newActivityQueue
  useEffect(() => {
    if (enableSearch) return;
    if (authContext.uid === "") return;

    // Subscribe to new activities where lastMessageReceivedAt flag is newer than the time since we last fetched
    const unsubscribe = inboxModel.subscribeToActivityMetadata(
      mostRecentFetchTime,
      newUserActivitiesSnapshotHandler,
      { folder },
    );

    //Call unsubscribe to cleanup previous render
    return () => {
      unsubscribe();
    };
  }, [enableSearch, authContext.uid, folder, mostRecentFetchTime]);

  const resetActivities = () => {
    setActivities({});
    unselectAllHandler();
    setOldestActivityTime(firebase.firestore.Timestamp.now());
  };

  //Updates activity in state by adding them to new queue. Oldest activity timestamp will not be updated
  // When updating, only update with changes. Use previous sharedActivity or metadataActivity if not provided
  // If not provided, and no previous value exists for either sharedActivity or metadataActivity, then the activity will not be added to the queue
  const updateActivitiesInState = (
    newActivities: {
      id: string;
      sharedActivity?: Activity.Activity;
      metadataActivity?: MetadataType;
    }[],
  ) => {
    const newActivityToQueue = newActivities
      .map((a) => {
        return {
          id: a.id,
          sharedActivity: a.sharedActivity ?? activities[a.id]?.sharedActivity,
          metadataActivity:
            a.metadataActivity ?? activities[a.id]?.metadataActivity,
        };
      })
      .filter(
        (a) =>
          a.sharedActivity !== undefined && a.metadataActivity !== undefined,
      );

    if (newActivityToQueue.length === 0) return;

    setNewActivityQueue({ items: newActivityToQueue, dontUpdateOldest: true });
  };

  const removeActivitiesFromState = (activityIds: string[]) => {
    const newActivities = { ...activities };
    // Remove activities from list that have been moved
    activityIds.forEach((activityId) => {
      delete newActivities[activityId];
    });
    setActivities(newActivities);
  };
  //#endregion

  // #region Open Activity

  // Open activity
  // Add query parameters to url to open activity
  const { addOrRemoveFromQueryParams, currentValue: openActivityId } =
    useQueryParamRouting({ paramName: "econsultId" });

  const openActivityHandler = (activityId: string) => {
    addOrRemoveFromQueryParams(activityId);
  };
  // Hide inbox/archive tab changer when activity is open
  useEffect(() => {
    if (setShowTabs !== undefined && !enableSearch) {
      setShowTabs(openActivityId === undefined);
    }
  }, [openActivityId]);

  // #endregion Open Activity

  // #region Search
  const updateSearchParams = (params: string[], status: string) => {
    setSearchParams({ params, status });
  };

  const runSearch = (
    params: string[],
    status: string,
    initialSearch?: boolean,
    searchFilters?: string[],
    folder?: Activity.UserActivityFolder,
  ) => {
    if (searchFilters) saveSubjectLineFilters(searchFilters);

    if (params.length === 0 && status === "") return;

    setProcessState(ProcessState.running);

    // Next time user presses load More, then run search instead using saved parameters
    if (initialSearch) {
      setSearchParams({ params, status });
      logAnalyticsEvent(analyticsLogs.secureMessaging.search);
      resetActivities();
    }
    const fetchBefore = initialSearch
      ? firebase.firestore.Timestamp.now()
      : oldestActivityTime;

    setDisableLoadMore(true);
    inboxModel
      .searchActivities(params, status, fetchBefore, 25, folder)
      .then(({ results, didReturnResults }) => {
        return newUserActivitiesSnapshotHandler(results).then(() => {
          if (initialSearch) {
            setEnableSearch(true);
          }
          setProcessState(ProcessState.idle);
          return didReturnResults;
        });
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: `Error running search`,
        });
        return false;
      })
      .then((didReturnResults: boolean) => {
        setDisableLoadMore(!didReturnResults);
      });
  };

  const saveSubjectLineFilters = async (searchFilters: string[]) => {
    if (authContext?.user) {
      const userRef = fbFirestore
        .collection(dbNames.users)
        .doc(authContext.user.uid);
      try {
        await userRef.update({
          searchFilters: searchFilters,
        });
      } catch (error) {
        console.error("Error saving search filters: ", error);
      }
    }
  };

  const fetchSubjectLineFilters = async () => {
    if (authContext?.user) {
      const userRef = fbFirestore
        .collection(dbNames.users)
        .doc(authContext.user.uid);
      try {
        const doc = await userRef.get();
        if (doc.exists) {
          const data = doc.data();
          const defaultSearchFilters = ["Specialty", "Subsite", "PHN"];
          return data?.searchFilters ?? defaultSearchFilters;
        }
      } catch (error) {
        console.error("Error fetching search filters: ", error);
      }
    }
    return [];
  };

  const clearSearch = () => {
    setSearchParams({ params: [], status: "" });
    setEnableSearch(false);
    resetActivities();
    loadActivities(firebase.firestore.Timestamp.now(), true);
  };

  // #endregion Search

  // #region Sorting
  const [sortedActivities, setSortedActivities] = useState<
    ActivityItem<MetadataType>[]
  >([]);
  const [sortedEconsults, setSortedEconsults] = useState<
    (Activity.Econsult | null)[]
  >([]);

  /**
   * Sort function to sort by recent message from newest to oldest
   */
  const sortOnRecentMessage = (
    a: ActivityItem<MetadataType>,
    b: ActivityItem<MetadataType>,
  ) => {
    return (
      b.sharedActivity.recentMessage.sentAt.toMillis() -
      a.sharedActivity.recentMessage.sentAt.toMillis()
    );
  };

  /**
   * Sort activities by overdue first, and then by recent message
   */
  const sortOnOverdueAndRecentMessage = useCallback(
    (a: ActivityItem<MetadataType>, b: ActivityItem<MetadataType>) => {
      // Case as UserActivity to check overdue. Will be undefined for clinic activity which is fine for this comparison
      const aOverdue = (a.metadataActivity as unknown as Activity.UserActivity)
        .overdue;
      const bOverdue = (b.metadataActivity as unknown as Activity.UserActivity)
        .overdue;
      if (aOverdue && !bOverdue) {
        return -1;
      } else if (!aOverdue && bOverdue) {
        return 1;
      } else {
        //If both include, or both don't include, use recent message to compare
        return sortOnRecentMessage(a, b);
      }
    },
    [],
  );

  const getDecryptedEconsults = async (
    sortedActivities: ActivityItem<MetadataType>[],
  ) => {
    setProcessState(ProcessState.running);
    const decryptedEconsults = await Promise.all(
      sortedActivities.map(async (activity) => {
        const econsultObj = await activity.sharedActivity.econsult?.get();
        if (activity.sharedActivity.econsult) {
          try {
            const decrypted = await aletheaMDCrypto.encryptDecryptEconsult(
              econsultObj.data() as Activity.Econsult,
              fbFirestore
                .collection(dbNames.system)
                .doc("keystore")
                .collection("keys")
                .doc("firestoreData"),
              { decrypt: true, inplace: false },
            );
            return decrypted;
          } catch (error) {
            console.error(`Error decrypting econsult ${activity.id}:`, error);
            return null;
          }
        }
        return null;
      }),
    );
    setSortedEconsults(decryptedEconsults);
    setProcessState(ProcessState.idle);
  };

  //Sort activities and store in state
  useEffect(() => {
    const sorted = Object.values(activities).sort(
      sortOnOverdueAndRecentMessage,
    );
    setSortedActivities(sorted);
    getDecryptedEconsults(sorted);
  }, [activities]);
  // #endregion Sort

  return {
    // State
    sortedActivities,
    sortedEconsults,
    activities,
    updateActivitiesInState,
    removeActivitiesFromState,
    loadMoreHandler,
    disableLoadMoreLoading:
      disableLoadMore && processState === ProcessState.running,
    disableLoadMoreEndOfResults:
      disableLoadMore && processState !== ProcessState.running,
    calculateGridSize,
    refillActivities,

    // Permissions
    permissions,

    isActivityOpen: openActivityId !== undefined,
    openActivityHandler,

    // Select
    selectedActivities,
    allSelected,
    selectAllHandler,
    activitySelectHandler,
    unselectAllHandler,

    // Search
    searchParams,
    updateSearchParams,
    enableSearch,
    runSearch,
    clearSearch,
    fetchSubjectLineFilters,
    saveSubjectLineFilters,

    //  Process state
    processState,
    setProcessState,
    processErrorMessage,
    errorHandler,
  };
}

export default InboxController;
