import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { FC, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import NotificationCenterController from "./NotificationCenterController";
import NotificationCenterToolbar from "./NotificationCenterToolbar";
import NotificationListItem from "./NotificationListItem";
import NotificationView from "./NotificationView/NotificationView";
import NotificationViewController from "./NotificationView/NotificationViewController";

const useStyles = makeStyles()(() => ({
  hidden: {
    display: "none",
  },
}));

interface NotificationCenterProps {
  height: number;
  setShowTabs: (showTabs: boolean) => void;
}

const NotificationCenter: FC<NotificationCenterProps> = ({
  height,
  setShowTabs,
}) => {
  const { classes, cx } = useStyles();

  const {
    sortedNotifications,
    markAllAsReadHandler,
    openNotificationHandler,
    closeNotificationHandler,
    loadMoreHandler,
    refresh,
    updateNotification,
    disableLoadMoreLoading,
    disableLoadMoreEndOfResults,
    loadingMessage,
    processState,
    setProcessState,
    processErrorMessage,
    errorHandler,
  } = NotificationCenterController();

  const { openNotification } = NotificationViewController({
    updateNotification,
    errorHandler,
  });

  useEffect(() => {
    setShowTabs(openNotification === undefined);
  }, [openNotification]);

  return (
    <>
      <div
        className={cx({
          [classes.hidden]: openNotification !== undefined,
        })}
      >
        <HeightLayout height={height}>
          <HeightLayoutChild flexDriver>
            {/* Toolbar */}
            <NotificationCenterToolbar
              markAllAsReadHandler={markAllAsReadHandler}
              refresh={refresh}
            />
          </HeightLayoutChild>
          <HeightLayoutChild flexDriven allowOverflowY>
            {/* Notifications */}
            {sortedNotifications.map((n) => (
              <NotificationListItem
                key={`notification_list_item${n.id}`}
                id={n.id}
                notification={n.notification}
                openNotification={openNotificationHandler}
              />
            ))}
            <ListItemButton
              onClick={loadMoreHandler}
              disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
              alignItems="center"
              divider
            >
              <ListItemIcon>
                <ArrowDownwardIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  disableLoadMoreLoading
                    ? "Loading"
                    : disableLoadMoreEndOfResults
                      ? "End of Notifications"
                      : "Load More"
                }
              />
            </ListItemButton>
          </HeightLayoutChild>
        </HeightLayout>
      </div>
      {openNotification && (
        <NotificationView
          notification={openNotification}
          goBack={closeNotificationHandler}
        />
      )}
      <ProcessStatus
        state={processState}
        setState={setProcessState}
        errorMessage={processErrorMessage}
        useSnackbar
        loadingMessage={loadingMessage}
      />
    </>
  );
};

export default NotificationCenter;
