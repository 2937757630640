import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";

const FormNumberField = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  errorTextPadding,
  ...rest
}: ControlledInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...rest}
          onChange={(e) => {
            if (/^[0-9]*$/.test(e.target.value)) field.onChange(e);
          }}
          label={label}
          error={fieldState.error !== undefined}
          helperText={
            errorTextPadding
              ? fieldState.error?.message
                ? fieldState.error.message
                : " "
              : fieldState.error?.message
          }
          variant="outlined"
          fullWidth
          margin="dense"
          autoComplete="off"
          InputLabelProps={{
            disableAnimation: true,
          }}
          inputProps={{
            "aria-autocomplete": "none",
            list: "autocompleteOff",
          }}
        />
      )}
    />
  );
};

export default FormNumberField;
