import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Plan, Role, SignupInfo } from "@alethea-medical/aletheamd-types";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import queryString from "query-string";
import { FC, Reducer, useEffect, useReducer } from "react";
import { useForm, useFormState } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import LeavePagePrompt from "../../../components/LeavePagePrompt";
import { fbAuth, logAnalyticsEvent, projectEnv } from "../../../firebase";
import globalStrings from "../../../globalStrings";
import serverRequest from "../../../models/serverRequest";
import SignupForm from "./Content/SignupForm";
import SignupSuccessPage from "./Helpers/Info/SignupSuccessPage";
import PageHeader from "./Helpers/Styling/page/PageHeader";
import PageHeaderFooterBars from "./Helpers/Styling/page/PageHeaderFooterBars";

export const PROVINCE_WATCH_KEY = "province" as const;
export const PLAN_WATCH_KEY = "selectedPlan" as const;
export const ROLE_WATCH_KEY = "selectedRole" as const;

// #region Reducer Helpers
// ========================================================================================================

export const SignupActionNames = {
  SET_FORWARDING_TO_ONBOARDING: "setForwardingToOnboarding",
  SET_PROCEED_WITHOUT_PRAC_ID: "setProceedWithoutPracId",
  SET_IS_PRAC_ID_VALID: "setIsPracIdValid",
  SET_SAVED_SIGNATURE: "setSavedSignature",
  SET_AGREEMENT_BLOB: "setAgreementBlob",
} as const;

type AvailableSignupActions = {
  [SignupActionNames.SET_FORWARDING_TO_ONBOARDING]: boolean;
  [SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID]: boolean;
  [SignupActionNames.SET_IS_PRAC_ID_VALID]: boolean;
  [SignupActionNames.SET_SAVED_SIGNATURE]: string | undefined;
  [SignupActionNames.SET_AGREEMENT_BLOB]: Blob | undefined;
};

type Action<T, U> = {
  type: T;
  payload: U;
};

// Creates a union type of all possible actions
export type SignupAction = {
  [K in keyof AvailableSignupActions]: Action<K, AvailableSignupActions[K]>; // Creates a map from available actions to actions with the correct type
}[keyof AvailableSignupActions]; // Turns the map into a union type

export const createSignupReducerAction = <
  T extends keyof AvailableSignupActions,
>(
  type: T,
  payload: AvailableSignupActions[T],
): { type: T; payload: AvailableSignupActions[T] } => ({
  type,
  payload,
});
// #endregion =============================================================================================

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(10, 8, 10, 8),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(8, 2, 8, 2),
    },
  },
  signupIcon: {
    //Center the icon vertically
    display: "block",
    margin: "auto",
  },
  pageContainer: {
    position: "relative",
    height: "100%",
    minHeight: "100vh",
    width: "100%",
  },
}));

export type SignupState = {
  isPracIdValid: boolean;
  proceedWithoutPracId: boolean;
  agreementBlob?: Blob;
  savedSignature?: string;
  forwardingToOnboarding: boolean;
};

const Signup: FC = () => {
  const location = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const form = useForm({
    mode: "onTouched",
    defaultValues: SignupInfo.defaultSignupInfo,
  });
  const { control, trigger, setValue, reset, watch, handleSubmit } = form;
  const { isDirty } = useFormState({ control });
  const processStateObj = useProcessState({ logAnalyticsEvent });
  const { processState, setProcessState, errorHandler } = processStateObj;

  const selectedPlan = watch(PLAN_WATCH_KEY);

  const [signupState, reduceSignupState] = useReducer<
    Reducer<SignupState, SignupAction>
  >(
    (prevState, action) => {
      switch (action.type) {
        case SignupActionNames.SET_FORWARDING_TO_ONBOARDING:
          return { ...prevState, forwardingToOnboarding: action.payload };
        case SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID:
          return { ...prevState, proceedWithoutPracId: action.payload };
        case SignupActionNames.SET_IS_PRAC_ID_VALID:
          return { ...prevState, isPracIdValid: action.payload };
        case SignupActionNames.SET_SAVED_SIGNATURE:
          return { ...prevState, savedSignature: action.payload };
        case SignupActionNames.SET_AGREEMENT_BLOB:
          return { ...prevState, agreementBlob: action.payload };
        default:
          return { ...prevState };
      }
    },
    {
      isPracIdValid: false,
      proceedWithoutPracId: false,
      agreementBlob: undefined,
      savedSignature: undefined,
      forwardingToOnboarding: false,
    },
  );

  const { isPracIdValid, forwardingToOnboarding, agreementBlob } = signupState;
  const setForwardingToOnboarding = (newValue: boolean) =>
    reduceSignupState(
      createSignupReducerAction("setForwardingToOnboarding", newValue),
    );

  const forwardToOnboarding = (
    firstName?: string,
    lastName?: string,
    email?: string,
  ) => {
    let hubspotLink =
      projectEnv === "prod"
        ? "https://meetings.hubspot.com/alana41/onboarding-session"
        : "https://meetings.hubspot.com/alana41/testing";
    if (firstName !== undefined)
      hubspotLink += `?firstName=${encodeURIComponent(firstName)}`;
    if (lastName !== undefined)
      hubspotLink += `&lastName=${encodeURIComponent(lastName)}`;
    if (email !== undefined)
      hubspotLink += `&email=${encodeURIComponent(email)}`;

    window.open(hubspotLink, "_self");
  };

  const signIn = (data: SignupInfo.SignupInfo, selectedOnboarding: boolean) => {
    setForwardingToOnboarding(selectedOnboarding);
    fbAuth.signInWithEmailAndPassword(data.email, data.password).then(() => {
      if (selectedOnboarding) {
        forwardToOnboarding(data.firstName, data.lastName, data.email);
      } else if (isPracIdValid) navigate("/dashboard/consult");
      else navigate("/dashboard/validation-required");
    });
  };

  const onSubmit = (data: SignupInfo.SignupInfo) => {
    if (agreementBlob === undefined) {
      errorHandler({
        error: new Error("Agreement is undefined."),
        userMessage:
          "There was an error loading your Agreement forms. Please try again.",
        hideErrorMessage: true,
      });
      return;
    }

    setProcessState(ProcessState.running);

    const formData = new FormData();
    formData.append("formData", JSON.stringify(data));

    formData.append("pracIdValid", JSON.stringify(isPracIdValid));

    formData.append(
      "attachment",
      agreementBlob,
      "Family Physician Platform Use Agreement Signed.pdf",
    );

    formData.append("plan", Plan.getDatabaseCode(data.selectedPlan)); // For compatibility with old backend
    formData.append("role", data.selectedRole); // For compatibility with old backend

    serverRequest(undefined, {}, formData, "signup/complete-signup-v2")
      .then(() => {
        logAnalyticsEvent("signup_complete_success");
        setProcessState(ProcessState.success);

        reset(data); // Reset form to mark as not dirty
        signIn(data, data.scheduleOnboarding === "Yes");
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: `There was an error completing the signup process. Contact us at ${globalStrings.salesEmail} or ${globalStrings.salesPhone} if this issue continues to occur.`,
          analyticsLog: "signup_complete_failed",
        });
      });
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  //Parse query parameters
  useEffect(() => {
    const params = queryString.parse(location.search);
    const plan = Plan.getFromQueryString((params.selectedPlan as string) ?? "");
    setValue(PLAN_WATCH_KEY, Plan.isValid(plan) ? plan : Plan.EmptyPlan);

    const role = params.selectedRole;
    if (Role.isValid(role)) {
      setValue(ROLE_WATCH_KEY, role);
      trigger(ROLE_WATCH_KEY);
    }
  }, []);

  // User successfully signed up so only show signup success page
  if (processState === ProcessState.success)
    return (
      <SignupSuccessPage
        forwardingToOnboarding={forwardingToOnboarding}
        forwardToOnboardingManually={forwardToOnboarding}
      />
    );

  return (
    <Grid
      container
      className={classes.pageContainer}
      size="grow"
      direction="column"
    >
      <PageHeaderFooterBars position="header" />
      <Grid size={{ xs: 12 }}>
        <PageHeader
          title={
            Plan.isValid(selectedPlan) ? "Set up your account" : "Pick a Plan"
          }
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <SignupForm
          form={form}
          reduceSignupState={reduceSignupState}
          signupState={signupState}
          processStateObj={processStateObj}
          onFormSubmission={handleSubmit(onSubmit, onError)}
        />
      </Grid>
      <PageHeaderFooterBars position="footer" />

      {/* Prompts user if they try to leave page, not displayed */}
      <LeavePagePrompt isDirty={isDirty} />
    </Grid>
  );
};

export default Signup;
