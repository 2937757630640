import { useRegisterSW } from "virtual:pwa-register/react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import type { FC } from "react";

const ReloadPrompt: FC = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisterError(error) {
      console.error(`SW registration error ${error}`);
    },
  });

  return (
    <Snackbar
      open={needRefresh}
      message="A new version is available!"
      onClick={() => updateServiceWorker(true)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      action={
        <Button
          color="secondary"
          size="small"
          onClick={() => updateServiceWorker(true)}
        >
          Reload
        </Button>
      }
    />
  );
};

export default ReloadPrompt;
