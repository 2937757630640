import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Grid2 as Grid, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../AuthProvider";
import { searchByString } from "./ApproverSearchFunctions";

interface ApproverDropdownUser {
  uid: string;
  firstName: string;
  lastName: string;
}

interface ApproverDropdownProps {
  selectedApproverUid: string | undefined;
  selectApproverUid: (uid: string) => void;
  deselectApproverUid: () => void;
}

const ApproverDropdown: FC<ApproverDropdownProps> = ({
  selectApproverUid,
  deselectApproverUid,
}: ApproverDropdownProps) => {
  const authContext = useContext(AuthContext);
  const [searchText, setSearchText] = useState<string>("");
  const [approvers, setApprovers] = useState<ApproverDropdownUser[]>([]);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const getUsers = () => {
    setProcessState(ProcessState.running);
    searchByString({
      typesenseUsersSearchApiKey: authContext.typesenseUsersSearchApiKey,
      searchText: searchText,
    })
      .then((approvers) => {
        // set approvers but turn each approver into an ApproverDropdownUser
        setApprovers(
          approvers.map((approver) => {
            return approver as ApproverDropdownUser;
          }),
        );

        // setApprovers(approvers as ApproverDropdownUser[]);
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: error.message,
        });
      });
  };

  useEffect(() => {
    if (searchText.length > 0) {
      getUsers();
    } else {
      setApprovers([]);
    }
  }, [searchText]);

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Autocomplete
          options={approvers}
          isOptionEqualToValue={(
            option: ApproverDropdownUser,
            value: ApproverDropdownUser,
          ) => option.uid === value.uid}
          getOptionLabel={(option: ApproverDropdownUser) =>
            `${option.firstName} ${option.lastName}`
          }
          onChange={(event: any, newValue: ApproverDropdownUser | null) => {
            if (newValue !== null) {
              selectApproverUid(newValue.uid);
            } else {
              deselectApproverUid();
            }
          }}
          inputValue={searchText}
          onInputChange={(event, newInputValue) => {
            setSearchText(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type to search for a user"
              variant="outlined"
            />
          )}
          // getOptionDisabled={isUserDisabled}
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
        />
      </Grid>
    </Grid>
  );
};

export default ApproverDropdown;
