import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import { FC } from "react";
import { FieldArrayMethodProps } from "react-hook-form";

interface AddPatientButtonProps {
  append: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined,
  ) => void;
  disabled?: boolean;
}

const AddPatientButton: FC<AddPatientButtonProps> = ({ append, disabled }) => {
  const handleClick = () => {
    append({
      phn: "",
      email: "",
    });
  };

  return (
    <Button
      onClick={handleClick}
      color="primary"
      disabled={disabled}
      startIcon={<AddCircleOutlineIcon color="primary" />}
    >
      Add another recipient
    </Button>
  );
};

export default AddPatientButton;
