import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { addParentPathToChildPaths } from "src/routes/internalHelpers";
import { type Route } from "src/routes/types";

// #region Types

/**
 * List of all route keys for dashboardChildrenRoutes as an enum object
 */
export const DashboardRouteNames = {
  profile: "profile",
  camera: "camera",
  gallery: "gallery",
  imageAnnotation: "imageAnnotation",
  billingReport: "billingReport",
  forms: "forms",
  oneWayMessaging: "oneWayMessaging",
  consult: "consult",
  secureMessaging: "secureMessaging",
  moaConsultInbox: "moaConsultInbox",
  patientMessages: "patientMessages",
  resources: "resources",
  editResources: "editResources",
  validationRequired: "validationRequired",
  specialistTemplates: "specialistTemplates",
  specialistSchedule: "specialistSchedule",
  notificationCenter: "notificationCenter",
} as const;

/**
 * Type of the keys of all of the routes for dashboardChildrenRoutes (e.g. to ensure that all routes are covered for display)
 */
export type DashboardChildRouteName =
  (typeof DashboardRouteNames)[keyof typeof DashboardRouteNames];

// #endregion

// #region Constants

/**
 * The parent route for all dashboard routes
 */
export const DASHBOARD_ROUTE = "/dashboard";

/**
 * All route metadata for all children routes of dashboard without the dashboard route prepended
 */
const DASHBOARD_CHILDREN_PARTIAL_ROUTES: {
  [key in DashboardChildRouteName]: Route;
} = {
  // Full route: /${DASHBOARD_ROUTE}/profile
  [DashboardRouteNames.profile]: {
    path: "/profile",
    name: "Profile",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  // Full route: /${DASHBOARD_ROUTE}/camera
  [DashboardRouteNames.camera]: {
    path: "/camera",
    name: "Camera",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.cameraAndGallery]],
  },
  // Full route: /${DASHBOARD_ROUTE}/gallery
  [DashboardRouteNames.gallery]: {
    path: "/gallery",
    name: "Gallery",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.cameraAndGallery]],
  },
  // Full route: /${DASHBOARD_ROUTE}/image-annotation
  [DashboardRouteNames.imageAnnotation]: {
    path: "/image-annotation",
    name: "Image Annotation",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.imageAnnotation]],
  },
  // Full route: /${DASHBOARD_ROUTE}/billing-report
  [DashboardRouteNames.billingReport]: {
    path: "/billing-report",
    name: "Billing Report",
    isValidHomepage: false,
    availableOnMobile: false,
    requiredResourcePermissions: [[resourceKeys.billingReport]],
  },
  // Full route: /${DASHBOARD_ROUTE}/form-sender
  [DashboardRouteNames.forms]: {
    path: "/form-sender",
    name: "Forms",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.forms]],
  },
  // Full route: /${DASHBOARD_ROUTE}/one-way-messaging
  [DashboardRouteNames.oneWayMessaging]: {
    path: "/one-way-messaging",
    name: "One-Way Messaging",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.oneWayMessaging]],
    isPremium: true,
  },
  // Full route: /${DASHBOARD_ROUTE}/consult
  [DashboardRouteNames.consult]: {
    path: "/consult",
    name: "Consult",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [
      [resourceKeys.econsultAndSecureMessaging],
      [resourceKeys.forwardEconsult],
      [resourceKeys.approveEconsult],
    ],
  },
  // Full route: /${DASHBOARD_ROUTE}/secure-messaging
  [DashboardRouteNames.secureMessaging]: {
    path: "/secure-messaging",
    name: "Secure Messaging",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  // Full route: /${DASHBOARD_ROUTE}/clinic-inbox
  [DashboardRouteNames.moaConsultInbox]: {
    path: "/clinic-inbox",
    name: "Clinic Inbox",
    isValidHomepage: true,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.moaConsultInbox]],
  },
  // Full route: /${DASHBOARD_ROUTE}/patient-messages
  [DashboardRouteNames.patientMessages]: {
    path: "/patient-messages",
    name: "Patient Messages",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [
      [resourceKeys.oneWayMessaging],
      [resourceKeys.forms],
      [resourceKeys.patientCC],
      [resourceKeys.moaMessaging],
    ],
    isPremium: true,
  },
  // Full route: /${DASHBOARD_ROUTE}/resources
  [DashboardRouteNames.resources]: {
    path: "/resources",
    name: "General Resources",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
  [DashboardRouteNames.editResources]: {
    path: "/edit-resources",
    name: "Edit Resources",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistTemplates]],
  },
  // Full route: /${DASHBOARD_ROUTE}/validation-required
  [DashboardRouteNames.validationRequired]: {
    path: "/validation-required",
    name: "Next Steps",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.pracIdValidationRequiredPage]],
  },
  // Full route: /${DASHBOARD_ROUTE}/edit-templates
  [DashboardRouteNames.specialistTemplates]: {
    path: "/edit-templates",
    name: "Edit Templates",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistTemplates]],
  },
  // Full route: /${DASHBOARD_ROUTE}/edit-schedule
  [DashboardRouteNames.specialistSchedule]: {
    path: "/edit-schedule",
    name: "Edit Schedule",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[resourceKeys.specialistScheduleEditing]],
  },
  // Full route: /${DASHBOARD_ROUTE}/secure-messaging?tab=notifications
  [DashboardRouteNames.notificationCenter]: {
    path: "/secure-messaging?tab=notifications",
    name: "Alethea News",
    isValidHomepage: false,
    availableOnMobile: true,
    requiredResourcePermissions: [[]],
  },
} as const;

// #endregion

// #region Exports

/**
 * Create new object from dashboardChildrenRoutes with /${DASHBOARD_ROUTE}/ prepended to each path
 */
export const dashboardChildrenRoutes: {
  [key in DashboardChildRouteName]: Route;
} = addParentPathToChildPaths(
  DASHBOARD_CHILDREN_PARTIAL_ROUTES,
  DashboardRouteNames,
  DASHBOARD_ROUTE,
);

// #endregion

export default dashboardChildrenRoutes;
