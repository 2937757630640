import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import LeavePagePrompt from "../../../../../components/LeavePagePrompt";
import SaveDiscardToolbar from "../../../../../components/SaveDiscardToolbar";
import SpecialistScheduleController from "../../Controllers/SpecialistScheduleController";
import {
  daysOfTheWeek,
  defaultTimeOptions,
} from "../../Models/SpecialistScheduleModel";
import CalendarTimeColumn from "./CalendarTimeColumn";
import ScheduleDay from "./ScheduleDay";

const useStyles = makeStyles()((theme: Theme) => ({
  calendarColumn: {
    width: "13%",
    borderLeft: "1px solid #ddd",
  },
  dayOfTheWeekText: {
    margin: theme.spacing(1, 0, 0, 1),
    // Disable text select
    "-webkit-user-select": "none" /* Safari */,
    "-ms-user-select": "none" /* IE 10 and IE 11 */,
    "user-select": "none" /* Standard syntax */,
  },
  timezoneNote: {
    fontSize: "1.0em",
    margin: theme.spacing(1),
  },
  timeColumn: {
    // 100 - 13*7
    width: "9%",
  },
  save: {
    padding: theme.spacing(1),
  },
  calendar: {
    marginBottom: theme.spacing(3),
  },
}));

interface SpecialistScheduleViewProps {
  height: number | string;
}

const SpecialistScheduleView: FC<SpecialistScheduleViewProps> = ({
  height,
}) => {
  const { classes } = useStyles();
  const {
    schedule,
    isDirty,
    saveHandler,
    discardHandler,
    setDirty,
    blocksRef,
    processState,
    processErrorMessage,
  } = SpecialistScheduleController();

  //Need to set width of header because the calendar view has a scroll bar which causes them to not line up unless we explicitly match their widths
  const { width: calendarWidth, sizeRef: dragContainerRef } = useSizeManager();

  return (
    <HeightLayout height={height} style={{ minWidth: "1000px" }}>
      <HeightLayoutChild flexDriver className={classes.save}>
        <SaveDiscardToolbar
          show={isDirty}
          modalText={"Discard changes to your schedule?"}
          saveHandler={saveHandler}
          discardHandler={discardHandler}
          unsavedText="Unsaved Schedule Changes"
        />
      </HeightLayoutChild>
      <HeightLayoutChild flexDriver>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
        />
      </HeightLayoutChild>
      <HeightLayoutChild flexDriver style={{ width: calendarWidth }}>
        <Grid container>
          <Grid size={{ xs: 12 }}>
            <Typography className={classes.timezoneNote}>
              All times are shown in Mountain Time.
            </Typography>
          </Grid>
          <Grid className={classes.timeColumn}></Grid>
          {daysOfTheWeek.map((d) => (
            <Grid className={classes.calendarColumn} key={`day_header_${d}`}>
              <Typography className={classes.dayOfTheWeekText}>{d}</Typography>
            </Grid>
          ))}
        </Grid>
      </HeightLayoutChild>
      <HeightLayoutChild
        flexDriven
        allowOverflowY
        ref={dragContainerRef}
        className={classes.calendar}
      >
        <Grid container>
          <Grid className={classes.timeColumn}>
            <CalendarTimeColumn times={defaultTimeOptions} />
          </Grid>
          {daysOfTheWeek.map((d, index) => (
            <Grid className={classes.calendarColumn} key={`day_${index}_${d}`}>
              <ScheduleDay
                day={d}
                dayIndex={index}
                scheduleDay={schedule[index]}
                times={defaultTimeOptions}
                blocksRef={blocksRef}
                dragContainerRef={dragContainerRef}
                setDirty={setDirty}
              />
            </Grid>
          ))}
        </Grid>
      </HeightLayoutChild>
      <LeavePagePrompt isDirty={isDirty} />
    </HeightLayout>
  );
};

export default SpecialistScheduleView;
