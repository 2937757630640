import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../../shared/types";
import { AuthContext } from "../../../../../AuthProvider";
import MessageListItem from "../../../../../components/MessageListItem";
import { formatTimeShort } from "../../../../../models/formatTime";

const useStyles = makeStyles()((theme: Theme) => ({
  infoContainer: {
    marginRight: theme.spacing(2),
  },
  timestamp: {
    whiteSpace: "nowrap",
  },
}));
interface ActivityListItemProps {
  draft: Activity.ConsultDraft;
  draftId: string;
  selected: boolean;
  setSelected: (id: string, checked: boolean) => void;
  openDraft: (id: string) => void;
}

const ActivityListItem: FC<ActivityListItemProps> = ({
  draft,
  draftId,
  openDraft,
  selected,
  setSelected,
}: ActivityListItemProps) => {
  const authContext = useContext(AuthContext);
  const { classes } = useStyles();
  const [read, setRead] = useState<boolean>(true);

  useEffect(() => {
    if (authContext.uid === "") return;

    setRead(draft.read ?? true);
  }, [authContext.uid, draft]);

  return (
    <MessageListItem
      selected={selected}
      setSelected={setSelected}
      openMessage={openDraft}
      id={draftId}
      read={read}
      primary={draft.subject}
    >
      <div className={classes.infoContainer}>
        <Typography className={classes.timestamp}>
          {" "}
          {formatTimeShort(draft.created.toDate())}
        </Typography>
      </div>
    </MessageListItem>
  );
};

export default ActivityListItem;
