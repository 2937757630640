import { Grid2 as Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { CSSProperties, FC, ReactNode } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1, 2),
  },
}));

type ConsultFormCardProps = {
  title?: string;
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
};

const ConsultFormCard: FC<ConsultFormCardProps> = ({
  title,
  className,
  children,
  style,
}) => {
  const { classes, cx } = useStyles();

  return (
    <Paper
      className={cx(className, classes.container)}
      variant="outlined"
      style={style}
    >
      {title ? (
        <Grid container spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="subtitle1">{title}</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>{children}</Grid>
        </Grid>
      ) : (
        children
      )}
    </Paper>
  );
};

export default ConsultFormCard;
