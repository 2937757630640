import { BillingRecord } from "@alethea-medical/admin-types";
import { TableCell, TableRow } from "@mui/material";
import firebase from "firebase/compat/app";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { formatStatus, isTimestampField } from "../billingFunctions";
import { HeadCell } from "../types";

interface BillingRowProps {
  headCells: HeadCell[];
  data: BillingRecord;
  index: number;
}

const useStyles = makeStyles()(() => ({
  statusCell: {
    textAlign: "center",
  },
  tableCell: {
    overflow: "auto",
  },
  subTable: {
    overflow: "hidden",
  },
}));

const BillingTableRow: FC<BillingRowProps> = ({ headCells, data, index }) => {
  const { classes } = useStyles();

  const getFormattedRow = (headCell: HeadCell, data: BillingRecord): string => {
    if (isTimestampField(headCell.id)) {
      // Need to force type otherwise typescript yells at me
      return data[headCell.id] !== undefined
        ? (data[headCell.id] as firebase.firestore.Timestamp)
            .toDate()
            .toLocaleString()
        : "N/A";
    } else if (headCell.id === "status") {
      return formatStatus(data[headCell.id]);
    } else {
      const tmp = data[headCell.id];
      if (!tmp) {
        return "N/A";
      }
      return tmp.toString();
    }
  };

  return (
    <TableRow tabIndex={-1}>
      {headCells.map((headCell) => (
        <TableCell
          className={classes.tableCell}
          align="left"
          key={`billing_row_${index}_${headCell.id}`}
        >
          {getFormattedRow(headCell, data)}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default BillingTableRow;
