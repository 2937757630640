import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import usePermissions from "src/components/usePermissions";

export interface PermissionsObject {
  specialty: string;
  userHasPermission: boolean;
}

interface PermissionsContextType {
  permissions: PermissionsObject[];
  loading: boolean;
}

interface PermissionsProviderProps {
  children: ReactNode;
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined,
);

export const PermissionsProvider: FC<PermissionsProviderProps> = ({
  children,
}) => {
  const [permissions, setPermissions] = useState<PermissionsObject[]>([]);
  const [loading, setLoading] = useState(true);

  const { granted: hasMdRoutingPermissions, loading: mdRoutingLoading } =
    usePermissions({
      resourceKey: resourceKeys.mdRouting,
    });
  const {
    granted: hasDentalRoutingPermissions,
    loading: dentalRoutingLoading,
  } = usePermissions({
    resourceKey: resourceKeys.dentalRouting,
  });
  const {
    granted: hasLongTermCareRoutingPermissions,
    loading: longTermCareRoutingLoading,
  } = usePermissions({
    resourceKey: resourceKeys.longTermCareRouting,
  });

  useEffect(() => {
    const permissions = [
      {
        specialty: "All Standard Specialties",
        userHasPermission: hasMdRoutingPermissions,
      },
      {
        specialty: "Dentistry",
        userHasPermission: hasDentalRoutingPermissions,
      },
      {
        specialty: "Home Sleep Apnea Test (HSAT - Level 3)",
        userHasPermission: hasDentalRoutingPermissions,
      },
      {
        specialty: "Long Term Care",
        userHasPermission: hasLongTermCareRoutingPermissions,
      },
    ];
    setPermissions(permissions);
    setLoading(
      !mdRoutingLoading && !dentalRoutingLoading && !longTermCareRoutingLoading,
    );
  }, [
    hasMdRoutingPermissions,
    hasDentalRoutingPermissions,
    hasLongTermCareRoutingPermissions,
    mdRoutingLoading,
    dentalRoutingLoading,
    longTermCareRoutingLoading,
    setLoading,
    setPermissions,
  ]);

  return (
    <PermissionsContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissionsContext = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error(
      "usePermissionsContext must be used within a PermissionsProvider",
    );
  }
  return context;
};
