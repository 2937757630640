import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { RolePermissions, UserProfile } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../firebase";

//!This is copy pasted from the backend, slightly modified

interface RoleDict {
  [key: string]: RolePermissions;
}

const lazyLoadDict: RoleDict = {};

/**
 * Checks if user has a role that has permissions to access the requested resourceKey
 * @param resourceKey Key to the resource user is trying to access (e.g. "econsult")
 * @param uid UID of the user trying to access the resource
 * @returns Promise true if user is allowed access to resource, false if user is not allowed access to resource. Throws error if there is an error accessing the firestore database
 */
function hasPermissions(
  resourceKey: string,
  userProfile: UserProfile | undefined,
): Promise<boolean> {
  //Check the roles field exists and profile is defiend
  if (userProfile === undefined || userProfile?.userRoles === undefined)
    return Promise.resolve(false);

  //Iterate over all roles (can't use some, since promises need to resolve before the result is known)
  return Promise.all(
    userProfile.userRoles.map((roleKey) => {
      let loadPromise = Promise.resolve();
      //Get the role permissions document for the role
      if (lazyLoadDict[roleKey] === undefined) {
        loadPromise = fbFirestore
          .collection(dbNames.rolePermissions)
          .doc(roleKey)
          .get()
          .then((doc) => {
            //If the document exists and has data, check that the permissions list contains the resourceKey the user is trying to access
            if (doc.exists) {
              const data: RolePermissions = doc.data() as RolePermissions;
              lazyLoadDict[roleKey] = data;
            }
          });
      }
      return loadPromise.then(() => {
        if (lazyLoadDict[roleKey]?.permissions !== undefined) {
          return lazyLoadDict[roleKey].permissions.includes(resourceKey);
        }
        return false;
      });
    }),
  )
    .then((permissions) => {
      //Get list of permissions to the requested resource for each role that the user has. If at least one is true, then return true
      return permissions.some((p) => p);
    })
    .catch(() => {
      return false;
    });
}

export default hasPermissions;
