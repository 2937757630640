import {
  HeightLayout,
  HeightLayoutChild,
  PaperModal,
  ProcessState,
  ProcessStatus,
  thinScrollbar,
  useProcessState,
} from "@alethea-medical/alethea-components";
import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC, useContext, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../../AuthProvider";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import EmailInput from "../../../../components/FormInputFields/EmailInput";
import FormTextArea from "../../../../components/FormInputFields/FormTextArea";
import FormTextField from "../../../../components/FormInputFields/FormTextField";
import { logAnalyticsEvent } from "../../../../firebase";
import serverRequest from "../../../../models/serverRequest";

interface MOAMessageProps {
  activityId: string;
  defaultSubject: string;
}
const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,

  messagesBox: {
    padding: theme.spacing(1, 1, 3, 1),
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const MOAMessageButton: FC<MOAMessageProps> = ({
  activityId,
  defaultSubject,
}) => {
  const authContext = useContext(AuthContext);
  const { handleSubmit, control, reset } = useForm();
  const { classes, cx } = useStyles();
  const [show, setShow] = useState<boolean>(false);

  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
    errorHandler,
  } = useProcessState({ logAnalyticsEvent });

  const getSubjectPrepend = (): string => {
    let subjectPrepend = "Secure Message from Dr. ";
    subjectPrepend += `${authContext?.profile?.lastName}: `;
    return subjectPrepend;
  };

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setProcessState(ProcessState.running);

    const form = new FormData();
    form.append("physicianUid", authContext.uid);
    form.append("activityId", activityId);
    form.append("patientEmail", data.email);
    form.append("subject", getSubjectPrepend() + data.subject);
    form.append("body", data.body);

    logAnalyticsEvent("secure_messaging_forward_econsult_start");

    serverRequest(authContext.user, {}, form, "secure-messaging-moa-message")
      .then(() => {
        logAnalyticsEvent("secure_messaging_forward_econsult_success");

        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
          setShow(false);
        }, 1000);
        resetForm();
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error forwarding econsult",
          analyticsLog: "secure_messaging_forward_econsult_failed",
        });
      });
  };

  const resetForm = () => {
    reset({
      email: "",
      subject: defaultSubject,
      body: "",
    });
  };

  const onError = () => {
    setProcessErrorMessage("Check form for errors.");
    setProcessState(ProcessState.error);
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  return (
    <>
      <DropdownMenuItem
        label="Forward to MOA"
        icon={<EmailIcon color="primary" />}
        onClick={() => {
          setShow(true);
        }}
        color="primary"
      />
      <PaperModal show={show} setShow={setShow} flexWidth enablePadding>
        <HeightLayout>
          <HeightLayoutChild
            flexDriven
            allowOverflowY
            className={cx(classes.messagesBox, classes.thinScrollbar)}
          >
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Typography variant="h5">Forward eConsult as PDF</Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant="subtitle1">
                  Securely forward this eConsult, message thread, and
                  attachments as a PDF.
                </Typography>
                <Typography variant="subtitle1">
                  Your MOA can view the eConsult by creating an{" "}
                  <a
                    href="https://alethea.care"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Alethea Care
                  </a>{" "}
                  account.
                </Typography>
                <Typography variant="subtitle1">
                  You can track the eConsults you have sent in the{" "}
                  <a
                    href="/dashboard/patient-messages"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Patient Messages
                  </a>{" "}
                  tab.
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  autoComplete="off"
                  aria-autocomplete="none"
                >
                  <fieldset disabled={isDisabled()} aria-autocomplete="none">
                    <Grid container spacing={1}>
                      <Grid size={{ xs: 12 }}>
                        <EmailInput
                          name="email"
                          label="To"
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <FormTextField
                          name="subject"
                          control={control}
                          label="Subject"
                          defaultValue={defaultSubject}
                          disabled={isDisabled()}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <FormTextArea
                          name="body"
                          label="Message"
                          control={control}
                          initRows={3}
                          disabled={isDisabled()}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <ProcessStatus
                          state={processState}
                          setState={setProcessState}
                          errorMessage={processErrorMessage}
                          successMessage={"Message sent."}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isDisabled()}
                          startIcon={<SendIcon color="primary" />}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </Grid>
            </Grid>
          </HeightLayoutChild>
        </HeightLayout>
      </PaperModal>
    </>
  );
};

export default MOAMessageButton;
