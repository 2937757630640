import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import firebase from "firebase/compat/app";
import { useContext, useEffect, useState } from "react";
import { SpecialistAvailability } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import {
  VacationPeriodItem,
  fetchVacation,
  saveVacation,
  validateVacation,
} from "../Models/SpecialistVacationModel";

type SpecialistVacationControllerReturn = {
  vacation: VacationPeriodItem[];
  isDirty: boolean;
  addRowHandler: () => void;
  updateRowHandler: (
    index: number,
    newValue: SpecialistAvailability.VacationPeriod,
  ) => void;
  removeRowHandler: (index: number) => void;
  discardHandler: () => void;
  saveHandler: () => Promise<void> | undefined;
  processState: ProcessState;
  processErrorMessage: string;
};

const SpecialistVacationController = (): SpecialistVacationControllerReturn => {
  const [vacation, setVacation] = useState<VacationPeriodItem[]>([]);
  const authContext = useContext(AuthContext);
  const [toDelete, setToDelete] = useState<string[]>([]);

  const [isDirty, setIsDirty] = useState(false);
  const setDirty = () => {
    setIsDirty(true);
  };

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const updateRowHandler = (
    index: number,
    newValue: SpecialistAvailability.VacationPeriod,
  ): void => {
    const newVacation = [...vacation];
    newVacation[index].data = newValue;

    setVacation(newVacation);

    setDirty();
  };

  const addRowHandler = (): void => {
    // Add new row, default to today for start and end
    const newVacation = [...vacation];
    const now = firebase.firestore.Timestamp.now();
    const start = now.toDate();
    start.setHours(0, 0, 0, 0);

    const end = now.toDate();
    end.setHours(23, 59, 59, 999);

    newVacation.push({
      data: {
        start: firebase.firestore.Timestamp.fromDate(start),
        end: firebase.firestore.Timestamp.fromDate(end),
        disableEconsult: true,
        disablePhoneConsult: true,
        description: "",
      },
      id: undefined, // No ID yet
    });

    setVacation(newVacation);

    setDirty();
  };

  const removeRowHandler = (index: number): void => {
    const newVacation = [...vacation];
    const deletedItem = newVacation.splice(index, 1);
    setVacation(newVacation);

    if (deletedItem[0]?.id !== undefined) {
      const newToDelete = [...toDelete];
      newToDelete.push(deletedItem[0].id);
      setToDelete(newToDelete);
    }

    setDirty();
  };

  const loadVacation = () => {
    if (authContext.uid !== "") {
      setProcessState(ProcessState.running);
      fetchVacation(authContext.uid)
        .then((newVacation) => {
          setProcessState(ProcessState.idle);
          setVacation(newVacation);
          setIsDirty(false);
          setToDelete([]);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error loading your vacation settings",
          });
        });
    }
  };

  useEffect(() => {
    loadVacation();
  }, [authContext.uid]);

  const saveHandler = () => {
    if (authContext.uid !== "") {
      if (!validateVacation(vacation)) {
        errorHandler({
          userMessage:
            'Please make sure the vacation period\'s "From" date is before it\'s "To" date.',
        });
        return;
      }

      setProcessState(ProcessState.running);

      return saveVacation(authContext.uid, vacation, toDelete)
        .then((newVacation) => {
          setProcessState(ProcessState.idle);
          logAnalyticsEvent(analyticsLogs.specialistAvailability.vacation.save);
          setVacation(newVacation);
          setIsDirty(false);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error saving your vacation settings",
            analyticsLog:
              analyticsLogs.specialistAvailability.vacation.saveFail,
          });
        });
    }
  };

  const discardHandler = () => {
    loadVacation();
  };

  return {
    vacation,
    isDirty,
    addRowHandler,
    updateRowHandler,
    removeRowHandler,
    discardHandler,
    saveHandler,
    processState,
    processErrorMessage,
  };
};

export default SpecialistVacationController;
