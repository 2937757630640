import { SignupInfo } from "@alethea-medical/aletheamd-types";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import { Control } from "react-hook-form";
import { TypedFormSelectAutocomplete } from "../../../../../components/FormInputFields/FormSelectAutocomplete";
import { TypedFormTextField } from "../../../../../components/FormInputFields/FormTextField";
import { TypedPhoneInput } from "../../../../../components/FormInputFields/PhoneInput";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";
import HelpModal from "../../../../../components/HelpModal";
import useCms from "../../../../../utils/useCms";
import strings from "../../Common/strings";

interface ClinicInformationProps {
  control: Control<SignupInfo.SignupInfo>;
  disabled: boolean;
}

const ClinicInformation: FC<ClinicInformationProps> = ({
  control,
  disabled,
}) => {
  const clinicsContent = useCms({ cmsId: "clinics" });

  return (
    <Grid container spacing={2} alignItems="flex-end">
      <Grid size={{ xs: 12 }}>
        <HelpModal
          buttonText="Multiple Clinics?"
          helpText={[strings.clinicNote]}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>Clinic Name</FormattedInputLabel>
        <TypedFormSelectAutocomplete
          control={control}
          name="clinicName"
          rules={{
            required: { value: true, message: "Clinic name is required." },
          }}
          disabled={disabled}
          options={clinicsContent.getArray<string>("clinicNames")}
          errorTextPadding
          {...{ freeSolo: true, autoSelect: true }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>City</FormattedInputLabel>
        <TypedFormTextField
          control={control}
          name="city"
          rules={{ required: { value: true, message: "City is required." } }}
          errorTextPadding
          disabled={disabled}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>Clinic Phone Number</FormattedInputLabel>
        <TypedPhoneInput
          control={control}
          name="clinicPhone"
          rules={{
            required: {
              value: true,
              message: "Clinic phone number is required.",
            },
          }}
          errorTextPadding
          disabled={disabled}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>Clinic Fax Number</FormattedInputLabel>
        <TypedPhoneInput
          control={control}
          name="fax"
          rules={{
            required: { value: true, message: "Clinic fax is required." },
          }}
          errorTextPadding
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

export default ClinicInformation;
