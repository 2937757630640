import Paper from "@mui/material/Paper";
import React from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../palette";
import { timeRowHeight } from "./TimeRow";

const useStyles = makeStyles()(() => ({
  timeBlocked: {
    position: "absolute",
    backgroundColor: palette.darkGreen,
    width: "95%",
  },
}));
const marginBottom = 2;
interface ScheduleBlockPaperProps {
  startIdx: number;
  endIdx: number;
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
}

const ScheduleBlockPaper: React.FC<ScheduleBlockPaperProps> = ({
  startIdx,
  endIdx,
  onClick,
  className,
  children,
}) => {
  const { classes, cx } = useStyles();

  const diff = endIdx - startIdx;
  return (
    <Paper
      className={cx(className, classes.timeBlocked)}
      style={{
        top: timeRowHeight * startIdx,
        height: timeRowHeight * diff - marginBottom,
      }}
      onClick={onClick}
    >
      {children}
    </Paper>
  );
};

export default ScheduleBlockPaper;
