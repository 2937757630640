import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import React from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    borderRadius: "5px",
  },
}));

type InformationBoxProps = {
  children: React.ReactNode;
};

const InformationBox: React.FC<InformationBoxProps> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Paper className={classes.paper} elevation={0}>
      {children}
    </Paper>
  );
};

export default InformationBox;
