import {
  PaperModal,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { fbFunctions } from "../../firebase";
import EmailInput from "../FormInputFields/EmailInput";

const ForgotPassword: FC = () => {
  const { handleSubmit, control, reset } = useForm();

  const [show, setShow] = useState(false);
  const sendForgotPasswordEmail = fbFunctions.httpsCallable(
    "resetPassword-sendForgotPasswordEmail",
  );
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);
    sendForgotPasswordEmail(data)
      .then((result) => {
        setSuccessMessage(result.data.message);
        setProcessState(ProcessState.success);
        resetForm();
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "There was an error sending your password reset email.",
        });
      });
  };

  const resetForm = () => {
    reset({
      email: "",
    });
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  const isDisabled = () => {
    return processState === ProcessState.running;
  };

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          setShow(true);
        }}
      >
        Forgot Password
      </Button>
      <PaperModal
        show={show}
        setShow={setShow}
        flexHeight
        flexWidth
        enablePadding
      >
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <fieldset disabled={isDisabled()}>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12 }}>
                <InputLabel>
                  Forgot your password? Enter your email below and we will send
                  you a link to reset your password.
                </InputLabel>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <EmailInput
                  name="email"
                  label="Email address"
                  control={control}
                  rules={{
                    required: { value: true, message: "Email is required" },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Button type="submit" variant="contained" color="primary">
                  Reset Password
                </Button>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <ProcessStatus
                  state={processState}
                  errorMessage={processErrorMessage}
                  successMessage={successMessage}
                />
              </Grid>
              {processState === ProcessState.success && (
                <Grid>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setProcessState(ProcessState.idle)}
                  >
                    OK
                  </Button>
                </Grid>
              )}
            </Grid>
          </fieldset>
        </form>
      </PaperModal>
    </>
  );
};

export default ForgotPassword;
