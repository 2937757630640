import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { useContext, useEffect, useRef, useState } from "react";
import {
  AIType,
  UserMediaMetadataAIPrediction,
} from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import analyticsLogs from "../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../firebase";
import useAITranslation from "../../useAITranslation";
import {
  addAIPredictionToMediaMetadata,
  createPredictionText,
  fetchPredictions,
  getPredictionStringList,
} from "../Models/AIPredictionsModel";
import { UserMediaMetadataItem } from "../Models/GalleryModel";

/** Summary
 * Show prediction for image with given filepath (url on google cloud storage)
 */

export interface AIPredictionsControllerProps {
  mediaItem: UserMediaMetadataItem;
  doctorLabels?: string[];
  modifyItem?: (id: string, newMediaItem: UserMediaMetadataItem) => void;
  allowAIDatabaseCaching?: boolean;
}

const PREDICTION_THRESHOLD = 0.5;

const AIPredictionsController = ({
  mediaItem,
  doctorLabels,
  modifyItem,
  allowAIDatabaseCaching,
}: AIPredictionsControllerProps) => {
  const [predictions, setPredictions] = useState<string[]>([]);
  const [labelType, setLabelType] = useState<"Doctor" | "AI">("AI");
  const [predictionType, setPredictionType] = useState<AIType | "" | "lvl1">(
    "",
  );
  const predictionTypeRef = useRef<AIType | "" | "lvl1">("");
  const authContext = useContext(AuthContext);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const { aiToGp } = useAITranslation({});

  useEffect(() => {
    if (mediaItem.data.fileType === "image") {
      //Only show prediction for images

      if (doctorLabels !== undefined) {
        setPredictions(doctorLabels);
        setLabelType("Doctor");
      } else {
        setPredictions([]);
        setLabelType("AI");
      }
    } else {
      setPredictions([]);
    }
  }, [doctorLabels, mediaItem]);

  const predictionTypeChangeHandler = (e: any) => {
    const newPredictionType = e.target.value as AIType | "";
    setPredictionType(newPredictionType);
    if (newPredictionType !== "")
      return getPredictionHandler(e.target.value as AIType);
  };

  const getPredictionHandler = (aiType: AIType | "lvl1") => {
    predictionTypeRef.current = aiType;
    // For debugging purposes (on dev only)
    if (aiType === "lvl1") {
      setProcessState(ProcessState.running);

      setPredictions([]);
      fetchPredictions(mediaItem.data.filePath, 0, aiType)
        .then((backendResponse) => {
          setProcessState(ProcessState.idle);
          console.log(backendResponse);
          //If prediction type changed while fetching, don't show these results
          if (predictionTypeRef.current === aiType) {
            setPredictions(createPredictionText(backendResponse.predictions));
          }
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error Predicting Image",
          });
        });
    }
    //Use pre-populated predictions if we have them for the diagnostic category
    else if (
      mediaItem.data.aiPredictions !== undefined &&
      mediaItem.data.aiPredictions[aiType] !== undefined
    ) {
      setProcessState(ProcessState.idle);
      //This is telling me it could be undefined, when we literally check it above
      //Forcing the type for now
      const { stringList } = getPredictionStringList(
        (
          mediaItem.data.aiPredictions[aiType] as UserMediaMetadataAIPrediction
        ).labels.map((aiPred) => {
          return {
            gpLabel: aiToGp(aiPred.aiLabel, true),
            score: aiPred.score,
          };
        }),
      );

      //Clear and then show to show that it changed, in case where user selects to prefetched predictions in a row
      setPredictions([]);
      setTimeout(() => {
        setPredictions(stringList);
      }, 100);
    }
    //Otherwise, fetch new predictions
    else {
      setProcessState(ProcessState.running);

      setPredictions([]);

      fetchPredictions(mediaItem.data.filePath, PREDICTION_THRESHOLD, aiType)
        .then((backendResponse) => {
          if (
            allowAIDatabaseCaching &&
            (mediaItem.data.aiPredictions === undefined ||
              mediaItem.data.aiPredictions[aiType] === undefined)
          ) {
            const aiData: UserMediaMetadataAIPrediction = {
              aiVersion: backendResponse.aiVersion,
              labels: backendResponse.predictions.map((p) => {
                return {
                  aiLabel: p.aiLabel,
                  score: p.score,
                };
              }),
            };
            return addAIPredictionToMediaMetadata(
              authContext.uid,
              mediaItem,
              aiType,
              aiData,
            ).then((newAIPredictions) => {
              //Update state if modify item is provided
              if (modifyItem !== undefined) {
                const newMediaItem = { ...mediaItem };
                newMediaItem.data.aiPredictions = newAIPredictions;
                modifyItem(newMediaItem.id, newMediaItem);
              }
              return backendResponse;
            });
          }
          return backendResponse;
        })
        .then((backendResponse) => {
          setProcessState(ProcessState.idle);
          //If prediction type changed while fetching, don't show these results
          if (predictionTypeRef.current === aiType) {
            const { stringList, cantDiagnose } = getPredictionStringList(
              backendResponse.predictions,
            );

            setPredictions(stringList);
            if (cantDiagnose)
              logAnalyticsEvent(analyticsLogs.ai.aiPredictCantDiagnose(aiType));
            else logAnalyticsEvent(analyticsLogs.ai.aiPredict(aiType));
          }
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error Predicting Image",
            analyticsLog: analyticsLogs.ai.aiPredictFail,
          });
        });
    }
  };

  const clearPredictions = () => {
    if (labelType !== "Doctor") {
      setPredictionType("");
      setPredictions([]);
    }
  };

  return {
    //states
    predictions,
    labelType,
    predictionType,
    //handlers
    predictionTypeChangeHandler,
    clearPredictions,
    //process
    aiState: processState,
    aiError: processErrorMessage,
  };
};

export default AIPredictionsController;
