import { RollupReport, RollupReportMonth } from "@alethea-medical/admin-types";
import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Grid2 as Grid } from "@mui/material";
import { format, getMonth, subMonths } from "date-fns";
import { FC, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../AuthProvider";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";
import { ChartPanel } from "../ChartPanel/ChartPanel";
import TotalPanel from "../Components/TotalPanel";

const initialReport: RollupReportMonth = {
  totalEconsults: 0,
  totalCommunications: 0,
  earningEconsult: 0,
  earningCommunications: 0,
};

const EarningSummary: FC = () => {
  const createRollupReport = fbFunctions.httpsCallable(
    "reporting-createRollupReport",
  );

  const authContext = useContext(AuthContext);
  const [currentMonthData, setCurrentMonthData] =
    useState<RollupReportMonth>(initialReport);
  const [lastMonthData, setLastMonthData] =
    useState<RollupReportMonth>(initialReport);
  const [ytdData, setYtdData] = useState<RollupReportMonth>(initialReport);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [chartEarningCommunications, setChartEarningCommunications] = useState<
    number[]
  >([]);
  const [chartEarningEconsult, setChartEarningEconsult] = useState<number[]>(
    [],
  );
  const [chartEarningTotal, setChartEarningTotal] = useState<number[]>([]);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    getDataPayroll();
  }, []);

  const getDataPayroll = () => {
    setProcessState(ProcessState.running);

    const currentDate = new Date();

    const currentMonth = getMonth(currentDate);
    const currentYear = format(currentDate, "yyyy");
    const lastMonthYear = format(subMonths(currentDate, 1), "yyyy");
    const lastMonth = getMonth(subMonths(currentDate, 1));

    let newCurrentMonthData = { ...initialReport };
    let newLastMonthData = { ...initialReport };
    const newYtdData = { ...initialReport };

    const newChartLabels: string[] = [];
    const newChartEarningCommunications: number[] = [];
    const newChartEarningEconsult: number[] = [];
    const newChartEarningTotal: number[] = [];

    const dataRequest = {
      billingDoctorUid: authContext.uid,
      millisecondsInYear: new Date().getTime(),
    };

    createRollupReport(dataRequest)
      .then((result) => {
        let startingMonth: number;

        Object.entries(result.data as RollupReport)
          .reverse()
          .forEach(([year, months]) => {
            if (year === currentYear) {
              startingMonth = currentMonth;
            } else {
              startingMonth = 11;
            }
            for (let i = startingMonth; i >= 0; i--) {
              //Ignore all records before November 2021 since they will be incorrect
              if (year === "2021" && i < 10) {
                continue;
              }

              if (i === currentMonth && year === currentYear) {
                newCurrentMonthData = months[i];
              }
              if (i === lastMonth && year === lastMonthYear) {
                newLastMonthData = months[i];
              }
              if (year === currentYear) {
                newYtdData.earningCommunications +=
                  months[i].earningCommunications;
                newYtdData.earningEconsult += months[i].earningEconsult;
                newYtdData.totalCommunications += months[i].totalCommunications;
                newYtdData.totalEconsults += months[i].totalEconsults;
              }

              if (newChartLabels.length < 12) {
                // DateObj only created to get the month name, year is not used
                const dateObj = new Date(0, i);
                const month = format(dateObj, "MMM");
                newChartLabels.push(`${month} ${year}`);
                newChartEarningCommunications.push(
                  months[i].earningCommunications,
                );
                newChartEarningEconsult.push(months[i].earningEconsult);
                newChartEarningTotal.push(
                  months[i].earningCommunications + months[i].earningEconsult,
                );
              }
            }
          });
        setCurrentMonthData(newCurrentMonthData);
        setLastMonthData(newLastMonthData);
        setYtdData(newYtdData);
        setChartLabels(newChartLabels.reverse());
        setChartEarningCommunications(newChartEarningCommunications.reverse());
        setChartEarningEconsult(newChartEarningEconsult.reverse());
        setChartEarningTotal(newChartEarningTotal.reverse());

        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          userMessage: "Error fetching earnings summary. Please try again.",
          error: error,
        });
      });
  };

  return (
    <>
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
        />
      </Grid>
      <Grid size={{ xs: 4 }}>
        <TotalPanel
          name="current"
          label="Accepted records for this month"
          communications={currentMonthData.totalCommunications}
          econsults={currentMonthData.totalEconsults}
          commEarnings={currentMonthData.earningCommunications}
          econsultsEarnings={currentMonthData.earningEconsult}
        />
      </Grid>
      <Grid size={{ xs: 4 }}>
        <TotalPanel
          name="past"
          label="Accepted records from last month"
          communications={lastMonthData.totalCommunications}
          econsults={lastMonthData.totalEconsults}
          commEarnings={lastMonthData.earningCommunications}
          econsultsEarnings={lastMonthData.earningEconsult}
        />
      </Grid>
      <Grid size={{ xs: 4 }}>
        <TotalPanel
          name="ytd"
          label="YTD earnings"
          communications={ytdData.totalCommunications}
          econsults={ytdData.totalEconsults}
          commEarnings={ytdData.earningCommunications}
          econsultsEarnings={ytdData.earningEconsult}
        />
      </Grid>

      <Grid size={{ xs: 12 }}>
        <ChartPanel
          chartLabels={chartLabels}
          chartEarningCommunications={chartEarningCommunications}
          chartEarningEconsult={chartEarningEconsult}
          chartEarningTotal={chartEarningTotal}
        />
      </Grid>
    </>
  );
};

export default EarningSummary;
