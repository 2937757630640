import { Grid2 as Grid, Paper, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { HeadCell } from "../types";
import useBillingTable from "./useBillingTable";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
}));

const headCells: HeadCell[] = [
  { id: "created", label: "Service Date" },
  { id: "lastUpdatedAt", label: "Status Last Updated" },
  { id: "type", label: "Activity" },
  { id: "subType", label: "Sub-Activity" },
  { id: "patientId", label: "PHN" },
  { id: "status", label: "Status" },
  { id: "comments", label: "Comments" },
];

export const ClosedTable: FC = () => {
  const { classes } = useStyles();
  const { createBillingTable, createMonthPicker } = useBillingTable({
    headCells: headCells,
    fetchBy: "created",
    statuses: ["closed", "notBillable", "deleted", "failed"],
    tableKey: "closed",
  });

  return (
    <Paper className={classes.container} variant="outlined">
      <Grid container>
        <Grid size={{ xs: 12 }}>
          <span className={classes.title}>
            <Typography variant="h6" color="primary">
              Closed
            </Typography>
          </span>
        </Grid>
        <Grid>{createMonthPicker()}</Grid>
        <Grid size={{ xs: 12 }}>{createBillingTable()}</Grid>
      </Grid>
    </Paper>
  );
};
