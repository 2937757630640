import firebase from "firebase/compat/app";

function isUnparsedTimestamp1(
  value: any,
): value is { _seconds: number; _nanoseconds: number } {
  return (
    typeof value === "object" &&
    "_seconds" in value &&
    "_nanoseconds" in value &&
    Object.keys(value).length === 2
  );
}

function isUnparsedTimestamp2(
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any -- This is a typechecking function
): value is { seconds: number; nanoseconds: number } {
  return (
    typeof value === "object" &&
    "seconds" in value &&
    "nanoseconds" in value &&
    Object.keys(value).length === 2
  );
}

/**
 * This function will iterate over all keys in an object and convert any null values to undefined.
 * It will also convert any objects with _seconds and _nanoseconds keys to a firebase.firestore.Timestamp object.
 * It will also convert any objects with seconds and nanoseconds keys to a firebase.firestore.Timestamp object.
 * @param value Any object
 * @returns void. This operation is done in-place.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function nullToUndefinedAndParseTimestamps(value: any) {
  // Check if value is an object or array
  // Check if not null since typeof null is 'object'
  if ((typeof value === "object" || Array.isArray(value)) && value !== null) {
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        if (value[i] === null) {
          value[i] = undefined;
        } else if (isUnparsedTimestamp1(value[i])) {
          value[i] = new firebase.firestore.Timestamp(
            value[i]._seconds,
            value[i]._nanoseconds,
          );
        } else if (isUnparsedTimestamp2(value[i])) {
          value[i] = new firebase.firestore.Timestamp(
            value[i].seconds,
            value[i].nanoseconds,
          );
        } else if (typeof value[i] === "object") {
          value[i] = nullToUndefinedAndParseTimestamps(value[i]);
        }
      }
    } else {
      for (const key in value) {
        if (value[key] === null) {
          value[key] = undefined;
        }
        if (isUnparsedTimestamp1(value[key])) {
          value[key] = new firebase.firestore.Timestamp(
            value[key]._seconds,
            value[key]._nanoseconds,
          );
        } else if (isUnparsedTimestamp2(value[key])) {
          value[key] = new firebase.firestore.Timestamp(
            value[key].seconds,
            value[key].nanoseconds,
          );
        } else if (typeof value[key] === "object") {
          value[key] = nullToUndefinedAndParseTimestamps(value[key]);
        }
      }
    }
  }
  return value;
}

export default nullToUndefinedAndParseTimestamps;
