import {
  HeightLayout,
  HeightLayoutChild,
  ProcessState,
  ProcessStatus,
  useProcessState,
  useScreenSize,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Alert from "@mui/material/Alert";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import usePermissions from "../../../../components/usePermissions";
import isMobileDevice from "../../../../models/isMobileDevice";
import SpecialistScheduleView from "./Calendar/SpecialistScheduleView";
import SpecialistVacationView from "./Vacation/SpecialistVacationView";

const useStyles = makeStyles()((theme: Theme) => ({
  hidden: {
    display: "none",
  },
  notAvailableOnMobile: {
    margin: theme.spacing(1),
  },
}));

const vacationTab = "vacation" as const;
const phoneConsultCalendarTab = "phoneConsultCalendar" as const;
type EditSchedulePageTab = typeof vacationTab | typeof phoneConsultCalendarTab;

const EditSchedulePage: FC = () => {
  const { classes, cx } = useStyles();
  const [tab, setTab] = useState<EditSchedulePageTab>();
  const [loading, setLoading] = useState(true);

  const { heightMinusAppBar } = useScreenSize({});

  const { height, sizeRef, updateSize } = useSizeManager();
  const handleSetTab = useCallback(
    (newTab: EditSchedulePageTab) => {
      setTab(newTab);
      updateSize();
    },
    [setTab, updateSize],
  );

  const { granted: phoneConsultGranted } = usePermissions({
    resourceKey: resourceKeys.phoneConsult,
    onPermissionFetched: (granted: boolean) => {
      setLoading(false);
      if (granted) {
        handleSetTab(phoneConsultCalendarTab);
      } else {
        handleSetTab(vacationTab);
      }
    },
  });

  useEffect(() => {
    updateSize();
  }, [updateSize]);

  if (loading) {
    return <p>Loading...</p>;
  }

  // TODO the height of something here isn't being calculated correctly, which breaks the "Add Vacation" section in mobile. Need to figure out how to fix this.
  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <Tabs
          value={tab}
          onChange={(_, newTab) => {
            handleSetTab(newTab);
          }}
          orientation={"horizontal"}
        >
          <Tab
            className={cx({ [classes.hidden]: !phoneConsultGranted })}
            value={phoneConsultCalendarTab}
            label="Phone Consult Schedule"
          />
          <Tab value={vacationTab} label="Vacation" />
        </Tabs>
      </HeightLayoutChild>
      <HeightLayoutChild flexDriven ref={sizeRef}>
        {phoneConsultGranted && (
          <div
            className={cx({
              [classes.hidden]: tab !== phoneConsultCalendarTab,
            })}
          >
            {!isMobileDevice() && <SpecialistScheduleView height={height} />}
            {isMobileDevice() && (
              <Alert severity="info" className={classes.notAvailableOnMobile}>
                Phone Consult Schedule Editing is not available on mobile
                devices. Please visit this page on a desktop or laptop PC to
                edit your phone consult schedule.
              </Alert>
            )}
          </div>
        )}
        <div className={cx({ [classes.hidden]: tab !== vacationTab })}>
          <SpecialistVacationView
            hasPhoneConsultPermission={phoneConsultGranted}
            height={height}
          />
        </div>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default EditSchedulePage;
