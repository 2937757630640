import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../../palette";

const useStyles = makeStyles()(() => ({
  bar: {
    backgroundColor: palette.darkGreenDark,
    height: "25px",
    width: "100%",
  },
  header: {
    top: 0,
    left: 0,
    position: "absolute",
  },
  footer: {
    bottom: 0,
    left: 0,
    position: "absolute",
  },
}));

interface PageHeaderFooterBarsProps {
  position: "header" | "footer";
}

const PageHeaderFooterBars: FC<PageHeaderFooterBarsProps> = ({ position }) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        {
          [classes.header]: position === "header",
          [classes.footer]: position === "footer",
        },
        classes.bar,
      )}
    />
  );
};

export default PageHeaderFooterBars;
