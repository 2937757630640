import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  outcomeText: {
    color: theme.palette.primary.main,
    whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
    wordWrap: "break-word", //break lines on word to fit
    fontWeight: 600,
  },
  outcomeTextSmall: {
    fontSize: "1.0em",
  },
}));

interface EconsultOutcomesLabelProps {
  specialistResponse?: {
    outcome: string;
    diagnosis: string;
  };
  fontSize?: "small" | "large";
}

const EconsultOutcomesLabel: FC<EconsultOutcomesLabelProps> = ({
  specialistResponse,
  fontSize = "large",
}: EconsultOutcomesLabelProps) => {
  const { classes, cx } = useStyles();

  return (
    <>
      {specialistResponse !== undefined && (
        <>
          <Typography
            className={cx(
              {
                [classes.outcomeTextSmall]: fontSize === "small",
              },
              classes.outcomeText,
            )}
          >
            Outcome: {specialistResponse.outcome}
          </Typography>
          <Typography
            className={cx(
              {
                [classes.outcomeTextSmall]: fontSize === "small",
              },
              classes.outcomeText,
            )}
          >
            Diagnosis: {specialistResponse.diagnosis}
          </Typography>
        </>
      )}
    </>
  );
};

export default EconsultOutcomesLabel;
