import { projectEnv } from "../firebase";

/**
 * Creates a console log function based on environment. If in prod function does nothing. Otherwise use normal console.log
 * A function factory is used so projectEnv, which will never change, is not compared every time devConsole.log is called
 * @returns
 */
function createLogFunction(): (
  message?: any,
  ...optionalParams: any[]
) => void {
  if (projectEnv === "dev" || projectEnv === "local") return console.log;
  else return () => {}; // eslint-disable-line @typescript-eslint/no-empty-function
}

const log = createLogFunction();

const devConsole = {
  log,
};

export default devConsole;
