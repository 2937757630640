import {
  PaperPage,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { defaultRoles, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import {
  UserNotificationPreferences,
  UserProfile,
} from "@alethea-medical/aletheamd-types";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { Control, useForm } from "react-hook-form";
import { TypedFormRadioGroup } from "src/components/FormInputFields/FormRadioGroup";
import { TypedFormTextField } from "src/components/FormInputFields/FormTextField";
import {
  getDefaultHomepageKey,
  isValidHomepagePreferenceForUser,
  validHomepageRoutes,
  validHomepageRoutesResourceKeys,
} from "src/routes";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../AuthProvider";
import { TypedEmailInput } from "../../../components/FormInputFields/EmailInput";
import { TypedFormCheckbox } from "../../../components/FormInputFields/FormCheckbox";
import { TypedFormNumberField } from "../../../components/FormInputFields/FormNumberField";
import { TypedPhoneInput } from "../../../components/FormInputFields/PhoneInput";
import FormattedInputLabel from "../../../components/FormattedInputLabel";
import HelpModal from "../../../components/HelpModal/HelpModal";
import usePermissions, {
  multiPermissionsSatisfies,
  useMultiPermissions,
} from "../../../components/usePermissions";
import { subOrUnsubToAletheaNews } from "../../../config/usePushNotifications";
import { fbFirestore, logAnalyticsEvent } from "../../../firebase";
import getProfile from "../../../models/getProfile";
import isNativeMobile from "../../../models/isNativeMobile";
import NotificationCollapse from "./NotificationCollapse";
import ProfileLocations from "./ProfileLocations";

const useStyles = makeStyles()((theme: Theme) => ({
  page: {
    padding: theme.spacing(1),
  },
  indented: {
    marginLeft: theme.spacing(3),
  },
}));

/**
 * Return default fax prefs if isDefault is true. Default will use what is provided, but will default to deprecated values if the provided value doesn't exist
 * ie secureMessage.outgoing.enabled will default to true if secureMessaging is true, or if receiveFaxes is true
 *
 * If isDefault is false, will return fax prefs based on the form data
 */
function getFaxPrefs(
  data: Partial<UserProfile>,
  isDefault?: boolean,
): UserNotificationPreferences["fax"] {
  if (isDefault) {
    const secureMsgFaxEnabled =
      data.preferences?.fax?.secureMessaging ??
      data.preferences?.receiveFaxes ??
      true;
    const formFaxEnabled = data.preferences?.receiveFaxes ?? true;
    return {
      // If undefined, default to formFaxEnabled
      forms: data.preferences?.fax?.forms ?? formFaxEnabled,
      econsult: {
        // If undefined, default to secureMsgFaxEnabled
        outgoing: {
          enabled:
            data.preferences?.fax?.econsult?.outgoing?.enabled ??
            secureMsgFaxEnabled,
        },
        incoming: {
          enabled:
            data.preferences?.fax?.econsult?.incoming?.enabled ??
            secureMsgFaxEnabled,
        },
      },
      secureMessage: {
        outgoing: {
          enabled:
            data.preferences?.fax?.secureMessage?.outgoing?.enabled ??
            secureMsgFaxEnabled,
        },
        incoming: {
          options: {
            // If undefined, default to false
            onlyFirstConsultantMessage:
              data.preferences?.fax?.secureMessage?.incoming?.options
                ?.onlyFirstConsultantMessage ?? false,
          },
          enabled:
            data?.preferences?.fax?.secureMessage?.incoming?.enabled ??
            secureMsgFaxEnabled,
        },
      },
      faxPdfSettings: {
        // if undefined, default to true
        includePdfs: data.preferences?.fax?.faxPdfSettings?.includePdfs ?? true,
        includeImages:
          data.preferences?.fax?.faxPdfSettings?.includeImages ?? true,
      },
    };
  } else {
    return {
      /** Deprecated in Version 8.4.2 */
      // secureMessaging: userData.preferences?.fax?.secureMessaging === true
      forms: data.preferences?.fax?.forms === true,
      econsult: {
        outgoing: {
          enabled: data.preferences?.fax?.econsult?.outgoing?.enabled === true,
        },
        incoming: {
          enabled: data.preferences?.fax?.econsult?.incoming?.enabled === true,
        },
      },
      secureMessage: {
        outgoing: {
          enabled:
            data.preferences?.fax?.secureMessage?.outgoing?.enabled === true,
        },
        incoming: {
          options: {
            onlyFirstConsultantMessage:
              data.preferences?.fax?.secureMessage?.incoming?.options
                ?.onlyFirstConsultantMessage === true,
          },
          enabled:
            data?.preferences?.fax?.secureMessage?.incoming?.enabled === true,
        },
      },
      // If undefined, default to true (so old behaviour is unchanged)
      faxPdfSettings: {
        includePdfs:
          data.preferences?.fax?.faxPdfSettings?.includePdfs === true,
        includeImages:
          data.preferences?.fax?.faxPdfSettings?.includeImages === true,
      },
    };
  }
}

const Profile: FC = () => {
  const { classes } = useStyles();

  const authContext = useContext(AuthContext);
  const [isSpecialist, setIsSpecialist] = useState(false);

  const { permissionDict } = useMultiPermissions({
    resourceKeys: validHomepageRoutesResourceKeys,
  });

  //Returns formatted profile to ensure we don't overwrite any values accidentally
  const getProfileValues = (
    data?: Partial<UserProfile>,
    isDefault?: boolean,
  ) => {
    const basePrefs = data?.preferences ?? {};

    if (data !== undefined) {
      const newProfile: Partial<UserProfile> = {
        practiceId: data.practiceId,
        credentials: data.credentials,
        locations: data.locations !== undefined ? data.locations : [],
        preferences: {
          ...basePrefs, // Populate all pre-existing preferences (in case this form doesn't contains all preferences)
          email: {
            // Then update with form values
            secureMessaging: data.preferences?.email?.secureMessaging === true,
            /** If forms preference does not exist on profile, then default to using the secure messaging value (Legacy - Pre-v8.5.4.2023.09.28: use secure messaging to check to send this email or not) */
            submittedPatientForms:
              data.preferences?.email?.submittedPatientForms ??
              data.preferences?.email?.secureMessaging === true,
            failedEmails: data.preferences?.email?.failedEmails === true,
            overdueSecureMessages:
              data.preferences?.email?.overdueSecureMessages === true,
          },
          fax: getFaxPrefs(data, isDefault),
          mobilePushNotifications: {
            /** If mobile push notification preferences do not exist on profile, then default to true (Legacy - Pre-v8.5.4.2023.09.28: did not check any preference) */
            secureMessaging:
              data.preferences?.mobilePushNotifications?.secureMessaging ??
              true,
            econsult:
              data.preferences?.mobilePushNotifications?.econsult ?? true,
            aletheaNews:
              data.preferences?.mobilePushNotifications?.aletheaNews ?? true,
          },
          secureMessaging: {
            nudgeOverdueMessages:
              data.preferences?.secureMessaging?.nudgeOverdueMessages === true,
          },
          phoneConsult: {
            phoneNumber: data.preferences?.phoneConsult?.phoneNumber ?? "",
            calendarEmail: data.preferences?.phoneConsult?.calendarEmail ?? "",
          },
          homepage:
            data.preferences?.homepage &&
            isValidHomepagePreferenceForUser(
              permissionDict,
              data.preferences?.homepage,
            )
              ? data.preferences.homepage
              : getDefaultHomepageKey(
                  permissionDict,
                  authContext.profile?.userRoles ?? [],
                ),
        },
      };
      return newProfile;
    }
  };

  const { handleSubmit, control, reset, watch, setValue } = useForm({
    mode: "onTouched",
    defaultValues: useMemo(
      () => getProfileValues(authContext.profile, true),
      [authContext.profile],
    ),
  });

  // Set homepage when permissions are loaded
  useEffect(() => {
    if (
      Object.keys(permissionDict).length > 0 &&
      authContext.profile?.userRoles
    ) {
      const defaultHomepage = getDefaultHomepageKey(
        permissionDict,
        authContext.profile.userRoles,
      );
      const homepageSetting = authContext.profile?.preferences?.homepage;
      const homepage =
        homepageSetting &&
        isValidHomepagePreferenceForUser(permissionDict, homepageSetting)
          ? homepageSetting
          : defaultHomepage;

      setValue("preferences.homepage", homepage);
    }
  }, [authContext.profile?.userRoles, permissionDict, setValue]);

  const incomingSecureMessagesEnabled = watch(
    "preferences.fax.secureMessage.incoming.enabled",
  );

  useEffect(() => {
    if (incomingSecureMessagesEnabled !== true) {
      setValue(
        "preferences.fax.secureMessage.incoming.options.onlyFirstConsultantMessage",
        false,
      );
    }
  }, [incomingSecureMessagesEnabled]);

  useEffect(() => {
    reset(getProfileValues(authContext.profile, true));
  }, [authContext.profile]);

  useEffect(() => {
    setIsSpecialist(
      (authContext.profile?.userRoles ?? []).includes(defaultRoles.specialist),
    );
  }, [authContext.profile?.userRoles]);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);

    // Trim input values for each clinic location
    data.locations.forEach((l: any) => {
      if (l.clinicName) l.clinicName = l.clinicName.trim();
      if (l.city) l.city = l.city.trim();
      l.emr = l.emr?.trim() ?? "";
    });

    //Type guard to protect against updating fields we don't want to update
    //Will always be defined since we are passing data and not relying on authContext
    const newProfile = getProfileValues(data) as Partial<UserProfile>;

    fbFirestore
      .collection("users")
      .doc(authContext.uid)
      .update(newProfile)
      .then(() => {
        setProcessState(ProcessState.success);
        getProfile(authContext.uid).then((result: UserProfile | undefined) => {
          if (result !== undefined) {
            authContext.setProfile(result);
            if (isNativeMobile()) {
              /** Update alethea news notification preferences on mobile */
              return subOrUnsubToAletheaNews(result);
            }
          }
        });
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);
      })
      .catch((error) => {
        errorHandler({
          error: error,
          userMessage: "Error updating profile",
          hideErrorMessage: true,
        });
      });
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  const { granted: hasPhoneConsultPermissions } = usePermissions({
    resourceKey: resourceKeys.phoneConsult,
  });
  const { granted: hasFormsPermission } = usePermissions({
    resourceKey: resourceKeys.forms,
  });

  return (
    <PaperPage enablePadding>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <fieldset disabled={isDisabled()}>
          <Grid container spacing={2} className={classes.page}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Grid container spacing={2}>
                <Grid size={{ xs: 12 }}>
                  <FormattedInputLabel>Profile</FormattedInputLabel>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <TypedFormNumberField
                    name="practiceId"
                    control={control}
                    label="Practice ID"
                    rules={{
                      required: {
                        value: true,
                        message: "Practice ID is required",
                      },
                    }}
                  />
                  <TypedFormTextField
                    name={`credentials`}
                    control={control}
                    label="Credentials"
                  />
                </Grid>
                {hasPhoneConsultPermissions && (
                  <>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <FormattedInputLabel>
                        Phone number for phone consults
                      </FormattedInputLabel>
                      <TypedPhoneInput
                        name="preferences.phoneConsult.phoneNumber"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Phone number is required",
                          },
                        }}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, md: 6 }}>
                      <FormattedInputLabel>
                        Email to send phone consult calendar invites to
                      </FormattedInputLabel>
                      <TypedEmailInput
                        name="preferences.phoneConsult.calendarEmail"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                        }}
                      />
                    </Grid>
                  </>
                )}

                <NotificationCollapse title="Fax Settings">
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                      <FormattedInputLabel>
                        Send me a fax for
                      </FormattedInputLabel>
                    </Grid>
                    {hasFormsPermission && (
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.fax.forms"
                          control={control}
                          label="Forms my patients submit"
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.fax.econsult.outgoing.enabled"
                        control={control}
                        label="Consults I send"
                      />
                    </Grid>
                    {isSpecialist && (
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.fax.econsult.incoming.enabled"
                          control={control}
                          label="Consults I receive"
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.fax.secureMessage.outgoing.enabled"
                        control={control}
                        label="Secure messages I send"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.fax.secureMessage.incoming.enabled"
                        control={control}
                        label="Secure messages I receive"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }} className={classes.indented}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid>
                          <TypedFormCheckbox
                            name="preferences.fax.secureMessage.incoming.options.onlyFirstConsultantMessage"
                            control={control}
                            label="Only send a fax for the first specialist response to my Consult"
                            disabled={incomingSecureMessagesEnabled !== true}
                          />
                        </Grid>
                        <Grid>
                          <HelpModal
                            helpText={[
                              "If this setting is enabled, any follow up messages sent from the specialist will not be faxed to you. You will only receive a fax for the first message sent by the specialist.",
                            ]}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <FormattedInputLabel>
                        Images and PDFs in Faxes
                      </FormattedInputLabel>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.fax.faxPdfSettings.includeImages"
                        control={control}
                        label="Include attached images in faxes sent to me"
                      />
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.fax.faxPdfSettings.includePdfs"
                        control={control}
                        label="Include attached PDFs in faxes sent to me"
                      />
                    </Grid>
                  </Grid>
                </NotificationCollapse>

                <NotificationCollapse title="Email Notifications">
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                      <FormattedInputLabel>
                        Email Notifications
                      </FormattedInputLabel>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.email.secureMessaging"
                        control={control}
                        label="Receive email notifications for new messages."
                      />
                    </Grid>
                    {isSpecialist && (
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.email.overdueSecureMessages"
                          control={control}
                          label="Receive email notifications for suggested eConsults to reply to"
                        />
                      </Grid>
                    )}
                    {hasFormsPermission && (
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.email.submittedPatientForms"
                          control={control}
                          label="Receive an email notification when a patient submits a form."
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.email.failedEmails"
                        control={control}
                        label="Receive an email notifications when a patient email fails to send."
                      />
                    </Grid>
                  </Grid>
                </NotificationCollapse>

                <NotificationCollapse title="Mobile Push Notifications">
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                      <FormattedInputLabel>
                        Mobile Push Notifications
                      </FormattedInputLabel>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.mobilePushNotifications.secureMessaging"
                        control={control}
                        label="Receive mobile push notifications for new messages."
                      />
                    </Grid>
                    {isSpecialist && (
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.mobilePushNotifications.econsult"
                          control={control}
                          label="Receive mobile push notifications for new eConsults."
                        />
                      </Grid>
                    )}
                    <Grid size={{ xs: 12 }}>
                      <TypedFormCheckbox
                        name="preferences.mobilePushNotifications.aletheaNews"
                        control={control}
                        label="Receive mobile push notifications for Alethea News."
                      />
                    </Grid>
                  </Grid>
                </NotificationCollapse>
                <NotificationCollapse title="Homepage Settings">
                  <Grid container spacing={1}>
                    <Grid size={{ xs: 12 }}>
                      <FormattedInputLabel>
                        Set my default homepage to
                      </FormattedInputLabel>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <TypedFormRadioGroup
                        control={control}
                        name="preferences.homepage"
                        label="Homepage"
                        options={Object.entries(validHomepageRoutes)
                          .filter(([, route]) =>
                            multiPermissionsSatisfies(
                              permissionDict,
                              route.requiredResourcePermissions,
                            ),
                          )
                          .map(([key]) => key)}
                        displayOption={(option) =>
                          validHomepageRoutes[option].name ?? option
                        }
                      />
                    </Grid>
                  </Grid>
                </NotificationCollapse>
                {isSpecialist && (
                  <NotificationCollapse title="Secure Messaging Settings">
                    <Grid container spacing={1}>
                      <Grid size={{ xs: 12 }}>
                        <FormattedInputLabel></FormattedInputLabel>
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <TypedFormCheckbox
                          name="preferences.secureMessaging.nudgeOverdueMessages"
                          control={control}
                          label="Suggested eConsults to reply to appear at the top of your inbox"
                        />
                      </Grid>
                    </Grid>
                  </NotificationCollapse>
                )}
              </Grid>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <ProfileLocations
                setValue={setValue}
                control={control as Control<UserProfile>}
              />
            </Grid>

            <Grid size={{ xs: 12 }}>
              <ProcessStatus
                state={processState}
                errorMessage={processErrorMessage}
                successMessage="Profile saved."
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </fieldset>
      </form>
    </PaperPage>
  );
};

export default Profile;
