import FormSelect from "../FormSelect";
import { ControlledInputProps } from "../types";

interface FormProvinceSelectProps extends ControlledInputProps {
  /** Optionally provide options that are enabled. The rest will be disabled */
  enabledOptions?: string[];
  sortByEnabled?: boolean;
}

const FormProvinceSelect = ({
  defaultValue,
  enabledOptions,
  sortByEnabled,
  ...rest
}: FormProvinceSelectProps) => {
  const provinces = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NT",
    "NS",
    "NU",
    "ON",
    "PE",
    "QC",
    "SK",
    "YT",
  ];
  const options =
    sortByEnabled && enabledOptions !== undefined
      ? provinces.sort((a, b) => {
          if (enabledOptions.includes(a) && !enabledOptions.includes(b))
            return -1;
          if (!enabledOptions.includes(a) && enabledOptions.includes(b))
            return 1;
          return 0;
        })
      : provinces;

  return (
    <FormSelect
      {...rest}
      options={options}
      getOptionDisabled={
        enabledOptions === undefined
          ? undefined
          : (option: string) => !enabledOptions.includes(option)
      }
      defaultValue={defaultValue ? defaultValue : "AB"}
    />
  );
};

export default FormProvinceSelect;
