import React from "react";
import CareForms from "src/views/Pages/CareForms";
import { buildRoute } from "../internalHelpers";
import careFormsChildrenRoutes, {
  CareFormsRouteName,
  CareFormsRouteNames,
  CARE_FORMS_ROUTE,
} from "./careFormsRoutesData";

/**
 * Elements to display for each route
 */
const careFormsRouteElements: {
  [key in CareFormsRouteName]: React.ReactElement;
} = {
  [CareFormsRouteNames.careForms]: <CareForms />,
};

/**
 * Creates the route
 */
const careFormsRoute = buildRoute(
  careFormsChildrenRoutes,
  careFormsRouteElements,
  CareFormsRouteNames,
  CARE_FORMS_ROUTE,
);

export default careFormsRoute;
