import { SidebarItemProps as AletheaSidebarItemProps } from "@alethea-medical/alethea-components";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useEffect } from "react";
import { PermissionAppRoute } from "src/types/AppRoute";
import { makeStyles } from "tss-react/mui";
import { useRoutePermissions } from "../../../components/PermissionRoute";
import palette from "../../../palette";
import { CollapseSidebarContext } from "./CollapseSidebarItem";
import AletheaSidebarItem from "./components/AletheaSidebarItem";

const useStyles = makeStyles()((theme: Theme) => ({
  drawerButtonSelected: {
    backgroundColor: palette.darkGreen,
    "&:hover": {
      backgroundColor: palette.darkGreen,
    },
  },
  drawerButtonTextSelected: {
    color: "white",
  },
  icon: {
    paddingTop: theme.spacing(0.5),
  },
  iconSelected: {
    color: "white",
  },
  iconUnselected: {
    color: palette.darkGreen,
  },
}));

interface SidebarItemProps extends AletheaSidebarItemProps {
  route: PermissionAppRoute;
}
const SidebarItem: FC<SidebarItemProps> = (props: SidebarItemProps) => {
  const { classes, cx } = useStyles();
  const { permissionGranted } = useRoutePermissions(props.route);

  // Tell the parent collapse item that there is at least one sidebar item that is enabled
  // If this item is not a child of a collapse item, then the function call just calls an empty function
  const collapseSidebarContext = useContext(CollapseSidebarContext);
  useEffect(() => {
    if (permissionGranted) collapseSidebarContext.atLeastOneAvailable();
  }, [permissionGranted]);

  const selected =
    props.currentPath !== undefined && props.currentPath === props.route?.path;

  return (
    <AletheaSidebarItem
      {...props}
      enabled={permissionGranted}
      classes={{
        drawerButtonSelected: classes.drawerButtonSelected,
      }}
      textClass={cx(props.textClass, {
        [classes.drawerButtonTextSelected]: selected,
      })}
      icon={
        <span
          className={cx(classes.icon, {
            [classes.iconSelected]: selected,
            [classes.iconUnselected]: !selected,
          })}
        >
          {props.icon}
        </span>
      }
      className={"aletheaSidebarItem"}
    />
  );
};

export default SidebarItem;
