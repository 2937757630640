import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import RefreshIcon from "@mui/icons-material/RefreshOutlined";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { Payment } from "../../../../../shared/types";
import { fbFunctions } from "../../../../firebase";
import parseJSONTimestamp from "../../../../models/parseJSONTimestamp";
import ActivityPayment from "./ActivityPayment";
import EconsultCard from "./EconsultCard";
import EconsultCardExpandHeader from "./EconsultCardExpandHeader";

interface ActivityPaymentListProps {
  activityId: string;
}

export interface GetPaymentsResponse {
  payment: Payment.Payment;
  paymentId: string;
  recordedBy: string;
}

const ActivityPaymentList: FC<ActivityPaymentListProps> = ({ activityId }) => {
  const [expandPayments, setExpandPayments] = useState(true);

  const [prevPayments, setPrevPayments] = useState<GetPaymentsResponse[]>([]);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = () => {
    setProcessState(ProcessState.running);
    fbFunctions
      .httpsCallable("secureMessaging-getActivityPayments")({ activityId })
      .then((response) => {
        setProcessState(ProcessState.idle);
        setPrevPayments(
          response.data.map((p: GetPaymentsResponse) => {
            p.payment.created = parseJSONTimestamp(p.payment.created);
            p.payment.statusUpdatedAt = parseJSONTimestamp(
              p.payment.statusUpdatedAt,
            );
            return p;
          }),
        );
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error fetching payment history",
          hideErrorMessage: true,
        });
      });
  };

  return (
    <EconsultCard
      titleComponent={
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid>
            <EconsultCardExpandHeader
              title="Payments"
              expand={expandPayments}
              setExpand={setExpandPayments}
            />
          </Grid>
          <Grid>
            <IconButton onClick={getPayments} size="large">
              <RefreshIcon />
            </IconButton>
          </Grid>
        </Grid>
      }
    >
      {prevPayments.length > 0 && (
        <Collapse in={expandPayments}>
          <Grid container spacing={1}>
            {prevPayments.map((p, i) => (
              <Grid size={{ xs: 12 }} key={`payments_${p.payment.name}_${i}`}>
                <ActivityPayment payment={p} />
              </Grid>
            ))}
          </Grid>
        </Collapse>
      )}
      {prevPayments.length === 0 && processState === ProcessState.idle && (
        <Typography variant="subtitle1">No Payments</Typography>
      )}
      <ProcessStatus
        state={processState}
        errorMessage={processErrorMessage}
        loadingMessage="Loading Payments..."
      />
    </EconsultCard>
  );
};

export default ActivityPaymentList;
