import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import firebase from "firebase/compat/app";
import { FC, useContext } from "react";
import { formatTimeDateThenTime } from "../../../../../models/formatTime";
import { ActivityContext } from "../../Inbox/Contexts/ActivityProvider";

const PrevInterpretationsView: FC = () => {
  const activityContext = useContext(ActivityContext);

  const getInterpString = (
    interpretationType: string,
    created: firebase.firestore.Timestamp,
  ): string => {
    switch (interpretationType) {
      case "sinusEndoscopy":
        return `A Sinus Endoscopy Interpretation was billed for this patient ${formatTimeDateThenTime(created.toDate())}`;
      case "audiogram":
        return `An Audiogram Interpretation was billed for this patient ${formatTimeDateThenTime(created.toDate())}`;
      default:
        return "";
    }
  };

  return (
    <Grid container>
      {(activityContext.econsult.interpretations ?? []).map((interp, i) => (
        <Grid
          size={{ xs: 12 }}
          key={`interp_${interp.created.toMillis()}_${i}`}
        >
          <Typography>
            {getInterpString(interp.interpretationType, interp.created)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

export default PrevInterpretationsView;
