import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import { set } from "date-fns";
import firebase from "firebase/compat/app";
import { ChangeEvent, FC } from "react";
import { makeStyles } from "tss-react/mui";
import { SpecialistAvailability } from "../../../../../../shared/types";
import VacationDatePicker from "./VacationDatePicker";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1),
  },
}));

interface VacationRowProps {
  index: number;
  vacationPeriod: SpecialistAvailability.VacationPeriod;
  removeRowHandler: (index: number) => void;
  updateRowHandler: (
    index: number,
    newValue: SpecialistAvailability.VacationPeriod,
  ) => void;
  hasPhoneConsultPermission: boolean;
}

const VacationRow: FC<VacationRowProps> = ({
  index,
  vacationPeriod,
  removeRowHandler,
  updateRowHandler,
  hasPhoneConsultPermission,
}) => {
  const { classes } = useStyles();

  const onStartChange = (newStart: Date | null) => {
    if (!newStart) return;

    const updatedStart = set(newStart, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });

    if (newStart.valueOf() > vacationPeriod.end.toMillis()) {
      // If start ends after end, then set end to start
      const newEnd = new Date(newStart);
      const updatedEnd = set(newEnd, {
        hours: 23,
        minutes: 59,
        seconds: 59,
        milliseconds: 999,
      });

      updateRowHandler(index, {
        ...vacationPeriod,
        start: firebase.firestore.Timestamp.fromMillis(updatedStart.valueOf()),
        end: firebase.firestore.Timestamp.fromMillis(updatedEnd.valueOf()),
      });
    } else {
      updateRowHandler(index, {
        ...vacationPeriod,
        start: firebase.firestore.Timestamp.fromMillis(updatedStart.valueOf()),
      });
    }
  };

  const onEndChange = (newEnd: Date | null) => {
    if (!newEnd) return;

    // Set to end of day
    const updatedEnd = set(newEnd, {
      hours: 23,
      minutes: 59,
      seconds: 59,
      milliseconds: 999,
    });

    updateRowHandler(index, {
      ...vacationPeriod,
      end: firebase.firestore.Timestamp.fromMillis(updatedEnd.valueOf()),
    });
  };

  const onDescriptionChanged = (e: ChangeEvent<HTMLInputElement>) => {
    updateRowHandler(index, {
      ...vacationPeriod,
      description: e.target.value,
    });
  };

  const onDisableEconsultChecked = (_: unknown, checked: boolean) => {
    updateRowHandler(index, {
      ...vacationPeriod,
      disableEconsult: !checked, // Invert value, since checked === true means enable econsult
    });
  };

  const onDisablePhoneConsultChecked = (_: unknown, checked: boolean) => {
    updateRowHandler(index, {
      ...vacationPeriod,
      disablePhoneConsult: !checked, // Invert value, since checked === true means enable phone consult
    });
  };

  const onDelete = () => {
    removeRowHandler(index);
  };

  return (
    <Paper className={classes.paper} elevation={3}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid size={{ xs: "grow" }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid size={{ xs: 12, md: 6 }}>
              <VacationDatePicker
                value={vacationPeriod.start.toDate()}
                label={"From"}
                dateChangeHandler={onStartChange}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <VacationDatePicker
                value={vacationPeriod.end.toDate()}
                label={"To"}
                dateChangeHandler={onEndChange}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <TextField
                variant="outlined"
                label="Name (Optional)"
                value={vacationPeriod.description}
                onChange={onDescriptionChanged}
                margin="dense"
                fullWidth
              />
            </Grid>
            {hasPhoneConsultPermission && (
              // Don't show either option if don't have permissions for phone consults (since it only makes sense when you can see both)
              // By default the values are both set to true
              <>
                <Grid>
                  <FormControlLabel
                    label={"Available for eConsults"}
                    checked={!vacationPeriod.disableEconsult} // Invert value, since it makes more sense for the user the other way
                    onChange={onDisableEconsultChecked}
                    control={<Checkbox color="primary" disableRipple />}
                  />
                </Grid>
                <Grid>
                  <FormControlLabel
                    label={"Available for Phone Consults"}
                    checked={!vacationPeriod.disablePhoneConsult} // Invert value, since it makes more sense for the user the other way
                    onChange={onDisablePhoneConsultChecked}
                    control={<Checkbox color="primary" disableRipple />}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <Grid>
          <IconButton onClick={onDelete} size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      {vacationPeriod.start > vacationPeriod.end && (
        <FormHelperText error>
          {'"From" date must be before "To" date'}
        </FormHelperText>
      )}
    </Paper>
  );
};

export default VacationRow;
