import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AISpecialty } from "../../../../../../shared/types";
import useAITranslation from "../../../../../components/useAITranslation";
import isNativeMobile from "../../../../../models/isNativeMobile";
import isWeb from "../../../../../models/isWeb";
import palette from "../../../../../palette";
import { ImageAnnotationSelectedDict } from "./EconsultImageAnnotationModel";
import useCustomAILabels from "./useCustomAILabels";

const useStyles = makeStyles()((theme: Theme) => ({
  autoCompleteOption: {
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.grey[200],
      borderColor: "transparent",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: palette.lightGreenLight,
      borderColor: "transparent",
    },
  },
}));
interface AddLabelsDropdownProps {
  specialty: string;
  labelHandler: (
    items: ImageAnnotationSelectedDict,
    aiLabels: string[],
  ) => void;
  unselectAllHandler: () => void;
  selectedMedia: ImageAnnotationSelectedDict;
}

const AddLabelsDropdown: FC<AddLabelsDropdownProps> = ({
  specialty,
  labelHandler,
  unselectAllHandler,
  selectedMedia,
}) => {
  const { classes } = useStyles();
  const [labels, setLabels] = useState<string[]>([]);
  const { spToAi, spLabelList, checkAILabelExists } = useAITranslation({
    useSpToAi: true,
    useSpLabelList: true,
  });
  const { customAILabels, addNewLabelsToState } = useCustomAILabels();

  const handleAddLabels = () => {
    //Pass true to spToAi so it returns input label if not found (for custom labels)
    const aiLabels = labels.map((sp) => spToAi(sp, true));

    updateCustomLabels(aiLabels);

    labelHandler(selectedMedia, aiLabels);
    setLabels([]);
    unselectAllHandler();
  };

  const updateCustomLabels = (labels: string[]) => {
    // Update custom labels in state. When user presses save, they will be saved to their custom label list in firestore
    //If label doesn't' exist in translation table, and doesn't exist in custom labels, then its a new custom label
    const newLabels: string[] = [];
    labels.forEach((label) => {
      if (!checkAILabelExists(label)) {
        if (!customAILabels.includes(label)) {
          newLabels.push(label);
        }
      }
    });
    addNewLabelsToState(newLabels);
  };

  const getHelperText = (inputValue: string, hasFocus: boolean) => {
    //If the textfield value could exist in the list, don't show helper text
    //If not focused, don't show helper text
    if (
      !hasFocus ||
      inputValue.length === 0 ||
      spLabelList[specialty as AISpecialty].some((t) =>
        t.toLowerCase().includes(inputValue.toLowerCase()),
      )
    )
      return undefined;

    //If no results showing, show helper text to add a new tag
    return "Press enter to create a new label";
  };

  const getLabelList = () => {
    const defaultLabels = spLabelList[specialty as AISpecialty] ?? [];
    return defaultLabels.concat(customAILabels).sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) return -1;
      else if (a.toLowerCase() > b.toLowerCase()) return 1;
      else return 0;
    });
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid size={{ xs: 6 }}>
        <Autocomplete
          classes={{
            option: classes.autoCompleteOption,
          }}
          value={labels}
          options={getLabelList()}
          getOptionLabel={(option: string) => option}
          onChange={(e, value) => {
            setLabels(value as string[]);
          }}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              placeholder="Add labels"
              margin="dense"
              helperText={
                isWeb() &&
                typeof params.inputProps.value === "string" &&
                getHelperText(
                  params.inputProps.value,
                  document.activeElement === params.inputProps.ref,
                )
              }
              slotProps={{
                htmlInput: {
                  ...params.inputProps,
                  enterKeyHint: "done", //Allow enter to add tag on mobile
                },
              }}
            />
          )}
          autoSelect={isNativeMobile()}
          multiple
          freeSolo
          clearOnBlur
          autoHighlight
        />
      </Grid>
      <Grid size={{ xs: 6 }}>
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          onClick={handleAddLabels}
          disabled={
            Object.keys(selectedMedia).length === 0 || labels.length === 0
          }
        >
          Label Images
        </Button>
      </Grid>
    </Grid>
  );
};

export default AddLabelsDropdown;
