import SearchIcon from "@mui/icons-material/Search";
import Button, { ButtonProps } from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, startOfDay, subDays, subMonths, subYears } from "date-fns";
import { FC, useEffect, useState } from "react";
import { SearchProps } from "../Controllers/GalleryController";
import MediaEditTags from "./MediaViewer/MediaEditTags";

type DateChoices = "week" | "month" | "6month" | "year" | "custom";

interface GallerySearchBarProps {
  searchProps: SearchProps;
}

const GallerySearchBar: FC<GallerySearchBarProps> = ({ searchProps }) => {
  const {
    searchDateFrom,
    setSearchDateFrom,
    searchDateTo,
    setSearchDateTo,
    searchNotes,
    setSearchNotes,
    searchTags,
    setSearchTags,
    enableSearch,
    performSearch,
    cancelSearch,
  } = searchProps;

  const [show, setShow] = useState(false);
  const [dateRangeChoice, setDateRangeChoice] = useState<DateChoices>("week");

  const handleShow = () => {
    setShow(!show);
  };

  const handleChangeDateFrom = (date: Date | null, keepChoice?: boolean) => {
    //Don't change choice if called from date range picker
    //Otherwise, if date changed by user, change choice to custom
    if (!keepChoice) setDateRangeChoice("custom");

    if (date === null) {
      return;
    }

    return setSearchDateFrom(startOfDay(date));
  };

  const handleChangeDateFromDatepicker = (date: Date | null) =>
    handleChangeDateFrom(date);

  const handleChangeDateTo = (date: Date | null, keepChoice?: boolean) => {
    if (!keepChoice) setDateRangeChoice("custom");

    if (date === null) {
      return;
    }
    return setSearchDateTo(endOfDay(date));
  };

  const handleChangeDateToDatepicker = (date: Date | null) => {
    handleChangeDateTo(date);
  };

  const handleTagsChanged = (newTags: string[]) => {
    setSearchTags(newTags);
    clearNotesIfDisabled(searchDateFrom, searchDateTo, newTags);
  };

  const clearNotesIfDisabled = (
    searchDateFrom: Date | null,
    searchDateTo: Date | null,
    searchTags: string[],
  ) => {
    if (
      searchDateFrom === null &&
      searchDateTo === null &&
      searchTags.length === 0
    )
      setSearchNotes("");
  };

  const isSearchButtonDisabled = () => {
    return (
      searchDateFrom === null &&
      searchDateTo === null &&
      searchTags.length === 0
    );
  };

  const dateRangeChangedHandler = (choice: DateChoices) => {
    const from = new Date();
    const to = new Date();
    setDateRangeChoice(choice);

    switch (choice) {
      case "week":
        handleChangeDateFrom(subDays(from, 7), true);
        handleChangeDateTo(to, true);
        break;
      case "month":
        handleChangeDateFrom(subMonths(from, 1), true);
        handleChangeDateTo(to, true);
        break;
      case "6month":
        handleChangeDateFrom(subMonths(from, 6), true);
        handleChangeDateTo(to, true);
        break;
      case "year":
        handleChangeDateFrom(subYears(from, 1), true);
        handleChangeDateTo(to, true);
        break;
      case "custom":
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    //Set initial date range on first load
    dateRangeChangedHandler(dateRangeChoice);
  }, []);

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid>
        <Button
          onClick={handleShow}
          variant="outlined"
          color="primary"
          startIcon={<SearchIcon />}
        >
          {show ? "Hide" : "Show"} Search
        </Button>
      </Grid>
      {!show && enableSearch && (
        <Grid>
          <Button color="primary" variant="outlined" onClick={cancelSearch}>
            Clear Search
          </Button>
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <Collapse in={show}>
          <Grid container alignItems="center" spacing={1}>
            <Grid size={{ xs: 12, md: 4 }}>
              <Select
                defaultValue="week"
                value={dateRangeChoice}
                onChange={(e) => {
                  dateRangeChangedHandler(e.target.value as DateChoices);
                }}
                variant="outlined"
                margin="dense"
                fullWidth
              >
                <MenuItem value="week">Last Week</MenuItem>
                <MenuItem value="month">Last Month</MenuItem>
                <MenuItem value="6month">Last 6 Months</MenuItem>
                <MenuItem value="year">Last Year</MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </Grid>
            <Grid size={{ xs: 6, md: 4 }}>
              <DatePicker
                views={["year", "month", "day"]}
                format="yyyy-MM-dd"
                label={"From"}
                value={searchDateFrom}
                onChange={handleChangeDateFromDatepicker}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid size={{ xs: 6, md: 4 }}>
              <DatePicker
                views={["year", "month", "day"]}
                format="yyyy-MM-dd"
                label={"To"}
                value={searchDateTo}
                onChange={handleChangeDateToDatepicker}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <MediaEditTags
                tags={searchTags}
                tagsChanged={handleTagsChanged}
                placeholder="Tags"
                saveNewTags={false}
              />
            </Grid>
            {/* Hide Grid if collapsed */}
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
              style={{
                display:
                  searchDateFrom !== null ||
                  searchDateTo !== null ||
                  searchTags.length > 0
                    ? "initial"
                    : "none",
              }}
            >
              <Grow
                in={
                  searchDateFrom !== null ||
                  searchDateTo !== null ||
                  searchTags.length > 0
                }
              >
                <TextField
                  label="Filter Notes"
                  value={searchNotes}
                  onChange={(e) => {
                    setSearchNotes(e.target.value);
                  }}
                  margin="dense"
                  fullWidth
                  variant="outlined"
                />
              </Grow>
            </Grid>
            <Grid>
              <SearchButton
                onClick={performSearch}
                disabled={isSearchButtonDisabled()}
              >
                Search
              </SearchButton>
            </Grid>
            <Grid>
              {enableSearch && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={cancelSearch}
                >
                  Clear Search
                </Button>
              )}
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

type SearchButtonProps = ButtonProps;

const SearchButton = (props: SearchButtonProps) => (
  <Button
    {...props}
    variant={props.disabled ? "outlined" : "contained"}
    color="primary"
    startIcon={<SearchIcon />}
    fullWidth
  >
    {props.children}
  </Button>
);

export default GallerySearchBar;
