import { useState } from "react";
import { UserMediaMetadataItem } from "../Models/GalleryModel";

/** Summary
 * Controller for full screen media viewer
 */

const MediaViewerController = () => {
  const [openMediaItem, setOpenMediaItem] = useState<
    UserMediaMetadataItem | undefined
  >();

  const openMediaItemHandler = (item: UserMediaMetadataItem) => {
    setOpenMediaItem(item);
  };
  const closeMediaItemHandler = () => {
    setOpenMediaItem(undefined);
  };

  return {
    openMediaItem,
    openMediaItemHandler,
    closeMediaItemHandler,
  };
};

export default MediaViewerController;
