import { Activity } from "@alethea-medical/aletheamd-types";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import CopyToClipboard from "./CopyToClipboard";
import EconsultCard from "./EconsultCard";

interface EconsultProfileProps {
  user: Activity.ActivityUser;
}

const EconsultProfile: FC<EconsultProfileProps> = ({ user }) => {
  return (
    <>
      {/* If current user is physician, then show physician text, otherwise show specialist text */}
      <EconsultCard
        title={Activity.ActivityRole.getDisplayName(user.activityRole)}
      >
        <CopyToClipboard
          formattedText={
            `${user.firstName} ${user.lastName}\n` +
            (user.credentials ? `${user.credentials}\n` : "") +
            `${user.location.clinicName} - ${user.location.city}, ${user.location.province}\n` +
            `Fax: ${user.location.fax}`
          }
        />
        <Typography>
          {user.firstName} {user.lastName}
        </Typography>
        <Typography>{user.credentials}</Typography>
        {user.practiceId && <Typography>PracID: {user.practiceId}</Typography>}
        <Typography>
          {user.location.clinicName} - {user.location.city},{" "}
          {user.location.province}
        </Typography>
        <Typography>Fax: {user.location.fax}</Typography>
      </EconsultCard>
    </>
  );
};

export default EconsultProfile;
