import CheckIcon from "@mui/icons-material/Check";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { IconButton } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Theme } from "@mui/material/styles";
import { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import isAndroid from "../../../../models/isAndroid";

const useStyles = makeStyles()((theme: Theme) => ({
  copyButton: {
    position: "absolute", // make sure to set parent to relative position
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 101, //Message z index is 100, so put this above message
  },
  smallIcon: {
    fontSize: "1rem",
  },
}));

interface CopyToClipboardProps {
  formattedText: string;
}

const CopyToClipboard: FC<CopyToClipboardProps> = ({ formattedText }) => {
  const { classes } = useStyles();

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const copyToClipHandler = () => {
    navigator.clipboard.writeText(formattedText);
    setSnackbarOpen(true);
  };

  if (isAndroid()) return null;
  else
    return (
      <>
        <IconButton
          size="small"
          className={classes.copyButton}
          onClick={() => copyToClipHandler()}
          color="primary"
        >
          {snackbarOpen ? (
            <CheckIcon className={classes.smallIcon} />
          ) : (
            <FileCopyIcon className={classes.smallIcon} />
          )}
        </IconButton>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message="Copied to clipboard"
        />
      </>
    );
};

export default CopyToClipboard;
