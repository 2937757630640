import {
  ProcessState,
  useMobileSizes,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import ClearIcon from "@mui/icons-material/Clear";
import Done from "@mui/icons-material/Done";
import Save from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { Activity } from "shared/types/dist";
import SnackbarMessage from "src/components/SnackbarMessage";
import usePermissions from "src/components/usePermissions";
import { makeStyles } from "tss-react/mui";
import isWeb from "../../../../models/isWeb";
import { defaultConsultStatuses } from "../Toolbar/ConsultStatus";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
  },
  helperText: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5),
  },
  noMarginBottom: {
    "& .MuiTextField-root": {
      marginBottom: 0,
    },
  },
  autocomplete: {
    flexGrow: 1,
  },
  optionsContainer: {
    marginTop: theme.spacing(1),
  },
}));

interface ConsultSearchBarProps {
  searchParams: {
    params: string[];
    status: string;
  };
  updateSearchParams: (params: string[], status: string) => void;
  enableSearch: boolean;
  runSearch: (
    params: string[],
    status: string,
    initialSearch?: boolean,
    searchFilters?: string[],
    folder?: Activity.UserActivityFolder,
  ) => void;
  clearSearch: () => void;
  saveSubjectLineFilters: (filters: string[]) => void;
  fetchSubjectLineFilters: () => Promise<string[]>;
  placeholderText: string;
  onSelectedOptionsChange: (options: string[]) => void;
  showOptions: boolean;
  setShowOptions: (show: boolean) => void;
  maxSearchTerms?: number;
  folder?: Activity.UserActivityFolder;
}

const ConsultSearchBar: FC<ConsultSearchBarProps> = ({
  folder,
  searchParams,
  updateSearchParams,
  enableSearch,
  runSearch,
  clearSearch,
  saveSubjectLineFilters,
  fetchSubjectLineFilters,
  maxSearchTerms = 10,
  placeholderText,
  onSelectedOptionsChange,
  showOptions,
  setShowOptions,
}) => {
  const { classes } = useStyles();
  const [inputValue, setInputValue] = useState("");
  const isMobileSize = useMobileSizes();
  const [showSearch, setShowSearch] = useState<boolean>(!isMobileSize);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const subjectFields = [
    "Specialty",
    "Subsite",
    "PHN",
    "Patient Name",
    "Referrer Name",
    "Consultant Name",
  ];

  const { processState, setProcessState } = useProcessState({
    defaultErrorMessage: "An error occurred",
  });

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  useEffect(() => {
    if (!showSearch) setShowOptions(false);
  }, [showSearch]);

  useEffect(() => {
    const initializeSearchFilters = async () => {
      const filters = await fetchSubjectLineFilters();
      setSelectedOptions(filters);
      onSelectedOptionsChange(filters);
    };
    initializeSearchFilters();
  }, []);

  useEffect(() => {
    onSelectedOptionsChange(selectedOptions);
  }, [selectedOptions, onSelectedOptionsChange]);

  const handleSearch = () => {
    runSearch(
      searchParams.params,
      searchParams.status,
      true,
      selectedOptions,
      folder,
    );
  };

  const handleClearSearch = () => {
    updateSearchParams([], "");
    clearSearch();
  };

  const handleOptionChange = (option: string) => {
    setSelectedOptions((prev) => {
      if (prev.includes(option)) {
        // Prevent unchecking if it's the last selected option
        if (prev.length === 1) {
          setShowSnackbar(true);
          return prev;
        }
        return prev.filter((item) => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const onSaveSubjectLineFilters = () => {
    setProcessState(ProcessState.running);
    saveSubjectLineFilters(selectedOptions);
    setProcessState(ProcessState.success);
    setTimeout(() => {
      setProcessState(ProcessState.idle);
      setShowOptions(false);
    }, 1000);
  };

  const getHelperText = () => {
    if (inputValue.length > 0 || searchParams.params.length > 0) {
      return "Press enter to add multiple search terms";
    }
    return undefined;
  };

  return (
    <Grid container size="grow" spacing={1}>
      {(isMobileSize || !showSearch) && (
        <Grid>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowSearch(!showSearch)}
          >
            {showSearch ? "Hide" : "Show"} Search
          </Button>
        </Grid>
      )}
      <Grid size={12}>
        <Collapse in={showSearch}>
          <Grid container spacing={1} alignItems={"center"}>
            <Grid size={{ xs: 12, md: "grow" }} alignItems={"center"}>
              <Autocomplete
                fullWidth
                multiple
                freeSolo
                options={[]}
                value={searchParams.params}
                filterSelectedOptions
                autoSelect
                limitTags={maxSearchTerms}
                onChange={(_, value) => {
                  const params = (value as string[]).map((str) => {
                    try {
                      return str.trim();
                    } catch (e) {
                      return str;
                    }
                  });
                  updateSearchParams(params, searchParams.status);
                }}
                onInputChange={(_, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={placeholderText}
                    fullWidth
                    size="small"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                      },
                      htmlInput: {
                        ...params.inputProps,
                        enterKeyHint: "done",
                      },
                    }}
                  />
                )}
                className={classes.autocomplete}
              />
            </Grid>
            {hasEditStatusPermissions && (
              <Grid size={{ xs: 12, md: 2 }} alignItems={"center"}>
                <TextField
                  select
                  fullWidth
                  label="Status"
                  value={searchParams.status}
                  onChange={(e) =>
                    updateSearchParams(
                      searchParams.params,
                      e.target.value as string,
                    )
                  }
                  slotProps={{
                    input: {
                      endAdornment:
                        searchParams.status === "" ? null : (
                          <IconButton
                            size="small"
                            onClick={() =>
                              updateSearchParams(searchParams.params, "")
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                    },
                    select: {
                      MenuProps: {},
                    },
                  }}
                >
                  {defaultConsultStatuses.order.map((status, i) => (
                    <MenuItem
                      key={`search_status_${status}_${i}`}
                      value={status}
                    >
                      <ListItemText
                        primary={defaultConsultStatuses.statuses[status]?.label}
                      />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid size="auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Grid>
            {enableSearch && (
              <Grid>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClearSearch}
                >
                  Clear
                </Button>
              </Grid>
            )}
          </Grid>
        </Collapse>
      </Grid>
      {isWeb() && getHelperText() && (
        <Grid>
          <Typography variant="caption" className={classes.helperText}>
            {getHelperText()}
          </Typography>
        </Grid>
      )}
      {showOptions && (
        <Collapse sx={{ width: "100%" }} in={showOptions}>
          <Grid
            container
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
            className={classes.optionsContainer}
          >
            <Grid container spacing={1}>
              {subjectFields.map((option) => (
                <Grid key={option}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedOptions.includes(option)}
                        onChange={() => handleOptionChange(option)}
                        name={option}
                      />
                    }
                    label={option}
                  />
                </Grid>
              ))}
            </Grid>
            <Button
              variant="contained"
              color={
                processState === ProcessState.success ? "secondary" : "primary"
              }
              onClick={onSaveSubjectLineFilters}
              startIcon={
                processState === ProcessState.success ? <Done /> : <Save />
              }
            >
              {processState === ProcessState.success
                ? "Subject Line Saved"
                : "Save Subject Line"}
            </Button>
          </Grid>
        </Collapse>
      )}
      <SnackbarMessage
        message="At least one column must be selected"
        show={showSnackbar}
        setShow={setShowSnackbar}
      />
    </Grid>
  );
};

export default ConsultSearchBar;
