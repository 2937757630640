import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import {
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PhoneConsult } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import {
  daysOfTheWeek,
  fetchPhoneConsultSchedule,
  initialSchedule,
  savePhoneConsultSchedule,
} from "../Models/SpecialistScheduleModel";

type SpecialistScheduleControllerReturn = {
  schedule: PhoneConsult.ScheduleDay[];
  blocksRef: MutableRefObject<PhoneConsult.Timeslot[][]>;
  isDirty: boolean;
  saveHandler: () => Promise<void> | undefined;
  discardHandler: () => void;
  setDirty: () => void;
  processState: ProcessState;
  processErrorMessage: string;
};

const SpecialistScheduleController = (): SpecialistScheduleControllerReturn => {
  const authContext = useContext(AuthContext);

  const [schedule, setSchedule] =
    useState<PhoneConsult.ScheduleDay[]>(initialSchedule);

  const blocksRef = useRef<PhoneConsult.Timeslot[][]>(
    daysOfTheWeek.map(() => []),
  );
  const [isDirty, setIsDirty] = useState(false);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    if (authContext.uid !== "") {
      setProcessState(ProcessState.running);
      fetchPhoneConsultSchedule(authContext.uid)
        .then((phoneConsultSchedule) => {
          setProcessState(ProcessState.idle);
          if (phoneConsultSchedule !== undefined) {
            setSchedule(phoneConsultSchedule.schedule);
          }
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error loading your schedule",
          });
        });
    }
  }, [authContext.uid]);

  const saveHandler = () => {
    if (authContext.uid !== "") {
      setProcessState(ProcessState.running);
      return savePhoneConsultSchedule(
        authContext.uid,
        schedule,
        blocksRef.current,
      )
        .then((newSchedule) => {
          setProcessState(ProcessState.idle);
          logAnalyticsEvent(
            analyticsLogs.specialistAvailability.phoneConsultCalendar.save,
          );

          setSchedule(newSchedule);
          setIsDirty(false);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error saving your schedule",
            analyticsLog:
              analyticsLogs.specialistAvailability.phoneConsultCalendar
                .saveFail,
          });
        });
    }

    // Debug
    // schedule.map((d) => {
    //     const blocks = convertTimeslotsToBlocks(d.timeslots)
    //     const newTimeslots = convertBlocksToTimeslots(blocks, defaultTimeslotDuration)

    //     const lengthEq = newTimeslots.length === d.timeslots.length
    //     if(lengthEq) {
    //         const contentsEq = newTimeslots.every((newT, index) => newT.start === d.timeslots[index].start && newT.end === d.timeslots[index].end )
    //         console.log(lengthEq && contentsEq)
    //     }
    //     else {
    //         console.log(lengthEq)
    //     }
    // })
  };

  const setDirty = () => {
    setIsDirty(true);
  };

  const discardHandler = () => {
    const oldSchedule = [...schedule];
    //Set schedule to empty
    setSchedule(initialSchedule);
    setTimeout(() => {
      // Then reset to old schedule to trigger updates on blocks
      setSchedule(oldSchedule);
      setIsDirty(false);
    }, 1);
  };

  return {
    schedule,
    blocksRef,
    isDirty,
    saveHandler,
    discardHandler,
    setDirty,
    processState,
    processErrorMessage,
  };
};

export default SpecialistScheduleController;
