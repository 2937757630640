import React from "react";
import { buildRoute } from "src/routes/internalHelpers";
import LinkAccount from "src/views/Pages/Ava/LinkAccount";
import avaChildrenRoutes, {
  AvaRouteNames,
  AvaRouteName,
  AVA_ROUTE,
} from "./avaRoutesData";

/**
 * Elements to display for each route
 */
const avaRouteElements: {
  [key in AvaRouteName]: React.ReactElement;
} = {
  [AvaRouteNames.linkAccount]: <LinkAccount />,
};

/**
 * Creates the route
 */
const avaRoute = buildRoute(
  avaChildrenRoutes,
  avaRouteElements,
  AvaRouteNames,
  AVA_ROUTE,
);
export default avaRoute;
