import { FC, useEffect, useState } from "react";

import { fbStorage } from "../../../firebase";
import { PatientMessage } from "./types";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import {
  HeightLayout,
  HeightLayoutChild,
  SwipeableOrGrid,
  useMobileSizes,
  useScreenSize,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import Collapse from "@mui/material/Collapse";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { PatientMessageFolder } from "../../../../shared/types";
import PDFViewer from "../../../components/PDFViewer";
import AttachmentViewer from "./AttachmentViewer";
import MessageSubheader from "./MessageSubheader";
import PatientMessageToolbar from "./PatientMessageToolbar";

const useStyles = makeStyles()((theme: Theme) => ({
  subject: {
    fontWeight: 500,
  },
  subjectContainer: {
    padding: theme.spacing(0, 2),
  },
  errorReason: {
    maxWidth: "50%",
  },
  fullHeight: {
    height: "100%",
  },
  toolbarContainer: {
    paddingLeft: theme.spacing(1),
  },
}));

interface ViewMessageProps {
  message: PatientMessage;
  goBackHandler: () => void;
  markAsUnread: () => void;
  moveFolder: (folder: PatientMessageFolder) => void;
}

const ViewMessage: FC<ViewMessageProps> = ({
  message,
  goBackHandler,
  markAsUnread,
  moveFolder,
}) => {
  const { classes } = useStyles();
  const [pdfURL, setPDFURL] = useState<string | undefined>(undefined);
  const [showMsgInfo, setShowMsgInfo] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);

  useEffect(() => {
    if (message.messagePDFURI !== undefined) {
      fbStorage
        .ref(message.messagePDFURI)
        .getDownloadURL()
        .then((url) => {
          setPDFURL(url);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [message]);

  const getNoDataText = () => {
    if (message.subType === "Forms" && message.messagePDFURI === undefined) {
      if (message.status === "sending") {
        return (
          <>
            <Typography variant="h5">
              This form has not yet been sent.
            </Typography>
          </>
        );
      } else {
        return (
          <>
            <Typography variant="h5">This form has no response yet.</Typography>
          </>
        );
      }
    }
    return undefined;
  };

  const isMobileSize = useMobileSizes();
  const {
    sizeRef: bodySizeRef,
    height: bodyHeight,
    width: bodyWidth,
  } = useSizeManager();
  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <HeightLayout height={heightMinusAppBar}>
        <HeightLayoutChild flexDriver>
          <>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid>
                <IconButton onClick={goBackHandler} size="large">
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
              <Grid>
                <div className={classes.toolbarContainer}>
                  <PatientMessageToolbar
                    folder={message.folder}
                    atLeastOneSelected={true}
                    markAsUnread={markAsUnread}
                    moveFolder={moveFolder}
                    showAttachments={showAttachments}
                    onShowAttachments={() =>
                      setShowAttachments(!showAttachments)
                    }
                  />
                </div>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid
                  className={classes.subjectContainer}
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid size={{ xs: 12 }}>
                    <Typography className={classes.subject}>
                      {message.subject}
                      <IconButton
                        onClick={() => {
                          setShowMsgInfo(!showMsgInfo);
                        }}
                        size="large"
                      >
                        {showMsgInfo ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <Collapse in={showMsgInfo}>
                      <Typography variant="subtitle1">
                        <MessageSubheader message={message} />
                      </Typography>
                    </Collapse>
                  </Grid>
                  {message.error && (
                    <Grid
                      size={{ xs: 12 }}
                      className={classes.errorReason}
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Typography variant="subtitle1">
                        {message.error}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider />
          </>
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven ref={bodySizeRef}>
          <SwipeableOrGrid
            showSwipe={isMobileSize}
            index={showAttachments ? 1 : 0}
            onChangeIndex={(index) => {
              setShowAttachments(index === 1);
            }}
            enableStepper={false}
            showInitialStepper={false}
            ignoreTouchInput
            gridSize={[8, 4]}
            containerClassName={classes.fullHeight}
            swipeClassName={classes.fullHeight}
            gridItemClassName={classes.fullHeight}
            gridContainerClassName={classes.fullHeight}
          >
            <PDFViewer
              pdfURL={pdfURL}
              noDataText={getNoDataText()}
              height={bodyHeight}
              width={isMobileSize ? bodyWidth : (bodyWidth * 8) / 12}
            />
            <AttachmentViewer messageMediaURIs={message.messageMediaURIs} />
          </SwipeableOrGrid>
        </HeightLayoutChild>
      </HeightLayout>
    </>
  );
};

export default ViewMessage;
