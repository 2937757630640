import { PaperModal } from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { ReactNode, useState } from "react";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
}));

type useConfirmDestructiveModalReturn = {
  ConfirmDestructiveModal: ReactNode;
  showConfirmDestructiveModal: () => void;
};

interface useConfirmDestructiveModalProps {
  confirmText: ReactNode;
  confirmButtonText: string;
  cancelButtonText?: string;
  action: () => void;
}

const useConfirmDestructiveModal = ({
  confirmText,
  confirmButtonText,
  cancelButtonText = "Cancel",
  action,
}: useConfirmDestructiveModalProps): useConfirmDestructiveModalReturn => {
  const { classes } = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);

  const showConfirmDestructiveModal = () => {
    setShowConfirm(true);
  };

  const confirmAction = () => {
    action();
    setShowConfirm(false);
  };

  const cancelAction = () => {
    setShowConfirm(false);
  };

  return {
    ConfirmDestructiveModal: (
      <PaperModal
        show={showConfirm}
        setShow={setShowConfirm}
        enablePadding
        flexWidth
        flexHeight
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h6">{confirmText}</Typography>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Button variant="outlined" onClick={cancelAction} fullWidth>
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Button
              variant="contained"
              className={classes.dangerButton}
              onClick={confirmAction}
              fullWidth
            >
              {confirmButtonText}
            </Button>
          </Grid>
        </Grid>
      </PaperModal>
    ),
    showConfirmDestructiveModal,
  };
};

export default useConfirmDestructiveModal;
