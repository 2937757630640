import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import MessageListItem from "../../../../components/MessageListItem";
import usePermissions from "../../../../components/usePermissions";
import { formatTimeShort } from "../../../../models/formatTime";
import palette from "../../../../palette";
import EconsultOutcomesLabel from "../EconsultComponents/EconsultOutcomesLabel";
import ConsultStatus from "../Toolbar/ConsultStatus";
import { ActivityItem } from "../types";

const useStyles = makeStyles()((theme: Theme) => ({
  infoContainer: {
    marginRight: theme.spacing(2),
  },
  phoneText: {
    color: palette.orange,
    fontWeight: 600,
    fontSize: "1.0em",
  },
  labelContainer: {
    padding: theme.spacing(1, 1, 0, 1),
  },
  icon: {
    color: palette.darkGreen,
  },
  timestamp: {
    whiteSpace: "nowrap",
  },
}));

interface MOAConsultListItemProps {
  activity: ActivityItem<Activity.ClinicActivity>;
  openActivityHandler: (id: string) => void;
}

const MOAConsultListItem: FC<MOAConsultListItemProps> = ({
  activity,
  openActivityHandler,
}) => {
  const { classes } = useStyles();

  const { granted: hasEditStatusPermissions } = usePermissions({
    resourceKey: resourceKeys.editActivityStatus,
  });

  return (
    <MessageListItem
      selected={false}
      openMessage={openActivityHandler}
      id={activity.id}
      read={true}
      labelComponent={
        <div className={classes.labelContainer}>
          {activity.sharedActivity.activityType === "econsult" && (
            <QuestionAnswerIcon className={classes.icon} />
          )}
          {activity.sharedActivity.activityType === "phoneConsult" && (
            <PermPhoneMsgIcon className={classes.icon} />
          )}
        </div>
      }
      primary={
        <Grid container alignItems="center" spacing={1}>
          {activity.metadataActivity.status !== undefined &&
            hasEditStatusPermissions && (
              <Grid>
                <ConsultStatus
                  status={activity.metadataActivity.status}
                  viewerRole={"moa"}
                />
              </Grid>
            )}
          <Grid>{activity.sharedActivity.subject}</Grid>
        </Grid>
      }
      secondary={
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <EconsultOutcomesLabel
              specialistResponse={
                activity.sharedActivity.exposedEconsultData?.specialistResponse
              }
              fontSize="small"
            />
          </Grid>
        </Grid>
      }
    >
      <div className={classes.infoContainer}>
        <Typography className={classes.timestamp}>
          {" "}
          {formatTimeShort(
            activity.metadataActivity.lastMessageReceivedAt.toDate(),
          )}
        </Typography>
      </div>
    </MessageListItem>
  );
};

export default MOAConsultListItem;
