import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Control,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from "react-hook-form";
import { Activity, Location } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { fbFunctions } from "../../../../firebase";

export interface SpecialistSelectItem {
  uid: string;
  location: Location | undefined;
  label: string;
  avgResponseTime?: number;
  labelSecondary?: string;
  labelV2: {
    name: string;
    credentials?: string;
    location: string;
    title?: string;
    onVacation?: boolean;
    vacationEndDateMili?: string;
  };
}

export interface RouteTree {
  [specialty: string]: {
    [subsite: string]: SpecialistSelectItem[];
  };
}

export const noSpecialistUid = "none";

export const emptySpecialist = {
  uid: noSpecialistUid,
  location: undefined,
  label: "No specialists available",
  labelV2: { name: "No specialists available", location: "" },
};
export const selectSubsiteSpecialist = {
  uid: noSpecialistUid,
  location: undefined,
  label: "Select a Subsite",
  labelV2: { name: "Select a Subsite", location: "" },
};
export const selectSpecialist = {
  uid: noSpecialistUid,
  location: undefined,
  label: "Select a Specialist",
  labelV2: { name: "Select a Specialist", location: "" },
};

export interface SpecialtySubsiteSpecialistSelectControlType {
  specialist: SpecialistSelectItem;
  specialty: string;
  subsite: string;
  locationIdx: number;
  activityType: Activity.ActivityType;
}

interface SpecialtySubsiteSpecialistSelectControllerProps {
  control: Control<SpecialtySubsiteSpecialistSelectControlType>;
  setValue: UseFormSetValue<SpecialtySubsiteSpecialistSelectControlType>;
  trigger: UseFormTrigger<SpecialtySubsiteSpecialistSelectControlType>;
}

const SpecialtySubsiteSpecialistSelectController = ({
  control,
  setValue,
  trigger,
}: SpecialtySubsiteSpecialistSelectControllerProps) => {
  const specialty = useWatch({ control, name: "specialty" });
  const subsite = useWatch({ control, name: "subsite" });
  const specialist = useWatch({ control, name: "specialist" });
  const locationIdx = useWatch({ control, name: "locationIdx" });
  const activityType = useWatch({ control, name: "activityType" });

  const authContext = useContext(AuthContext);

  const routeReferral = fbFunctions.httpsCallable("routing-routeReferral_v2");

  const [specialists, setSpecialists] = useState<SpecialistSelectItem[]>([
    emptySpecialist,
  ]);
  const [subsites, setSubsites] = useState<string[]>([]);
  const [routeTree, setRouteTree] = useState<RouteTree | undefined>();

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const routeTreeRequest = useRef<{
    locationIdx: number;
    activityType: Activity.ActivityType;
  }>();

  const isRunning = () => {
    return processState === ProcessState.running;
  };

  useEffect(() => {
    if (authContext?.profile?.locations === undefined) return;

    const selectedLocation = authContext?.profile?.locations[locationIdx];

    if (
      selectedLocation?.city === undefined ||
      selectedLocation?.province === undefined ||
      selectedLocation.city === ""
    )
      return;

    if (activityType === undefined) return;

    // Set up the request ref to check. If these values change while the request is running the request's results will be ignored
    routeTreeRequest.current = {
      activityType,
      locationIdx,
    };

    setProcessState(ProcessState.running);
    routeReferral({
      city: selectedLocation.city,
      province: selectedLocation.province,
      activityType: activityType,
    })
      // Test data
      // Promise.resolve({
      //     data: {
      //         "Otolaryngology": {
      //             "Ear": [
      //                 {
      //                     uid: "LJfrjk11EafXLvpEUUdJeRHdr6p2",
      //                     location: {
      //                         city: "Calgary",
      //                         province: "Alberta",
      //                         billable: true,
      //                         clinicName: "Test",
      //                         clinicPhone: "(000) 000-0000",
      //                         fax: "(587) 387-7237",
      //                     },
      //                     label: `James Graham-Hu - Calgary, AB`
      //                 }
      //             ],
      //             "Nose": []
      //         }
      //     }
      // })
      .then((result) => {
        //If another request was made while this was running, then abort the current request
        if (
          activityType !== routeTreeRequest.current?.activityType ||
          locationIdx !== routeTreeRequest.current?.locationIdx
        ) {
          return;
        }

        setProcessState(ProcessState.idle);
        const newRouteTree = result.data as RouteTree;
        setRouteTree(newRouteTree);

        // Refresh specialist selection if there is a valid specialist selected --> this will update vacation data
        if (specialty && subsite && specialist.uid !== noSpecialistUid) {
          const specialistIndex = newRouteTree[specialty][subsite].findIndex(
            (s) => s.uid === specialist.uid,
          );

          if (specialistIndex !== -1) {
            setValue(
              "specialist",
              newRouteTree[specialty][subsite][specialistIndex],
            );
            trigger("specialist");
          }
        }
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error routing referral",
        });
        setRouteTree({});
      });
  }, [locationIdx, authContext?.profile, activityType]);

  // Update subsites when routeTree or specialty changes
  useEffect(() => {
    if (routeTree && routeTree[specialty] !== undefined) {
      const subsiteList = Object.keys(routeTree[specialty]).sort();
      setSubsites(subsiteList);
    }
  }, [routeTree, specialty]);

  // Update specialists when routeTree, specialty or subsite changes
  // Reset selections if they are invalid whenever anything changes (as long as we have previously loaded a routeTree)
  useEffect(() => {
    if (routeTree) {
      if (
        routeTree[specialty] !== undefined &&
        routeTree[specialty][subsite] !== undefined
      ) {
        setSpecialists([selectSpecialist, ...routeTree[specialty][subsite]]);
      }

      resetInvalidSelections(routeTree, specialty, subsite, specialist);
    }
  }, [routeTree, specialty, subsite]);

  const setSpecialistEmpty = () => {
    setSpecialists([emptySpecialist]);
    setValue("specialist", emptySpecialist);
  };

  const setSpecialistSelectSubsite = () => {
    setSpecialists([selectSubsiteSpecialist]);
    setValue("specialist", selectSubsiteSpecialist);
  };

  const resetInvalidSelections = (
    routeTree: RouteTree,
    specialty: string,
    subsite: string,
    specialist: SpecialistSelectItem,
  ) => {
    if (routeTree[specialty] === undefined) {
      setValue("specialty", Object.keys(routeTree)[0] ?? "");
      setValue("subsite", "");
      setSpecialistEmpty();
    } else if (routeTree[specialty][subsite] === undefined) {
      setValue("subsite", "");
      setSpecialistSelectSubsite();
    } else if (
      routeTree[specialty][subsite].length > 0 &&
      routeTree[specialty][subsite].find((s) => s.uid === specialist.uid) ===
        undefined
    ) {
      // Set specialist to say "Select a Specialist"
      setValue("specialist", selectSpecialist);
    } else if (routeTree[specialty][subsite].length === 0) {
      setSpecialistEmpty();
    }
  };

  return {
    routeTree,
    specialists,
    subsites,
    processState,
    processErrorMessage,
    isRunning,
  };
};

export default SpecialtySubsiteSpecialistSelectController;
