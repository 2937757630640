import { FC, ReactElement } from "react";
import isMobileDevice from "../../../../models/isMobileDevice";
import useConsultFormStyles from "../useConsultFormStyles";

interface ConsultAttachmentsProps {
  createDropzone: (
    text?: string | undefined,
    dropZoneMinHeight?: string | undefined,
  ) => ReactElement;
  createFileList: () => ReactElement;
  createFileThumbs: () => ReactElement;
  createUploadFromClipboardButton: () => ReactElement | null;
}

const ConsultAttachments: FC<ConsultAttachmentsProps> = ({
  createDropzone,
  createFileList,
  createFileThumbs,
  createUploadFromClipboardButton,
}) => {
  const { classes } = useConsultFormStyles();

  return (
    <div>
      <div className={classes.spacing}>{createUploadFromClipboardButton()}</div>
      <div className={classes.spacing}>
        {createDropzone(
          isMobileDevice()
            ? "Tap to Upload"
            : "Click Here or Drag and Drop to Upload",
          "150px",
        )}
      </div>
      <div className={classes.spacing}>{createFileList()}</div>
      <div className={classes.spacing}>{createFileThumbs()}</div>
    </div>
  );
};

export default ConsultAttachments;
