import { useEffect, useState } from "react";

export const useUpdateCameraDevices = () => {
  const [deviceId, setDeviceId] = useState({});
  const [devices, setDevices] = useState<any>([]);

  //gets camera devices, sorts list, then defaults the camera to main device camera available
  const handleDevices = (mediaDevices: MediaDeviceInfo[]) => {
    //get device ids from available cameras
    const deviceIds: ConstrainDOMString[] = mediaDevices
      .filter(({ kind }: any) => kind === "videoinput")
      .map((deviceInfo: MediaDeviceInfo) => {
        return deviceInfo.deviceId;
      });

    //!-------------------------------------------------START OF MAP---------------------------------------------------------------------------------------
    //gets device labels
    const labeledDevices: Promise<{
      deviceLabel: string;
      deviceId: ConstrainDOMString;
      noLabel: boolean;
    }>[] = deviceIds.map(
      (retrievedDeviceId: ConstrainDOMString, index: number) => {
        return navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: { deviceId: retrievedDeviceId },
          })
          .then((s: any) => {
            const track = s.getVideoTracks();

            const label = track[0].label;

            s.getTracks().forEach((t: any) => {
              t.stop();
            });

            s = null;
            return {
              deviceLabel: label,
              deviceId: retrievedDeviceId,
              noLabel: false,
            };
          })
          .catch((error) => {
            console.log(
              `Attempts to access certain devices have failed and are unavailable,\n ERROR: ${error}`,
            );
            return {
              deviceLabel: `camera2 ${index + 1}`,
              deviceId: retrievedDeviceId,
              noLabel: true,
            };
          });
      },
    );

    Promise.all(labeledDevices).then((triplet) => {
      const sortedDevices = triplet.sort((a, b) => {
        if (a.noLabel && !b.noLabel) {
          return 1;
        }
        if (b.noLabel && !a.noLabel) {
          return -1;
        } else {
          return a.deviceLabel.localeCompare(b.deviceLabel);
        }
      });

      // ( triplet.filter( ( triplet ) => triplet !== undefined ) as { deviceLabel: string, deviceId: ConstrainDOMString, noLabel: boolean }[] )

      if (sortedDevices.length > 0) setDeviceId(sortedDevices[0].deviceId);
      setDevices(sortedDevices);
    });

    //!-------------------------------------------------END OF MAP---------------------------------------------------------------------------------------
  };

  //handles refreshing if a device is unplugged or plugged in

  useEffect(() => {
    /*this is the same code as handlePermissionsAndUpdate, however if you try to 
        use that method here instead of having this code, the dropdown will no longer switch camera's*/
    //Initialize camera
    handlePermissionsAndUpdate();

    //Listen for changes to devices
    navigator.mediaDevices.addEventListener(
      "devicechange",
      handlePermissionsAndUpdate,
    );

    //Unsubscribe when component unmounts
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handlePermissionsAndUpdate,
      );
    };
  }, []);

  //firefox requires you to get permissions to use camera before you can request device info
  //thats why labels were blank(getusermedia handles the permissions)
  const handlePermissionsAndUpdate = () => {
    navigator.mediaDevices
      .enumerateDevices()
      .then(handleDevices)
      .catch((error) =>
        console.log(
          `Attempts to access certain devices have failed and are unavailable,\n ERROR: ${error}`,
        ),
      );
  };

  //    //debugging text
  //     useEffect(() => {

  //       console.log('mini')
  //       console.log(miniDeviceList)
  //     }, [ miniDeviceList])

  //     useEffect( () =>
  //     {
  //         console.log( 'full' )
  //         console.log( devices )

  //     }, [ devices] )

  return {
    deviceId,
    setDeviceId,
    devices,
    setDevices,
  };
};

//?this can potentially be used in the future if we find a way to get all available cameras on android
//handle mobile devices camera list by seperating the cameras into two lists
//full list of available cameras and the two main cameras.
// useEffect(
//     () =>
//     {

//         if ( isMobile && !isIOS )
//         {

//             const frontCameraList: any[] = []
//             const backCameraList: any[] = []

//             devices.map( ( device: any ) =>
//             {

//                 if ( device.label.includes( 'front' ) )
//                     frontCameraList.push( device )
//                 else if ( device.label.includes( 'back' ) )
//                     backCameraList.push( device )

//             } )

//             sortDevices( frontCameraList )
//             sortDevices( backCameraList )

//             if ( frontCameraList.length > 0 && backCameraList.length > 0 )
//                 setMiniDeviceList( [ backCameraList[ 0 ], frontCameraList[ 0 ] ] )

//         }

//         if ( isIOS || !isMobile )
//         {
//             setMiniDeviceList( devices )
//         }

//     }, [ devices ]
// )
