import {
  ProcessState,
  thinScrollbar,
} from "@alethea-medical/alethea-components";
import {
  HeightLayout,
  HeightLayoutChild,
  SwipeableOrGrid,
  useMobileSizes,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useState } from "react";
import { makeStyles } from "tss-react/mui";
import LoadingPage from "../../../components/LoadingPage";
import EconsultView from "./EconsultComponents/EconsultView";
import { ActivityContext } from "./Inbox/Contexts/ActivityProvider";
import MOAMessageView from "./MOAConsultInbox/MOAMessageView";
import MOAViewSecureMessageToolbar from "./MOAConsultInbox/MOAViewSecureMessageToolbar";
import MessageView from "./MessageComponents/MessageView";
import ViewSecureMessageToolbar from "./Toolbar/ViewSecureMessageToolbar";
import { InputProvider } from "./Utilities/InputProvider";
import sharedStyles from "./sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  ...sharedStyles(theme),
  loading: {
    marginTop: "50px",
  },
}));

type ViewSecureMessageProps = {
  refillActivities: () => void;
};

const ViewSecureMessage: FC<ViewSecureMessageProps> = ({
  refillActivities,
}) => {
  const { classes } = useStyles();

  const isMobileSize = useMobileSizes();
  const activityContext = useContext(ActivityContext);

  const [swipeIndex, setSwipeIndex] = useState(0);

  const goToMessages = () => {
    setSwipeIndex(1);
  };

  const goToEconsult = () => {
    setSwipeIndex(0);
  };

  const { heightMinusAppBar } = useScreenSize({});

  if (
    activityContext !== null &&
    activityContext.isActivityOpen &&
    activityContext.loaded
  ) {
    return (
      <SwipeableOrGrid
        showSwipe={isMobileSize}
        gridSize={6}
        index={swipeIndex}
        onChangeIndex={setSwipeIndex}
        onSwipedEdgeRight={activityContext.closeActivity}
        enableStepper
      >
        {/* Econsult view */}
        <Box className={classes.boxBorderColor} borderRight={1}>
          <HeightLayout height={heightMinusAppBar}>
            <HeightLayoutChild flexDriver>
              {activityContext.viewType === "md" && (
                <ViewSecureMessageToolbar
                  goToMessages={goToMessages}
                  refillActivities={refillActivities}
                />
              )}
              {activityContext.viewType === "moa" && (
                <MOAViewSecureMessageToolbar goToMessages={goToMessages} />
              )}
            </HeightLayoutChild>
            <HeightLayoutChild
              flexDriven
              allowOverflowY
              className={classes.thinScrollbar}
            >
              <EconsultView />
            </HeightLayoutChild>
          </HeightLayout>
        </Box>
        {/* Messages and image annotation */}
        <Box>
          <InputProvider>
            {activityContext.viewType === "md" && (
              <MessageView
                visible={swipeIndex === 1 || !isMobileSize}
                goToEconsult={goToEconsult}
              />
            )}
            {activityContext.viewType === "moa" && (
              <MOAMessageView
                visible={swipeIndex === 1 || !isMobileSize}
                goToEconsult={goToEconsult}
              />
            )}
          </InputProvider>
        </Box>
      </SwipeableOrGrid>
    );
  }

  if (
    activityContext !== null &&
    activityContext.isActivityOpen &&
    !activityContext.loaded &&
    activityContext.processState !== ProcessState.error
  ) {
    return (
      <LoadingPage
        label="Loading Consult"
        value={activityContext.getLoadPercentage()}
      />
    );
  }

  return null;
};

export default ViewSecureMessage;
