import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import ToggleButton from "../../../../../components/ToggleButton";
import palette from "../../../../../palette";

const useStyles = makeStyles()(() => ({
  toggleButtonOn: {
    color: "white",
    background: palette.orange,
    "&:hover": {
      background: "#d8530c",
    },
  },
  toggleButtonOff: {
    color: palette.orange,
  },
}));

interface StartStopLabellingButtonProps {
  show: boolean;
  startLabellingHandler: () => void;
  stopLabellingHandler: () => void;
  disabled: boolean;
}

const StartStopLabellingButton: FC<StartStopLabellingButtonProps> = ({
  show,
  startLabellingHandler,
  stopLabellingHandler,
  disabled,
}: StartStopLabellingButtonProps) => {
  const { classes, cx } = useStyles();

  const toggleHandler = () => {
    if (show) {
      stopLabellingHandler();
    } else {
      startLabellingHandler();
    }
  };

  return (
    <ToggleButton
      className={cx({
        [classes.toggleButtonOn]: show,
        [classes.toggleButtonOff]: !show,
      })}
      startIcon={<VisibilityIcon />}
      toggleState={show}
      onClick={toggleHandler}
      {...{ fullWidth: true, color: "inherit" }}
      disabled={disabled}
    >
      {show ? "Save Labels" : "Start Labelling"}
      {show ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </ToggleButton>
  );
};

export default StartStopLabellingButton;
