/**
 * Formats date as either hh:mm if the Date object occurs today
 * ex. 4:17 PM
 * Or as mmm dd if it occurred this year
 * ex. Nov 16
 * Or as mmm dd, yyyy if it occurred not this year
 * ex. Nov 16, 2021
 * @param date Date to format
 * @returns Formatted date string
 */
export function formatTimeShort(date: Date): string {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  if (date.getTime() >= today.getTime()) {
    return `${date.toLocaleString("en-US", { hour: "numeric", hour12: true, minute: "numeric" })}`;
  } else if (date.getFullYear() !== today.getFullYear()) {
    return `${date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" })}`;
  } else {
    return `${date.toLocaleString("en-US", { month: "short", day: "numeric" })}`;
  }
}

/**
 * Formats date as "hh:mm AM/PM on mmm dd, yyyy"
 * ex: 12:16 Nov 16, 2022
 * @param date Date to format
 * @returns Formatted date string
 */
export function formatTimeLong(date: Date) {
  return `${date.toLocaleString("en-US", { hour: "numeric", hour12: true, minute: "numeric" })} on ${date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" })}`;
}

/**
 * Formats date as "mmm dd, yyyy at hh:mm AM/PM"
 * ex: Nov 16, 2022 at 12:16
 * @param date Date to format
 * @returns Formatted date string
 */
export function formatTimeDateThenTime(date: Date) {
  return `${date.toLocaleString("en-US", { month: "short", day: "numeric", year: "numeric" })} at ${date.toLocaleString("en-US", { hour: "numeric", hour12: true, minute: "numeric" })}`;
}

export function formatDateAsYYYYMMDD(inputDate: Date | string): string {
  const dateObj = new Date(inputDate);

  if (dateObj.toString() === "Invalid Date") {
    return "";
  }

  // Format object into YYYY/MM/DD
  const day =
    dateObj.getDate() < 10 ? "0" + dateObj.getDate() : dateObj.getDate(); // 3 -> 03
  const month =
    dateObj.getMonth() + 1 < 10
      ? "0" + (dateObj.getMonth() + 1)
      : dateObj.getMonth() + 1; // 3 -> 03
  const date = dateObj.getFullYear() + "/" + month + "/" + day;
  return date;
}
