import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { GridSize } from "@mui/material/Grid2";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";

interface FormRadioGroupProps extends ControlledInputProps {
  options: string[];
  breakpoints?: { xs?: GridSize; sm?: GridSize; md?: GridSize; lg?: GridSize };
  displayOption?: (option: string) => string;
  onClick?: (option: string) => void;
}

const FormRadioGroup: FC<FormRadioGroupProps> = ({
  name,
  control,
  defaultValue,
  options,
  disabled,
  displayOption,
  rules,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue !== undefined ? defaultValue : false}
      rules={rules}
      render={({ field }) => {
        const handleClickOption = (optionSelected: string) => {
          field.onChange(optionSelected);
        };

        return (
          <RadioGroup>
            {options.map((option, index) => (
              <FormControlLabel
                key={`rounded_radio_button_${option}_${index}`}
                label={displayOption ? displayOption(option) : option}
                value={option}
                control={
                  <Radio
                    checked={field.value === option}
                    color="primary"
                    disableRipple
                    disabled={disabled}
                    onClick={() => {
                      handleClickOption(option);
                    }}
                  />
                }
              />
            ))}
          </RadioGroup>
        );
      }}
    />
  );
};

export default FormRadioGroup;
