import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import LeavePagePrompt from "../../../../components/LeavePagePrompt";
import SaveDiscardToolbar from "../../../../components/SaveDiscardToolbar";
import AddCategoryButton from "../AddCategoryButton";
import SpecialistTemplatesController from "../SpecialistTemplatesController";
import SpecialistTemplatesInputController from "../SpecialistTemplatesInputController";
import useEditButtons from "../useEditButtons";
import Category from "./Category";

const useStyles = makeStyles()((theme: Theme) => ({
  toolbar: {
    padding: theme.spacing(1),
  },
  container: {
    margin: 0,
    width: "100%", //Stops overflow x
  },
}));
interface SpecialistTemplatesViewProps {
  /** If true, edit will be enabled by default and edit button will not show */
  alwaysEnableEditing?: boolean;
  /** Optional height value to pass to HeightLayout  */
  height?: number | string;
  /** If true, drag handle is hidden even when editing */
  disableDrag?: boolean;
}

const SpecialistTemplatesView: FC<SpecialistTemplatesViewProps> = ({
  height,
  alwaysEnableEditing,
  disableDrag,
}) => {
  const { classes } = useStyles();

  const controller = SpecialistTemplatesController();
  const inputController = SpecialistTemplatesInputController();
  const { isEditing, EditButton } = useEditButtons({
    unsavedChanges: controller.unsavedChanges,
    alwaysEnableEditing,
  });

  return (
    <>
      <HeightLayout height={height}>
        {!alwaysEnableEditing && (
          <Typography variant="h4"> Templates </Typography>
        )}
        <HeightLayoutChild flexDriver className={classes.toolbar}>
          {!alwaysEnableEditing && EditButton}
          {(alwaysEnableEditing || isEditing) && (
            <SaveDiscardToolbar
              show={controller.unsavedChanges}
              modalText={"Discard changes to your templates?"}
              saveHandler={controller.saveTemplates}
              discardHandler={controller.discardChanges}
            />
          )}
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven allowOverflowY>
          <DragDropContext onDragEnd={controller.onDragEndHandler}>
            <Droppable droppableId={"category"} type="category">
              {(provided) => (
                <Grid
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  container
                  className={classes.container}
                  spacing={2}
                  sx={{ padding: 1 }}
                >
                  {controller.categoryOrder.map((categoryId, index) => {
                    if (controller.categories[categoryId] === undefined)
                      return null;
                    const id = `category_${categoryId}`;
                    return (
                      <Grid size={{ xs: 12 }} key={id}>
                        <Category
                          index={index}
                          category={controller.categories[categoryId]}
                          deleteCategoryHandler={
                            controller.deleteCategoryHandler
                          }
                          updateCategoryHandler={
                            controller.updateCategoryHandler
                          }
                          items={controller.items}
                          addItemHandler={controller.addItemHandler}
                          deleteItemHandler={controller.deleteItemHandler}
                          updateItemHandler={controller.updateItemHandler}
                          outcomesList={controller.outcomesList}
                          isEditing={isEditing}
                          templateInsertHandler={
                            inputController.templateInsertHandler
                          }
                          disableDrag={disableDrag}
                        />
                      </Grid>
                    );
                  })}
                  {provided.placeholder}
                  {isEditing && (
                    <Grid size="grow">
                      <AddCategoryButton
                        addCategoryHandler={controller.addCategoryHandler}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </HeightLayoutChild>
      </HeightLayout>
      <LeavePagePrompt isDirty={controller.unsavedChanges} />
      <ProcessStatus
        state={controller.processState}
        errorMessage={controller.processErrorMessage}
        setState={controller.setProcessState}
        useSnackbar
        hideProgressBar
        successMessage="Templates saved."
        snackbarAllProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "right" },
        }}
      />
    </>
  );
};

export default SpecialistTemplatesView;
