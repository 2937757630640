import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { logAnalyticsEvent } from "../../../firebase";
import ActivityPayment from "../SecureMessaging/EconsultComponents/ActivityPayment";
import AveiroExternalPaymentForm from "./AveiroExternalPaymentForm";
import AveiroPaymentForm from "./AveiroPaymentForm";
import AveiroRetryForm from "./AveiroRetryForm";
import useAveiroItemsDropdown from "./useAveiroItemsDropdown";

const useStyles = makeStyles()((theme: Theme) => ({
  totalDivider: {
    margin: theme.spacing(0, 5, 0, 0),
  },
  totalText: {
    margin: theme.spacing(1, 0),
  },
}));

interface AveiroPaymentPageProps {
  activityId: string;
}

const AveiroPaymentPage: FC<AveiroPaymentPageProps> = ({ activityId }) => {
  const { classes } = useStyles();

  const {
    selectedItem,
    availableItems,
    getItems,
    processState: dropdownState,
    processErrorMessage: dropdownError,
    renderDropdown,
  } = useAveiroItemsDropdown({ activityId });
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const doShowPaymentForm = () => {
    return (
      selectedItem !== undefined &&
      selectedItem.existingPayment?.payment.status !== "pending" &&
      !selectedItem.isExternalPayment
    );
  };

  const doShowRetryForm = () => {
    return (
      selectedItem !== undefined &&
      selectedItem.existingPayment?.payment.status === "pending" &&
      !selectedItem.isExternalPayment
    );
  };

  const doShowExternalPaymentForm = () => {
    return (
      selectedItem !== undefined && selectedItem.isExternalPayment === true
    );
  };

  const doShowSuccessPage = () => {
    return processState === ProcessState.success;
  };

  return (
    <Grid container spacing={2} justifyContent="center">
      {doShowSuccessPage() ? (
        <>
          <Grid size={{ xs: 12 }}>
            {selectedItem?.existingPayment !== undefined && (
              <ActivityPayment payment={selectedItem?.existingPayment} />
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography style={{ textAlign: "center" }}>
              You may now close this popup
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid size={{ xs: 12 }}>
            <Typography variant="h5">Make a Payment</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {renderDropdown(selectedItem, availableItems)}
          </Grid>
          {selectedItem !== undefined && (
            <Grid size={{ xs: 12 }}>
              <Typography className={classes.totalText}>
                Subtotal: ${(selectedItem.cost / 100).toFixed(2)}
              </Typography>
              <Typography className={classes.totalText} variant="subtitle1">
                Tax ({(selectedItem.taxRate * 100).toFixed(2)}%): $
                {((selectedItem.cost / 100) * selectedItem.taxRate).toFixed(2)}
              </Typography>
              <Divider className={classes.totalDivider} />
              <Typography className={classes.totalText}>
                Total: $
                {(
                  (selectedItem.cost +
                    selectedItem.cost * selectedItem.taxRate) /
                  100
                ).toFixed(2)}
              </Typography>
            </Grid>
          )}
          <Grid size={{ xs: 12 }}>
            <AveiroPaymentForm
              visible={doShowPaymentForm()}
              itemCode={selectedItem?.itemCode}
              activityId={activityId}
              refetchPayments={getItems}
              processState={processState}
              setProcessState={setProcessState}
              errorHandler={errorHandler}
            />
            <AveiroRetryForm
              visible={doShowRetryForm()}
              itemCode={selectedItem?.itemCode}
              activityId={activityId}
              refetchPayments={getItems}
              processState={processState}
              setProcessState={setProcessState}
              errorHandler={errorHandler}
            />
            <AveiroExternalPaymentForm
              visible={doShowExternalPaymentForm()}
              itemCode={selectedItem?.itemCode}
              activityId={activityId}
              refetchPayments={getItems}
              processState={processState}
              setProcessState={setProcessState}
              errorHandler={errorHandler}
            />
          </Grid>
        </>
      )}
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={dropdownState}
          errorMessage={dropdownError}
          loadingMessage="Loading items"
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
          successMessage="Payment successful"
        />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography style={{ textAlign: "center" }} variant="subtitle1">
          Secure Payments Powered by Clover
        </Typography>
        <Typography style={{ textAlign: "center" }} variant="subtitle1">
          <a
            href="https://www.clover.com/privacy-policy"
            rel="noreferrer noopener"
            target="_blank"
          >
            Privacy Policy
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AveiroPaymentPage;
