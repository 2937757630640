import TextField, { TextFieldProps } from "@mui/material/TextField";
import { ReactNode, useState } from "react";
import { Controller, FieldValues } from "react-hook-form";
import { BaseFormInputProps } from "../FormInputFieldsV2";

interface TypedFormSelectV2Props<
  T extends FieldValues,
  I extends TextFieldProps,
> extends BaseFormInputProps<T, I> {
  children?: ReactNode;
}
function FormSelectV2<T extends FieldValues, I extends TextFieldProps>({
  controlProps,
  inputFieldProps,
  errorTextPadding,
  children,
}: TypedFormSelectV2Props<T, I>) {
  // Defaults
  const margin = inputFieldProps?.margin ?? "dense";
  const fullWidth = inputFieldProps?.fullWidth ?? true;
  const variant = inputFieldProps?.variant ?? "outlined";

  const [isOpen, setIsOpen] = useState(false);

  /* Only allow selecting when the menu is open
    There is a bug where the menu will still be clickable after the user has selected an option, and the menu is closed
    This prevents the user from selecting an "invisible" item if they click fast enough
    */
  const handleSelectChange = (
    event: any,
    fieldOnChange: (event: any) => void,
    handler: (...event: any[]) => void = () => {},
  ) => {
    if (isOpen) {
      fieldOnChange(event);
      handler(event);
    }
  };

  const handleSelectOpen = (
    event: any,
    handler: (event: any) => void = () => {},
  ) => {
    setIsOpen(true);
    handler(event);
  };

  const handleSelectClose = (
    event: any,
    handler: (event: any) => void = () => {},
  ) => {
    setIsOpen(false);
    handler(event);
  };

  return (
    <Controller
      {...controlProps}
      render={({ field, fieldState }) => {
        return (
          <TextField
            select
            error={fieldState.error !== undefined}
            helperText={
              errorTextPadding
                ? fieldState.error?.message
                  ? fieldState.error.message
                  : " "
                : fieldState.error?.message
            }
            margin={margin}
            fullWidth={fullWidth}
            variant={variant}
            {...field}
            {...inputFieldProps}
            SelectProps={{
              ...inputFieldProps?.SelectProps,
              // Overwrite handlers with our implementation first, then pass the event to the original handler
              MenuProps: {
                MenuListProps: {
                  style: { padding: 0 },
                },
              },
              onOpen: (event) =>
                handleSelectOpen(event, inputFieldProps?.SelectProps?.onOpen),
              onClose: (event) =>
                handleSelectClose(event, inputFieldProps?.SelectProps?.onClose),
              onChange: (event) =>
                handleSelectChange(
                  event,
                  field.onChange,
                  inputFieldProps?.SelectProps?.onChange,
                ),
            }}
          >
            {children}
          </TextField>
        );
      }}
    />
  );
}

export default FormSelectV2;
