export function downloadBlob(
  dataBase64: string,
  fileName: string,
  mimeType: string,
): void {
  const data = base64ToArrayBuffer(dataBase64);
  const blob = new Blob([data], {
    type: mimeType,
  });

  const url = window.URL.createObjectURL(blob);
  downloadURL(url, fileName);
  setTimeout(() => {
    return window.URL.revokeObjectURL(url);
  }, 1000);
}

export function base64ToArrayBuffer(base64: string): Uint8Array {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export function downloadURL(data: string, fileName: string): void {
  const a = document.createElement("a");
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
}
