import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { FCM } from "@capacitor-community/fcm";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouteNames, getPathFromRouteName } from "src/routes";
import { UserProfile } from "../../shared/types";
import { AuthContext } from "../AuthProvider";
import analyticsLogs from "../analyticsLogs";
import { fbFirestore, logAnalyticsEvent } from "../firebase";
import isAndroid from "../models/isAndroid";
import isNativeMobile from "../models/isNativeMobile";

export function subOrUnsubToAletheaNews(profile: UserProfile) {
  /** If alethea news is enabled, or mobile push notification preference does not exist (Legacy - Pre-v8.5.4.2023.09.28: Always send push notifications), then subscribe to alethea news topic */
  if (
    profile.preferences?.mobilePushNotifications === undefined ||
    profile.preferences?.mobilePushNotifications?.aletheaNews === true
  ) {
    return FCM.subscribeTo({ topic: "all" });
  }
  /** Otherwise unsubscribe (user does not wish to receive notifications) */
  return FCM.unsubscribeFrom({ topic: "all" });
}

const usePushNotifications = () => {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const [androidMsg, setAndroidMsg] = useState<string>();
  const [route, setRoute] = useState<string>();
  const [fcmToken, setFcmToken] = useState<string | undefined>(undefined);

  const initializePushNotifications = (profile: UserProfile) => {
    if (isNativeMobile()) {
      console.log("Initializing Push Notifications");

      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          console.log("Push Notifications permission granted");
          // Register with Apple / Google to receive push via APNS/FCM
          return PushNotifications.register()
            .then(() => {
              return subOrUnsubToAletheaNews(profile);
            })
            .catch((error: Error) => {
              console.error(error);
            });
        } else {
          // Show some error
          console.error("Push Notifications permission denied");
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
        console.log(
          "Received push notification registration token: ",
          token.value,
        );
        //token is the APNS (iOS) / FCM (Google) token
        //This token identifies the user, allows us to send push notifications to this specific device
        setFcmToken(token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", () => {
        // alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {
          console.log("Push notification received: ", notification);
          //Remove notification so badge goes away
          PushNotifications.removeAllDeliveredNotifications();

          //Do something when push notification received on the device (and app is open)
          //alert( 'recieved: ' + JSON.stringify( notification ) );
          if (isAndroid()) {
            setAndroidMsg(notification.body);
            setRoute(notification.data?.route);
          }
        },
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {
          console.log("Push notification action performed: ", notification);

          //Remove notification so badge goes away
          PushNotifications.removeAllDeliveredNotifications();

          // const exampleNotificationJSON = {
          //     actionId: "tap",
          //     notification: {
          //         id: "...",
          //         data: {
          //             "google.delivered_priority": "high",
          //             "google.sent_time": "<timestamp millis>",
          //             "google.ttl": "2419200",
          //             "google.original_priority": "high",
          //             "from": "138705568071",
          //             "route": "/dashboard/econsult",//Custom key value pair
          //             "collapse_key": "com.aletheamedical.app"
          //         }
          //     }
          // }

          logAnalyticsEvent(analyticsLogs.pushNotifications.open, {
            customData: notification.notification.data,
          });

          if (notification.notification.data?.route) {
            //navigate to route in app
            console.log(
              `Navigating to ${notification.notification.data.route}`,
            );
            navigate(notification.notification.data.route);
          } else if (notification.notification.data?.link) {
            //open browser to page
            console.log(
              `Opening in browser: ${notification.notification.data.link}`,
            );
            window.open(notification.notification.data.link);
          } else if (notification.notification.data?.campaignId) {
            console.log(
              `Navigating to notification center campaignID ${notification.notification.data.campaignId}`,
            );
            navigate(
              `${getPathFromRouteName(RouteNames.notificationCenter)}&campaignId=${notification.notification.data.campaignId}`,
            );
          }
        },
      );
    }
  };

  useEffect(() => {
    if (authContext.authenticated && authContext.profile !== undefined) {
      initializePushNotifications(authContext.profile);
    }
  }, [authContext.authenticated, authContext.profile]);

  // Add FCM token to profile when its received and user is logged in
  useEffect(() => {
    if (fcmToken !== undefined && authContext.uid !== "") {
      const data = { fcmToken };
      fbFirestore
        .collection(dbNames.tokens)
        .doc(authContext.uid)
        .set(data, { merge: true });
    }
  }, [fcmToken, authContext.uid]);

  return { androidMsg, setAndroidMsg, route, setRoute };
};

export default usePushNotifications;
