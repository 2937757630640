import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../../palette";
import sharedStyles from "../../../../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  header: {
    backgroundColor: palette.darkGreen,
    width: "100%",
    padding: theme.spacing(6, 4),
  },
  title: {
    color: "white",
    fontSize: "3em",
  },
}));

interface PageHeaderProps {
  title: string;
}
const PageHeader: FC<PageHeaderProps> = ({ title }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.header}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12 }}>
          <a
            className={classes.unstyledLink}
            href="https://aletheamedical.com/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className={classes.imgFluid}
              width="200px"
              src="/Alethea Logo Reverse.svg"
            />
          </a>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default PageHeader;
