import { FileWithPreview } from "../components/useFileDrop/useFileDrop";
import { fbStorage } from "../firebase";

/**
 * Handles uploading attachments to temp folder on storage
 * Used to send attachments to backend services without putting them in an HTTP POST request, since they have a 10MB limit
 */
class RequestAttachmentHandler {
  private attachmentPaths: string[] = [];
  private attachmentTempFolder = "attachment_temp_files";

  /**
   * Upload attachments to the temp folder
   * @param uid UID of the user to upload for
   * @param files Files to upload
   */
  uploadAttachments(uid: string, files: FileWithPreview[]): Promise<void> {
    return Promise.all(
      files.map((file) => {
        // If draft storage path provided, use that instead of reuploading the file
        if (file.draftStoragePath !== undefined) return file.draftStoragePath;
        // Make sure to use file.filename here and NOT file.file.name (filename is renamed to be unique by file upload handler. file.file.name is readonly so we can't change it)
        else
          return fbStorage
            .ref(`${this.attachmentTempFolder}/${uid}/${file.filename}`)
            .put(file.file)
            .then((result) => {
              return result.ref.fullPath;
            });
      }),
    ).then((newPaths) => {
      this.attachmentPaths = newPaths;
    });
  }

  /**
   * Delete all attachments that were uploaded in the last uploadAttachments call
   */
  clearAttachments(): Promise<void> {
    return Promise.all(
      this.attachmentPaths.map((path) => {
        return fbStorage
          .ref(path)
          .delete()
          .catch((error: Error) => console.error(error));
      }),
    ).then(() => {
      this.attachmentPaths = [];
    });
  }

  /**
   * Get the storage paths for the attachments uploaded in the last uploadAttachments call
   * @returns array of storage paths (e.g. attachment_temp_files/<uid>/<attachment_name>.<file_extension>
   */
  getAttachmentPaths(): string[] {
    return this.attachmentPaths;
  }
}

export default RequestAttachmentHandler;
