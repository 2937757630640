import Button from "@mui/material/Button";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../palette";
import { ImageAnnotationSelectedDict } from "./EconsultImageAnnotationModel";

const useStyles = makeStyles()(() => ({
  aiConfirm: {
    color: "white",
    backgroundColor: palette.orange,
    "&:hover": {
      backgroundColor: palette.orangeLight,
    },
  },
}));
interface ConfirmAIPredictionButtonProps {
  confirmHandler: (items: ImageAnnotationSelectedDict) => void;
  unselectAllHandler: () => void;
  selectedMedia: ImageAnnotationSelectedDict;
}

const ConfirmAIPredictionButton: FC<ConfirmAIPredictionButtonProps> = ({
  confirmHandler,
  unselectAllHandler,
  selectedMedia,
}) => {
  const { classes } = useStyles();
  const handleConfirm = () => {
    confirmHandler(selectedMedia);
    unselectAllHandler();
  };
  return (
    <Button
      fullWidth
      variant="contained"
      className={classes.aiConfirm}
      onClick={handleConfirm}
      disabled={Object.keys(selectedMedia).length === 0}
    >
      Use AI Labels
    </Button>
  );
};

export default ConfirmAIPredictionButton;
