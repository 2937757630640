import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Payment } from "../../../../shared/types";
import { fbFunctions } from "../../../firebase";
import parseJSONTimestamp from "../../../models/parseJSONTimestamp";
import { GetPaymentsResponse } from "../SecureMessaging/EconsultComponents/ActivityPaymentList";

interface useAveiroItemsDropdownProps {
  activityId: string;
}
export interface AveiroDropdownItem extends Payment.InventoryItem {
  itemCode: string;
  existingPayment?: GetPaymentsResponse;
}

const useAveiroItemsDropdown = ({
  activityId,
}: useAveiroItemsDropdownProps) => {
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});
  const getAvailableItems = fbFunctions.httpsCallable(
    "aveiro-getAvailableItems",
  );

  const [availableItems, setAvailableItems] = useState<AveiroDropdownItem[]>(
    [],
  );
  const [selectedItem, setSelectedItem] = useState<
    AveiroDropdownItem | undefined
  >();

  useEffect(() => {
    getItems();
  }, []);

  const itemSelectedHandler = (itemCode: string) => {
    const item = availableItems.find((i) => i.itemCode === itemCode);
    setSelectedItem(item);
  };

  const getItems = () => {
    setSelectedItem(undefined);
    setProcessState(ProcessState.running);
    getAvailableItems({ activityId })
      .then((response) => {
        setProcessState(ProcessState.idle);
        response.data.forEach((item: AveiroDropdownItem) => {
          const payment = item.existingPayment?.payment;
          if (payment) {
            payment.created = parseJSONTimestamp(payment.created);
            payment.statusUpdatedAt = parseJSONTimestamp(
              payment.statusUpdatedAt,
            );
          }
        });
        setAvailableItems(response.data);
        setSelectedItem(
          response.data.find((d: any) => d.itemCode === selectedItem?.itemCode),
        );
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error loading available items.",
        });
      });
  };

  return {
    selectedItem,
    availableItems,
    getItems,
    processState,
    processErrorMessage,
    renderDropdown: (
      selectedItem: AveiroDropdownItem | undefined,
      availableItems: AveiroDropdownItem[],
    ) => (
      <TextField
        label="Select an Item"
        margin="dense"
        fullWidth
        variant="outlined"
        select
        defaultValue={""}
        value={selectedItem?.itemCode ?? ""}
        disabled={availableItems.length === 0}
        onChange={(e) => itemSelectedHandler(e.target.value as string)}
      >
        {availableItems.length > 0 &&
          availableItems.map((item, index) => (
            <MenuItem
              key={`${item.itemCode}_${index}`}
              value={item.itemCode}
              disabled={item?.existingPayment?.payment.status === "approved"}
            >
              {item?.existingPayment?.payment.status === "approved"
                ? "(Paid) "
                : ""}
              {item.name}
            </MenuItem>
          ))}
        {availableItems.length === 0 && <MenuItem value={""}>Loading</MenuItem>}
      </TextField>
    ),
  };
};

export default useAveiroItemsDropdown;
