import { Role, SignupInfo } from "@alethea-medical/aletheamd-types";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Grid from "@mui/material/Grid2";
import { FC, useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import RoundedButton from "../../../../../components/RoundedButton";
import { logAnalyticsEvent } from "../../../../../firebase";
import SectionContainer from "../../Helpers/Container/SectionContainer";
import AgreementModal from "../UserAgreement/AgreementModal";
import PlatformUseAgreement from "../UserAgreement/PlatformUseAgreement";

interface UserAgreementProps {
  control: Control<SignupInfo.SignupInfo>;
  selectedRole: Role.Role;
  isAgreementSigned: boolean;
  savedSignature: string | undefined;
  setSavedSignature: (value: string | undefined) => void;
  setAgreementBlob: (blob: Blob) => void;
  number: number;
}

const UserAgreement: FC<UserAgreementProps> = ({
  control,
  selectedRole,
  isAgreementSigned,
  savedSignature,
  setSavedSignature,
  setAgreementBlob,
  number,
}: UserAgreementProps) => {
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  const [contractButtonText, setContractButtonText] = useState("");

  //Add watches here instead of passing in to isolate component
  const firstName = useWatch({ control, name: "firstName", defaultValue: "" });
  const lastName = useWatch({ control, name: "lastName", defaultValue: "" });
  const email = useWatch({ control, name: "email", defaultValue: "" });
  const city = useWatch({ control, name: "city", defaultValue: "" });
  const province = useWatch({ control, name: "province", defaultValue: "AB" });

  const onSaveAgreement = () => {
    setShowAgreementModal(false);
  };

  const onHoverSignedButton = (hover: boolean) => {
    if (hover && isAgreementSigned) {
      setContractButtonText("Review Contract");
    } else if (!hover && isAgreementSigned) {
      setContractButtonText("Signed");
    }
  };

  const openContract = () => {
    logAnalyticsEvent("signup_contract_open");
    setShowAgreementModal(true);
  };

  useEffect(() => {
    if (isAgreementSigned) {
      setContractButtonText("Signed");
    } else {
      setContractButtonText("Sign Contract and IMA");
    }
  }, [isAgreementSigned]);

  return (
    <SectionContainer title="User Agreement" number={number}>
      <Grid container alignItems="center" spacing={2}>
        <Grid size={{ xs: 12, md: 4 }}>
          <RoundedButton
            endIcon={isAgreementSigned ? <CheckIcon /> : <OpenInNewIcon />}
            onClick={openContract}
            onMouseOver={() => {
              onHoverSignedButton(true);
            }}
            onMouseLeave={() => {
              onHoverSignedButton(false);
            }}
            fullWidth
          >
            {contractButtonText}
          </RoundedButton>
        </Grid>
        <Grid size={{ xs: 12 }}>{/* Spacer */}</Grid>
      </Grid>
      <AgreementModal show={showAgreementModal} setShow={setShowAgreementModal}>
        <PlatformUseAgreement
          selectedRole={selectedRole}
          requiredUserInfo={{
            firstName,
            lastName,
            email,
            city,
            province,
          }}
          setBlob={setAgreementBlob}
          savedSignature={savedSignature}
          setSavedSignature={setSavedSignature}
          onSave={onSaveAgreement}
        />
      </AgreementModal>
    </SectionContainer>
  );
};

export default UserAgreement;
