import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { formatTimeDateThenTime } from "../../../../models/formatTime";
import palette from "../../../../palette";
import { GetPaymentsResponse } from "./ActivityPaymentList";
import EconsultCard from "./EconsultCard";

function statusToColor(status: string) {
  switch (status) {
    case "approved":
      return "green";
    case "declined":
      return "red";
    case "pending":
      return palette.darkGreen;
    default:
      return undefined;
  }
}

interface ActivityPaymentProps {
  payment: GetPaymentsResponse;
}

const ActivityPayment: FC<ActivityPaymentProps> = ({ payment }) => {
  return (
    <EconsultCard title={payment.payment.name}>
      <Typography style={{ color: statusToColor(payment.payment.status) }}>
        {payment.payment.status.toUpperCase()}
      </Typography>
      {payment.payment.status === "pending" && (
        <Alert severity="warning">
          Retry this payment by pressing the Payment button above
        </Alert>
      )}
      <Typography>
        {formatTimeDateThenTime(payment.payment.statusUpdatedAt.toDate())}
      </Typography>
      <Typography>{`Total amount paid: $${(payment.payment.amount / 100).toFixed(2)}`}</Typography>
      <Typography>Recorded by: {payment.recordedBy}</Typography>
      {payment.payment.paymentType === "clover" &&
        payment.payment.receiptLink !== undefined && (
          <Typography>
            <a
              href={payment.payment.receiptLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Receipt
            </a>
          </Typography>
        )}
      {payment.payment.paymentType === "external" && (
        <Typography>External Payment</Typography>
      )}
    </EconsultCard>
  );
};

export default ActivityPayment;
