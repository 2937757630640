import {
  ProcessState,
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import InfoIcon from "@mui/icons-material/Info";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import StopIcon from "@mui/icons-material/Stop";
import VideocamIcon from "@mui/icons-material/Videocam";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Theme,
  Tooltip,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  captureButton: {
    height: "100px",
    backgroundColor: palette.orange,
    width: "100px",
    borderRadius: "50%",
  },
  mobileCaptureButton: {
    height: "62px",
    backgroundColor: palette.orange,
    width: "50px",
    borderRadius: "50%",
  },

  mobileRecordButton: {
    height: "62x",
    backgroundColor: palette.orange,
    width: "50px",
    borderRadius: "50%",
  },
  recordingButton: {
    height: "100px",
    backgroundColor: "red",
    width: "100px",
    borderRadius: "50%",
  },
  mobileRecordingButton: {
    height: "62px",
    backgroundColor: "red",
    width: "50px",
    borderRadius: "50%",
  },
  refreshDevices: {
    height: "62px",
    width: "50px",
    borderRadius: "50%",
  },
  refreshIcon: {
    height: "32px",
    width: "32px",
  },
  cameraMode: {
    height: "50px",
  },
  videoCameraIcon: {
    // marginTop: '-6px',
    // marginRight: '10px',

    height: "32px",
    width: "32px",
  },
  cameraModeContainer: {
    display: "inline-block",
  },
  cameraModeIcon: {
    color: theme.palette.text.secondary,
  },
  toggle: {
    position: "relative",
    color: "black",
    left: "10px",
  },
  captureIcon: {
    marginTop: theme.spacing(1),
  },
  fullHeight: {
    height: "100%",
  },
}));
interface ICameraButtonsProps {
  capture: any;
  setDevices: any;
  webcamRef: React.MutableRefObject<any>;
  recordingModeActive: boolean;
  setrecordingModeActive: React.Dispatch<React.SetStateAction<boolean>>;
  handleStopRecordClick: any;
  handleStartRecordClick: any;
  capturing: boolean;
  processState: any;
  processErrorMessage: any;
  devices: any[];
  setDeviceId: any;
  deviceId: any;
  setMirrored: React.Dispatch<React.SetStateAction<boolean>>;
  mirrored: boolean;
}

interface ICameraDevices {
  ChangeDevice:
    | ((
        event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>,
        child: React.ReactNode,
      ) => void)
    | undefined;
  isDisabledSwitchDevice: () => boolean | undefined;
  devices: any[];
  deviceId: any;
}

interface ICameraCaptureButtonProps {
  isMobileSize: boolean;
  classes: {
    mobileCaptureButton: any;
    captureButton: any;
    cameraModeContainer: any;
    captureIcon: any;
  };
  webcamRef: any;
  capture: () => void;
  isDisabledCaptureAndSwitchMode: () => boolean | undefined;
}

interface IStopRecordingButtonProps {
  isMobileSize: boolean;
  classes: {
    recordingButton: any;
    mobileRecordingButton: any;
    cameraModeContainer: any;
    captureIcon: any;
  };
  current: any;
  handleStopRecordClick: () => void;
}

interface IStartRecordingButton {
  isMobileSize: boolean;
  classes: {
    mobileCaptureButton: any;
    captureButton: any;
    cameraModeContainer: any;
    captureIcon: any;
  };
  current: any;
  handleStartRecordClick: () => void;
  isDisabledCaptureAndSwitchMode: () => boolean | undefined;
}
//drop down menu that allows users to change the camera that they are currently using
const CameraDevicesDropDown: FC<ICameraDevices> = ({
  ChangeDevice,
  isDisabledSwitchDevice,
  devices,
  deviceId,
}) => {
  return (
    <FormControl fullWidth>
      <Select
        labelId="deviceListLabel"
        id="deviceList"
        onChange={ChangeDevice}
        value={deviceId}
        disabled={isDisabledSwitchDevice()}
      >
        {devices.map((device: any) => (
          <MenuItem key={device.deviceLabel} value={device.deviceId}>
            {device.deviceLabel}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

//Take a picture
const CameraCaptureButton: FC<ICameraCaptureButtonProps> = ({
  classes,
  isMobileSize,
  webcamRef,
  capture,
  isDisabledCaptureAndSwitchMode,
}) => {
  return (
    <Button
      className={`${isMobileSize ? classes.mobileCaptureButton : classes.captureButton} ${classes.cameraModeContainer}`}
      color="primary"
      variant="contained"
      onClick={(e) => {
        if (webcamRef !== undefined) {
          e.preventDefault();
          capture();
        }
      }}
      disabled={isDisabledCaptureAndSwitchMode()}
    >
      <PhotoCameraIcon className={classes.captureIcon} />
    </Button>
  );
};

//stop recording
const StopRecordingButton: FC<IStopRecordingButtonProps> = ({
  classes,
  current,
  handleStopRecordClick,
  isMobileSize,
}) => {
  return (
    <Button
      className={`${isMobileSize ? classes.mobileRecordingButton : classes.recordingButton} ${classes.cameraModeContainer}`}
      onClick={(e) => {
        if (current !== undefined) {
          e.preventDefault();
          handleStopRecordClick();
        }
      }}
      color="primary"
      variant="contained"
    >
      <StopIcon className={classes.captureIcon} />
    </Button>
  );
};

//start recording
const StartRecordingButton: FC<IStartRecordingButton> = ({
  isMobileSize,
  classes,
  current,
  handleStartRecordClick,
  isDisabledCaptureAndSwitchMode,
}) => {
  return (
    <Button
      className={`${isMobileSize ? classes.mobileCaptureButton : classes.captureButton} ${classes.cameraModeContainer}`}
      onClick={(e) => {
        if (current !== undefined) {
          e.preventDefault();
          handleStartRecordClick();
        }
      }}
      color="primary"
      variant="contained"
      disabled={isDisabledCaptureAndSwitchMode()}
    >
      <FiberManualRecordIcon className={classes.captureIcon} />
    </Button>
  );
};

export const CameraButtons = ({
  capture,
  webcamRef,
  recordingModeActive,
  setrecordingModeActive,
  capturing,
  handleStopRecordClick,
  handleStartRecordClick,
  processState,
  processErrorMessage,
  devices,
  setDeviceId,
  deviceId,
  setMirrored,
  mirrored,
}: ICameraButtonsProps) => {
  const { classes } = useStyles();
  //prevent page scrolling by space bar on this page
  const spacebarDownListener = (e: any) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  //listen for space bar presses start and stop recording or take pictures
  const spacebarUpListener = (e: any) => {
    if (!isDisabledNoDevice()) {
      if (e.keyCode === 32) {
        e.preventDefault();
        if (recordingModeActive) {
          if (capturing) {
            handleStopRecordClick();
          } else {
            handleStartRecordClick();
          }
        } else {
          if (webcamRef !== undefined) {
            capture();
          }
        }
      }
    }
  };

  //add and remove spacebar listeners on entry/exit
  useEffect(() => {
    document.body.addEventListener("keydown", spacebarDownListener);
    document.body.addEventListener("keyup", spacebarUpListener);
    return () => {
      document.body.removeEventListener("keydown", spacebarDownListener);
      document.body.removeEventListener("keyup", spacebarUpListener);
    };
  }); //TODO find a way to make this get called less
  // this is so bad

  //changes from Single frame image capture to video recording
  const changeMode = () => {
    setrecordingModeActive(!recordingModeActive);
  };

  //change the camera to use when selected from the drop down menu
  const ChangeDevice = (event: any) => {
    setDeviceId(event.target.value);
  };
  //!what even is this variable naming
  const isDisabledNoDevice = () => {
    return (
      deviceId === undefined || webcamRef.current === undefined || isDisabled()
    );
  };
  //!what even is this variable naming
  const isDisabledCapturing = () => {
    return capturing || webcamRef.current === undefined || isDisabled();
  };

  const isDisabled = () => {
    return processState === ProcessState.running;
  };
  const isMobileSize = useMobileSizes();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={1}
      padding={1}
    >
      <Grid
        size={12}
        sx={{ display: "flex" }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {recordingModeActive ? (
          <>
            {" "}
            {capturing ? (
              <StopRecordingButton
                classes={classes}
                current={webcamRef.current}
                handleStopRecordClick={handleStopRecordClick}
                isMobileSize={isMobileSize}
              />
            ) : (
              <StartRecordingButton
                classes={classes}
                isDisabledCaptureAndSwitchMode={isDisabledNoDevice}
                isMobileSize={isMobileSize}
                current={webcamRef.current}
                handleStartRecordClick={handleStartRecordClick}
              />
            )}
          </>
        ) : (
          <CameraCaptureButton
            classes={classes}
            isMobileSize={isMobileSize}
            capture={capture}
            webcamRef={webcamRef}
            isDisabledCaptureAndSwitchMode={isDisabledNoDevice}
          />
        )}
      </Grid>

      <Grid size={{ xs: 12 }}>
        <ProcessStatus
          state={processState}
          errorMessage={processErrorMessage}
        />
      </Grid>

      <Grid
        container
        alignItems={"center"}
        size={{ xs: 12, sm: 9, md: 9, lg: "grow" }}
      >
        <Grid size="auto">
          {!isMobileSize && (
            <Tooltip title="If the camera image is not displaying it is possible that the camera is being used by another application.">
              <InfoIcon color="primary" />
            </Tooltip>
          )}
        </Grid>
        <Grid size="grow">
          <CameraDevicesDropDown
            ChangeDevice={ChangeDevice}
            deviceId={deviceId}
            isDisabledSwitchDevice={isDisabledCapturing}
            devices={devices}
          />
        </Grid>
      </Grid>

      <Grid
        size={{ xs: 6, sm: 3, md: "auto" }}
        sx={{ display: "flex" }}
        justifyContent="center"
      >
        <Tooltip title="Mirroring is only available for photos and not video">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mirrored}
                  onChange={() => setMirrored(!mirrored)}
                />
              }
              label="Mirror Image"
            />
          </FormGroup>
        </Tooltip>
      </Grid>

      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        size={{ xs: 6, md: 4, lg: "auto" }}
      >
        <VideocamIcon
          className={`${classes.videoCameraIcon} ${classes.cameraModeContainer} ${classes.cameraModeIcon}`}
        />
        <FormGroup
          className={`${classes.cameraModeContainer} ${classes.toggle}`}
        >
          <FormControlLabel
            control={<Switch color="default" />}
            label={""}
            disabled={isDisabledCapturing()}
            checked={!recordingModeActive}
            onMouseUp={(e) => {
              if (!capturing) {
                e.preventDefault();
                changeMode();
              }
            }}
          />
        </FormGroup>
        <CameraAltIcon
          className={`${classes.cameraModeContainer} ${classes.cameraModeIcon}`}
        />
      </Grid>
    </Grid>
  );
};

export default CameraButtons;
