import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { FC } from "react";

interface ServiceSearchProps {
  searchText: string;
  handleSearchText: (text: string) => void;
  onStringSearch: (searchText: string) => void;
  handleRestartSearchResults: () => void;
}

const ServiceSearch: FC<ServiceSearchProps> = ({
  searchText,
  handleSearchText,
  onStringSearch,
  handleRestartSearchResults,
}) => {
  const handleSearch = (text: string) => {
    if (text == "") handleRestartSearchResults();

    onStringSearch(text);
  };

  const feelGoodForClickingSearch = () => {
    handleRestartSearchResults();
    onStringSearch(searchText);
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid size={{ xs: 12, sm: 12, md: "grow" }}>
        <TextField
          placeholder={"Service name, specialty, keywords..."}
          onChange={(event) => {
            handleSearchText(event.target.value);
            handleSearch(event.target.value);
          }}
          variant="outlined"
          margin="dense"
          fullWidth
        />
      </Grid>
      <Button
        color="primary"
        variant="contained"
        onClick={feelGoodForClickingSearch}
      >
        Search
      </Button>
    </Grid>
  );
};

export default ServiceSearch;
