import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItemButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    fontSize: "1.02em",
  },
  listItem: {
    paddingLeft: theme.spacing(2),
  },
  listItemIcon: {
    minWidth: "35px",
  },
  childrenContainer: {
    background: theme.palette.grey[200],
  },
}));

interface CollapseSidebarContextProps {
  atLeastOneAvailable: () => void;
}

export const CollapseSidebarContext =
  React.createContext<CollapseSidebarContextProps>({
    atLeastOneAvailable: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  });

interface SidebarCollapseItemProps {
  title: string;
  showCollapse: boolean;
  children: React.ReactNode;
}

const SidebarCollapseItem: React.FC<SidebarCollapseItemProps> = ({
  title,
  showCollapse,
  children,
}) => {
  const { classes } = useStyles();

  const [showCategory, setShowCategory] = useState(false);

  const [open, setOpen] = useState(false);

  const clickItemHandler = () => {
    setOpen(!open);
  };

  /* Each sidebar item will call this function if they have permissions to display
    If no sidebar items call this function (meaning none are available), then this entire collapse menu is hidden
    
    For example, if the user it not a specialist, then none of the specialist sidebar items should be shown. 
    Therefore, this entire collapse menu should be hidden */
  const atLeastOneAvailableHandler = () => {
    setShowCategory(true);
  };

  return (
    <CollapseSidebarContext.Provider
      value={{ atLeastOneAvailable: atLeastOneAvailableHandler }}
    >
      <span style={{ display: showCategory ? "unset" : "none" }}>
        {showCollapse ? (
          <>
            <ListItemButton
              onClick={clickItemHandler}
              className={classes.listItem}
              disableGutters
              divider
            >
              <ListItemText
                primary={
                  <Typography className={classes.title}>
                    {title.toUpperCase()}
                  </Typography>
                }
              />
              <ListItemIcon className={classes.listItemIcon}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
            </ListItemButton>
            <Collapse in={open} className={classes.childrenContainer}>
              {children}
            </Collapse>
          </>
        ) : (
          <>
            {children}
            <Divider />
          </>
        )}
      </span>
    </CollapseSidebarContext.Provider>
  );
};

export default SidebarCollapseItem;
