import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Grid2 as Grid, Typography } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { FC, useCallback } from "react";
import GalleryController from "../../../../components/Gallery/Controllers/GalleryController";
import MediaViewerController from "../../../../components/Gallery/Controllers/MediaViewerController";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../../components/Gallery/Models/GalleryModel";
import GalleryRenderer from "../../../../components/Gallery/Views/GalleryRenderer";
import GalleryTimestampBins from "../../../../components/Gallery/Views/GalleryTimestampBins";
import MediaViewer from "../../../../components/Gallery/Views/MediaViewer/MediaViewer";
import useMobileCameraListeners from "../../Camera/useMobileCameraListeners";
import useConsultFormStyles from "../useConsultFormStyles";

/** Summary
 * Handles rendering the gallery for the referral form
 * Also contains GalleryController which handles gallery states
 */

interface ConsultGalleryProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  selectHandler: (item: UserMediaMetadataItem) => void;
  unselectHandler: (id: string) => void;
  unselectAllHandler: () => void;
  onCloseGallery: () => void;
  /** Number of images before a warning is shown */
  imageWarningLimit: number;
}

const ConsultGallery: FC<ConsultGalleryProps> = ({
  selectedMedia,
  selectHandler,
  unselectHandler,
  unselectAllHandler,
  onCloseGallery,
  imageWarningLimit,
}) => {
  const { classes, cx } = useConsultFormStyles();

  const {
    // data
    mediaItems,
    binnedMediaItems,
    // handlers
    loadMoreHandler,
    refreshHandler,
    modifyItemHandler,
    fetchNewHandler,
    // states
    galleryLoadState,
    galleryLoadError,
  } = GalleryController();

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        selected={selectedMedia[photo.id] !== undefined}
        enableSelect={true}
        editing={false}
        select={selectHandler}
        unselect={unselectHandler}
        handleOpenMedia={openMediaItemHandler}
      />
    ),
    [selectedMedia],
  );

  //Trigger refresh when camera uploads done
  const fetchNewCallback = useCallback(() => fetchNewHandler, [mediaItems]);
  useMobileCameraListeners({ onUploadsFinished: fetchNewCallback() });

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <HeightLayout
        height={heightMinusAppBar}
        className={cx({
          [classes.displayNone]: openMediaItem !== undefined,
        })}
      >
        <HeightLayoutChild flexDriver>
          <Grid container spacing={1} alignItems="center">
            <Grid size={{ xs: 12 }}>
              <Grid container spacing={1} alignItems="center">
                <Grid>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<ChevronLeftIcon />}
                    onClick={onCloseGallery}
                  >
                    Back to Consult
                  </Button>
                </Grid>
                <Grid>
                  <IconButton onClick={refreshHandler} size="large">
                    <RefreshIcon />
                  </IconButton>
                </Grid>
                <Grid size={{ xs: 12 }}>
                  <ProcessStatus
                    state={galleryLoadState}
                    errorMessage={galleryLoadError}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">
                {" "}
                {Object.keys(selectedMedia).length} images selected
              </Typography>
            </Grid>
            <Grid>
              {Object.keys(selectedMedia).length > 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={unselectAllHandler}
                >
                  Unselect All
                </Button>
              )}
            </Grid>
            {Object.keys(selectedMedia).length > imageWarningLimit ? (
              <Grid size={{ xs: 12 }}>
                <MuiAlert severity="warning">
                  Please do not attach more than {imageWarningLimit} images
                  unless necessary.
                </MuiAlert>
              </Grid>
            ) : (
              <Grid>
                <MuiAlert severity="info">
                  Do not include blurry images. Limit duplicate images.
                </MuiAlert>
              </Grid>
            )}
          </Grid>
        </HeightLayoutChild>
        <HeightLayoutChild
          flexDriven
          allowOverflowY
          className={classes.spacing}
        >
          <GalleryTimestampBins
            binnedMediaItems={binnedMediaItems}
            galleryRenderer={galleryRenderer}
          />
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.spacing}>
          <Button onClick={loadMoreHandler} variant="outlined" color="primary">
            Load More
          </Button>
        </HeightLayoutChild>
      </HeightLayout>
      {openMediaItem !== undefined && (
        <MediaViewer
          height={heightMinusAppBar}
          mediaItem={openMediaItem}
          closeMediaItem={closeMediaItemHandler}
          modifyItemHandler={modifyItemHandler}
          fullWidth
        />
      )}
    </>
  );
};

export default ConsultGallery;
