import {
  PaperModal,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Utilities } from "../../../../shared/types";
import useAITranslation from "../../useAITranslation";
import MediaViewerController from "../Controllers/MediaViewerController";
import { UserMediaMetadataItem } from "../Models/GalleryModel";
import {
  getCleanedFileNameFromActivityPath,
  getMockMetadataForReferralMedia,
} from "../Models/MessagingGalleryModel";
import GalleryGrid from "./GalleryGrid";
import GalleryRenderer from "./GalleryRenderer";
import MultiMediaViewer from "./MediaViewer/MultiMediaViewer";

const useStyles = makeStyles()((theme: Theme) => ({
  linkMedia: {
    marginBottom: theme.spacing(2),
  },
  mediaHeader: {
    fontWeight: 500,
  },
  mediaViewerContainer: {
    height: "100%",
  },
}));

interface MessagingGalleryProps {
  referralMediaURIs: string[];
  econsultImageLabels?: Utilities.StringDict<string[]>;
}

export const MessagingGallery: FC<MessagingGalleryProps> = ({
  referralMediaURIs,
  econsultImageLabels,
}) => {
  const { classes } = useStyles();

  const [linkMedia, setLinkMedia] = useState<UserMediaMetadataItem[]>([]);
  const [mediaItems, setMediaItems] = useState<UserMediaMetadataItem[]>([]);
  const [doctorLabels, setDoctorLabels] = useState<string[] | undefined>(
    undefined,
  );

  const { aiToSp } = useAITranslation({ useSpToAi: true });

  const getDoctorLabels = (
    item: UserMediaMetadataItem,
  ): string[] | undefined => {
    return econsultImageLabels !== undefined &&
      econsultImageLabels[item.data.filePath] !== undefined
      ? econsultImageLabels[item.data.filePath].map((label) =>
          aiToSp(label, true),
        )
      : undefined;
  };

  const { openMediaItem, openMediaItemHandler, closeMediaItemHandler } =
    MediaViewerController();

  const changeMediaItemHandler = (item: UserMediaMetadataItem) => {
    openMediaItemHandler(item);
    setDoctorLabels(getDoctorLabels(item));
  };

  const galleryRenderer = useCallback(
    (photo: UserMediaMetadataItem) => (
      <GalleryRenderer
        //React photo gallery props
        item={photo}
        //Custom props
        enableSelect={false}
        editing={false}
        handleOpenMedia={changeMediaItemHandler}
        labels={getDoctorLabels(photo)}
      />
    ),
    //Refresh on aiToSp, since it takes a sec to load
    [aiToSp],
  );

  useEffect(() => {
    getMockMetadataForReferralMedia(referralMediaURIs).then(
      ({ otherMedia, imageVideoMedia }) => {
        // Sort media by their IDs
        otherMedia.sort((a, b) => a.id.localeCompare(b.id));
        imageVideoMedia.sort((a, b) => a.id.localeCompare(b.id));

        setLinkMedia(otherMedia);
        setMediaItems(imageVideoMedia);
      },
    );
  }, [referralMediaURIs]);

  const { sizeRef, height: mediaViewerHeight, updateSize } = useSizeManager();

  return (
    <>
      <Box className={classes.linkMedia}>
        <Typography className={classes.mediaHeader}>Files</Typography>
        <ul>
          {linkMedia.map((file) => (
            <li key={`econsult_file_${file.id}`}>
              <div>
                <a
                  href={file.data.fileDownloadUrl ?? ""}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getCleanedFileNameFromActivityPath(file.data.filePath)}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </Box>
      <Typography className={classes.mediaHeader}>Images and Videos</Typography>
      <GalleryGrid photos={mediaItems} galleryRenderer={galleryRenderer} />
      {openMediaItem !== undefined && (
        <PaperModal
          show={openMediaItem !== undefined}
          setShow={(show) => {
            if (!show) closeMediaItemHandler();
          }}
          updateSize={updateSize}
          enablePadding
        >
          <div className={classes.mediaViewerContainer} ref={sizeRef}>
            <MultiMediaViewer
              height={mediaViewerHeight}
              currentMediaItem={openMediaItem}
              mediaItems={mediaItems}
              closeMediaItem={closeMediaItemHandler}
              openMediaItem={changeMediaItemHandler}
              doctorLabels={doctorLabels}
              allowAIDatabaseCaching={false}
            />
          </div>
        </PaperModal>
      )}
    </>
  );
};

export default MessagingGallery;
