import { addParentPathToChildPaths } from "src/routes/internalHelpers";
import { type Route } from "src/routes/types";

// #region Types

/**
 * List of all route keys for dashboardChildrenRoutes as an enum object
 */
export const AuthRouteNames = {
  login: "login",
  signup: "signup",
  resetPassword: "resetPassword",
} as const;

/**
 * Type of the keys of all of the routes for dashboardChildrenRoutes (e.g. to ensure that all routes are covered for display)
 */
export type AuthChildRouteName =
  (typeof AuthRouteNames)[keyof typeof AuthRouteNames];

// #endregion

// #region Constants

/**
 * The parent route for all auth routes
 */
export const AUTH_ROUTE = "/";

/**
 * All route metadata for all children routes of auth without the AUTH_ROUTE prepended
 */
const AUTH_CHILDREN_PARTIAL_ROUTES: {
  [key in AuthChildRouteName]: Route;
} = {
  [AuthRouteNames.login]: {
    path: "/login",
    availableOnMobile: true,
    isValidHomepage: false,
    requiredResourcePermissions: [[]],
    name: "Login",
  },
  [AuthRouteNames.signup]: {
    path: "/signup",
    availableOnMobile: true,
    isValidHomepage: false,
    requiredResourcePermissions: [[]],
    name: "Signup",
  },
  [AuthRouteNames.resetPassword]: {
    path: "/auth/reset-password",
    availableOnMobile: true,
    isValidHomepage: false,
    requiredResourcePermissions: [[]],
    name: "Reset Password",
  },
} as const;
// #endregion

// #region Exports

/**
 * Create new object from authChildrenRoutes with /${AUTH_ROUTE}/ prepended to each path
 */
export const authChildrenRoutes: {
  [key in AuthChildRouteName]: Route;
} = addParentPathToChildPaths(
  AUTH_CHILDREN_PARTIAL_ROUTES,
  AuthRouteNames,
  AUTH_ROUTE,
);

// #endregion

export default authChildrenRoutes;
