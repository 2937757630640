import { Theme } from "@mui/material";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import React from "react";
import { makeStyles } from "tss-react/mui";

export interface SidebarProps {
  className?: string;
  paperClassName?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  isMobileSize: boolean;
  drawerWidthOpen: number;
  drawerWidthClosed: number;
  children: React.ReactNode;
}

const AletheaSidebar: React.FC<SidebarProps> = ({
  className,
  paperClassName,
  open,
  setOpen,
  isMobileSize,
  drawerWidthOpen,
  drawerWidthClosed,
  children,
}) => {
  const useStyles = makeStyles()((theme: Theme) => ({
    drawer: {
      flexShrink: 0,
      whiteSpace: "nowrap",
      overflowX: "hidden",
    },
    drawerOpen: {
      transition: theme.transitions.create("width", {
        // should match transitions in AppBar
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: drawerWidthOpen,
      overflowX: "hidden",
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: drawerWidthClosed,
      overflowX: "hidden",
    },
  }));
  const { classes, cx } = useStyles();

  return (
    <SwipeableDrawer
      variant={isMobileSize ? "temporary" : "permanent"}
      className={cx(
        {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        },
        classes.drawer,
        className,
      )}
      classes={{
        paper: cx(
          {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          },
          paperClassName,
        ),
      }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default AletheaSidebar;
