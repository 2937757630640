import List from "@mui/material/List";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useEffect, useState } from "react";
import {
  NavigationType,
  useLocation,
  useNavigate,
  useNavigationType,
} from "react-router-dom";

import { makeStyles } from "tss-react/mui";

import firebase from "firebase/compat/app";
import { fbFirestore, logAnalyticsEvent } from "../../../firebase";

import { PatientMessage } from "./types";

import { AuthContext } from "../../../AuthProvider";

import PatientMessageListItem from "./PatientMessageListItem";
import Toolbar from "./PatientMessageToolbar";
import ViewMessage from "./ViewMessage";

import { thinScrollbar } from "@alethea-medical/alethea-components";

import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useProcessState,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ListItemButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PatientMessageFolder } from "../../../../shared/types";
import { SearchBar } from "../../../components/SearchBar";
import SnackbarMessage from "../../../components/SnackbarMessage";
import useUndoMoveFolder from "./useUndoMoveFolder";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  listBox: {
    padding: 0,
    overflowY: "scroll",
  },
  list: {
    padding: 0,
  },
  header: {
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  hidden: {
    display: "none",
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
  },
}));

export interface PatientMessageDict {
  [messageId: string]: PatientMessage;
}

const PatientMessages: FC = () => {
  const { classes } = useStyles();

  const authContext = useContext(AuthContext);

  const loadMoreAmount = 20;
  const location = useLocation();
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const [messages, setMessages] = useState<PatientMessageDict>({});
  const [newMessageQueue, setNewMessageQueue] = useState<PatientMessage[]>([]);
  const [openMessageId, setOpenMessageId] = useState<string | undefined>(
    undefined,
  );
  const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [oldestMessageTime, setOldestMessageTime] =
    useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
  const [folder, setFolder] = useState<PatientMessageFolder>("inbox");

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const [enableSearch, setEnableSearch] = useState<boolean>(false);
  const [messageFilter, setMessageFilter] = useState<
    ((message: PatientMessage) => boolean) | undefined
  >(undefined);

  const loadMessages = (fetchLessThan: firebase.firestore.Timestamp) => {
    fbFirestore
      .collection("patient_messages")
      .where("physicianUid", "==", authContext.uid)
      .where("folder", "==", folder)
      .where("statusUpdatedAt", "<", fetchLessThan)
      .orderBy("statusUpdatedAt", "desc")
      .limit(loadMoreAmount)
      .get()
      .then(newMessagesHandler)
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error loading patient messages",
        });
      });
  };

  const loadMoreHandler = () => {
    logAnalyticsEvent("patient_message_load_more");

    loadMessages(oldestMessageTime);
  };

  const newMessagesHandler = (
    snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    if (snapshot.size > 0) {
      console.log(`Retrieved ${snapshot.size} messages`);
      const newMessages = snapshot.docs
        .filter((doc) => {
          return doc.exists;
        })
        .map((doc) => {
          return Object.assign(doc.data(), { id: doc.id }) as PatientMessage;
        });
      setNewMessageQueue(newMessages);
    }
  };

  const openMessageHandler = (id: string) => {
    logAnalyticsEvent("patient_message_select");
    setOpenMessageId(id);
    markAsRead(id);
    navigate(location.pathname); //Push current path so when back is pressed, user stays on current page
  };

  const goBackHandler = () => {
    setOpenMessageId(undefined);
  };

  const tabChangeHandler = (_: any, value: PatientMessageFolder) => {
    if (value !== folder) {
      setFolder(value);
      resetMessages();
    }
  };

  const runSearch = (searchTerms: string[]) => {
    logAnalyticsEvent("patient_message_search", {
      searchTermCount: searchTerms.length,
    });

    const filter = (message: PatientMessage) => {
      // What the "type" user will search vs. what is it actually called in the database is slightly different, this addresses that
      const messageTypeMapping: { [key: string]: string } = {
        "One Way Message": "OneWayPatient",
        "One-Way Message": "OneWayPatient",
        "Patient CC": "PatientCC",
        "MOA Message": "MOAMessage",
      };

      return searchTerms.every((term) =>
        Object.entries(message).some(([key, value]) => {
          if (key === "type" || key === "subType") {
            // Exact match for type and subType
            return (
              typeof value === "string" &&
              (value.toLowerCase() === term.toLowerCase() ||
                messageTypeMapping[term]?.toLowerCase() === value.toLowerCase())
            );
          } else if (typeof value === "string") {
            // Partial match for other string fields
            return value.toLowerCase().includes(term.toLowerCase());
          } else if (key === "patientInfo" && typeof value === "object") {
            // Search within patientInfo object
            return Object.values(value).some(
              (v) =>
                typeof v === "string" &&
                v.toLowerCase().includes(term.toLowerCase()),
            );
          }
          return false;
        }),
      );
    };

    setEnableSearch(true);
    setMessageFilter(() => filter);
  };

  const clearSearch = () => {
    setEnableSearch(false);
    setMessageFilter(undefined);
  };

  const resetMessages = () => {
    setMessages({});
    setSelectedMessages([]);
    setOldestMessageTime(firebase.firestore.Timestamp.now());
  };

  const markAsRead = (messageId: string) => {
    if (!messages[messageId].read) {
      const newMessages = { ...messages };
      fbFirestore
        .collection("patient_messages")
        .doc(messageId)
        .update({ read: true })
        .then(() => {
          newMessages[messageId].read = true;
          setMessages(newMessages);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
          });
        });
    }
  };

  const messageSelectedHandler = (id: string, checked: boolean) => {
    if (checked) {
      if (!selectedMessages.includes(id)) {
        const newSelectedMessages = [...selectedMessages];
        newSelectedMessages.push(id);
        setSelectedMessages(newSelectedMessages);
      }
    } else {
      const idxToRemove = selectedMessages.indexOf(id);
      if (idxToRemove !== -1) {
        const newSelectedMessages = [...selectedMessages];
        newSelectedMessages.splice(idxToRemove, 1);
        setSelectedMessages(newSelectedMessages);
      }
    }
  };

  const selectAllHandler = (selectAll: boolean) => {
    if (selectAll) {
      const newSelectedMessages = [...selectedMessages];
      Object.keys(messages).forEach((id) => {
        if (!newSelectedMessages.includes(id)) {
          newSelectedMessages.push(id);
        }
      });
      setSelectedMessages(newSelectedMessages);
    } else {
      setSelectedMessages([]);
    }
  };

  const handleRefresh = () => {
    resetMessages();
    loadMessages(firebase.firestore.Timestamp.now());
  };

  const onUndoMoveFolder = (
    prevFolder: PatientMessageFolder,
    changedIds: string[],
  ) => {
    return Promise.all(
      changedIds.map((id) => {
        return fbFirestore
          .collection("patient_messages")
          .doc(id)
          .update({ folder: prevFolder });
      }),
    )
      .then(() => {
        setShowUndoSnackbar(false);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error undoing the previous action",
        });
      });
  };

  const { undo, setUndoIds, setPrevFolder } = useUndoMoveFolder(
    messages,
    setMessages,
    onUndoMoveFolder,
  );
  const [showUndoSnackbar, setShowUndoSnackbar] = useState<boolean>(false);
  const [undoSnackbarAction, setUndoSnackbarAction] = useState<string>("");
  const [showUnreadSnackbar, setShowUnreadSnackbar] = useState<boolean>(false);

  const markAsUnreadHandler = (messageIds: string[]) => {
    const newMessages = { ...messages };
    return Promise.all(
      messageIds.map((id) => {
        return fbFirestore
          .collection("patient_messages")
          .doc(id)
          .update({ read: false })
          .then(() => {
            newMessages[id].read = false;
          });
      }),
    )
      .then(() => {
        logAnalyticsEvent("patient_message_mark_unread");
        setShowUnreadSnackbar(true);
        setOpenMessageId(undefined);
        setMessages(newMessages);
        setSelectedMessages([]);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error marking message as unread",
        });
      });
  };

  const moveFolderHandler = (
    messageIds: string[],
    newFolder: PatientMessageFolder,
  ) => {
    const newMessages = { ...messages };
    const newUndoIds: string[] = [];
    return Promise.all(
      messageIds.map((id) => {
        return fbFirestore
          .collection("patient_messages")
          .doc(id)
          .update({ folder: newFolder })
          .then(() => {
            newUndoIds.push(id);
            delete newMessages[id];
          });
      }),
    )
      .then(() => {
        logAnalyticsEvent(`patient_message_move_to_${newFolder}`);

        setUndoSnackbarAction(newFolder);
        setUndoIds(newUndoIds);
        setPrevFolder(folder);
        setSelectedMessages([]);
        setShowUndoSnackbar(true);
        setOpenMessageId(undefined);
        setMessages(newMessages);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: `Error moving messages to ${newFolder}`,
        });
      });
  };

  useEffect(() => {
    loadMessages(firebase.firestore.Timestamp.now());
  }, [folder]);

  useEffect(() => {
    if (navigationType === NavigationType.Pop) {
      setOpenMessageId(undefined); //Go back to message list instead of previous route
    }
  }, [location, navigationType, setOpenMessageId]);

  //Run when new activities are put into newActivityQueue
  useEffect(() => {
    //Put the update logic into a separate function from the onSnapshot function so that it contains
    //all updated values of activityIndex and activities
    const newMessages = { ...messages };
    newMessageQueue.forEach((newMessage) => {
      if (newMessage.created < oldestMessageTime) {
        setOldestMessageTime(newMessage.created);
      }
      newMessages[newMessage.id] = newMessage;
    });
    setMessages(newMessages);
  }, [newMessageQueue]);

  useEffect(() => {
    if (selectedMessages.length > 0) {
      setAllSelected(
        Object.keys(messages).every((id) => {
          return selectedMessages.includes(id);
        }),
      );
    } else {
      setAllSelected(false);
    }
  }, [selectedMessages]);

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <div className={openMessageId === undefined ? "" : classes.hidden}>
        <List className={classes.header}>
          <HeightLayout height={heightMinusAppBar}>
            <HeightLayoutChild flexDriver>
              <ListItem divider style={{ padding: 0 }}>
                <Tabs
                  value={folder}
                  onChange={tabChangeHandler}
                  variant="fullWidth"
                >
                  <Tab value={"inbox"} label="Inbox" />
                  <Tab value={"sent"} label="Sent" />
                  <Tab value={"archive"} label="Archive" />
                </Tabs>
              </ListItem>
              <ProcessStatus
                state={processState}
                setState={setProcessState}
                errorMessage={processErrorMessage}
                useSnackbar={true}
              />
              <ListItem divider>
                <SearchBar
                  enableSearch={enableSearch}
                  runSearch={runSearch}
                  clearSearch={clearSearch}
                  maxSearchTerms={10}
                  placeholderText="PHN, Patient Email, Subject, Message Type"
                />
              </ListItem>
              <ListItem className={classes.toolbar} divider>
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid>
                    <Checkbox
                      checked={allSelected}
                      onChange={(e) => {
                        selectAllHandler(e.target.checked);
                      }}
                    />
                  </Grid>
                  <Grid>
                    <IconButton onClick={handleRefresh}>
                      <RefreshIcon />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <Toolbar
                      folder={folder}
                      atLeastOneSelected={selectedMessages.length > 0}
                      markAsUnread={() => {
                        markAsUnreadHandler(selectedMessages);
                      }}
                      moveFolder={(folder: PatientMessageFolder) => {
                        moveFolderHandler(selectedMessages, folder);
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </HeightLayoutChild>
            <HeightLayoutChild
              flexDriven
              allowOverflowY
              className={classes.thinScrollbar}
            >
              {Object.values(messages)
                .sort((a, b) => {
                  if (a.statusUpdatedAt < b.statusUpdatedAt) return 1;
                  else if (a.statusUpdatedAt > b.statusUpdatedAt) return -1;
                  else return 0;
                })
                .map((m, index) => {
                  const jsx = (
                    <PatientMessageListItem
                      key={`message_${m.subject}_${index}`}
                      message={m}
                      selected={selectedMessages.includes(m.id)}
                      setSelected={messageSelectedHandler}
                      openMessage={openMessageHandler}
                    />
                  );
                  return messageFilter === undefined || messageFilter(m)
                    ? jsx
                    : null;
                })}
              <ListItemButton
                onClick={loadMoreHandler}
                alignItems="center"
                divider
              >
                <ListItemIcon>
                  <ArrowDownwardIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    enableSearch ? "Load More Search Results" : "Load More"
                  }
                />
              </ListItemButton>
            </HeightLayoutChild>
          </HeightLayout>
        </List>
      </div>
      {openMessageId !== undefined && messages[openMessageId] !== undefined && (
        <ViewMessage
          message={messages[openMessageId]}
          goBackHandler={goBackHandler}
          markAsUnread={() => {
            markAsUnreadHandler([openMessageId]);
          }}
          moveFolder={(folder) => {
            moveFolderHandler([openMessageId], folder);
          }}
        />
      )}

      <SnackbarMessage
        message={`Moved to ${undoSnackbarAction}`}
        show={showUndoSnackbar}
        setShow={setShowUndoSnackbar}
        useButton
        buttonText="UNDO"
        onClick={undo}
      />
      <SnackbarMessage
        message={`Marked as unread`}
        show={showUnreadSnackbar}
        setShow={setShowUnreadSnackbar}
      />
    </>
  );
};

export default PatientMessages;
