import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  label: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    display: "inline-block", //Make paper not take full width
    wordBreak: "break-all",
  },
  orange: {
    backgroundColor: palette.orange,
    color: "white",
  },
  dark: {
    backgroundColor: theme.palette.secondary.main,
  },
  light: {
    backgroundColor: theme.palette.secondary.light,
  },
  labelRemoveButton: {
    padding: theme.spacing(0),
  },
  text: {
    fontSize: "0.95em",
  },
}));

interface LabelProps {
  text: string;
  color: "dark" | "light" | "orange";
  closeButton?: boolean;
  labelKey?: string;
  handleRemove?: (name: string) => void;
  className?: string;
  startIcon?: ReactNode;
}

const Label: FC<LabelProps> = ({
  text,
  color,
  closeButton,
  labelKey,
  handleRemove,
  className = "",
  startIcon,
}) => {
  const { classes } = useStyles();
  const getColorClass = () => {
    switch (color) {
      case "dark":
        return classes.dark;
      case "light":
        return classes.light;
      case "orange":
        return classes.orange;
    }
  };
  return (
    <Paper
      elevation={1}
      className={`${classes.label} ${getColorClass()} ${className}`}
    >
      <Grid container alignItems="center" spacing={1}>
        {startIcon && <Grid>{startIcon}</Grid>}
        <Grid>
          <Typography className={classes.text}>{text}</Typography>
        </Grid>
        {closeButton && labelKey !== undefined && (
          <Grid>
            <IconButton
              className={classes.labelRemoveButton}
              onClick={() => {
                handleRemove?.(labelKey);
              }}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default Label;
