import { App, type URLOpenListenerEvent } from "@capacitor/app";
import { type FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//from capacitorjs deep-links documentation
const AppUrlListener: FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = event.url.split(".app").pop();
      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing
      // logic take over
    });
  }, []);

  return null;
};

export default AppUrlListener;
