import { useSizeManager } from "@alethea-medical/alethea-components";
import ReactImageMagnify from "@blacklab/react-image-magnify";
import { FC, useEffect, useState } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  image: {
    //Center image
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

interface ImageToAnnotateProps {
  src?: string;
  imageHeight?: number;
}

const ImageToAnnotate: FC<ImageToAnnotateProps> = ({ src, imageHeight }) => {
  const { classes } = useStyles();
  const [imageWidth, setImageWidth] = useState<number | undefined>(undefined);

  const { sizeRef, height, width, updateSize } = useSizeManager();
  const [scale] = useState(2);

  // const onZoom = (e: any) => {
  //     if(e) {
  //         //scale by 20% each click of wheel
  //         //Multiply by scale to increase the rate of scaling as you zoom in to make it "feel" linear
  //         setScale(Math.min(8, Math.max(scale - e.deltaY/500*scale, 1)));
  //     }
  // }

  useEffect(() => {
    const img = new Image();
    img.addEventListener("load", function () {
      if (imageHeight !== undefined) {
        setImageWidth((imageHeight * this.naturalWidth) / this.naturalHeight);
        updateSize();
      }
    });
    img.src = src ? src : "";
  }, [src]);

  return (
    <div ref={sizeRef}>
      <ReactImageMagnify
        className={classes.image}
        imageProps={{
          src: src ? src : "",
          height: imageHeight,
          width: imageWidth,
          onLoad: updateSize,
        }}
        magnifiedImageProps={{
          src: src ? src : "",
          width: (imageWidth ? imageWidth : width) * scale,
          height: (imageHeight ? imageHeight : height) * scale,
        }}
        hoverDelayInMs={0}
        fadeDurationInMs={100}
      />
    </div>
  );
};

export default ImageToAnnotate;
