import { FC, ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "src/AuthProvider";
import { routes } from "src/routes/routesData";

interface ShowOrRedirectToLoginProps {
  children: ReactNode;
}

/**
 *
 */

const ShowOrRedirectToLogin: FC<ShowOrRedirectToLoginProps> = ({
  children,
}) => {
  const { authenticated } = useContext(AuthContext);
  const location = useLocation();

  if (!authenticated) {
    return (
      <Navigate replace to={routes.login.path} state={{ from: location }} />
    );
  }

  return children;
};

export default ShowOrRedirectToLogin;
