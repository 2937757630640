import { PaperModal } from "@alethea-medical/alethea-components";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { FC, ReactElement, useState } from "react";
import { makeStyles } from "tss-react/mui";
const useStyles = makeStyles()(() => ({
  helpButton: {
    cursor: "pointer",
  },
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

interface HelpModalProps {
  /** Text to show in the button. If modalTitle is undefined, this will also be used as the modal header */
  buttonText?: string;
  /** Text to show in the modal, as well as the tooltip */
  helpText: string[] | ReactElement[];
  /** If provided, this will be used as the modal header instead of buttonText */
  modalTitle?: string;
}

const HelpModal: FC<HelpModalProps> = ({
  modalTitle,
  buttonText,
  helpText,
}) => {
  const { classes } = useStyles();

  const [showHelp, setShowHelp] = useState(false);

  return (
    <>
      <Tooltip
        title={
          <>
            {helpText.length === 1 ? (
              <>{helpText[0]}</>
            ) : (
              <ul>
                {helpText.map((t, i) => (
                  <li key={`help_tooltip_${t}_${i}`}>{t}</li>
                ))}
              </ul>
            )}
          </>
        }
      >
        <Grid
          className={classes.helpButton}
          container
          spacing={1}
          onClick={() => {
            setShowHelp(true);
          }}
        >
          <Grid>
            <HelpIcon color="primary" />
          </Grid>
          {buttonText !== undefined && (
            <Grid>
              <Typography color="primary">{buttonText}</Typography>
            </Grid>
          )}
        </Grid>
      </Tooltip>
      <PaperModal
        className={classes.modal}
        show={showHelp}
        setShow={setShowHelp}
        flexHeight
        flexWidth
        enablePadding
      >
        <Grid container spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Grid container spacing={1}>
              <Grid>
                <HelpIcon color="primary" />
              </Grid>
              {(modalTitle || buttonText) && (
                <Grid>
                  <Typography color="primary">
                    {modalTitle ?? buttonText}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid size={{ xs: 12 }}>
            {helpText.length === 1 ? (
              <Typography variant="subtitle1">{helpText[0]}</Typography>
            ) : (
              <ul>
                {helpText.map((t, i) => (
                  <li key={`help_modal_${t}_${i}`}>
                    <Typography variant="subtitle1">{t}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </Grid>
        </Grid>
      </PaperModal>
    </>
  );
};

export default HelpModal;
