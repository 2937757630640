import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC, useContext, useEffect } from "react";

import { defaultRoles } from "@alethea-medical/aletheamd-db-keys";
import { Theme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteNames, getPathFromRouteName } from "src/routes";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../AuthProvider";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5),
  },
  text: {
    textAlign: "center",
  },
}));

const NoPermissionsPage: FC = () => {
  const location = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (
      location.pathname.includes(
        getPathFromRouteName(RouteNames.validationRequired),
      ) &&
      authContext?.profile?.userRoles !== undefined &&
      !authContext.profile.userRoles.includes(
        defaultRoles.pracIdValidationRequired,
      )
    ) {
      navigate("/dashboard/consult");
    }
  }, [authContext.profile]);

  return (
    <Grid container className={classes.container}>
      <Grid size={{ xs: 12 }}>
        <Typography className={classes.text}>
          Unable to load this page.
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography className={classes.text}>
          This page may not be available on your device, or your account lacks
          the permissions to view this page.
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography className={classes.text}>
          Please contact{" "}
          <a href="mailto:sales@aletheamedical.com">sales@aletheamedical.com</a>{" "}
          to gain access to this feature.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoPermissionsPage;
