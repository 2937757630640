import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { FC } from "react";
import { Control, useWatch } from "react-hook-form";

interface FormSendButtonProps {
  control: Control;
  disabled: boolean;
}

const FormSendButton: FC<FormSendButtonProps> = ({ control, disabled }) => {
  const patients = useWatch({ control, name: "patients", defaultValue: [] });

  return (
    <Button
      type="submit"
      color="primary"
      startIcon={<SendIcon color="primary" />}
      disabled={disabled || patients === undefined || patients.length === 0}
    >
      Send
    </Button>
  );
};

export default FormSendButton;
