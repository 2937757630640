import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

const FormattedInputLabel = styled(InputLabel)(() => {
  return {
    fontSize: "0.875em",
    fontWeight: 700,
    whiteSpace: "initial",
  };
});

export default FormattedInputLabel;
