import { defaultRoles } from "@alethea-medical/aletheamd-db-keys";
import { multiPermissionsSatisfies } from "src/components/usePermissions";
import { RouteName, RouteNames, routes } from "./routesData";
import { Route } from "./types";

// #region Route Getters

/**
 * Gets the route object for the given route name
 * @param routeName Name of the route
 * @returns Route object
 */
export const getRouteFromRouteName = (routeName: RouteName): Route =>
  routes[routeName];

/**
 * Gets the path of the associated route for the given route name
 * @param routeName Name of the route
 * @returns The path of the associated route
 */
export const getPathFromRouteName = (routeName: RouteName): string =>
  getRouteFromRouteName(routeName).path;

/**
 * Gets the required permissions of the associated route for the given route name
 * @param routeName Name of the route
 * @returns The required permissions for the associated route
 */
export const getRequiredPermissionsFromRouteName = (
  routeName: RouteName,
): string[][] => getRouteFromRouteName(routeName).requiredResourcePermissions;

/**
 * Gets the header of the associated route for the given path
 * @param path The path of the route
 * @returns The header of the associated route for the given path
 */
export const getRouteHeaderFromPath = (path: string): string | undefined =>
  Object.values(routes).find((route) => route.path === path)?.name;

// #endregion

// #region Homepage

/** List of all routes that can be used as a homepage setting */
export const validHomepageRoutes: { [routeName: string]: Route } =
  Object.fromEntries(
    Object.entries(routes).filter(([, route]) => route.isValidHomepage),
  );

/** List of all keys for all valid homepage routes */
export const validHomepageRoutesResourceKeys: string[] = Array.from(
  new Set(
    Object.values(validHomepageRoutes)
      .map((route) => route.requiredResourcePermissions)
      .flat()
      .flat(),
  ),
);

/** Gets the default homepage setting for the user */
export const getDefaultHomepageRoute = (
  permissionDict: { [key: string]: boolean },
  userRoles?: string[],
): Route => {
  if (
    userRoles?.includes(defaultRoles.specialist) &&
    multiPermissionsSatisfies(
      permissionDict,
      routes[RouteNames.secureMessaging].requiredResourcePermissions,
    )
  ) {
    return routes[RouteNames.secureMessaging];
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes[RouteNames.consult].requiredResourcePermissions,
    )
  ) {
    return routes[RouteNames.consult];
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes[RouteNames.moaConsultInbox].requiredResourcePermissions,
    )
  ) {
    return routes[RouteNames.moaConsultInbox];
  }

  if (
    multiPermissionsSatisfies(
      permissionDict,
      routes[RouteNames.gallery].requiredResourcePermissions,
    )
  ) {
    return routes[RouteNames.gallery];
  }

  // Fallback to profile if no permissions are granted
  return routes[RouteNames.profile];
};

/** Gets the key of the default homepage setting for the user */
export const getDefaultHomepageKey = (
  permissionDict: { [key: string]: boolean },
  userRoles?: string[],
): string => {
  const defaultHomepageRouteString = JSON.stringify(
    getDefaultHomepageRoute(permissionDict, userRoles),
  );
  const [key] = Object.entries(routes).find(
    ([, route]) => JSON.stringify(route) === defaultHomepageRouteString,
  ) ?? [RouteNames.profile];
  return key;
};

/** Checks if the user has permission to use a specific homepage setting */
export const isValidHomepagePreferenceForUser = (
  permissionDict: { [key: string]: boolean },
  homepageSetting: string,
): boolean =>
  validHomepageRoutes[homepageSetting] &&
  multiPermissionsSatisfies(
    permissionDict,
    validHomepageRoutes[homepageSetting].requiredResourcePermissions,
  );

/** Gets all keys that the user has permission to use as a homepage setting */
export const getAllValidHomepagePreferencesForUser = (permissionDict: {
  [key: string]: boolean;
}): string[] =>
  Object.keys(validHomepageRoutes).filter((key) =>
    isValidHomepagePreferenceForUser(permissionDict, key),
  );

// #endregion
