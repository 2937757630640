import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";
import { Control } from "react-hook-form";
import { Province } from "shared/types/dist";
import EmailInput from "../../../components/FormInputFields/EmailInput";
import FormCheckbox from "../../../components/FormInputFields/FormCheckbox";
import PHNInputLookup from "../../../components/FormInputFields/PHNInput";
import RemovePatientButton from "./RemovePatientButton";

interface PatientRowProps {
  control: Control;
  index: number;
  setValue: (name: string, value: any, options?: any) => void;
  remove: (index?: number | number[] | undefined) => void;
  disabled: boolean;
  province?: Province;
}

const PatientRow: FC<PatientRowProps> = ({
  control,
  index,
  setValue,
  remove,
  disabled,
  province,
}) => {
  return (
    <Grid size={{ xs: 12 }}>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 10 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid size={{ xs: 12, md: 4 }}>
              <PHNInputLookup
                name={`patients.${index}.phn`}
                control={control}
                label="PHN"
                required
                handlePatientInfo={(patientInfo) => {
                  setValue(`patients.${index}.email`, patientInfo.email, {
                    shouldValidate: true,
                  });
                }}
                skipServerWarmup={index !== 0}
                disabled={disabled}
                province={province}
                autocomplete
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <EmailInput
                name={`patients.${index}.email`}
                control={control}
                label="Patient Email"
                rules={{
                  required: { value: true, message: "Email is required" },
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 4 }}>
              <Tooltip
                title="Delay sending the form until the next day at 7:00 AM MST"
                placement="right"
              >
                {/* //Wrap in fragment to stop error about refs from being thrown */}
                <div>
                  <FormCheckbox
                    name={`patients.${index}.delay`}
                    control={control}
                    label={"Delay Sending"}
                  />
                </div>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <RemovePatientButton
            index={index}
            remove={remove}
            disabled={disabled}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PatientRow;
