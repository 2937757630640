import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import {
  AIType,
  UserMediaMetadata,
  UserMediaMetadataAIPrediction,
  UserMediaMetadataAIPredictionDict,
} from "@alethea-medical/aletheamd-types";
import { fbFirestore, fbFunctions } from "../../../firebase";
import { UserMediaMetadataItem } from "./GalleryModel";

const predictImage = fbFunctions.httpsCallable("ai-predictImage");

interface PredictionBackendResponse {
  predictions: {
    aiLabel: string;
    spLabel: string;
    gpLabel: string;
    score: number;
  }[];
  aiVersion: string;
}

type AITypeToDisplayStringDict = {
  [aiType in AIType | "lvl1"]: string;
};
const aiTypeToDisplayString: AITypeToDisplayStringDict = {
  ear: "Ear",
  nasal: "Nasal",
  derm: "Dermatology",
  oral: "Throat",
  rear: "Rear Facing Camera",
  endoscope: "Endoscope",
  lvl1: "Level 1 Classifier",
};

export function getAITypeDisplayString(aiType: AIType | "lvl1") {
  return aiTypeToDisplayString[aiType];
}

export function createPredictionText(
  predictions: { gpLabel: string; score: number }[],
): string[] {
  return predictions.map((prediction) => {
    if (prediction.gpLabel !== "I can't diagnose this")
      return `${prediction.gpLabel}: ${(prediction.score * 100).toFixed(2)}%`;
    else return "";
  });
}

/**
 * Fetch predictions from backend
 * @param storageUrl URL of file on storage
 * @param predictionThreshold Ignore any labels with score below this threshold
 * @param aiType Diagnostic category to choose AI
 * @returns AI Predictions and AI Version used
 */
export function fetchPredictions(
  storageUrl: string,
  predictionThreshold: number,
  aiType: AIType | "lvl1",
): Promise<PredictionBackendResponse> {
  return predictImage({
    uri: storageUrl,
    scoreThreshold: predictionThreshold,
    aiType: aiType,
  }).then((result) => {
    return result.data as PredictionBackendResponse;
  });
}

/**
 * Format predictions into list of strings to display
 * @param predictions gpLabel and score list
 * @returns list of strings to display
 */
export function getPredictionStringList(
  predictions: { gpLabel: string; score: number }[],
): { stringList: string[]; cantDiagnose: boolean } {
  if (predictions.length > 0) {
    if (predictions[0].gpLabel === "I can't diagnose this") {
      return {
        stringList: ["I can't diagnose this"],
        cantDiagnose: true,
      };
    } else {
      return {
        stringList: createPredictionText(predictions),
        cantDiagnose: false,
      };
    }
  } else {
    return {
      stringList: ["I can't diagnose this"],
      cantDiagnose: true,
    };
  }
}

/**
 * Updates the AI predictions object for the media metadata item
 * @param uid User UID
 * @param item User media metadata item
 * @param aiType AI diagnostic type
 * @param aiData Predictions and version
 * @returns Updated aiPredictions object
 */
export function addAIPredictionToMediaMetadata(
  uid: string,
  item: UserMediaMetadataItem,
  aiType: AIType,
  aiData: UserMediaMetadataAIPrediction,
): Promise<UserMediaMetadataAIPredictionDict> {
  const aiPredictions: UserMediaMetadataAIPredictionDict =
    item.data.aiPredictions ?? {};
  aiPredictions[aiType] = aiData;

  const updateObj: Partial<UserMediaMetadata> = {
    aiPredictions: aiPredictions,
  };
  return fbFirestore
    .collection(dbNames.userMediaMetadata)
    .doc(uid)
    .collection(dbNames.userMediaMetadata_media)
    .doc(item.id)
    .update(updateObj)
    .then(() => {
      return aiPredictions;
    });
}
