import { Resources } from "@alethea-medical/aletheamd-types";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import { Menu } from "@mui/icons-material";
import { Grid2 as Grid, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import InternalStateTextField from "../../../../components/InternalStateTextField";
import palette from "../../../../palette";
import AddResourceButton from "../AddResourceButton";
import DeleteButton from "../DeleteButton";
import Resource from "./Resource";

const useStyles = makeStyles()((theme: Theme) => ({
  category: {
    backgroundColor: palette.lightGreenLight,
  },
  categoryEditing: {
    padding: theme.spacing(1),
  },
  categoryNotEditing: {
    padding: theme.spacing(1),
  },
  dragging: {
    backgroundColor: palette.lightGreen,
  },
}));

interface CategoryProps {
  index: number;
  category: Resources.Category;
  deleteCategoryHandler: (categoryId: string, index: number) => void;
  updateCategoryHandler: (newCategory: Resources.Category) => void;
  resources: Resources.ResourceDict;
  addResourceHandler: (name: string, categoryId: string) => void;
  deleteResourceHandler: (
    resourceId: string,
    index: number,
    categoryId: string,
  ) => void;
  updateResourceHandler: (newResource: Resources.Resource) => void;
  isEditing: boolean;
  resourceInsertHandler: (resource: Resources.Resource) => void;
  disableDrag?: boolean;
}

const Category: FC<CategoryProps> = ({
  index,
  category,
  deleteCategoryHandler,
  updateCategoryHandler,
  resources,
  addResourceHandler,
  deleteResourceHandler,
  updateResourceHandler,
  isEditing,
  resourceInsertHandler,
  disableDrag,
}) => {
  const { classes, cx } = useStyles();

  const [expandNewResource, setExpandNewResource] = useState(false);

  // When new resource is added, automatically expand it
  const addResource = (resourceName: string) => {
    setExpandNewResource(true);
    addResourceHandler(resourceName, category.id);
  };

  const deleteResource = (id: string, index: number) => {
    deleteResourceHandler(id, index, category.id);
  };

  const categoryNameChangeHandler = (name: string) => {
    const newCategory = { ...category };
    newCategory.name = name;
    updateCategoryHandler(newCategory);
  };

  // When resources update, reset expand new resource
  useEffect(() => {
    setExpandNewResource(false);
  }, [category.resourceIds]);

  return (
    <Draggable
      draggableId={category.id}
      index={index}
      isDragDisabled={!isEditing}
    >
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx(
            {
              [classes.categoryEditing]: isEditing,
              [classes.categoryNotEditing]: !isEditing,
              [classes.dragging]: snapshot.isDragging,
            },
            classes.category,
          )}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid {...provided.dragHandleProps}>
              {/* Only disable icon. If dragHandleProps are not found, then react-beautiful-dnd throws an error. Dragging is disabled when not editing */}
              {isEditing && !disableDrag && <Menu />}
            </Grid>
            <Grid size={{ xs: 6 }}>
              {isEditing ? (
                <InternalStateTextField
                  value={category.name}
                  setValue={categoryNameChangeHandler}
                  multiline
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rules={(value: string) =>
                    value === "" ? "Category name cannot be empty." : undefined
                  }
                />
              ) : (
                <Typography>{category.name}</Typography>
              )}
            </Grid>
            <Grid size={{ xs: "grow" }}>
              <Grid container justifyContent="flex-end">
                {isEditing && (
                  <Grid>
                    <DeleteButton
                      id={category.id}
                      index={index}
                      name={category.name}
                      deleteHandler={deleteCategoryHandler}
                      type="category"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Droppable droppableId={category.id} type="item">
                {(provided) => (
                  <Grid
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    container
                    spacing={2}
                  >
                    {category.resourceIds.map((resourceId, index) => {
                      if (resources[resourceId] === undefined) return null;

                      const id = `resource_${resourceId}`;
                      return (
                        <Grid size={{ xs: 12 }} key={id}>
                          <Resource
                            index={index}
                            resource={resources[resourceId]}
                            deleteResourceHandler={deleteResource}
                            updateResourceHandler={updateResourceHandler}
                            isEditing={isEditing}
                            resourceInsertHandler={resourceInsertHandler}
                            // Default to expanded, if last resource in list, and an resource has been added
                            defaultExpand={
                              expandNewResource &&
                              index === category.resourceIds.length - 1
                            }
                            disableDrag={disableDrag}
                          />
                        </Grid>
                      );
                    })}
                    {provided.placeholder}
                    {isEditing && (
                      <Grid size={{ xs: 12 }}>
                        <AddResourceButton addResourceHandler={addResource} />
                      </Grid>
                    )}
                  </Grid>
                )}
              </Droppable>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

export default Category;
