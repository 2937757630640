import { FC } from "react";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import AppUrlListener from "src/components/AppDeepLinks";
import Analytics from "src/config/Analytics";
import PushNotifications from "src/config/PushNotifications";
import authRoutes from "./auth/authRoutesDisplay";
import avaRoute from "./ava/avaRoutesDisplay";
import careFormsRoute from "./careForms/careFormsRoutesDisplay";
import dashboardRoute from "./dashboard/dashboardRoutesDisplay";
import { createLegacyRouteRedirects } from "./internalHelpers";
import { RouteNames, routes } from "./routesData";

/**
 * Create alias routes that forward to other pages
 *
 * If you are changing the name of a route, it is a good idea to put the old pathname as an alias for the new one in this dictionary
 * so that users visiting the page again at the previous route aren't sent to a blank page
 *
 * Key: Alias path name
 * Value: Route the alias should forward to
 */
const legacyRoutes = createLegacyRouteRedirects({
  "dashboard/econsult": RouteNames.consult,
  "auth/login": RouteNames.login,
  "auth/signup": RouteNames.signup,
});

/**
 * Provider that wraps the entire app to provide necessary context for things such as analytics
 * @returns Component that wraps the entire app with the necessary providers
 */
const RoutingWrapper: FC = () => {
  return (
    <>
      <Analytics />
      <PushNotifications />
      <AppUrlListener />
      <Outlet />
    </>
  );
};

const browserRouter = createBrowserRouter([
  {
    path: "/",
    element: <RoutingWrapper />,
    children: [
      {
        index: true,
        element: <Navigate replace to={routes[RouteNames.login].path} />,
      },
      dashboardRoute,
      avaRoute,
      careFormsRoute,
      authRoutes,
      ...legacyRoutes,
    ],
  },
]);

const Router = () => <RouterProvider router={browserRouter} />;

export default Router;
