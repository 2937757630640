import { Button, Grid2 as Grid, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../palette";

const useStyles = makeStyles()(() => ({
  SigContainer: {
    backgroundColor: "rgb(209, 209, 209)",
    borderBottom: "1px gray solid",
    borderTop: "1px gray solid",
    borderRight: "1px gray solid",
    borderLeft: "1px gray solid",
    borderRadius: "10px",
    width: "100%",
    height: "10vh",
  },
  SigPad: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    borderRadius: "10px",
  },
  clearBtn: {
    height: "20px",
    backgroundColor: palette.darkGreen,
    borderRadius: "7px",
    color: "white",
  },
}));
interface SignatureCardProps {
  savedSignature: string | undefined;
  setSavedSignature: (value: string | undefined) => void;
}

export const SignatureCard: FC<SignatureCardProps> = ({
  savedSignature,
  setSavedSignature,
}) => {
  const { classes } = useStyles();

  const signCanvas = useRef<SignaturePad>();

  const clear = () => {
    signCanvas.current?.clear();
    setSavedSignature(undefined);
  };

  const onSignatureFinish = () => {
    setSavedSignature(signCanvas.current?.toDataURL());
  };

  //Load saved signature if opened again
  useEffect(() => {
    if (savedSignature !== undefined) {
      signCanvas.current?.fromDataURL(savedSignature);
    }
  }, [signCanvas, savedSignature]);

  return (
    <Grid container spacing={1}>
      <Grid size={{ xs: 12 }}>
        <Typography>Please sign below.</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <div className={classes.SigContainer}>
          <SignaturePad
            clearOnResize={false}
            penColor="black"
            canvasProps={{ className: classes.SigPad }}
            ref={(ref) => {
              signCanvas.current = ref ?? undefined;
            }}
            onEnd={onSignatureFinish}
          />
        </div>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Button
          fullWidth
          className={classes.clearBtn}
          onClick={clear}
          id={"clear-sig-button"}
        >
          Clear
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignatureCard;
