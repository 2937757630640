import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { SignupInfo } from "@alethea-medical/aletheamd-types";
import CheckIcon from "@mui/icons-material/Check";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import { FC, useEffect, useState } from "react";
import { Control, useWatch } from "react-hook-form";
import { TypedPracIDInput } from "src/components/FormInputFields/PracticeIDInput";
import { makeStyles } from "tss-react/mui";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";
import HelpModal from "../../../../../components/HelpModal";
import RoundedButton from "../../../../../components/RoundedButton";
import { fbFunctions, logAnalyticsEvent } from "../../../../../firebase";
import useCms from "../../../../../utils/useCms";
import strings from "../../Common/strings";
import InformationBox from "../Container/InformationBox";

const useStyles = makeStyles()(() => ({
  buttonMargin: {
    marginBottom: "4px",
  },
}));

interface ValidationSettings {
  validationEnabled: boolean;
  fieldName: string;
}

interface PracIdValidationProps {
  control: Control<SignupInfo.SignupInfo>;
  isPracIdValid: boolean;
  setIsPracIdValid: (value: boolean) => void;
  proceedWithoutPracId: boolean;
  setProceedWithoutPracId: (value: boolean) => void;
  disabled: boolean;
}

const PracIdValidation: FC<PracIdValidationProps> = ({
  control,
  isPracIdValid,
  setIsPracIdValid,
  proceedWithoutPracId,
  setProceedWithoutPracId,
  disabled,
}) => {
  const { classes } = useStyles();

  const cmsSignup = useCms({ cmsId: "signup" });

  const province = useWatch({ control, name: "province", defaultValue: "" });
  const pracId = useWatch({ control, name: "practiceId", defaultValue: "" });

  // Default to no validation and Practice ID as field name if Province not found in CMS
  const [validationSettings, setValidationSettings] =
    useState<ValidationSettings>({
      validationEnabled: false,
      fieldName: "Practice ID",
    });
  const [showInvalidMessage, setShowInvalidMessage] = useState(false);
  const [
    showProceedWithoutValidationButton,
    setShowProceedWithoutValidationButton,
  ] = useState(false);

  const validatePracId = fbFunctions.httpsCallable("signup-validatePracId");

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    if (province !== "" && cmsSignup.isLoaded()) {
      const newSettings = cmsSignup.getValue<{
        [province: string]: ValidationSettings;
      }>(`validationSettings`);
      if (newSettings?.[province] !== undefined) {
        setValidationSettings(newSettings[province]);
      }
    }
  }, [province, cmsSignup]);

  useEffect(() => {
    setProcessState(ProcessState.idle);
    if (validationSettings.validationEnabled !== true) {
      setProceedWithoutPracId(true);
      setIsPracIdValid(false);
      setShowInvalidMessage(false);
      setShowProceedWithoutValidationButton(false);
    } else {
      setProceedWithoutPracId(false);
      setIsPracIdValid(false);
      setShowInvalidMessage(false);
      setShowProceedWithoutValidationButton(false);
    }
  }, [validationSettings]);

  //Reset values when changing prac ID
  useEffect(() => {
    if (validationSettings.validationEnabled) {
      setProceedWithoutPracId(false);
      setIsPracIdValid(false);
    }
  }, [pracId, validationSettings]);

  const handleValidatePracId = () => {
    setProceedWithoutPracId(false);
    setIsPracIdValid(false);
    setShowInvalidMessage(false);
    setShowProceedWithoutValidationButton(false);

    setProcessState(ProcessState.running);

    // Remove all non-numeric characters from the practice ID based on the province
    const cleanPracId =
      province === "BC"
        ? pracId.replace(/[^\dJ]/g, "")
        : pracId.replace(/\D/g, "");

    validatePracId({ practiceId: cleanPracId, province: province })
      .then((result) => {
        if (result.data.validated) {
          logAnalyticsEvent("signup_prac_id_validation_success");
          setIsPracIdValid(true);
        } else {
          logAnalyticsEvent("signup_prac_id_validation_fail");
          setIsPracIdValid(false);

          setShowInvalidMessage(true);
          setShowProceedWithoutValidationButton(true);
        }
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        setIsPracIdValid(false);
        setShowProceedWithoutValidationButton(true);

        errorHandler({
          error: error,
          userMessage: strings.systemErrorValidatingPracId(
            validationSettings.fieldName,
          ),
          hideErrorMessage: true,
          analyticsLog: `signup_prac_id_validation_fail`,
        });
      });
  };

  const handleProceedWithoutPracId = () => {
    setProcessState(ProcessState.idle);

    setProceedWithoutPracId(true);
    setIsPracIdValid(false);

    setShowInvalidMessage(false);

    logAnalyticsEvent("signup_prac_id_continue_without_validate");
  };

  const isDisabledValidating = () => {
    return processState === ProcessState.running;
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid size={{ xs: 12, md: "grow" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid size={{ xs: 11 }}>
              <FormattedInputLabel>
                {validationSettings.fieldName}
              </FormattedInputLabel>
              <TypedPracIDInput
                control={control}
                name="practiceId"
                disabled={isDisabledValidating() || disabled}
                errorTextPadding
                {...{
                  InputProps: {
                    endAdornment: (
                      <>
                        {isPracIdValid && (
                          <InputAdornment position="end">
                            <CheckIcon color="primary" />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  },
                }}
              />
            </Grid>
            <Grid size={{ xs: 1 }}>
              <HelpModal
                helpText={[strings.pracIdNote(validationSettings.fieldName)]}
              />
            </Grid>
          </Grid>
        </Grid>
        {validationSettings.validationEnabled && (
          <>
            <Grid size={{ xs: 12, md: 4 }}>
              <RoundedButton
                className={classes.buttonMargin}
                onClick={handleValidatePracId}
                fullWidth
                disabled={isDisabledValidating() || disabled}
              >
                Validate {validationSettings.fieldName}
              </RoundedButton>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <ProcessStatus
                state={processState}
                errorMessage={processErrorMessage}
              />
            </Grid>
            {showInvalidMessage && (
              <Grid size={{ xs: 12 }}>
                <Alert severity="error">
                  {strings.invalidPracIdNote(validationSettings.fieldName)}
                </Alert>
              </Grid>
            )}
            {showProceedWithoutValidationButton && (
              <>
                <Grid size={{ xs: 12, md: 8 }}>
                  <InformationBox>
                    {strings.proceedWithoutPracIdNote(
                      validationSettings.fieldName,
                    )}
                  </InformationBox>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                  <RoundedButton
                    className={classes.buttonMargin}
                    onClick={handleProceedWithoutPracId}
                    disabled={
                      isPracIdValid ||
                      proceedWithoutPracId ||
                      isDisabledValidating() ||
                      disabled
                    }
                    fullWidth
                  >
                    Proceed without {validationSettings.fieldName}
                  </RoundedButton>
                </Grid>
              </>
            )}
          </>
        )}
        {!validationSettings.validationEnabled && (
          <Grid size={{ xs: 12 }}>
            <InformationBox>
              {strings.proceedWithoutPracIdNoteNoValidation(
                validationSettings.fieldName,
              )}
            </InformationBox>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PracIdValidation;
