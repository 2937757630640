import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { FC, ReactNode, useState } from "react";

interface NotificationCollapseProps {
  title: string;
  children: ReactNode;
}

const NotificationCollapse: FC<NotificationCollapseProps> = ({
  title,
  children,
}) => {
  const [showNotifSettings, setShowNotifSettings] = useState<boolean>(false);

  return (
    <>
      <Grid size={{ xs: 12 }}>
        <Button
          onClick={() => setShowNotifSettings(!showNotifSettings)}
          endIcon={showNotifSettings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {title}
        </Button>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Collapse in={showNotifSettings}>
          <>
            {children}
            <Divider style={{ marginTop: "8px" }} />
          </>
        </Collapse>
      </Grid>
    </>
  );
};

export default NotificationCollapse;
