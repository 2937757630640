import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: "2.0em",
  },
}));
interface SectionHeaderProps {
  title: string;
}

const SectionHeader: FC<SectionHeaderProps> = ({ title }) => {
  const { classes } = useStyles();

  return <Typography className={classes.title}>{title}</Typography>;
};

export default SectionHeader;
