import Typography from "@mui/material/Typography";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  title: {
    color: "white",
    fontSize: "1.8em",
  },
}));
interface LinkPreviewerHeaderProps {
  title: string;
}

const LinkPreviewerHeader: FC<LinkPreviewerHeaderProps> = ({ title }) => {
  const { classes } = useStyles();
  return (
    <Typography variant="h6" className={classes.title}>
      {title}
    </Typography>
  );
};

export default LinkPreviewerHeader;
