import {
  type ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { aletheaMDCrypto } from "@alethea-medical/aletheamd-crypto";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import type { Activity } from "@alethea-medical/aletheamd-types";
import type firebase from "firebase/compat/app";
import {
  type Context,
  type ReactNode,
  createContext,
  useEffect,
  useState,
} from "react";
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import useQueryParamRouting from "../../../../../components/useQueryParamRouting/useQueryParamRouting";
import { fbFirestore } from "../../../../../firebase";
import type { ActivitiesListControllerFields } from "../Controllers/ActivitiesListController";

// TODO fix async/await for consult here

interface BaseContextProps<MetadataType> {
  /** True if all data is loaded. False if any data is undefined */
  loaded: boolean;
  /** True if activityId is defined, meaning an activity is being loaded or is opened */
  isActivityOpen: boolean;
  viewType: "md" | "moa";
  activityId: string;
  sharedActivity: Activity.Activity;
  metadataActivity: MetadataType;
  econsultId: string;
  econsult: Activity.Econsult;
  processState: ProcessState;
  processErrorMessage: string;
  getLoadPercentage: () => number;

  updateSharedActivity: (update: Partial<Activity.Activity>) => Promise<void>;
  updateMetadataActivity: (update: Partial<MetadataType>) => Promise<void>;
  updateEconsult: (update: Partial<Activity.Econsult>) => Promise<void>;

  sendMessage: (message: Activity.Message) => Promise<string>;
  closeActivity: () => void;
  moveFolder: (folder: Activity.UserActivityFolder) => Promise<void>;
  markAsUnread: () => Promise<void>;
}

interface UserActivityContextProps
  extends BaseContextProps<Activity.UserActivity> {
  metadataActivity: Activity.UserActivity;
}

interface ClinicActivityContextProps
  extends BaseContextProps<Activity.ClinicActivity> {
  metadataActivity: Activity.ClinicActivity;
}

/** Generic context with metadataActivity being either UserActivity or ClinicActivity type */
export const ActivityContext = createContext<
  BaseContextProps<Activity.UserActivity | Activity.ClinicActivity>
>(
  null as unknown as BaseContextProps<
    Activity.UserActivity | Activity.ClinicActivity
  >,
);

/** Context available with metadataActivity typed with UserActivity. Make sure you are calling this context in a component where the metadataActivity is guaranteed to be UserActivity type */
export const UserActivityContext =
  ActivityContext as Context<UserActivityContextProps>;

/** Context available with metadataActivity typed with ClinicActivity. Make sure you are calling this context in a component where the metadataActivity is guaranteed to be ClinicActivity type */
export const ClinicActivityContext =
  ActivityContext as Context<ClinicActivityContextProps>;

interface ActivityProviderProps<MetadataType> {
  activitiesListController?: ActivitiesListControllerFields;
  /** Update activities in list state. Only available for activity list using UserActivity */
  updateActivitiesInListState?: (
    newActivities: {
      id: string;
      sharedActivity?: Activity.Activity;
      metadataActivity?: MetadataType;
    }[],
  ) => void;
  viewType: "md" | "moa";
  metadataRefId: string;
  metadataCollection: string;
  metadataSubCollection: string;
  children?: ReactNode;
}

export function ActivityProvider<
  MetadataType extends Activity.UserActivity | Activity.ClinicActivity,
>({
  updateActivitiesInListState,
  activitiesListController,
  viewType,
  metadataRefId,
  metadataCollection,
  metadataSubCollection,
  children,
}: ActivityProviderProps<MetadataType>) {
  const location = useLocation();
  const navType = useNavigationType();
  const { addOrRemoveFromQueryParams, currentValue: activityId } =
    useQueryParamRouting({ paramName: "econsultId" });

  // Handler for when the activity back button is pressed
  const closeActivity = () => {
    addOrRemoveFromQueryParams(undefined);
  };

  // Listen to the browser back button. If user presses back, close the activity
  useEffect(() => {
    if (navType === NavigationType.Pop) {
      closeActivity();
    }
  }, [location]);

  const { processState, processErrorMessage, setProcessState, errorHandler } =
    useProcessState({});
  const [sharedActivity, setSharedActivity] = useState<Activity.Activity>();
  const [metadataActivity, setMetadataActivity] = useState<MetadataType>();
  const [econsultId, setEconsultId] = useState<string>();
  const [econsult, setEconsult] = useState<Activity.Econsult>();

  const unloadActivityHandler = () => {
    setSharedActivity(undefined);
    setMetadataActivity(undefined);
    setEconsultId(undefined);
    setEconsult(undefined);
  };

  function snapshotHandler<T>(
    snapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>,
  ): Promise<T> {
    if (!snapshot.exists)
      return Promise.reject(
        new Error(`Consult with ID ${activityId} does not exist.`),
      );
    const data = snapshot.data() as T | undefined;
    if (data === undefined)
      return Promise.reject(
        new Error(`Consult with ID ${activityId} does not exist.`),
      );

    return Promise.resolve(data);
  }

  const subscribeToSharedActivity = (activityId: string) => {
    const unsubscribe = fbFirestore
      .collection(dbNames.activities)
      .doc(activityId)
      .onSnapshot((snapshot) => {
        return snapshotHandler<Activity.Activity>(snapshot)
          .then((newSharedActivity) => {
            setSharedActivity(newSharedActivity);
            setEconsultId(newSharedActivity.econsult.id);
          })
          .catch((error: Error) => {
            closeActivity();
            errorHandler({ error });
          });
      });
    return unsubscribe;
  };

  const subscribeToMetadataActivity = (activityId: string) => {
    const unsubscribe = fbFirestore
      .collection(metadataCollection)
      .doc(metadataRefId)
      .collection(metadataSubCollection)
      .doc(activityId)
      .onSnapshot((snapshot) => {
        return snapshotHandler<MetadataType>(snapshot)
          .then((newMetadataActivity) => {
            setMetadataActivity(newMetadataActivity);
          })
          .catch((error: Error) => {
            closeActivity();
            errorHandler({ error });
          });
      });
    return unsubscribe;
  };

  const subscribeToEconsult = (econsultId: string) => {
    const unsubscribe = fbFirestore
      .collection(dbNames.econsults)
      .doc(econsultId)
      .onSnapshot((snapshot) => {
        return snapshotHandler<Activity.Econsult>(snapshot)
          .then((newEconsult) => {
            return aletheaMDCrypto
              .encryptDecryptEconsult(
                newEconsult,
                fbFirestore
                  .collection("system")
                  .doc("keystore")
                  .collection("keys")
                  .doc("firestoreData"),
                { decrypt: true },
              )
              .then(() => {
                setEconsult(newEconsult);
              });
          })
          .catch((error: Error) => {
            closeActivity();
            errorHandler({ error });
          });
      });
    return unsubscribe;
  };

  useEffect(() => {
    if (activityId === undefined) {
      unloadActivityHandler();
      return;
    }

    // Mark as read
    if (activitiesListController)
      activitiesListController.markAsReadHandler(activityId);

    const unsubscribeShared = subscribeToSharedActivity(activityId);
    const unsubscribeUser = subscribeToMetadataActivity(activityId);

    return () => {
      unsubscribeShared();
      unsubscribeUser();
    };
  }, [activityId]);

  useEffect(() => {
    if (econsultId === undefined) {
      unloadActivityHandler();
      return;
    }

    const unsubscribeEconsult = subscribeToEconsult(econsultId);
    return () => {
      unsubscribeEconsult();
    };
  }, [econsultId]);

  // Update activity list with only the changes
  useEffect(() => {
    if (updateActivitiesInListState && activityId !== undefined) {
      updateActivitiesInListState([
        {
          id: activityId,
          sharedActivity: sharedActivity,
          metadataActivity: metadataActivity,
        },
      ]);
    }
  }, [activityId, sharedActivity, metadataActivity]);

  const sendMessage = (message: Activity.Message): Promise<string> => {
    return aletheaMDCrypto
      .encryptDecryptMessages(
        [message],
        fbFirestore
          .collection(dbNames.system)
          .doc("keystore")
          .collection("keys")
          .doc("firestoreData"),
        { encrypt: true },
      )
      .then(() => {
        // Create message document
        return fbFirestore
          .collection(dbNames.activities)
          .doc(activityId)
          .collection(dbNames.activities_messages)
          .add(message);
      })
      .then((ref) => {
        // Update recentMessage in sharedActivity
        return updateSharedActivity({
          recentMessage: {
            message: message.message,
            sentAt: message.sentAt,
            sentBy: message.sentBy,
            readBy: [message.sentBy],
          },
        }).then(() => ref.id);
      });
  };

  const updateSharedActivity = (
    update: Partial<Activity.Activity>,
    batch?: firebase.firestore.WriteBatch,
  ): Promise<void> => {
    const ref = fbFirestore.collection(dbNames.activities).doc(activityId);
    if (batch !== undefined) {
      batch.update(ref, update);
      return Promise.resolve();
    }
    return ref.update(update);
  };

  const updateMetadataActivity = (
    update: Partial<Activity.UserActivity | Activity.ClinicActivity>,
    batch?: firebase.firestore.WriteBatch,
  ): Promise<void> => {
    const ref = fbFirestore
      .collection(metadataCollection)
      .doc(metadataRefId)
      .collection(metadataSubCollection)
      .doc(activityId);
    if (batch !== undefined) {
      batch.update(ref, update);
      return Promise.resolve();
    }

    return ref.update(update);
  };

  const updateEconsult = (
    update: Partial<Activity.Econsult>,
    batch?: firebase.firestore.WriteBatch,
  ): Promise<void> => {
    const ref = fbFirestore.collection(dbNames.econsults).doc(econsultId);
    if (batch !== undefined) {
      batch.update(ref, update);
      return Promise.resolve();
    }
    return ref.update(update);
  };

  const moveFolder = (folder: Activity.UserActivityFolder) => {
    if (!activitiesListController) return Promise.resolve();

    if (activityId === undefined) return Promise.resolve();

    closeActivity();

    return activitiesListController.moveFolderHandler([activityId], folder);
  };

  const markAsUnread = () => {
    if (!activitiesListController) return Promise.resolve();

    if (activityId === undefined) return Promise.resolve();

    closeActivity();
    return activitiesListController.markAsUnreadHandler([activityId]);
  };

  const getLoadPercentage = () => {
    if (sharedActivity === undefined) return 0;
    if (metadataActivity === undefined) return 50;
    if (econsult === undefined) return 95;
    return 100;
  };

  const isEmpty = <T,>(val: T | null | undefined): val is null | undefined => {
    return val == null;
  };

  return (
    <ActivityContext.Provider
      value={{
        loaded:
          !isEmpty(sharedActivity) &&
          !isEmpty(metadataActivity) &&
          !isEmpty(econsult) &&
          !isEmpty(econsultId),
        isActivityOpen: activityId !== undefined,
        viewType,
        activityId: activityId as string,
        sharedActivity: sharedActivity as Activity.Activity,
        metadataActivity: metadataActivity as MetadataType,
        econsultId: econsultId as string,
        econsult: econsult as Activity.Econsult,
        processState,
        processErrorMessage,
        getLoadPercentage,

        updateSharedActivity,
        updateMetadataActivity: updateMetadataActivity,
        updateEconsult,

        sendMessage,
        closeActivity,
        moveFolder,
        markAsUnread,
      }}
    >
      {children}
      <ProcessStatus
        state={processState}
        errorMessage={processErrorMessage}
        useSnackbar
        setState={setProcessState}
      />
    </ActivityContext.Provider>
  );
}

export default ActivityProvider;
