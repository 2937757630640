import {
  HeightLayout,
  HeightLayoutChild,
  Swipeable,
  thinScrollbar,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material/styles";
import {
  FC,
  Fragment,
  ReactChild,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import hasPermissions from "src/models/hasPermissions";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../../AuthProvider";
import usePermissions from "../../../../components/usePermissions";
import { fbFirestore, logAnalyticsEvent } from "../../../../firebase";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import DentistOutcomesModal from "../SpecialistTools/Outcomes/DentistOutcomes/DentistOutcomesModal";
import EconsultOutcomesModal from "../SpecialistTools/Outcomes/EconsultOutcomes/EconsultOutcomesModal";
import OutcomesContainer from "../SpecialistTools/Outcomes/OutcomesContainer";
import ResourcesPullout from "../SpecialistTools/SpecialistResourcesPullout";
import SpecialistTemplatesPullout from "../SpecialistTools/SpecialistTemplatesPullout";
import { InputContext } from "../Utilities/InputProvider";
import { SpecialistContext } from "../Utilities/SpecialistProvider";
import sharedStyles from "../sharedStyles";
import MessageInput from "./MessageInput";
import Messages, { MessagesElement } from "./Messages";
import MessagesHeader from "./MessagesHeader";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  ...sharedStyles(theme),
  messagesHeaderBox: {
    paddingLeft: theme.spacing(1),
  },
  messageInputBox: {
    padding: theme.spacing(1),
  },
  messageFileDropBox: {
    padding: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  fullHeight: {
    height: "100%",
  },
}));

interface MessageViewProps {
  goToEconsult: () => void;
  visible: boolean;
}

const MessageView: FC<MessageViewProps> = ({ goToEconsult, visible }) => {
  const { classes, cx } = useStyles();

  const inputContext = useContext(InputContext);
  const activityContext = useContext(ActivityContext);
  const authContext = useContext(AuthContext);
  const { templatesAvailable } = useContext(SpecialistContext);
  const [showTemplates, setShowTemplates] = useState(false);
  const [showOutcomesMenu, setShowOutcomesMenu] = useState(false);
  const [showOutcomesButton, setShowOutcomesButton] = useState(false);
  const [showResourcesMenu, setShowResourcesMenu] = useState(false);
  const [canViewResourcesTab, setCanViewResourcesTab] = useState(false);

  useEffect(() => {
    const fetchPermissions = async () => {
      setCanViewResourcesTab(
        await hasPermissions(
          resourceKeys.specialistTemplates, // To show it to specialists only
          authContext.profile,
        ),
      );
    };

    fetchPermissions();
  }, []);

  const messagesRef = useRef<MessagesElement>(null);

  const scrollToMessagesBottom = () => {
    if (showTemplates === false && visible) {
      if (messagesRef.current !== null) {
        messagesRef.current.scrollToBottom();
      }
    }
  };

  const backPressedHandler = () => {
    if (showTemplates === true) {
      setShowTemplates(false);
    } else if (showOutcomesMenu === true) {
      setShowOutcomesMenu(false);
    } else {
      goToEconsult();
    }
  };

  //Update sizes when templates slide out
  useEffect(scrollToMessagesBottom, [
    showTemplates,
    showResourcesMenu,
    templatesAvailable,
    visible,
  ]);

  //* Only one menu can be open at a time
  useEffect(() => {
    if (showTemplates === true) setShowResourcesMenu(false);
  }, [showTemplates]);

  useEffect(() => {
    if (showResourcesMenu === true) setShowTemplates(false);
  }, [showResourcesMenu]);
  //*

  //Close templates when message is sent
  useEffect(() => {
    //Turned on and off when message is sent
    if (inputContext.messageSent === true) {
      setShowTemplates(false);
    }
  }, [inputContext.messageSent]);

  const { heightMinusAppBar } = useScreenSize({});

  const { granted: hasSecureMessagingSendMessagesPermission } = usePermissions({
    resourceKey: resourceKeys.secureMessagingSendMessages,
  });

  //Use function instead of && to optionally render specialist templates, since using && causes swipeable to not ignore other screen even if templatesAvailable is false
  const renderSwipeableChildren = (): ReactChild[] => {
    const jsx: ReactChild[] = [
      <Fragment key="message_view_swipe_1">
        <Messages ref={messagesRef} />
      </Fragment>,
    ];

    if (templatesAvailable && showTemplates) {
      jsx.push(
        <Fragment key="template_view_swipe_2">
          <SpecialistTemplatesPullout />
        </Fragment>,
      );
    } else if (showResourcesMenu) {
      jsx.push(
        <Fragment key="template_view_swipe_3">
          <ResourcesPullout />
        </Fragment>,
      );
    }
    return jsx;
  };

  const swipeableSetIndex = showTemplates || showResourcesMenu ? 1 : 0;

  // TODO: Check, make sure it works then remove
  const swipeableOnChangeIndex = () => {
    // if (index === 0) {
    //   setShowTemplates(false);
    //   setShowResourcesMenu(false);
    // } else if (index === 1) {
    //   if (showTemplates) setShowResourcesMenu(false);
    //   else if (showResourcesMenu) setShowTemplates(true);
    // } else if (index === 2) {
    //   setShowTemplates(false);
    //   setShowResourcesMenu(true);
    // }
  };

  const saveMessageDraft = async () => {
    const valueToSave = inputContext.value;
    await fbFirestore
      .collection(dbNames.userActivities)
      .doc(authContext.uid)
      .collection(dbNames.userActivities_activities)
      .doc(activityContext.activityId)
      .update({ messageDraft: valueToSave });
    logAnalyticsEvent(
      `saved new message draft for user ${authContext.uid} and activity ${activityContext.activityId}`,
    );
    // Label form not dirty so I can go back without the alert
    inputContext.setFormDirty(false);
    inputContext.setSavedValue(valueToSave);
  };

  const clearMessageDraft = async () => {
    await fbFirestore
      .collection(dbNames.userActivities)
      .doc(authContext.uid)
      .collection(dbNames.userActivities_activities)
      .doc(activityContext.activityId)
      .update({ messageDraft: "" });
    logAnalyticsEvent(
      `cleared message draft for user ${authContext.uid} and activity ${activityContext.activityId}`,
    );
    inputContext.setSavedValue("");
  };

  return (
    <>
      <HeightLayout height={heightMinusAppBar}>
        <HeightLayoutChild flexDriver>
          <Box
            borderBottom={1}
            className={cx(classes.messagesHeaderBox, classes.boxBorderColor)}
          >
            <MessagesHeader
              sharedActivity={activityContext.sharedActivity}
              showTemplatesButton={templatesAvailable}
              showTemplates={showTemplates}
              setShowTemplates={setShowTemplates}
              showOutcomesButton={showOutcomesButton}
              showOutcomesMenu={showOutcomesMenu}
              setShowOutcomesMenu={setShowOutcomesMenu}
              showSaveMessageDraftButton={true}
              saveMessageDraft={saveMessageDraft}
              onGoBack={backPressedHandler}
              showResourcesButton={canViewResourcesTab}
              showResourcesMenu={showResourcesMenu}
              setShowResourcesMenu={setShowResourcesMenu}
            />
          </Box>
        </HeightLayoutChild>
        <HeightLayoutChild
          flexDriven
          allowOverflowY
          className={cx(classes.boxPadding, classes.thinScrollbar)}
        >
          <Swipeable
            index={swipeableSetIndex}
            onChangeIndex={swipeableOnChangeIndex}
            disableUsedEventBubbling
            containerClassName={classes.fullHeight}
            swipeClassName={classes.fullHeight}
          >
            {renderSwipeableChildren()}
          </Swipeable>
        </HeightLayoutChild>
        {hasSecureMessagingSendMessagesPermission && (
          <HeightLayoutChild
            flexDriver
            maxHeight="50%"
            allowOverflowY
            className={classes.thinScrollbar}
          >
            <Box
              className={cx(classes.messageInputBox, classes.boxBorderColor)}
              borderTop={1}
            >
              {/* Where you type in the message */}
              <MessageInput
                viewType="md"
                clearMessageDraft={clearMessageDraft}
              />
            </Box>
          </HeightLayoutChild>
        )}
      </HeightLayout>
      <OutcomesContainer
        resourceKey={resourceKeys.econsultOutcomes}
        activity={activityContext.sharedActivity}
        showOutcomesButton={showOutcomesButton}
        setShowOutcomesButton={setShowOutcomesButton}
      >
        <EconsultOutcomesModal
          activityId={activityContext.activityId}
          showOutcomesMenu={showOutcomesMenu}
          setShowOutcomesMenu={setShowOutcomesMenu}
        />
      </OutcomesContainer>
      <OutcomesContainer
        resourceKey={resourceKeys.dentistOutcomes}
        activity={activityContext.sharedActivity}
        showOutcomesButton={showOutcomesButton}
        setShowOutcomesButton={setShowOutcomesButton}
      >
        <DentistOutcomesModal
          showOutcomesMenu={showOutcomesMenu}
          setShowOutcomesMenu={setShowOutcomesMenu}
        />
      </OutcomesContainer>
    </>
  );
};

export default MessageView;
