import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Tooltip from "@mui/material/Tooltip";
import { CSSProperties, FC } from "react";

// Show it in format of 1-2 digits and 1 letter (eg. 1y, 2m, 3w, 4d, 5h, 18h)
function minimalTimeFormat(timeInMS: number) {
  const oneHourInMs = 1000 * 60 * 60;
  const oneDayInMs = oneHourInMs * 24;
  const oneWeekInMs = oneDayInMs * 7;
  const oneMonthInMs = oneDayInMs * 30;
  const oneYearInMs = oneDayInMs * 365;

  if (timeInMS < oneHourInMs) {
    return `<1h`;
  } else if (timeInMS < oneDayInMs) {
    return `${Math.floor(timeInMS / oneHourInMs)}hr`;
  } else if (timeInMS < oneWeekInMs) {
    return `${Math.floor(timeInMS / oneDayInMs)}d`;
  } else if (timeInMS < oneMonthInMs) {
    return `${Math.floor(timeInMS / oneWeekInMs)}wk`;
  } else if (timeInMS < oneYearInMs) {
    return `${Math.floor(timeInMS / oneMonthInMs)}mo`;
  } else {
    return `>1yr`;
  }
}

interface ResponseTimeProps {
  responseTimeMillis?: number;
  fontSize?: "small" | "inherit" | "large" | "medium";
  styleProps?: CSSProperties;
}

const ResponseTime: FC<ResponseTimeProps> = ({
  responseTimeMillis,
  fontSize,
  styleProps,
}) => {
  if (responseTimeMillis === undefined || responseTimeMillis === -1) {
    return null;
  }

  return (
    <div style={{ display: "flex", alignItems: "center", ...styleProps }}>
      <span style={{ marginRight: "2px" }}>
        Avg: {minimalTimeFormat(responseTimeMillis)}
      </span>
      <span>
        <Tooltip title="Average Response Time (Last 20)">
          <AccessTimeIcon fontSize={fontSize} />
        </Tooltip>
      </span>
    </div>
  );
};

export default ResponseTime;
