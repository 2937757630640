import { useContext, useEffect } from "react";
import { AuthContext } from "../../../AuthProvider";
import { fbFirestore } from "../../../firebase";

//updated the db to reflect the users favorites choices for preservation
export function useFavorites(favorites: string[], selectedForm: unknown) {
  const authContext = useContext(AuthContext);
  useEffect(() => {
    if (selectedForm !== undefined) {
      fbFirestore
        .collection("form_users")
        .doc(authContext.uid)
        .set({ favoritedForms: favorites }, { merge: true })
        .then(() => {
          console.log("...Succesfully Updated Favorites");
        })
        .catch((error) => {
          console.log(`error updating: ${error}`);
        });
    }
  }, [favorites]);
}
