import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../AuthProvider";
import analyticsLogs from "../../../../analyticsLogs";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { logAnalyticsEvent } from "../../../../firebase";
import { uploadFilesToAVA } from "./AVAGalleryModel";

interface AVAGalleryControllerProps {
  selectedMedia: UserMediaMetadataSelectedDict;
  unselectAll: () => void;
}

const AVAGalleryController = ({
  selectedMedia,
  unselectAll,
}: AVAGalleryControllerProps) => {
  const [enableAVAUpload, setEnableAVAUpload] = useState(false);
  const authContext = useContext(AuthContext);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const [loadingMessage, setLoadingMessage] = useState("");
  const [showSelectError, setShowSelectError] = useState(false);

  const isDisabled = () => {
    return processState === ProcessState.running;
  };

  const uploadFilesToAVAHandler = () => {
    if (Object.keys(selectedMedia).length === 0) {
      setShowSelectError(true);
      setTimeout(() => {
        setShowSelectError(false);
      }, 2000);
      return;
    }

    setProcessState(ProcessState.running);
    setLoadingMessage("Uploading files to Ava...");
    uploadFilesToAVA(Object.values(selectedMedia).map((media) => media.id))
      .then(() => {
        unselectAll();
        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1500);
        logAnalyticsEvent(analyticsLogs.ava.gallery.upload);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error",
          analyticsLog: analyticsLogs.ava.gallery.uploadFail,
        });
      });
  };

  useEffect(() => {
    if (authContext.profile?.integrations?.avaUuid !== undefined) {
      setEnableAVAUpload(true);
    }
  }, [authContext.profile?.integrations?.avaUuid]);

  return {
    enableAVAUpload,
    uploadFilesToAVAHandler,
    avaGalleryState: processState,
    avaGalleryError: processErrorMessage,
    setAVAGalleryState: setProcessState,
    avaGalleryLoading: loadingMessage,
    isDisabled: isDisabled(),
    showSelectError,
  };
};

export default AVAGalleryController;
