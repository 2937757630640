import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../AuthProvider";
import analyticsLogs from "../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../firebase";
import { UserMediaMetadataItem, deleteMedia } from "../Models/GalleryModel";

export interface UserMediaMetadataSelectedDict {
  [uid: string]: UserMediaMetadataItem;
}

interface SelectGalleryControllerProps {
  /**Function to remove items from state in GalleryController. Only provide if deletion is enabled */
  removeMediaItems?: (ids: string[]) => void;
}

const SelectGalleryController = ({
  removeMediaItems,
}: SelectGalleryControllerProps) => {
  const authContext = useContext(AuthContext);
  const [editing, setEditing] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] =
    useState<UserMediaMetadataSelectedDict>({});
  const [isDirty, setDirty] = useState(false);

  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
  } = useProcessState({ logAnalyticsEvent });

  const selectHandler = (
    item: UserMediaMetadataItem,
    shouldDirty?: boolean,
  ) => {
    const newSelectedMedia = { ...selectedMedia };
    newSelectedMedia[item.id] = item;
    setSelectedMedia(newSelectedMedia);

    if (shouldDirty !== false) setDirty(true);
  };

  const selectMultipleHandler = (
    items: UserMediaMetadataItem[],
    shouldDirty?: boolean,
  ) => {
    const newSelectedMedia = { ...selectedMedia };
    items.forEach((item) => {
      newSelectedMedia[item.id] = item;
    });
    setSelectedMedia(newSelectedMedia);

    if (shouldDirty !== false) setDirty(true);
  };

  const unselectHandler = (id: string, shouldDirty?: boolean) => {
    const newSelectedMedia = { ...selectedMedia };
    if (newSelectedMedia[id] !== undefined) {
      delete newSelectedMedia[id];
      setSelectedMedia(newSelectedMedia);
    }

    if (shouldDirty !== false) setDirty(true);
  };

  const unselectMultipleHandler = (ids: string[], shouldDirty?: boolean) => {
    const newSelectedMedia = { ...selectedMedia };
    ids.forEach((id) => {
      if (newSelectedMedia[id] !== undefined) {
        delete newSelectedMedia[id];
      }
    });
    setSelectedMedia(newSelectedMedia);

    if (shouldDirty !== false) setDirty(true);
  };

  const unselectAllHandler = (shouldDirty?: boolean) => {
    setSelectedMedia({});

    if (shouldDirty !== false) setDirty(true);
  };

  // Delete media in firestore and storage (including thumbnail)
  const deleteSelectedMediaHandler = () => {
    if (removeMediaItems !== undefined) {
      setProcessState(ProcessState.running);
      deleteMedia(authContext.uid, Object.values(selectedMedia)).then(
        (results) => {
          if (results.fail.length > 0) {
            setProcessErrorMessage(
              `Failed to delete ${results.fail.length} files. Please try again. If this error continues to occur, please contact support.`,
            );
            setProcessState(ProcessState.error);
            logAnalyticsEvent(analyticsLogs.gallery.deleteFail);
          } else {
            setProcessState(ProcessState.success);
            logAnalyticsEvent(analyticsLogs.gallery.delete);
          }

          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 2000);

          // Remove deleted media from list in state
          removeMediaItems(results.success);
          // Deselect deleted media
          unselectMultipleHandler(results.success);
        },
      );
    }
  };

  // Unselect all media when editing is cancelled
  useEffect(() => {
    if (!editing) unselectAllHandler(false);
  }, [editing]);

  return {
    // data
    selectedMedia,
    // handlers
    selectHandler,
    selectMultipleHandler,
    unselectHandler,
    unselectAllHandler,
    unselectMultipleHandler,
    deleteSelectedMediaHandler,
    // states
    editing,
    setEditing,
    selectState: processState,
    selectError: processErrorMessage,
    isDirty,
    setDirty,
  };
};

export default SelectGalleryController;
