import { useMobileSizes } from "@alethea-medical/alethea-components";
import {
  ChevronLeft,
  ChevronRight,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { Button, Grid2 as Grid, IconButton } from "@mui/material";
import { FC } from "react";

interface ViewSecureMessageNavigationProps {
  showToolbar: boolean;
  setShowToolbar: (show: boolean) => void;
  goBack: () => void;
  goToMessages: () => void;
}

const ViewSecureMessageNavigation: FC<ViewSecureMessageNavigationProps> = ({
  showToolbar,
  setShowToolbar,
  goBack,
  goToMessages,
}) => {
  const isMobileSize = useMobileSizes();

  const handleCollapseToolbar = () => {
    setShowToolbar(!showToolbar);
  };
  return isMobileSize ? (
    <Grid size={{ xs: 12 }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid>
          <Button onClick={goBack} startIcon={<ChevronLeft />}>
            Back to Inbox
          </Button>
        </Grid>
        <Grid>
          <IconButton onClick={handleCollapseToolbar}>
            {showToolbar ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        <Grid>
          <Button onClick={goToMessages} endIcon={<ChevronRight />}>
            Messages
          </Button>
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid>
      <Button onClick={goBack} startIcon={<ChevronLeft />}>
        Back to Inbox
      </Button>
    </Grid>
  );
};

export default ViewSecureMessageNavigation;
