import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { MouseEventHandler, useState } from "react";
import analyticsLogs from "src/analyticsLogs";
import { logAnalyticsEvent } from "src/firebase";
import { makeStyles } from "tss-react/mui";
import { Service } from "../../../../../shared/types";
import {
  getFormattedClinicAddressFromService,
  getFormattedUserNameWithCredsFromServiceUser,
  getLatestCurrentVacationEndDate,
  isServiceOnVacation,
} from "../../../../db/Service";
import palette from "../../../../palette";
import ResponseTime from "../SpecialtyDropdown/ResponseTime";
import AcceptanceFlags from "./AcceptanceFlags/AcceptanceFlags";

const useStyles = makeStyles()((theme: Theme) => ({
  cardBorder: {
    borderColor: palette.darkGreen,
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    padding: theme.spacing(1),
  },
  cardVacation: {
    backgroundColor: "#eee",
  },
  serviceTitle: {
    fontSize: "1.1rem",
    fontWeight: "bold",
    color: palette.darkGreen,
  },
  vacationText: {
    textAlign: "right",
    color: palette.orange,
    fontWeight: "bold",
  },
  actionContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-between",
    },
  },
  acceptanceFlags: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
}));

interface ServiceCardProps {
  serviceId: string;
  service: Service.Service;
  showStartConsultButton?: boolean;
  selectService?: (serviceId: string) => void;
}

const ServiceCard = ({
  serviceId,
  service,
  showStartConsultButton,
  selectService,
}: ServiceCardProps) => {
  const { classes, cx } = useStyles();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleStartConsult: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    logAnalyticsEvent(analyticsLogs.services.selectSearchBarService);

    // Select the service
    selectService?.(serviceId);
  };

  const isOnVacation = isServiceOnVacation(service);
  const vacationEndDate = getLatestCurrentVacationEndDate(service);

  return (
    <div
      className={cx(
        {
          [classes.cardVacation]: isOnVacation,
        },
        classes.cardBorder,
      )}
      onClick={() => toggleExpanded()}
    >
      <Grid container alignItems="center">
        <Grid container size={6}>
          <Grid>{expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</Grid>
          <Grid>
            <div className={classes.serviceTitle}>{service.serviceName}</div>
          </Grid>
          <Grid size={12}>
            <Typography variant="subtitle1">
              {getFormattedClinicAddressFromService(service)}
            </Typography>
          </Grid>
        </Grid>
        <Grid size={{ xs: 6 }}>
          <Grid
            container
            justifyContent="flex-end"
            spacing={2}
            className={classes.actionContainer}
          >
            <div className={classes.acceptanceFlags}>
              <AcceptanceFlags
                acceptanceFlags={service.acceptanceFlags}
                size="2.5em"
                mobileQuestionMarkFraction={0.3}
              />
            </div>
            <Grid>
              <ResponseTime responseTimeMillis={service.econsultResponseTime} />
            </Grid>
            {showStartConsultButton && (
              <Grid>
                <Tooltip title="Start Consult">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleStartConsult}
                  >
                    <ChevronRightIcon fontSize="small" />
                  </Button>
                </Tooltip>
              </Grid>
            )}
            {isOnVacation && (
              <Grid size={12}>
                <Typography className={classes.vacationText}>
                  On Vacation{" "}
                  {vacationEndDate &&
                    " - Return date: " +
                      vacationEndDate?.toDate()?.toDateString()}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid size={12}>
          <Collapse in={expanded}>
            <Grid container>
              <Grid size={12}>
                <ul>
                  {service.users.map((user) => (
                    <li key={user.uid}>
                      <Typography variant="subtitle1">
                        {getFormattedUserNameWithCredsFromServiceUser(user)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Grid>
              <Grid size={12}>
                <Typography variant="subtitle1">
                  {service.shortDescription}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

export default ServiceCard;
