import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { ReactElement, useState } from "react";
import { FileRejection, FileWithPath, useDropzone } from "react-dropzone";
import isNativeMobile from "src/models/isNativeMobile";
import { makeStyles } from "tss-react/mui";
import { FileType } from "../../../shared/types";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";

const useStyles = makeStyles()((theme: Theme) => ({
  iconButton: {
    padding: theme.spacing(0.5),
  },
  dragOverlay: {
    padding: theme.spacing(1),
    margin: 0,
    top: 0,
    left: 0,
    overflow: "hidden",
    position: "absolute",
    width: "100%",
    height: "100%",
    borderWidth: theme.spacing(0.2),
    borderStyle: "dashed",
    borderRadius: theme.spacing(1),
    zIndex: 200,
  },
  dragOverlayActive: {
    backgroundColor: "rgba(134, 208, 200, 0.2)",
    borderColor: theme.palette.secondary.main,
  },
  dragOverlayText: {
    height: "100%",
  },
  fileThumb: {
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block",
    margin: "auto",
  },
  uploadIcon: {
    paddingBottom: theme.spacing(0.5),
  },
  dragOverlayWrapper: {
    position: "relative",
    minHeight: "200px",
  },
  uploadFromClipboardButton: {
    margin: theme.spacing(1, 0),
  },
}));

export interface FileWithPreview {
  /** Original file. Do not use file.name, instead use filename on this interface */
  file: FileWithPath;
  draftStoragePath?: string;
  /** File name that we can edit. file.file.name is READONLY, so we cannot change it. When accessing filename use this value */
  filename: string;
  src: string;
  fileType: FileType;
}

export interface FileDict {
  [key: string]: FileWithPreview;
}

interface FileDropProps {
  disabled?: boolean;
  acceptedFileTypes?: { [values: string]: string[] };
  onDropRejected?: ((fileRejections: FileRejection[]) => void) | undefined;
  maxFiles?: number; // Optional field to limit how many files can be uploaded
}

export interface FileDropReturnTypes {
  files: FileDict;
  handleUpload: (acceptedFiles: FileWithPath[], shouldDirty?: boolean) => void;
  handleUploadFilesWithPreview: (
    acceptedFiles: FileWithPreview[],
    shouldDirty?: boolean,
  ) => void;
  getRootProps: () => any;
  getInputProps: () => any;
  isDragActive: boolean;
  removeFile: (key: string) => void;
  removeFiles: (keys: string[]) => void;
  resetFiles: () => void;
  createUploadFromClipboardButton: () => ReactElement | null;
  createFileList: () => ReactElement;
  createFileThumbs: () => ReactElement;
  createDropzone: (text?: string, dropZoneMinHeight?: string) => ReactElement;
  openFileDialog: () => void;
  isDirty: boolean;
  setDirty: (dirty: boolean) => void;
}

export const getFileType = (filename: string): FileType => {
  if (/.*\.jpg|.*\.png|.*.jpeg/i.test(filename)) {
    return "image";
  } else if (/.*\.mp4/i.test(filename)) {
    return "video";
  } else {
    return "other";
  }
};

class KeyGen {
  currentKey = 0;

  GetKey(): string {
    const key = this.currentKey.toString();
    this.currentKey += 1;
    return key;
  }
}

/** Adds a number at the end of the filename to make it unique
 * Will continue to increase the number until the filename is unique
 */
function renameUnique(
  existingFilenames: string[],
  filename: string,
  index = 0,
): string {
  const indexOfDot = filename.lastIndexOf(".");
  const filenameNoExtension =
    indexOfDot !== -1 ? filename.substring(0, indexOfDot) : filename;
  const extension = indexOfDot !== -1 ? filename.substring(indexOfDot) : "";
  const newFilename = `${filenameNoExtension}${
    index > 0 ? `_${index}` : ""
  }${extension}`;
  if (existingFilenames.includes(newFilename)) {
    index = index + 1;
    return renameUnique(existingFilenames, filename, index);
  }
  return newFilename;
}

//See https://github.com/okonet/attr-accept for more information on accepted file types
function useFileDrop({
  disabled,
  acceptedFileTypes,
  onDropRejected,
  maxFiles,
}: FileDropProps): FileDropReturnTypes {
  const { classes } = useStyles();

  const [files, setFiles] = useState<FileDict>({});
  const [isDirty, setDirty] = useState(false);

  //Used to generate keys for the file dictionary so we don't have to worry about conflicting keys
  const [keyGen] = useState<KeyGen>(new KeyGen());

  const onDrop = (acceptedFiles: FileWithPath[]): void => {
    // If maxFiles is defined and the number of existing + new files exceeds it, handle the rejection
    if (
      maxFiles !== undefined &&
      Object.keys(files).length + acceptedFiles.length > maxFiles
    ) {
      if (onDropRejected) {
        const excessFiles = acceptedFiles.slice(
          maxFiles - Object.keys(files).length,
        );
        const fileRejections: FileRejection[] = excessFiles.map((file) => ({
          file: file as File, // Cast to File to ensure compatibility
          errors: [
            {
              code: "too-many-files",
              message: `Can't upload more than ${maxFiles} file${maxFiles > 1 ? "s" : ""}`,
            },
          ],
        }));
        onDropRejected(fileRejections);
      }

      // Limit the acceptedFiles to the number that can still be accepted
      acceptedFiles = acceptedFiles.slice(
        0,
        maxFiles - Object.keys(files).length,
      );
    }

    // Proceed with handling the accepted files
    handleUpload(acceptedFiles);
  };

  const handleUploadFilesWithPreview = (
    acceptedFiles: FileWithPreview[],
    shouldDirty?: boolean,
  ): void => {
    const newFiles: FileDict = { ...files };

    const existingFilenames = Object.values(newFiles).map(
      (existing) => existing.filename,
    );

    acceptedFiles.forEach((file: FileWithPreview) => {
      file.filename = renameUnique(existingFilenames, file.filename);
      // Add new filename to existing filenames in case two files with the same name were uploaded at the same time
      existingFilenames.push(file.filename);
      newFiles[keyGen.GetKey()] = file;
    });

    setFiles({ ...newFiles });

    if (shouldDirty !== false) setDirty(true);
  };

  const handleUpload = (
    acceptedFiles: FileWithPath[],
    shouldDirty?: boolean,
  ): void => {
    const newFilesList: FileWithPreview[] = acceptedFiles.map(
      (file: FileWithPath) => {
        const fileWithPreview: FileWithPreview = {
          file: file,
          filename: file.name,
          src: URL.createObjectURL(file),
          fileType: getFileType(file.name),
        };

        return fileWithPreview;
      },
    );

    handleUploadFilesWithPreview(newFilesList, shouldDirty);
  };

  //Called when files are dropped into the dropzone
  // TODO https://react-dropzone.org/#!/Accepting%20specific%20file%20types
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    disabled: disabled,
    accept: acceptedFileTypes,
    noClick: true,
    onDrop: onDrop,
    onDropRejected: onDropRejected,
  });

  const removeFile = (key: string, shouldDirty?: boolean) => {
    const newFiles: FileDict = { ...files };
    URL.revokeObjectURL(newFiles[key].src);
    delete newFiles[key];
    setFiles(newFiles);

    if (shouldDirty !== false) setDirty(true);
  };

  const removeFiles = (keys: string[]) => {
    const newFiles: FileDict = { ...files };
    keys.forEach((key) => {
      URL.revokeObjectURL(newFiles[key].src);
      delete newFiles[key];
    });
    setFiles(newFiles);
  };

  const resetFiles = () => {
    Object.values(files).forEach((file) => URL.revokeObjectURL(file.src));
    setFiles({});
  };

  const createDropzone = (text?: string, dropZoneMinHeight?: string) => {
    return (
      <div {...getRootProps()} onClick={openFileDialog}>
        <input {...getInputProps()} />
        <div
          className={`${classes.dragOverlayWrapper}`}
          style={{
            minHeight: dropZoneMinHeight,
          }}
        >
          <div
            className={`${classes.dragOverlay} ${
              isDragActive ? classes.dragOverlayActive : ""
            }`}
          >
            <Grid
              container
              className={classes.dragOverlayText}
              alignItems="center"
              justifyContent="center"
            >
              <Grid>
                <Typography variant="subtitle1">
                  {text}{" "}
                  <InsertDriveFileIcon
                    className={classes.uploadIcon}
                    color="primary"
                  />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    );
  };

  // handlePaste is a function that will be called when the user presses a button to paste an image from their clipboard
  // Only pastes the latest image from the clipboard
  const handlePaste = async () => {
    try {
      const clipboardItems = await navigator.clipboard.read();
      if (clipboardItems.length > 0) {
        const latestClipboardItem = clipboardItems[0];
        for (const type of latestClipboardItem.types) {
          if (type.startsWith("image/")) {
            const blob = await latestClipboardItem.getType(type);
            const extension = type.split("/")[1];
            const filename = `pasted-image.${extension}`;
            const file = new File([blob], filename, {
              type,
              lastModified: new Date().getTime(),
            });
            const fileType: FileType = "image";
            const image: FileWithPreview = {
              file: file,
              filename: filename,
              src: URL.createObjectURL(file),
              fileType: fileType,
            };
            handleUploadFilesWithPreview([image], true);
            return;
          }
        }
      }
    } catch (error) {
      console.error("Error accessing clipboard:", error);
    }
  };

  const createUploadFromClipboardButton = () => {
    if (isNativeMobile()) {
      return null;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        className={classes.uploadFromClipboardButton}
        onClick={handlePaste}
      >
        Upload Image from Clipboard
      </Button>
    );
  };

  const createFileList = () => {
    return (
      <Grid container>
        {Object.keys(files).map((key: string) => {
          const file = files[key];
          if (file.fileType !== "other") {
            return null;
          } else {
            return (
              <Grid size={{ xs: 12 }} key={`file_drop_${key}`}>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    removeFile(key);
                  }}
                  size="large"
                >
                  <HighlightOffIcon color="primary" />
                </IconButton>
                <a href={file.src} target="_blank" rel="noopener noreferrer">
                  {file.filename}
                </a>
              </Grid>
            );
          }
        })}
      </Grid>
    );
  };

  const createFileThumbs = () => (
    <ThumbnailGrid files={files} handleRemove={removeFile} />
  );

  return {
    files,
    handleUpload,
    handleUploadFilesWithPreview,
    getRootProps,
    getInputProps,
    isDragActive,
    resetFiles,
    removeFile,
    removeFiles,
    createUploadFromClipboardButton,
    createFileList,
    createFileThumbs,
    createDropzone,
    openFileDialog,
    isDirty,
    setDirty,
  };
}

export default useFileDrop;
