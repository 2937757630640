import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { useProcessState } from "@alethea-medical/alethea-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fbFunctions, logAnalyticsEvent } from "../'../../../../firebase";
import ColumnViewContainer from "../../../components/ColumnViewContainer";

import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
}));

const LinkAccount: FC = () => {
  const location = useLocation();
  const linkAccount = fbFunctions.httpsCallable("ava-linkAccount");
  const { classes } = useStyles();
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    const params = queryString.parse(location.search);
    setProcessState(ProcessState.running);
    if (params.jwt) {
      linkAccount({ jwt: params.jwt })
        .then(() => {
          setProcessState(ProcessState.success);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage:
              "Error linking your AVA account to your Alethea account",
          });
        });
    } else {
      errorHandler({
        userMessage:
          "Invalid information provided to link your AVA account to your Alethea account. Please try again.",
      });
    }
  }, []);

  return (
    <ColumnViewContainer>
      <Grid container justifyContent="center" spacing={1} alignItems="center">
        <Grid size={{ xs: 2 }}>
          <img src="/Ava_logo.png" alt="Ava Logo" width="100%" />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <Grid container justifyContent="center" alignItems="center">
            <ArrowForwardIcon color="primary" />
          </Grid>
        </Grid>
        <Grid size={{ xs: 2 }}>
          <img src="/logo_big.png" alt="Alethea Logo" width="100%" />
        </Grid>
      </Grid>

      <Grid container justifyContent="center" spacing={4} alignItems="center">
        <Grid size={{ xs: 12 }}>
          <Typography component="h1" variant="h5" className={classes.header}>
            Account Link
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <ProcessStatus
            state={processState}
            setState={setProcessState}
            errorMessage={processErrorMessage}
            successMessage="Your AVA and Alethea accounts have been successfully linked. You may now close this page."
            loadingMessage="Linking your AVA account..."
          />
        </Grid>
      </Grid>
    </ColumnViewContainer>
  );
};

export default LinkAccount;
