import { Plan, SignupInfo } from "@alethea-medical/aletheamd-types";
import { FC } from "react";
import { Control } from "react-hook-form";
import { TypedFormProvinceSelect } from "../../../../../components/FormInputFields/FormProvinceSelect";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";
import useCms from "../../../../../utils/useCms";

interface SignupProvinceSelectProps {
  control: Control<SignupInfo.SignupInfo>;
  selectedPlan: Plan.Plan;
}

const SignupProvinceSelect: FC<SignupProvinceSelectProps> = ({
  control,
  selectedPlan,
}) => {
  const content = useCms({ cmsId: "signup" });

  return (
    <>
      <FormattedInputLabel>Select your Province</FormattedInputLabel>
      <TypedFormProvinceSelect
        sx={{ width: "4.5em" }} //width to fit 2 letter province codes
        control={control}
        name={"province"}
        defaultValue=""
        enabledOptions={content.getArray<string>(Plan.getCMSKey(selectedPlan))}
        sortByEnabled
        {...{ fullWidth: false }}
      />
    </>
  );
};
export default SignupProvinceSelect;
