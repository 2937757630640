import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import { FC } from "react";

interface NotificationCenterToolbarProps {
  markAllAsReadHandler: () => void;
  refresh: () => void;
}

const NotificationCenterToolbar: FC<NotificationCenterToolbarProps> = ({
  markAllAsReadHandler,
  refresh,
}) => {
  return (
    <ListItem divider>
      <Grid container spacing={2} alignItems="center">
        <Grid>
          <IconButton onClick={refresh} size="large">
            <RefreshIcon />
          </IconButton>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            onClick={markAllAsReadHandler}
          >
            Mark all as read
          </Button>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default NotificationCenterToolbar;
