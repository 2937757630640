import { AIExcludeSubsite } from "@alethea-medical/aletheamd-types";

function isOfTypeAIExcludeSubsite(
  subsite: string,
): subsite is AIExcludeSubsite {
  const types: AIExcludeSubsite[] = ["Thyroid", "Neck Mass"];
  return types.includes(subsite as AIExcludeSubsite);
}

export default isOfTypeAIExcludeSubsite;
