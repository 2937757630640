import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";
import useConfirmDestructiveModal from "../../../../components/useConfirmDestructiveModal";

interface DraftsToolbarProps {
  atLeastOneSelected: boolean;
  moreThanOneSelected: boolean;
  discard: () => void;
}

const DraftsToolbar: FC<DraftsToolbarProps> = ({
  atLeastOneSelected,
  moreThanOneSelected,
  discard,
}) => {
  const { showConfirmDestructiveModal, ConfirmDestructiveModal } =
    useConfirmDestructiveModal({
      action: discard,
      confirmText: `Discard Draft${moreThanOneSelected ? "s" : ""}?`,
      confirmButtonText: "Discard",
    });
  return (
    <>
      {ConfirmDestructiveModal}
      <Collapse in={atLeastOneSelected} unmountOnExit>
        <ButtonGroup>
          <Tooltip title="Discard">
            <Button
              color="primary"
              variant="outlined"
              onClick={showConfirmDestructiveModal}
              startIcon={<DeleteIcon />}
            >
              Discard
            </Button>
          </Tooltip>
        </ButtonGroup>
      </Collapse>
    </>
  );
};

export default DraftsToolbar;
