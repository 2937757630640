import Autocomplete from "@mui/material/Autocomplete";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../shared/sharedStyles";
import { ControlledInputProps } from "../types";

const useStyles = makeStyles()(() => {
  return {
    ...sharedStyles(),
  };
});

interface FormSelectAutocompleteProps extends ControlledInputProps {
  options: any[];
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  disableWhenOne?: boolean; //Disable the field when only one option is available
  rules?: any;
  disabled?: boolean;
  isLoading?: boolean;
  freeSolo?: boolean;
  autoSelect?: boolean;
}

const FormSelectAutocomplete = ({
  name,
  control,
  options,
  getOptionLabel,
  getOptionValue,
  disableWhenOne,
  defaultValue,
  label,
  rules,
  disabled,
  isLoading,
  errorTextPadding,
  freeSolo,
  autoSelect,
  ...rest
}: FormSelectAutocompleteProps) => {
  const { classes } = useStyles();

  const tryGetOptionValue = (option: any) => {
    if (option !== undefined)
      return getOptionValue ? getOptionValue(option) : option;
    else return "";
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Autocomplete
          {...field}
          {...rest}
          autoSelect={autoSelect}
          freeSolo={freeSolo}
          options={options}
          getOptionLabel={getOptionLabel}
          groupBy={(option) => option.favoritedStatus}
          defaultValue={defaultValue ?? ""}
          disableClearable
          disabled={isLoading || disabled}
          value={field.value || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              className={classes.canDisable}
              disabled={(disableWhenOne && options.length <= 1) || disabled}
              error={fieldState.error !== undefined}
              helperText={
                errorTextPadding
                  ? fieldState.error?.message
                    ? fieldState.error.message
                    : " "
                  : fieldState.error?.message
              }
              margin="dense"
              fullWidth
              variant="outlined"
            >
              {options.map((option: any, index: number) => {
                return (
                  <MenuItem
                    key={`${option}_${index}`}
                    value={tryGetOptionValue(option)}
                  >
                    {getOptionLabel ? getOptionLabel(option) : option}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
};

export default FormSelectAutocomplete;
