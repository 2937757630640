import DeleteIcon from "@mui/icons-material/Delete";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import IconButton from "@mui/material/IconButton";
import { type Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import useConfirmDestructiveModal from "../../../components/useConfirmDestructiveModal";
import sharedStyles from "../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  iconButton: {
    padding: theme.spacing(1),
  },
}));

interface DeleteButtonProps {
  id: string;
  index: number;
  name: string;
  type: "category" | "item";
  deleteHandler: (id: string, index: number) => void;
}

const DeleteButton: FC<DeleteButtonProps> = ({
  id,
  index,
  name,
  type,
  deleteHandler,
}) => {
  const { classes } = useStyles();

  const { ConfirmDestructiveModal, showConfirmDestructiveModal } =
    useConfirmDestructiveModal({
      confirmText: `Delete ${type === "category" ? "category" : "template"} "${name}"${type === "category" ? " and all templates within it" : ""}?`,
      confirmButtonText: "Delete",
      action: () => {
        deleteHandler(id, index);
      },
    });

  return (
    <>
      <IconButton
        className={classes.iconButton}
        onClick={showConfirmDestructiveModal}
        size="large"
      >
        {type === "category" ? <DeleteSweepIcon /> : <DeleteIcon />}
      </IconButton>
      {ConfirmDestructiveModal}
    </>
  );
};

export default DeleteButton;
