import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip from "@mui/material/Tooltip";
import { Control, useWatch } from "react-hook-form";

interface DelayAllCheckboxProps {
  control: Control;
  setValue: (name: string, value: any) => void;
}

export const DelayAllCheckbox = ({
  control,
  setValue,
}: DelayAllCheckboxProps) => {
  const patients = useWatch({ control, name: "patients", defaultValue: [] });

  const checkDelayAll = (): boolean => {
    return (
      patients !== undefined &&
      patients.length > 0 &&
      patients.every((patient: any) => {
        return patient.delay;
      })
    );
  };

  return (
    <Tooltip
      title="Delay sending the email until the next day at 7:00 AM MST"
      placement="right"
    >
      <FormControlLabel
        label="Delay All"
        checked={checkDelayAll()}
        onChange={(event: any) => {
          patients.forEach((_: any, index: number) => {
            setValue(`patients.${index}.delay`, event.target.checked);
          });
        }}
        control={<Checkbox color="primary" disableRipple />}
      />
    </Tooltip>
  );
};

export default DelayAllCheckbox;
