import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { FC, useContext, useEffect, useState } from "react";
import { Activity, AuditLog } from "../../../../../shared/types";
import { fbFirestore } from "../../../../firebase";
import { formatTimeLong } from "../../../../models/formatTime";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import { defaultConsultStatuses } from "../Toolbar/ConsultStatus";

const ConsultAuditLog: FC = () => {
  const { activityId } = useContext(ActivityContext);
  const [auditLogs, setAuditLogs] = useState<AuditLog<Activity.Activity>[]>([]);

  const subscribeToAuditLogs = () => {
    //Subscribe to new messages
    const unsubscribeAuditLog = fbFirestore
      .collection(dbNames.activities)
      .doc(activityId)
      .collection(dbNames.auditLogs)
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        setAuditLogs(
          snapshot.docs.map((doc) => {
            return doc.data() as AuditLog<Activity.Activity>;
          }),
        );
      });
    return () => {
      unsubscribeAuditLog();
    };
  };

  useEffect(() => {
    return subscribeToAuditLogs();
  }, [activityId]);

  return (
    <Grid container spacing={1}>
      {auditLogs.length === 0 && <Grid size={{ xs: 12 }}>No audit logs</Grid>}
      {auditLogs.map((auditLog, i) => {
        const before = auditLog.before?.status ?? "no_status";
        const after = auditLog.after?.status ?? "no_status";
        const username = `${auditLog?.userInfo?.firstName ?? auditLog?.authUid ?? "ADMIN"}${auditLog?.userInfo?.lastName ? ` ${auditLog?.userInfo?.lastName}` : ""}`;

        return (
          <Grid key={`activity_audit_log_${i}`} size={{ xs: 12 }}>
            <Grid container spacing={1}>
              <Grid size={{ xs: 12 }}>
                {formatTimeLong(auditLog.timestamp.toDate())}
              </Grid>
              <Grid size={{ xs: 12 }}>{username} changed status from</Grid>
              <Grid size={{ xs: 12 }}>
                <b>
                  {defaultConsultStatuses.statuses[before]?.label ?? before}
                </b>{" "}
                to{" "}
                <b>{defaultConsultStatuses.statuses[after]?.label ?? after}</b>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ConsultAuditLog;
