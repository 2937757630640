import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { PermissionAppRoute } from "src/types/AppRoute";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../AuthProvider";
import { fbFirestore, logAnalyticsEvent } from "../../firebase";
import isAndroid from "../../models/isAndroid";
import isIOS from "../../models/isIOS";
import isWeb from "../../models/isWeb";
import { useRoutePermissions } from "../PermissionRoute";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(6), //Make room for button
    backgroundColor: theme.palette.primary.light,
    color: "white",
    position: "relative",
  },
  closeButton: {
    right: theme.spacing(1),
    bottom: theme.spacing(1),
    position: "absolute",
    color: "white",
  },
  actionButton: {
    color: "white",
  },
  header: {
    fontSize: "1.5em",
  },
  title: {
    "& a": {
      //Change color of hyperlinks to make more visible on dark background
      color: "#6bf2e2",
    },
  },
  subtitle: {
    color: theme.palette.grey[700],
  },
  // Popper and arrow taken from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  arrow: {
    zIndex: 3000,
    overflow: "hidden",
    position: "absolute",
    width: "1em",
    height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: "border-box",
    color: theme.palette.primary.light,
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: "100%",
      height: "100%",
      boxShadow: theme.shadows[1],
      backgroundColor: "currentColor",
      transform: "rotate(45deg)",
    },
  },
}));

interface OneTimeTooltipProps {
  /** Name to store in database as */
  tooltipName: string;
  /** Main header */
  header?: string;
  /** Smaller title text */
  title: string | React.ReactChild;
  /** Even smaller subtitle text */
  subtitle?: string[] | string | React.ReactChild | React.ReactChild[];
  placement?: PopperPlacementType;
  /** Optionally specify what platform this tooltip should show on. Default all */
  platform?: "all" | "web" | "ios" | "android";
  /** Optionally require a permission to view the tooltip */
  routePermission?: PermissionAppRoute;
  /** If not visible, then hide tooltip. Useful if tooltip is off-screen but still mounted. Default true */
  visible?: boolean;
  action?: () => void;
  actionText?: string;
  /** If provided, the popper will anchor to this element only if this element is currently on screen. Otherwise it will anchor to the child element of this component. */
  onScreenAnchor?: HTMLElement | null;
  /** If true, the arrow on the popper will not be shown */
  hideArrow?: boolean;
  children: React.ReactElement;
}

const OneTimeTooltip = ({
  tooltipName,
  header,
  title,
  subtitle,
  placement = "bottom",
  platform = "all",
  routePermission,
  visible = true,
  actionText,
  action,
  onScreenAnchor,
  hideArrow,
  children,
}: OneTimeTooltipProps) => {
  const { classes } = useStyles();
  const authContext = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [useOnScreenAnchor, setUseOnScreenAnchor] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [openTime] = useState<Date>(new Date());

  const { permissionGranted } = useRoutePermissions(routePermission);

  useEffect(() => {
    if (authContext.uid !== "") {
      fbFirestore
        .collection(dbNames.oneTimeTooltips)
        .doc(authContext.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data !== undefined) {
              //Object exists
              if (data[tooltipName] === true) {
                setShowTooltip(false);
              } else {
                setShowTooltip(true);
              }
              return;
            }
          }

          //Initialize object for user
          return fbFirestore
            .collection(dbNames.oneTimeTooltips)
            .doc(authContext.uid)
            .set({})
            .then(() => {
              setShowTooltip(true);
            });
        });
    }
  }, [authContext.uid]);

  useEffect(() => {
    if (
      showTooltip &&
      visible &&
      onScreenAnchor !== undefined &&
      onScreenAnchor !== null
    ) {
      const handleScroll = () => {
        const rect = onScreenAnchor.getBoundingClientRect();
        const isOnScreen =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
        setUseOnScreenAnchor(isOnScreen);
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [onScreenAnchor, showTooltip, visible]);

  const handleDismiss = () => {
    const updateObj: any = {};
    updateObj[tooltipName] = true;

    fbFirestore
      .collection(dbNames.oneTimeTooltips)
      .doc(authContext.uid)
      .update(updateObj);
    const durationOpen = new Date().getTime() - openTime.getTime();
    logAnalyticsEvent(`tooltip_${tooltipName}_dismissed`, {
      durationOpen: durationOpen,
    });
    setShowTooltip(false);
  };

  const generateSubtitle = () => {
    if (subtitle !== undefined) {
      if (subtitle.constructor === Array) {
        return subtitle.map((s, i) => {
          return (
            <Typography key={`${s}-${i}`} className={classes.subtitle}>
              {s}
            </Typography>
          );
        });
      } else {
        return <Typography className={classes.subtitle}>{subtitle}</Typography>;
      }
    } else {
      return <></>;
    }
  };

  const shouldShowOnPlatform = () => {
    switch (platform) {
      case "all":
        return true;
      case "android":
        return isAndroid();
      case "ios":
        return isIOS();
      case "web":
        return isWeb();
    }
  };

  const hasRoutePermission = () => {
    return routePermission === undefined ? true : permissionGranted;
  };

  return (
    <>
      <Popper
        className={classes.popper}
        open={
          showTooltip &&
          shouldShowOnPlatform() &&
          hasRoutePermission() &&
          visible
        }
        anchorEl={useOnScreenAnchor ? onScreenAnchor : anchorEl}
        placement={placement}
      >
        {!hideArrow && <span className={classes.arrow} />}
        <Paper className={classes.paper} elevation={4}>
          {header && (
            <Typography className={classes.header}>{header}</Typography>
          )}
          <Typography className={classes.title}>{title}</Typography>
          {generateSubtitle()}
          <Button className={classes.closeButton} onClick={handleDismiss}>
            OK
          </Button>
          {actionText && action && (
            <Button
              className={classes.actionButton}
              onClick={() => {
                handleDismiss();
                action();
              }}
            >
              {actionText}
            </Button>
          )}
        </Paper>
      </Popper>
      <div ref={setAnchorEl}>{children}</div>
    </>
  );
};

export default OneTimeTooltip;
