import React, { createContext, useEffect, useState } from "react";

//Context for message input so that specialist template and message input components can edit the same value

export interface InputContextProps {
  value: string;
  setValue: (inputString: string) => void;
  outcome: string;
  setOutcome: (outcome: string) => void;
  diagnosis: string;
  setDiagnosis: (diagnosis: string) => void;
  messageSent: boolean; //Set to true to signify message was sent,
  formDirty: boolean;
  setFormDirty: (dirty: boolean) => void;
  onMessageSent: () => void;
  billingCode: string;
  setBillingCode: (billingCode: string) => void;
  savedValue: string;
  setSavedValue: (savedValue: string) => void;
}

/* eslint-disable @typescript-eslint/no-empty-function -- Used as default values */
export const InputContext = createContext<InputContextProps>({
  value: "",
  setValue: () => {},
  outcome: "",
  setOutcome: () => {},
  diagnosis: "",
  setDiagnosis: () => {},
  messageSent: false,
  formDirty: false,
  setFormDirty: () => {},
  onMessageSent: () => {},
  billingCode: "",
  setBillingCode: () => {},
  savedValue: "",
  setSavedValue: () => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

type InputProviderProps = {
  children: React.ReactNode;
};

export const InputProvider: React.FC<InputProviderProps> = ({ children }) => {
  const [value, setValue] = useState("");
  const [outcome, setOutcome] = useState("");
  const [diagnosis, setDiagnosis] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [formDirty, setFormDirty] = useState(false);
  const [billingCode, setBillingCode] = useState("");
  const [savedValue, setSavedValue] = useState("");

  //Send event
  const onMessageSent = () => {
    //Quickly toggle on and off to trigger useEffects
    setMessageSent(true);
    setTimeout(() => {
      setMessageSent(false);
    }, 1);
  };

  //When message changes, set form dirty
  useEffect(() => {
    if (value !== savedValue) {
      setFormDirty(true);
    }
  }, [value, billingCode, outcome, diagnosis]);

  return (
    <InputContext.Provider
      value={{
        value,
        setValue,
        outcome,
        setOutcome,
        diagnosis,
        setDiagnosis,
        messageSent,
        onMessageSent,
        formDirty,
        setFormDirty,
        billingCode,
        setBillingCode,
        savedValue,
        setSavedValue,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};
