import { PaperModal } from "@alethea-medical/alethea-components";
import DescriptionIcon from "@mui/icons-material/Description";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "tss-react/mui";
import { DropdownMenuItem } from "../../../components/DropdownMenuButton/DropdownMenuButton";

import { FC, useState } from "react";
import { Activity } from "../../../../shared/types";
import AveiroInvoicePage from "./AveiroInvoicePage";

const useStyles = makeStyles()(() => ({
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

interface AveiroInvoiceButtonProps {
  activityId: string;
  econsult: Activity.Econsult;
  variant: "button" | "dropdown";
}

const AveiroInvoiceButton: FC<AveiroInvoiceButtonProps> = ({
  activityId,
  econsult,
  variant,
}) => {
  const { classes } = useStyles();

  const [show, setShow] = useState(false);

  const openModalHandler = () => {
    setShow(true);
  };

  return (
    <>
      {variant === "button" && (
        <Tooltip title="Create Invoice">
          <Button
            onClick={openModalHandler}
            color="primary"
            variant="outlined"
            startIcon={<DescriptionIcon />}
          >
            Create Invoice
          </Button>
        </Tooltip>
      )}
      {variant === "dropdown" && (
        <DropdownMenuItem
          label="Create Invoice"
          icon={<DescriptionIcon color="primary" />}
          onClick={openModalHandler}
          color="primary"
        />
      )}
      <PaperModal
        className={classes.modal}
        show={show}
        setShow={setShow}
        enablePadding
        flexHeight
        flexWidth
      >
        {show && (
          <AveiroInvoicePage activityId={activityId} econsult={econsult} />
        )}
      </PaperModal>
    </>
  );
};

export default AveiroInvoiceButton;
