export const firebaseConfigProd = {
  apiKey: "AIzaSyB8Uqi8I9nG08d90W_k3S4Xq9Qn6YAkQcY",
  authDomain: "alethea-medical.firebaseapp.com",
  projectId: "alethea-medical",
  storageBucket: "alethea-medical.appspot.com",
  messagingSenderId: "311812574179",
  appId: "1:311812574179:web:f794e3c1f183e7c89e6ed6",
};

export const firebaseConfigDev = {
  apiKey: "AIzaSyCYOBWJIP_a0lUTKqNFXog61HbPhycc12w",
  authDomain: "entid-dev-9c711.firebaseapp.com",
  databaseURL: "https://entid-dev-9c711.firebaseio.com",
  projectId: "entid-dev-9c711",
  storageBucket: "entid-dev-9c711.appspot.com",
  messagingSenderId: "138705568071",
  appId: "1:138705568071:web:c3a960c748b2419a61cc62",
};

export const firebaseConfigStaging = {
  apiKey: "AIzaSyAITjGlPXkcDwX8ZiAwIEgtBx5cXAtbmMU",
  authDomain: "entid-staging.firebaseapp.com",
  projectId: "entid-staging",
  storageBucket: "entid-staging.appspot.com",
  messagingSenderId: "1085705409173",
  appId: "1:1085705409173:web:bd220d81898720e4dbc19c",
  measurementId: "G-4P0TEWN6GK",
};

export const firebaseConfigDemo = {
  apiKey: "AIzaSyDYZgBDa_mLpPSYC40CR5htxfLX91RyGLg",
  authDomain: "alethea-medical-demo.firebaseapp.com",
  projectId: "alethea-medical-demo",
  storageBucket: "alethea-medical-demo.appspot.com",
  messagingSenderId: "844958453874",
  appId: "1:844958453874:web:8c4816639a36bc27b1b2eb",
  measurementId: "G-T81V0EBWKT",
};
