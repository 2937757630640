import {
  PaperPage,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Send from "@mui/icons-material/Send";
import {
  Button,
  Grid2 as Grid,
  InputAdornment,
  InputLabel,
  Tooltip,
} from "@mui/material";
import FormData from "form-data";
import React, { FC, useContext, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { PatientInfo } from "src/../shared/types";
import { AuthContext } from "src/AuthProvider";
import EmailInput from "src/components/FormInputFields/EmailInput";
import FormCheckbox from "src/components/FormInputFields/FormCheckbox";
import FormTextArea from "src/components/FormInputFields/FormTextArea";
import FormTextField from "src/components/FormInputFields/FormTextField";
import PHNInputLookup from "src/components/FormInputFields/PHNInput";
import HelpModal from "src/components/HelpModal";
import LeavePagePrompt from "src/components/LeavePagePrompt";
import LocationDropdown from "src/components/LocationDropdown";
import SelectSendOnBehalfOf from "src/components/SelectSendOnBehalfOf";
import { SendingOnBehalfOfOption } from "src/components/SelectSendOnBehalfOf/SelectSendOnBehalfOf";
import SuccessModal from "src/components/SuccessModal";
import useFileDrop from "src/components/useFileDrop";
import usePermissions from "src/components/usePermissions";
import { logAnalyticsEvent } from "src/firebase";
import buildSignoff from "src/models/buildSignoff";
import isMobileDevice from "src/models/isMobileDevice";
import serverRequest from "src/models/serverRequest";

const OneWayMessaging: FC = () => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { dirtyFields, isDirty },
  } = useForm({ mode: "onTouched" });
  const locationIdx = useWatch({
    control,
    name: "locationIdx",
    defaultValue: 0,
  });
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  useEffect(() => {
    setValue("locationIdx", getValues("locationIdx")); // Set locationIdx for default clinic
  }, [locationIdx]);

  const {
    files,
    resetFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
  } = useFileDrop({
    disabled: isDisabled(),
  });

  const authContext = useContext(AuthContext);

  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });
  const [optionMustBeChosen, setOptionMustBeChosen] = React.useState(true);

  const sendingOnBehalfOfOption = useWatch({
    control,
    name: "sendingOnBehalfOfOption",
  });

  useEffect(() => {
    setOptionMustBeChosen(
      sendMsgOnBehalfOfPhysicianOrClinic && !sendingOnBehalfOfOption,
    ); // If MOA can, MOA MUST choose someone
  }, [sendMsgOnBehalfOfPhysicianOrClinic, sendingOnBehalfOfOption]);

  const getSubjectPrepend = (option: SendingOnBehalfOfOption): string => {
    let subjectPrepend = "Secure Message from ";
    if (sendMsgOnBehalfOfPhysicianOrClinic) {
      // If they are an MOA with permission to send on behalf of someone else
      if (option)
        subjectPrepend += `${option.label}: `; // If option chosen, go with label
      else
        subjectPrepend += `${authContext?.profile?.locations[locationIdx].clinicName}: `; // If option NOT chosen, default to clinic
    } else subjectPrepend += `Dr. ${authContext?.profile?.lastName}: `;

    return subjectPrepend;
  };

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);

    logAnalyticsEvent("one_way_patient_email_start");

    const form = new FormData();
    form.append("physicianUid", authContext.uid);
    form.append("locationIdx", data.locationIdx);
    form.append("patientEmail", data.email);
    form.append("phn", data.phn);
    form.append(
      "subject",
      getSubjectPrepend(data?.sendingOnBehalfOfOption) + data.subject,
    );
    form.append("body", `${data.body}\n\n${data.signOff}`);
    form.append("delay", data.delaySend);
    if (data.sendingOnBehalfOfOption) {
      form.append(
        "sendingOnBehalfOfOptionUid",
        data.sendingOnBehalfOfOption.id,
      );
      form.append(
        "sendingOnBehalfOfOptionKind",
        data.sendingOnBehalfOfOption.isClinic ? "clinic" : "physician",
      );
    }
    Object.values(files).forEach((file) => {
      form.append("attachment", file.file, file.filename);
    });

    serverRequest(authContext.user, {}, form, "one-way-patient-message")
      .then(() => {
        logAnalyticsEvent("one_way_patient_email_success");

        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
        }, 1000);
        resetForm();
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error sending one way patient message",
          analyticsLog: "one_way_patient_email_failed",
        });
      });
  };

  const resetForm = () => {
    reset({
      locationIdx: getValues("locationIdx"),
      delaySend: false,
      phn: "",
      email: "",
      subject: "",
      body: "",
      signOff: getValues("signOff"),
      sendingOnBehalfOfOption: getValues("sendingOnBehalfOfOption"),
    });
    resetFiles();
  };

  const onError = () => {
    errorHandler({
      userMessage: "Check form for errors.",
    });
  };

  useEffect(() => {
    if (dirtyFields["signOff"] !== true) {
      const nameObject: {
        first: string | undefined;
        last: string | undefined;
      } = { first: undefined, last: undefined };
      if (sendingOnBehalfOfOption) {
        if (!sendingOnBehalfOfOption.isClinic) {
          nameObject.first = sendingOnBehalfOfOption.firstName;
          nameObject.last = sendingOnBehalfOfOption.lastName;
        }
        // Otherwise default to no name (clinic)
      } else {
        nameObject.first = authContext.profile?.firstName;
        nameObject.last = authContext.profile?.lastName;
      }

      setValue(
        "signOff",
        buildSignoff(nameObject, authContext?.profile?.locations[locationIdx]),
      );
    }
  }, [locationIdx, sendingOnBehalfOfOption]);

  return (
    <>
      <PaperPage enablePadding>
        <form
          onSubmit={handleSubmit(onSubmit, onError)}
          autoComplete="off"
          aria-autocomplete="none"
        >
          <fieldset disabled={isDisabled()} aria-autocomplete="none">
            <Grid container spacing={1}>
              <Grid size={{ xs: 12 }}>
                <HelpModal
                  buttonText="About One-Way Messaging"
                  helpText={[
                    "Your message is securely sent to the patient through the alethea.care portal.",
                    "If delay is checked, your message is sent the next day at 7:00 AM MST.",
                  ]}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <LocationDropdown
                      name="locationIdx"
                      control={control}
                      label="Clinic"
                      disabled={isDisabled()}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <SelectSendOnBehalfOf control={control} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <PHNInputLookup
                      name="phn"
                      control={control}
                      label="PHN"
                      required
                      handlePatientInfo={(newPatientInfo: PatientInfo) => {
                        setValue("email", newPatientInfo.email, {
                          shouldValidate: true,
                        });
                      }}
                      disabled={isDisabled()}
                      province={
                        authContext?.profile?.locations[locationIdx].province
                      }
                      autocomplete
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <EmailInput
                      name="email"
                      label="Patient Email"
                      control={control}
                      rules={{
                        required: { value: true, message: "Email is required" },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* Delay email checkbox */}
              <Grid size={{ xs: 12 }}>
                <Tooltip
                  title="Delay sending the email until the next day at 7:00 AM MST"
                  placement="right"
                >
                  {/* Wrap in fragment to prevent error about refs from being thrown */}
                  <>
                    <FormCheckbox
                      name="delaySend"
                      label="Delay sending"
                      control={control}
                    />
                  </>
                </Tooltip>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Subject</InputLabel>
                <FormTextField
                  name="subject"
                  control={control}
                  {...{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {getSubjectPrepend(sendingOnBehalfOfOption)}
                        </InputAdornment>
                      ),
                    },
                  }}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Message</InputLabel>
                <FormTextArea
                  name="body"
                  control={control}
                  initRows={3}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <FormTextArea
                  name="signOff"
                  control={control}
                  initRows={3}
                  disabled={isDisabled()}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <InputLabel>Attachments</InputLabel>
              </Grid>
              <Grid size={{ xs: 12 }}>
                {createDropzone(
                  isMobileDevice()
                    ? "Tap to Upload"
                    : "Click Here or Drag and Drop to Upload",
                  "150px",
                )}
              </Grid>
              <Grid size={{ xs: 12 }}>{createFileList()}</Grid>
              <Grid size={{ xs: 12 }}>{createFileThumbs()}</Grid>
              <Grid size={{ xs: 12 }}>
                <ProcessStatus
                  state={processState}
                  errorMessage={processErrorMessage}
                />
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={optionMustBeChosen || isDisabled()}
                  startIcon={<Send color="primary" />}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </form>
      </PaperPage>
      <SuccessModal
        text={"Message sent successfully."}
        show={processState === ProcessState.success}
      />
      <LeavePagePrompt isDirty={isDirty} />
    </>
  );
};

export default OneWayMessaging;
