import { FC } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import Sidebar from "./Sidebar";

const useStyles = makeStyles()((theme) => ({
  content: {
    flexGrow: 1,
    maxWidth: "100%",
  },
  appBarOffset: {
    height: theme.spacing(6),
  },
}));

const Dashboard: FC = () => {
  const { classes } = useStyles();
  return (
    <>
      <Sidebar />
      <main className={classes.content}>
        {/* Add offset equal to size of toolbar so appbar doesn't cover content on mobile */}
        <div className={classes.appBarOffset} />
        <Outlet />
      </main>
    </>
  );
};

export default Dashboard;
