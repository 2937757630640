import Button from "@mui/material/Button";
import React from "react";
interface ToggleButtonProps {
  className?: string;
  startIcon?: React.ReactNode;
  toggleState: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
  children: React.ReactNode;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  className,
  startIcon,
  toggleState,
  onClick,
  disabled,
  children,
  ...rest
}) => {
  return (
    <Button
      className={className}
      disableElevation
      color="primary"
      variant={toggleState ? "contained" : "outlined"}
      startIcon={startIcon}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default ToggleButton;
