import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { Activity, Service } from "@alethea-medical/aletheamd-types";
import nullToUndefinedAndParseTimestamps from "src/utils/nullToUndefinedAndParseTimestamps";
import { UserMediaMetadataSelectedDict } from "../../../../components/Gallery/Controllers/SelectGalleryController";
import { UserMediaMetadataItem } from "../../../../components/Gallery/Models/GalleryModel";
import {
  FileDict,
  FileWithPreview,
  getFileType,
} from "../../../../components/useFileDrop/useFileDrop";
import { fbFirestore, fbFunctions, fbStorage } from "../../../../firebase";
import versionStrings from "../../../../versionStrings";

export type ConsultDraftsCollectionType =
  | "user_drafts"
  | "drafts_pending_approval";
export function GetDraftCollectionName(
  collectionType: ConsultDraftsCollectionType,
): string {
  return collectionType === "user_drafts"
    ? dbNames.consultDrafts_userDrafts
    : dbNames.consultDrafts_draftsPendingApproval;
}

/**
 * Loads a draft from the database.
 * @param uid - The user ID.
 * @param draftId - The ID of the draft to load.
 * @returns A promise that resolves to an object containing the loaded draft data.
 */
export async function loadDraft(
  consultDraftsCollectionType: ConsultDraftsCollectionType,
  uid: string,
  draftId: string,
): Promise<{
  formData: Activity.ConsultFormFields;
  subject: string;
  service: Service.Service | undefined;
  gallerySelectedMedia: UserMediaMetadataItem[];
  files: FileWithPreview[];
  econsultForwarder?: Activity.EconsultForwarder;
}> {
  const { data } = await fbFunctions.httpsCallable("consultDraft-getDraft")({
    consultDraftsCollectionType,
    appVersion: versionStrings.version,
    draftId,
  });

  // Convert null to undefined and timestamp values (since the https call turns them into plain objects)
  nullToUndefinedAndParseTimestamps(data);

  const formData: Activity.ConsultFormFields = data.formData;
  const subject: string = data.subject;
  const gallerySelectedMedia: UserMediaMetadataItem[] =
    data.gallerySelectedMedia;
  const service: Service.Service | undefined = data.service;

  const files = await downloadDraftFiles(uid, draftId);
  return {
    formData,
    subject,
    service,
    gallerySelectedMedia,
    files,
    econsultForwarder: data.econsultForwarder,
  };
}

/**
 * Saves or updates a draft.
 * Files are uploaded on the frontend to prevent having to pass files back to the server.
 * @param uid - The user ID.
 * @param draftId - The ID of the draft. If undefined a new draft will be saved. If defined the draft will be updated.
 * @param formData - The form data for the draft from the consult form.
 * @param subject - The subject of the draft.
 * @param selectedMedia - The selected media dictionary from the gallery.
 * @param files - Attachments that have been uploaded to the consult not from the gallery.
 * @returns A promise that resolves to an object containing the new draft ID and subject.
 */
export async function saveOrUpdateDraft(
  consultDraftsCollectionType: ConsultDraftsCollectionType,
  uid: string,
  draftId: string | undefined,
  formData: Activity.ConsultFormFields,
  subject: string,
  selectedMedia: UserMediaMetadataSelectedDict,
  files: FileDict,
  econsultForwarder?: Activity.EconsultForwarder,
  approverUid?: string,
): Promise<{ newDraftId: string; newDraftSubject: string }> {
  // Generates a document ID to be used when uploading documents & further logic after this, this document isn't actually created
  if (draftId === undefined) {
    const docRef = fbFirestore
      .collection(dbNames.consultDrafts)
      .doc(approverUid ?? uid)
      .collection(GetDraftCollectionName(consultDraftsCollectionType))
      .doc();
    draftId = docRef.id;
  }

  //* 1. Call uploadDraftFiles first (with the ID above)

  // NORMALLY uploads to draft_temp_files/<user_id>/<draft_id>, we assume we read everything from here when loading draft
  await uploadDraftFiles(uid, draftId, files);

  //? WHEN FORWARDING: Call backend function to COPY files from draft_temp_files/forward_uid to draft_temp_files/approver_uid, then delete old ones
  if (approverUid) {
    await fbFunctions.httpsCallable("consultDraft-moveStorageAttachments")({
      approverUid,
      draftId,
    });
  }

  //* 2. Call backend saveDraft (PASS the ID above)

  //? WHEN FORWARDING: In the backend, if econsultForwarder exists, copy images in selectedMedia, to the files of the draft --> draft_temp_files/<approver_id>/<draft_id>
  //? Upload these to drafts_pending_approval/<user_id>/<draft_id>
  const { data } = await fbFunctions.httpsCallable("consultDraft-saveDraft")({
    draftId,
    consultDraftsCollectionType,
    appVersion: versionStrings.version,
    formData,
    subject,
    selectedMedia,
    econsultForwarder,
    ...(approverUid && { approverUid }),
  });

  const newDraftSubject = data.draftSubject;

  return {
    newDraftId: draftId,
    newDraftSubject,
  };
}

/** Delete draft from database and any temporary draft files from storage
 * The backend handles all operations
 * @param draftId - The ID of the draft to delete.
 */
export async function discardDraft(
  consultDraftsCollectionType: ConsultDraftsCollectionType,
  draftId: string,
): Promise<void> {
  await fbFunctions.httpsCallable("consultDraft-deleteDraft")({
    consultDraftsCollectionType,
    appVersion: versionStrings.version,
    draftId,
  });
}

//#region Handling uploaded files
// This is done client-side so that we don't have to pass files back to the server
// The only way this interacts with the storage bucket is to upload all files, download all files, or delete all files in the draft folder
function downloadDraftFiles(
  uid: string,
  draftId: string,
): Promise<FileWithPreview[]> {
  const baseUrl = `draft_temp_files/${uid}/${draftId}`;

  // Download all files in draft folder
  return fbStorage
    .ref(baseUrl)
    .listAll()
    .then((result) => {
      return Promise.all(
        result.items.map((ref) => {
          return ref.getDownloadURL().then((url) => {
            const fileWithPreview: FileWithPreview = {
              file: new File([], "empty"),
              draftStoragePath: ref.fullPath,
              filename: ref.name,
              src: url,
              fileType: getFileType(ref.name),
            };
            return fileWithPreview;
          });
        }),
      );
    });
}

function uploadDraftFiles(
  uid: string,
  draftId: string,
  files: FileDict,
): Promise<void> {
  const baseUrl = `draft_temp_files/${uid}/${draftId}`;

  // Don't delete files that have been uploaded previously. The data for these does not exist locally
  const pathsToNotDelete = Object.values(files)
    .filter((file) => file.draftStoragePath !== undefined)
    .map((file) => file.draftStoragePath) as string[];

  // Delete all contents in old draft folder, except for the paths not to delete
  return deleteDraftFiles(uid, draftId, pathsToNotDelete)
    .catch((error: Error) => {
      console.error(error);
    })
    .then(() => {
      // Then re-upload new files
      // Do a complete reupload in case the file name stayed the same but the contents changed
      return Promise.all(
        Object.values(files).map((file) => {
          // If file already exists don't upload it
          if (file.draftStoragePath !== undefined) return Promise.resolve();

          return fbStorage.ref(`${baseUrl}/${file.filename}`).put(file.file);
        }),
      );
    })
    .then(() => Promise.resolve());
}

function deleteDraftFiles(
  uid: string,
  draftId: string,
  filesToSkip?: string[],
): Promise<void> {
  const baseUrl = `draft_temp_files/${uid}/${draftId}`;
  // Delete all contents in old draft folder
  return fbStorage
    .ref(baseUrl)
    .listAll()
    .then((result) => {
      return Promise.all(
        result.items.map((ref) => {
          // Don't delete if file name is in files to skip
          if (filesToSkip !== undefined && filesToSkip.includes(ref.fullPath))
            return Promise.resolve();
          return ref.delete();
        }),
      );
    })
    .then(() => Promise.resolve());
}
//#endregion
