import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Utilities } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";
import analyticsLogs from "../../../../analyticsLogs";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";
import OutcomesController from "../SpecialistTools/Outcomes/OutcomesController";

type StringDict<T> = Utilities.StringDict<T>;

type CallCompleteControllerReturn = {
  callCompleteHandler: () => void;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  enableButtonControl?: boolean;
  billingCodeKey: string;
  setBillingCodeKey: (key: string) => void;
  outcomesEmpty: boolean;
  disableSubmit: boolean;
  disableCancel: boolean;
  processState: ProcessState;
  processErrorMessage: string;

  selectedOutcome: string;
  setSelectedOutcome: (outcome: string) => void;
  selectedDiagnosis: string;
  setSelectedDiagnosis: (diagnosis: string) => void;
};

interface CallCompleteControllerProps {
  activityId: string;
  userActivityRole: string;
}

interface BillingCodeOption {
  code: string;
  description: string;
}

export const billingCodes: StringDict<BillingCodeOption> = {
  "03.01LJ": {
    code: "03.01LJ",
    description: "Phone Consult: Weekday: 7am - 5pm",
  },
  "03.01LK": {
    code: "03.01LK",
    description: "Phone Consult: Weekday: 5pm - 10pm",
  },
  "03.01LK1": {
    code: "03.01LK",
    description: "Phone Consult: Weekend: Any Time",
  },
  "03.01LK2": {
    code: "03.01LK",
    description: "Phone Consult: Stat Holiday: 7am - 10pm",
  },
  "03.01LL": {
    code: "03.01LL",
    description: "Phone Consult: Any Day: 10pm - 7am",
  },
  "03.01O": {
    code: "03.01O",
    description: "eConsult",
  },
};

const CallCompleteController = ({
  activityId,
  userActivityRole,
}: CallCompleteControllerProps): CallCompleteControllerReturn => {
  const billPhoneConsult = fbFunctions.httpsCallable(
    "phoneConsult-billAndMarkComplete",
  );
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const [showModal, setShowModal] = useState(false);

  const [enableButtonControl, setEnableButtonControl] = useState<boolean>();

  const [billingCodeKey, setBillingCodeKey] = useState<string>(
    Object.keys(billingCodes)[0],
  );

  const {
    selectedOutcome,
    setSelectedOutcome,
    selectedDiagnosis,
    setSelectedDiagnosis,
    handleSubmitOutcomes,
    processState: outcomeProcessState,
  } = OutcomesController({});

  const callCompleteHandler = () => {
    setProcessState(ProcessState.running);
    return billPhoneConsult({
      activityId,
      billingCode: billingCodes[billingCodeKey].code,
    })
      .then(() => {
        logAnalyticsEvent(analyticsLogs.phoneConsult.markCallComplete.success);
        setProcessState(ProcessState.idle);

        // Don't show user error if outcomes can't be updated
        return handleSubmitOutcomes().then(() => {
          setShowModal(false);
        });
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error marking call as complete.",
          analyticsLog: analyticsLogs.phoneConsult.markCallComplete.fail,
        });
      });
  };

  useEffect(() => {
    if (userActivityRole === "consultant") {
      setEnableButtonControl(true);
    } else {
      setEnableButtonControl(false);
    }
  }, [userActivityRole]);

  const areOutcomesEmpty = (): boolean => {
    return selectedOutcome === "";
  };

  const isSubmitDisabled = (): boolean => {
    return (
      areOutcomesEmpty() ||
      processState === ProcessState.running ||
      outcomeProcessState === ProcessState.running
    );
  };

  const isCancelDisabled = (): boolean => {
    return (
      processState === ProcessState.running ||
      outcomeProcessState === ProcessState.running
    );
  };

  return {
    callCompleteHandler,
    showModal,
    setShowModal,
    enableButtonControl,
    billingCodeKey,
    setBillingCodeKey,
    outcomesEmpty: areOutcomesEmpty(),
    disableSubmit: isSubmitDisabled(),
    disableCancel: isCancelDisabled(),
    processState,
    processErrorMessage,

    selectedOutcome,
    setSelectedOutcome,
    selectedDiagnosis,
    setSelectedDiagnosis,
  };
};

export default CallCompleteController;
