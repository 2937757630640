import { BillingRecord } from "@alethea-medical/admin-types";
import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { AsyncParser } from "@json2csv/whatwg";
import GridOnIcon from "@mui/icons-material/GridOn";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import firebase from "firebase/compat/app";
import { FC } from "react";
import { logAnalyticsEvent } from "../../../../firebase";

interface ExportBillingReportCSVProps {
  billingData: BillingRecord[];
}
const ExportBillingReportCSV: FC<ExportBillingReportCSVProps> = ({
  billingData,
}) => {
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const valueOrBlank = (value: string | undefined) => {
    if (value === undefined) {
      return "";
    }
    return value;
  };
  const dateOrBlank = (date: firebase.firestore.Timestamp | undefined) => {
    if (date === undefined) {
      return "";
    }
    return date.toDate().toLocaleString();
  };

  const saveCSV = async () => {
    setProcessState(ProcessState.running);
    logAnalyticsEvent("billing_report_export_csv_failed_start");

    try {
      const parser = new AsyncParser();
      const csvData = billingData.map((data: BillingRecord) => {
        return {
          "Service Date": dateOrBlank(data.created),
          "Data Accepted or Rejected": dateOrBlank(data.hlinkResultReceivedAt),
          "Date Paid": dateOrBlank(data.paidAt),
          Activity: valueOrBlank(data.type),
          "Sub-Activity": valueOrBlank(data.subType),
          PHN: valueOrBlank(data.patientId),
          Status: valueOrBlank(data.status),
          "Diagnosis Code": valueOrBlank(data.dxCode),
          "Billing Code": valueOrBlank(data.billingCode),
          "Amount Paid": valueOrBlank(`${data.payToDoctor}`),
          "Error Code": valueOrBlank(data.errorCode),
        };
      });
      const csv = await parser.parse(csvData).promise();
      const fileName = `billing_report.csv`;

      // Create a blob of the data
      const fileToSave = new Blob([csv], { type: "text/plain;charset=utf-8" });
      saveAs(fileToSave, fileName);

      logAnalyticsEvent("billing_report_export_csv_failed_success");
      setProcessState(ProcessState.idle);
    } catch (e) {
      let error: Error = new Error("Error");
      if (typeof e === "string") {
        error = new Error(e.toUpperCase()); // works, `e` narrowed to string
      } else if (e instanceof Error) {
        error = e; // works, `e` narrowed to Error
      }
      errorHandler({
        error: error,
        userMessage: "Error exporting CSV. Please try again.",
        analyticsLog: "billing_report_export_csv_failed",
      });
    }
  };

  return (
    <div>
      <Tooltip title="Export billing records as a CSV">
        <Button
          variant="contained"
          color="primary"
          startIcon={<GridOnIcon />}
          onClick={saveCSV}
        >
          Export as CSV
        </Button>
      </Tooltip>
      <ProcessStatus state={processState} errorMessage={processErrorMessage} />
    </div>
  );
};

export default ExportBillingReportCSV;
