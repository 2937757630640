import {
  HeightLayout,
  HeightLayoutChild,
  ProcessState,
  ProcessStatus,
  thinScrollbar,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { FC, useContext, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import { ClaimsContext } from "../../../../config/ClaimsProvider";
import ConsultSearchBar from "../Inbox/ConsultSearchBar";
import ActivityProvider from "../Inbox/Contexts/ActivityProvider";
import InboxController from "../Inbox/Controllers/InboxController";
import InboxModel from "../Inbox/Models/InboxModel";
import ViewSecureMessage from "../ViewSecureMessage";
import MOAConsultListItem from "./MOAConsultListItem";

const useStyles = makeStyles()(() => ({
  ...thinScrollbar,
  threadSidebar: {
    padding: 0,
  },
  hidden: {
    display: "none",
  },
  centerListItem: {
    justifyContent: "center",
  },
  noConsults: {
    textAlign: "center",
  },
}));

interface MOAConsultInboxViewProps {
  hasEditStatusPermissions: boolean;
}

const MOAConsultInboxView: FC<MOAConsultInboxViewProps> = ({
  hasEditStatusPermissions,
}) => {
  const { classes } = useStyles();

  const claimsContext = useContext(ClaimsContext);

  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [statuses, setStatuses] = useState<string[] | undefined>(
    hasEditStatusPermissions ? ["to_book", "to_book_urgent"] : undefined,
  );

  const setStatusesAll = () => {
    setStatuses(undefined);
  };
  const setStatusesToBook = () => {
    setStatuses(["to_book", "to_book_urgent"]);
  };

  const [, setSelectedOptions] = useState<string[]>([]);

  const {
    sortedActivities,
    loadMoreHandler,
    disableLoadMoreLoading,
    disableLoadMoreEndOfResults,
    updateActivitiesInState,
    refillActivities,

    isActivityOpen,
    openActivityHandler,
    // Search
    searchParams,
    updateSearchParams,
    enableSearch,
    runSearch,
    clearSearch,
    fetchSubjectLineFilters,
    saveSubjectLineFilters,

    processState,
    setProcessState,
    processErrorMessage,
  } = InboxController<Activity.ClinicActivity>({
    inboxModel: new InboxModel(
      claimsContext.userClaims?.clinicId ?? "",
      dbNames.activities,
      dbNames.clinicActivities,
      dbNames.clinicActivities_activities,
      "lastMessageReceivedAt",
    ),
    metadataFieldToSortBy: "lastMessageReceivedAt",
    statuses: statuses,
    setShowTabs,
  });

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <>
      <div className={isActivityOpen ? classes.hidden : ""}>
        <List className={classes.threadSidebar}>
          <HeightLayout height={heightMinusAppBar}>
            {showTabs && hasEditStatusPermissions && (
              <HeightLayoutChild flexDriver>
                <ListItem divider>
                  <ToggleButtonGroup>
                    <ToggleButton
                      value={"all"}
                      onClick={setStatusesAll}
                      selected={statuses === undefined}
                    >
                      All
                    </ToggleButton>
                    <ToggleButton
                      value={"to_book"}
                      onClick={setStatusesToBook}
                      selected={statuses !== undefined}
                    >
                      To Book
                    </ToggleButton>
                  </ToggleButtonGroup>
                </ListItem>
              </HeightLayoutChild>
            )}
            <HeightLayoutChild flexDriver>
              <ListItem divider>
                <ConsultSearchBar
                  searchParams={searchParams}
                  updateSearchParams={updateSearchParams}
                  enableSearch={enableSearch}
                  runSearch={runSearch}
                  clearSearch={clearSearch}
                  fetchSubjectLineFilters={fetchSubjectLineFilters}
                  saveSubjectLineFilters={saveSubjectLineFilters}
                  placeholderText={
                    "PHN, Patient Name, Specialty, Subsite, Referring Provider/Consultant name"
                  }
                  maxSearchTerms={10}
                  onSelectedOptionsChange={setSelectedOptions}
                  showOptions={showOptions}
                  setShowOptions={setShowOptions}
                />
              </ListItem>
              <ProcessStatus
                state={processState}
                setState={setProcessState}
                errorMessage={processErrorMessage}
                useSnackbar={true}
              />
            </HeightLayoutChild>
            <HeightLayoutChild
              flexDriven
              allowOverflowY
              className={classes.thinScrollbar}
            >
              {sortedActivities.length == 0 &&
                processState !== ProcessState.running && (
                  <ListItem className={classes.centerListItem}>
                    <Typography className={classes.noConsults}>
                      No Consults
                    </Typography>
                  </ListItem>
                )}
              {sortedActivities.map((a) => (
                <MOAConsultListItem
                  key={`moa_inbox_list_item_${a.id}`}
                  activity={a}
                  openActivityHandler={openActivityHandler}
                />
              ))}
              <ListItemButton
                onClick={loadMoreHandler}
                disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
                alignItems="center"
                divider
              >
                <ListItemIcon>
                  <ArrowDownwardIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    disableLoadMoreLoading
                      ? "Loading"
                      : disableLoadMoreEndOfResults
                        ? "End of Consults"
                        : enableSearch
                          ? "Load More Search Results"
                          : "Load More"
                  }
                />
              </ListItemButton>
            </HeightLayoutChild>
          </HeightLayout>
        </List>
      </div>
      <ActivityProvider
        viewType="moa"
        metadataRefId={claimsContext.userClaims?.clinicId ?? ""}
        updateActivitiesInListState={updateActivitiesInState}
        metadataCollection={dbNames.clinicActivities}
        metadataSubCollection={dbNames.clinicActivities_activities}
      >
        <ViewSecureMessage
          refillActivities={() => {
            const loadedActivitiesCount = sortedActivities.length;
            refillActivities(loadedActivitiesCount);
          }}
        />
      </ActivityProvider>
    </>
  );
};

export default MOAConsultInboxView;
