import { FC } from "react";
import isMobileDevice from "../../../../models/isMobileDevice";
import SpecialistTemplatesView from "../../SpecialistTemplates/Views/SpecialistTemplatesView";

const SpecialistTemplatesPullout: FC = () => {
  return (
    <SpecialistTemplatesView
      alwaysEnableEditing={false}
      disableDrag={isMobileDevice()}
    />
  );
};

export default SpecialistTemplatesPullout;
