import { SidebarItem } from "@alethea-medical/alethea-components";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import Badge from "@mui/material/Badge";
import { FC, useContext } from "react";
import { AndroidAPKUpdateContext } from "./AndroidAPKUpdateProvider";

interface AndroidAPKUpdateSidebarProps {
  showText: boolean;
}

const AndroidAPKUpdateSidebar: FC<AndroidAPKUpdateSidebarProps> = ({
  showText,
}) => {
  const { updateAvailable, checkForUpdate } = useContext(
    AndroidAPKUpdateContext,
  );

  return (
    <>
      {updateAvailable && (
        <SidebarItem
          label="Update Now"
          tooltip="Update Now"
          showText={showText}
          icon={
            <Badge color="error" badgeContent={"!"}>
              <SystemUpdateIcon color="primary" />
            </Badge>
          }
          onClick={checkForUpdate}
        />
      )}
    </>
  );
};

export default AndroidAPKUpdateSidebar;
