import { PaperPage } from "@alethea-medical/alethea-components";
import { Grid2 as Grid } from "@mui/material";
import { FC } from "react";
import { ClosedTable } from "./Components/ClosedTable";
import { ExplanationPanel } from "./Components/ExplanationsPanel";
import { NewAndPendingTable } from "./Components/NewAndPendingTable";
import { RejectionTable } from "./Components/RejectionsTable";
import EarningSummary from "./EarningSummary/EarningSummary";
import MonthlyBillingTable from "./MonthlyBillingTable/MonthlyBillingTable";

const BillingReport: FC = () => {
  return (
    <PaperPage enablePadding>
      <Grid container spacing={1}>
        <EarningSummary />
        {/* rejection and new Tables */}
        <Grid size={{ xs: 6 }}>
          <NewAndPendingTable />
        </Grid>
        <Grid size={{ xs: 6 }}>
          <RejectionTable />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <ClosedTable />
        </Grid>
        {/* Explanations */}
        <Grid size={{ xs: 12 }}>
          <ExplanationPanel />
        </Grid>
        {/* Billing */}
        <Grid size={{ xs: 12 }}>
          <MonthlyBillingTable />
        </Grid>
      </Grid>
    </PaperPage>
  );
};

export default BillingReport;
