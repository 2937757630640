import * as emailValidator from "email-validator";

function isValidEmailInput(email: string): string | undefined {
  return isEmail(email) || email === "" ? undefined : "Email is invalid";
}

export function isEmail(email: string): boolean {
  return emailValidator.validate(email);
}

export default isValidEmailInput;
