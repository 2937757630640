// #region Helper Components
// ========================================================================================================

import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Plan, Role, SignupInfo } from "@alethea-medical/aletheamd-types";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Collapse, Grid2 as Grid } from "@mui/material";
import { FC, FormEventHandler, useEffect, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { OptionalRole } from "shared/types/dist/namespaces/Role";
import RoundedButton from "src/components/RoundedButton";
import strings from "../Common/strings";
import InformationBox from "../Helpers/Container/InformationBox";
import InviteCodeBox from "../Helpers/Container/InviteCodeBox";
import SectionContainer from "../Helpers/Container/SectionContainer";
import NoPlanErrorPage from "../Helpers/Info/NoPlanErrorPage";
import SelectRoleDropdown from "../Helpers/Input/SelectRoleDropdown";
import SignupProvinceSelect from "../Helpers/Input/SignupProvinceSelect";
import Spacer from "../Helpers/Styling/Spacer";
import {
  PLAN_WATCH_KEY,
  PROVINCE_WATCH_KEY,
  ROLE_WATCH_KEY,
  SignupAction,
  SignupActionNames,
  SignupState,
  createSignupReducerAction,
  useStyles,
} from "../Signup";
import ClinicInformation from "./Sections/ClinicInformation";
import DoctorInformation from "./Sections/DoctorInformation";
import Onboarding from "./Sections/Onboarding";
import PreceptorInformation from "./Sections/PreceptorInformation";
import UserAgreement from "./Sections/UserAgreement";

type SignupFormContentProps = {
  plan: Plan.Plan;
  maybeRole: OptionalRole;
  form: UseFormReturn<SignupInfo.SignupInfo>;
  reduceSignupState: (action: SignupAction) => void;
  signupState: SignupState;
  processStateObj: ReturnType<typeof useProcessState>;
  onFormSubmission: FormEventHandler<HTMLFormElement>;
};
// Shows remainder of the form or error page if the role isn't eligible for the plan (i.e nurse for plus)
const SignupFormFields: FC<SignupFormContentProps> = ({
  plan,
  maybeRole: role,
  form,
  reduceSignupState,
  signupState,
  processStateObj,
  onFormSubmission,
}: SignupFormContentProps) => {
  if (!Role.isValid(role)) {
    return null;
  }

  if (!Role.isEligibleForPlan(role, plan)) {
    return (
      <NoPlanErrorPage
        title={strings.invalidRoleAndPlan(Role.getLongDisplayName(role))}
      />
    );
  }

  const { classes } = useStyles();

  const { processState, processErrorMessage } = processStateObj;
  const { proceedWithoutPracId, isPracIdValid, savedSignature, agreementBlob } =
    signupState;
  const selectedRole = form.watch(ROLE_WATCH_KEY);
  const province = form.watch(PROVINCE_WATCH_KEY);

  const setIsPracIdValid = (newValue: boolean) =>
    reduceSignupState(
      createSignupReducerAction(
        SignupActionNames.SET_IS_PRAC_ID_VALID,
        newValue,
      ),
    );
  const setProceedWithoutPracId = (newValue: boolean) =>
    reduceSignupState(
      createSignupReducerAction(
        SignupActionNames.SET_PROCEED_WITHOUT_PRAC_ID,
        newValue,
      ),
    );
  const setAgreementBlob = (newValue?: Blob) =>
    reduceSignupState(
      createSignupReducerAction(SignupActionNames.SET_AGREEMENT_BLOB, newValue),
    );
  const setSavedSignature = (newValue?: string) =>
    reduceSignupState(
      createSignupReducerAction(
        SignupActionNames.SET_SAVED_SIGNATURE,
        newValue,
      ),
    );

  const isDisabledNeedPracId = useMemo(() => {
    return !(
      proceedWithoutPracId ||
      isPracIdValid ||
      (Role.isValid(selectedRole) && !Role.acceptsPracId(selectedRole))
    );
  }, [
    proceedWithoutPracId,
    isPracIdValid,
    selectedRole,
    Role.isValid,
    Role.acceptsPracId,
  ]);

  const isAgreementSigned = useMemo(() => {
    return agreementBlob !== undefined && savedSignature !== undefined;
  }, [agreementBlob, savedSignature]);

  useEffect(() => {
    // Reset the Prac ID when province changes since their formats are different (eg. BC is J#### vs. AB is ####-#####)
    setValue("practiceId", "");
    clearErrors("practiceId");
  }, [province]);

  const isDisabled = useMemo(() => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  }, [processState]);

  const { control, trigger, setValue, clearErrors } = form;

  return (
    <Grid container spacing={3}>
      <Grid size={{ xs: 12 }}>
        <SignupProvinceSelect control={control} selectedPlan={plan} />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <InformationBox>{strings.provinceInfo(plan)}</InformationBox>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Collapse in={province !== ""}>
          <form onSubmit={onFormSubmission}>
            <fieldset disabled={isDisabled}>
              {/* Numbers and header */}
              <Grid container>
                <Grid size={{ xs: 12 }}>
                  <DoctorInformation
                    control={control}
                    trigger={trigger}
                    isPracIdValid={isPracIdValid}
                    setIsPracIdValid={setIsPracIdValid}
                    proceedWithoutPracId={proceedWithoutPracId}
                    setProceedWithoutPracId={setProceedWithoutPracId}
                    disabled={isDisabled}
                    disabledNeedPracId={isDisabledNeedPracId}
                    disablePracIdEntry={!Role.acceptsPracId(role)}
                    role={role}
                    number={1}
                  />
                  {role === Role.Roles.Resident ? (
                    <PreceptorInformation control={control} number={2} />
                  ) : (
                    <SectionContainer title="Clinic Information" number={2}>
                      <ClinicInformation
                        control={control}
                        disabled={isDisabled || isDisabledNeedPracId}
                      />
                    </SectionContainer>
                  )}
                  <UserAgreement
                    control={control}
                    selectedRole={role}
                    isAgreementSigned={isAgreementSigned}
                    savedSignature={savedSignature}
                    setSavedSignature={setSavedSignature}
                    setAgreementBlob={setAgreementBlob}
                    number={3}
                  />
                  <Onboarding
                    setValue={setValue}
                    trigger={trigger}
                    control={control}
                    disabled={
                      isDisabled || isDisabledNeedPracId || !isAgreementSigned
                    }
                    number={4}
                  />
                  <SectionContainer>
                    <Grid container spacing={2}>
                      <Grid size={{ xs: 12 }}>
                        <Spacer height="50px" />
                      </Grid>
                      <Grid>
                        <InviteCodeBox
                          control={control}
                          disabled={isDisabled}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <Spacer height="50px" />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <RoundedButton
                          fullWidth
                          type="submit"
                          disabled={
                            isDisabled ||
                            isDisabledNeedPracId ||
                            !isAgreementSigned
                          }
                        >
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid>Complete Sign Up</Grid>
                            <Grid>
                              <ChevronRightIcon
                                className={classes.signupIcon}
                              />
                            </Grid>
                          </Grid>
                        </RoundedButton>
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <ProcessStatus
                          state={processState}
                          errorMessage={processErrorMessage}
                        />
                      </Grid>
                    </Grid>
                  </SectionContainer>
                </Grid>
              </Grid>
            </fieldset>
          </form>
        </Collapse>
      </Grid>
    </Grid>
  );
};

type SignupFormProps = {
  form: UseFormReturn<SignupInfo.SignupInfo>;
  reduceSignupState: (action: SignupAction) => void;
  signupState: SignupState;
  processStateObj: ReturnType<typeof useProcessState>;
  onFormSubmission: FormEventHandler<HTMLFormElement>;
};

// Shows form or error page if the plan is invalid (i.e. not plus or econsult)
const SignupFormWrapped: FC<SignupFormProps> = ({
  form,
  reduceSignupState,
  signupState,
  processStateObj,
  onFormSubmission,
}) => {
  const { control, watch } = form;
  const plan = watch(PLAN_WATCH_KEY);

  if (!Plan.isValid(plan)) {
    return <NoPlanErrorPage title={strings.pickAPlan} />;
  }

  const role = watch(ROLE_WATCH_KEY);

  return (
    <Grid container size="grow" spacing={1}>
      <SelectRoleDropdown control={control} plan={plan} />
      <SignupFormFields
        plan={plan}
        maybeRole={role}
        form={form}
        reduceSignupState={reduceSignupState}
        signupState={signupState}
        processStateObj={processStateObj}
        onFormSubmission={onFormSubmission}
      />
    </Grid>
  );
};

// Wraps the form in a container
const SignupForm: FC<SignupFormProps> = ({
  form,
  reduceSignupState,
  signupState,
  processStateObj,
  onFormSubmission,
}: SignupFormProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SignupFormWrapped
        form={form}
        reduceSignupState={reduceSignupState}
        signupState={signupState}
        processStateObj={processStateObj}
        onFormSubmission={onFormSubmission}
      />
    </div>
  );
};

export default SignupForm;
