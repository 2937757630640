import { FC, useEffect, useState } from "react";
import { fbFirestore } from "../../../../firebase";
import CopyToClipboard from "./CopyToClipboard";
import EconsultCard from "./EconsultCard";

import {
  Activity,
  ConsultFormInputField,
  ConsultFormSpecialty,
} from "../../../../../shared/types";

import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { getDocumentData } from "@alethea-medical/utilities";
import palette from "../../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  checkTypographyOrRadio: {
    paddingLeft: theme.spacing(2),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  section: {
    marginTop: theme.spacing(1),
  },
  header: {
    color: palette.darkGreen,
  },
  content: {
    paddingLeft: theme.spacing(3),
  },
}));

interface EconsultInfoProps {
  econsult: Activity.Econsult;
}

const EconsultInfo: FC<EconsultInfoProps> = ({ econsult }) => {
  const { classes } = useStyles();
  const bullet = <span className={classes.bullet}>•</span>;

  const [sortedSymptoms, setSortedSymptoms] = useState<
    { header: string; value: any }[]
  >([]);

  const getSymptomIndex = (
    header: string,
    subsite: ConsultFormInputField[],
  ) => {
    return subsite
      .map((field: ConsultFormInputField) => field.header)
      .indexOf(header);
  };

  useEffect(() => {
    if (econsult.specialty !== "" && econsult.specialty !== undefined) {
      fbFirestore
        .collection(dbNames.specialties)
        .doc(econsult.specialty)
        .get()
        .then(getDocumentData)
        .then((specialty: ConsultFormSpecialty) => {
          const subsite: ConsultFormInputField[] = specialty[econsult.subsite];
          const headers = Object.keys(econsult.symptoms).sort(
            (a: string, b: string) => {
              const indexA = getSymptomIndex(a, subsite);
              const indexB = getSymptomIndex(b, subsite);
              return indexA < indexB ? -1 : 1;
            },
          );

          setSortedSymptoms(
            headers.map((header: string) => {
              return {
                header: header,
                value: econsult.symptoms[header],
              };
            }),
          );
        })
        .catch(() => {
          //Fall back if we can't load the specialty
          setSortedSymptoms(
            Object.keys(econsult.symptoms).map((header: string) => {
              return {
                header,
                value: econsult.symptoms[header],
              };
            }),
          );
        });
    }
  }, [econsult]);

  return (
    <>
      {sortedSymptoms.length > 0 && (
        <Grid size={{ xs: 12 }}>
          <EconsultCard>
            <CopyToClipboard
              formattedText={sortedSymptoms
                .map(({ header, value }) => {
                  if (typeof value === "string") {
                    return value !== "" ? `${header}: ${value}\n` : "";
                  } else {
                    const selectedItems = Object.entries(value)
                      .filter(
                        ([, itemValue]) =>
                          itemValue === true ||
                          (typeof itemValue === "string" && itemValue !== ""),
                      )
                      .map(([itemKey, itemValue]) =>
                        typeof itemValue === "string"
                          ? `${itemKey}: ${itemValue}`
                          : itemKey,
                      )
                      .join(", ");
                    return selectedItems ? `${header}: ${selectedItems}\n` : "";
                  }
                })
                .join("")}
            />
            <Grid container spacing={1}>
              {sortedSymptoms.map((keyValue: any) => {
                const key = keyValue.header;
                const value = keyValue.value;
                if (typeof value === "string") {
                  //Textfield
                  return (
                    <Grid size={{ xs: 12 }} key={`textfield_${key}`}>
                      <Typography className={classes.header}>
                        {`${key}`}
                      </Typography>
                      <Typography className={classes.content}>
                        {value !== "" ? value : "No information provided"}
                      </Typography>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid size={{ xs: 12 }} key={`header_${key}`}>
                      <Typography className={classes.header}>
                        {`${key}`}
                      </Typography>
                      {Object.keys(value).map((entry: string) => {
                        if (typeof value[entry] === "boolean" && value[entry]) {
                          //Checkboxes
                          return (
                            <Typography
                              key={`checkbox_${entry}`}
                              className={classes.content}
                            >
                              {bullet}
                              {entry}
                            </Typography>
                          );
                        } else if (
                          typeof value[entry] === "string" &&
                          value[entry] !== ""
                        ) {
                          //Radio
                          return (
                            <Typography
                              key={`radio_${entry}`}
                              className={classes.content}
                            >
                              {bullet}
                              {entry}: {value[entry]}
                            </Typography>
                          );
                        }
                      })}
                    </Grid>
                  );
                }
              })}
            </Grid>
          </EconsultCard>
        </Grid>
      )}
    </>
  );
};
export default EconsultInfo;
