import {
  Grid2 as Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { FC } from "react";

import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

import Typography from "@mui/material/Typography";
import { getComparator, stableSort } from "../billingFunctions";

import { BillingRecord } from "@alethea-medical/admin-types";
import getMonthName from "../../../../models/getMonthName";
import BillingTableHead from "../Components/BillingTableHead";
import BillingTableRow from "../Components/BillingTableRow";
import useBillingTable from "../Components/useBillingTable";
import { HeadCell } from "../types";
import ExportBillingReportCSV from "./ExportBillingReportCSV";
import ExportBillingReportPDF from "./ExportBillingReportPDF";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  table: {
    overflow: "visible",
    marginTop: theme.spacing(2),
  },
  pdfableTable: {
    width: "100%",
  },
  loadingStatus: {
    marginTop: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
}));

const headCells: HeadCell[] = [
  { id: "created", label: "Service Date" },
  { id: "hlinkResultReceivedAt", label: "Date Accepted or Rejected " },
  { id: "paidAt", label: "Date Paid" },
  { id: "type", label: "Activity" },
  { id: "subType", label: "Sub-Activity" },
  { id: "patientId", label: "PHN" },
  { id: "status", label: "Status" },
  { id: "dxCode", label: "Diagnosis Code" },
  { id: "billingCode", label: "Billing Code" },
  { id: "payToDoctor", label: "Amount Paid" },
  { id: "errorCode", label: "Error Code" },
];

const MonthlyBillingTable: FC = () => {
  const { classes } = useStyles();

  const {
    createBillingTable,
    createMonthPicker,
    billingData,
    order,
    orderBy,
    fromDate,
  } = useBillingTable({
    headCells: headCells,
    fetchBy: "hlinkResultReceivedAt",
    tableKey: "billing",
    defaultSort: "hlinkResultReceivedAt",
  });

  const createPDFPages = () => {
    const sorted = stableSort([...billingData], getComparator(order, orderBy));

    const chunked: BillingRecord[][] = [];
    const rowsPerChunk = 65;
    let chunkIndex = 0;

    //Split array into smaller arrays of rowsPerChunk length
    sorted.forEach((row, index) => {
      if (index !== 0 && index % rowsPerChunk === 0) chunkIndex++;
      if (chunked[chunkIndex] === undefined) chunked[chunkIndex] = [];
      chunked[chunkIndex].push(row);
    });

    return chunked.map((pageRows, index) => {
      return (
        <Grid
          container
          key={`billing_report_pdf_page_${index}`}
          spacing={2}
          style={{ position: "relative" }}
        >
          {index === 0 && (
            <>
              <img
                src="/Alethea LogoPDFWriter.png"
                width="10%"
                style={{ position: "absolute", top: 15, left: 15 }}
              />
              <Grid size={{ xs: 12 }}>
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  Billing Report {getMonthName(fromDate.getMonth())}{" "}
                  {fromDate.getFullYear()}
                </Typography>
              </Grid>
            </>
          )}
          <Grid size={{ xs: 12 }}>
            <TableContainer>
              <Table
                className={classes.pdfableTable}
                size={"small"}
                stickyHeader
              >
                <BillingTableHead
                  headCells={headCells}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
                />
                <TableBody>
                  {pageRows.map((data, index) => (
                    <BillingTableRow
                      key={`table_row_${index}`}
                      index={index}
                      headCells={headCells}
                      data={data}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Paper className={classes.container} variant="outlined">
      <Grid container justifyContent="space-between">
        <Grid size={{ xs: 12 }}>
          <span className={classes.title}>
            <Typography variant="h6" color="primary">
              Processed Billing Records
            </Typography>
          </span>
        </Grid>
        <Grid>{createMonthPicker()}</Grid>
        <Grid>
          {/* Keep buttons together */}
          <Grid container justifyContent="flex-start" spacing={1}>
            <Grid>
              <ExportBillingReportPDF pages={createPDFPages()} />
            </Grid>
            <Grid>
              <ExportBillingReportCSV
                billingData={stableSort(
                  billingData,
                  getComparator(order, orderBy),
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {createBillingTable()}
    </Paper>
  );
};

export default MonthlyBillingTable;
