import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";

import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";

import { FC } from "react";
import { Activity } from "../../../../../shared/types";

interface SecureMessagingToolbarProps {
  folder: Activity.UserActivityFolder;
  atLeastOneSelected: boolean;
  markAsUnread: () => void;
  moveFolder: (folder: Activity.UserActivityFolder) => void;
  onAnimationEnd?: () => void;
}

const SecureMessagingToolbar: FC<SecureMessagingToolbarProps> = ({
  folder,
  atLeastOneSelected,
  markAsUnread,
  moveFolder,
  onAnimationEnd,
}) => {
  return (
    <Collapse in={atLeastOneSelected} unmountOnExit onExited={onAnimationEnd}>
      <ButtonGroup>
        <Tooltip title="Mark as Unread">
          <Button
            color="primary"
            variant="outlined"
            onClick={markAsUnread}
            startIcon={<MarkunreadMailboxIcon />}
          >
            Unread
          </Button>
        </Tooltip>
        <Tooltip
          title={folder === "inbox" ? "Move to Archive" : "Move to Inbox"}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => {
              moveFolder(folder === "inbox" ? "archive" : "inbox");
            }}
            startIcon={folder === "inbox" ? <ArchiveIcon /> : <UnarchiveIcon />}
          >
            {folder === "inbox" ? "Archive" : "Unarchive"}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </Collapse>
  );
};

export default SecureMessagingToolbar;
