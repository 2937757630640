import { FC, useEffect, useRef, useState } from "react";

import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import useUploadMediaFile from "./useUploadMediaFile";

import { AletheaMedicalCapacitorCamera } from "@alethea-medical/capacitor-camera";

import { PluginListenerHandle } from "@capacitor/core";
import { makeStyles } from "tss-react/mui";
import isIOS from "../../../models/isIOS";

const useStyles = makeStyles()(() => ({
  button: {
    color: "white",
  },
}));
const MobileCameraButton: FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [uploadsRemaining, setUploadsRemaining] = useState(0);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");

  const { uploadFileFromMobileURL, processState } = useUploadMediaFile({
    updateRemainingUploads: setUploadsRemaining,
    mobileUploadErrorOccured: setUploadErrorMessage,
  });

  const openCameraHandler = () => {
    AletheaMedicalCapacitorCamera.launchCamera().catch((error: Error) => {
      alert(error.message);
    });
    // Deprecated. We not longer support USB camera for mobile
    // if (isIOS()) {
    //   AletheaMedicalCapacitorCamera.launchCamera().catch((error: Error) => {
    //     alert(error.message);
    //   });
    // } else {
    //   AletheaMedicalCapacitorCamera.launchUsbCamera()
    //     .then((usbResult) => {
    //       if (usbResult.status_code === 0) {
    //         if (usbResult.exit_code === "exit_no_device") {
    //           return AletheaMedicalCapacitorCamera.launchCamera();
    //         }
    //       }
    //     })
    //     .catch((error: Error) => {
    //       alert(error.message);
    //     });
    // }
  };

  const imageListenerRef = useRef<PluginListenerHandle>();
  const videoListenerRef = useRef<PluginListenerHandle>();
  const galleryListenerRef = useRef<PluginListenerHandle>();

  useEffect(() => {
    //Remove pre-existing listeners on mount (if they exist)
    removeListeners();

    //Add listeners for image and video captuer
    addListeners();

    //Remove listeners on component unmount
    return () => {
      removeListeners();
    };
  }, []);

  //Send upload finished event. Any components using the useMobileCameraListeners event will be notified (Gallery listens to refresh)
  useEffect(() => {
    if (uploadsRemaining === 0) {
      document.body.dispatchEvent(new Event("onMobileCameraUploadsFinished"));
    }
  }, [uploadsRemaining]);

  const addListeners = async () => {
    imageListenerRef.current = await AletheaMedicalCapacitorCamera.addListener(
      "onImageCaptured",
      (info) => {
        console.log("Received image capture event");
        return uploadFileFromMobileURL(info.url, "image");
      },
    );

    videoListenerRef.current = await AletheaMedicalCapacitorCamera.addListener(
      "onVideoCaptured",
      (info) => {
        return uploadFileFromMobileURL(info.url, "video");
      },
    );

    galleryListenerRef.current =
      await AletheaMedicalCapacitorCamera.addListener(
        "onGalleryPressed",
        () => {
          navigate("/dashboard/gallery");
        },
      );
  };

  const removeListeners = () => {
    if (imageListenerRef.current !== undefined)
      imageListenerRef.current.remove();
    if (videoListenerRef.current !== undefined)
      videoListenerRef.current.remove();
  };

  const isUploading = () => {
    return processState === ProcessState.running;
  };

  const getUploadingMessage = () => {
    return `${uploadsRemaining} upload${
      uploadsRemaining > 1 ? "s" : ""
    } remaining. Do not close the app.`;
  };

  const getProcessState = () => {
    if (uploadErrorMessage !== "" && !isUploading()) {
      return ProcessState.error;
    } else {
      return processState;
    }
  };

  return (
    <>
      <IconButton onClick={openCameraHandler} size="large">
        <PhotoCameraIcon className={classes.button} />
      </IconButton>
      {/* Show modal if you want to stop user from navigating during upload */}
      {/* <PaperModal show={isUploading()} flexHeight flexWidth>
            <ProcessStatus state={ProcessState.running} loadingMessage={getUploadingMessage()}/>
        </PaperModal> */}
      {/* Use Process state to display error messages. Allow user to close */}
      <ProcessStatus
        state={getProcessState()}
        setState={() => {
          setUploadErrorMessage("");
        }}
        errorMessage={uploadErrorMessage}
        useSnackbar
        loadingMessage={getUploadingMessage()}
      />
    </>
  );
};

export default MobileCameraButton;
