import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { getDocumentData } from "@alethea-medical/utilities";
import { useEffect, useState } from "react";
import { fbFirestore } from "../firebase";

class CMSData {
  private data: { [key: string]: any };
  private loaded: boolean;

  constructor(data: { [key: string]: any }, loaded = false) {
    this.data = data;
    this.loaded = loaded;
  }

  getArray<T>(key: string): T[] {
    if (this.data[key] !== undefined) {
      const array = this.data[key];
      if (Array.isArray(array)) {
        return array;
      }
    }

    return this.keyAccessErrorHandler<T[]>(key, []);
  }

  getValue<T>(key: string): T | undefined {
    if (this.data[key] !== undefined) {
      return this.data[key] as T;
    }
    return this.keyAccessErrorHandler<undefined>(key, undefined);
  }

  isLoaded(): boolean {
    return this.loaded;
  }

  private keyAccessErrorHandler<T>(key: string, returnValue: T): T {
    // If not loaded, then return undefined or empty
    if (!this.loaded) return returnValue;
    else {
      // If is loaded, then key does not exist. Throw error
      console.error(new Error(`Key ${key} does not exist in the cms`));
      return returnValue;
    }
  }
}

interface useCmsProps {
  cmsId: string;
}

const useCms = ({ cmsId }: useCmsProps) => {
  const [content, setContent] = useState<CMSData>(new CMSData({}));

  useEffect(() => {
    fbFirestore
      .collection(dbNames.cms)
      .doc(cmsId)
      .get()
      .then(getDocumentData)
      .then((data) => {
        setContent(new CMSData(data, true));
      });
  }, []);

  return content;
};

export default useCms;
