import React, { FC, useCallback, useContext, useState } from "react";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";

import EconsultCard from "./EconsultCard";

import {
  ProcessState,
  ProcessStatus,
  thinScrollbar,
  useProcessState,
} from "@alethea-medical/alethea-components";

import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  messageInputBox: {
    position: "relative",
    [`& fieldset`]: {
      borderRadius: "15px",
    },
  },
  inputProps: {
    minHeight: "20px",
  },
  iconButton: {
    marginRight: theme.spacing(1),
  },
  sendText: {
    marginLeft: theme.spacing(1),
    fontWeight: "bold",
  },
}));

const EconsultMessaging: FC = () => {
  const activityContext = useContext(ActivityContext);
  const { classes, cx } = useStyles();
  const [text, setText] = useState("");
  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
  } = useProcessState({
    logAnalyticsEvent,
  });

  const onSendPressed = useCallback(async () => {
    setProcessState(ProcessState.running);
    try {
      await fbFunctions.httpsCallable("fax-queueMOAEConsultFax")({
        messageToMOA: text,
        activityId: activityContext.activityId,
      });
      setText("");
      setProcessState(ProcessState.success);
    } catch (e: any) {
      setProcessErrorMessage(e?.message);
      setProcessState(ProcessState.error);
    }
  }, [text, activityContext.activityId, setProcessState, setText]);

  const keydownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      onSendPressed();
    }
  };

  const isDisabled = processState === ProcessState.running;

  return (
    <EconsultCard>
      <Grid size={{ xs: "grow" }}>
        <TextField
          className={cx(classes.thinScrollbar, classes.messageInputBox)}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={keydownHandler}
          variant="outlined"
          fullWidth
          multiline
          margin="dense"
          placeholder="Message to MOA (Optional)"
          InputProps={{ classes: { input: classes.inputProps } }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid container wrap="nowrap" alignItems="center" spacing={1}>
        <IconButton
          className={classes.iconButton}
          onClick={onSendPressed}
          disabled={isDisabled}
          size="large"
        >
          <SendIcon color={isDisabled ? undefined : "primary"} />
          <Typography
            variant="body2"
            color={isDisabled ? "textSecondary" : "primary"}
            className={classes.sendText}
          >
            Send
          </Typography>
        </IconButton>
      </Grid>
      <ProcessStatus
        state={processState}
        errorMessage={`Error sending fax: ${processErrorMessage}`}
        loadingMessage="Sending fax..."
        successMessage="Fax sent successfully!"
      />
    </EconsultCard>
  );
};

export default EconsultMessaging;
