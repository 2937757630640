import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../AuthProvider";
import hasPermissions from "../../models/hasPermissions";

interface UsePermissionsProps {
  resourceKeys: string[];
  onPermissionFetched?: (permissionDict: { [key: string]: boolean }) => void;
  setLoadingPermissions?: (val: boolean) => void;
}

export const multiPermissionsSatisfies = (
  permissionDict: { [key: string]: boolean },
  requiredResourcePermissions: string[][],
) =>
  requiredResourcePermissions.some((resourcePermissions) =>
    resourcePermissions.every((resource) => permissionDict[resource]),
  );

const useMultiPermissions = ({
  resourceKeys,
  onPermissionFetched,
  setLoadingPermissions,
}: UsePermissionsProps) => {
  const authContext = useContext(AuthContext);

  const [permissionDict, setPermissionDict] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (authContext.authenticated && authContext.profile !== undefined) {
      const newPermissionDict: { [key: string]: boolean } = {};
      Promise.all(
        resourceKeys.map((key) => {
          return hasPermissions(key, authContext.profile).then((granted) => {
            newPermissionDict[key] = granted;
          });
        }),
      )
        .then(() => {
          setPermissionDict(newPermissionDict);
          onPermissionFetched?.(newPermissionDict);
        })
        .catch((error: Error) => {
          console.error(error);
          // Fall back to set granted to false if error occurs
          resourceKeys.forEach((key) => {
            newPermissionDict[key] = false;
          });
          setPermissionDict(newPermissionDict);
        });
    } else {
      setPermissionDict({});
    }
    setLoadingPermissions?.(false);
  }, [authContext.profile, authContext.authenticated]);

  return {
    permissionDict,
  };
};

export default useMultiPermissions;
