import { ErrorHandlerOptions } from "@alethea-medical/alethea-components";
import { useEffect, useState } from "react";
import { Activity } from "../../../../../../shared/types";
import analyticsLogs from "../../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../../firebase";
import { ActivityDict, ActivityItem } from "../../types";
import ActivitiesListModel from "../Models/ActivitiesListModel";

interface ActivitiesListControllerProps {
  activitiesListModel: ActivitiesListModel;
  activities: ActivityDict<Activity.UserActivity>;
  updateActivitiesInState: (
    newActivities: ActivityItem<Activity.UserActivity>[],
  ) => void;
  removeActivitiesFromState: (activityIds: string[]) => void;
  unselectAllHandler: () => void;
  errorHandler: (options: ErrorHandlerOptions) => void;
}

export interface ActivitiesListControllerFields {
  moveFolderHandler: (
    activityIds: string[],
    folder: Activity.UserActivityFolder,
  ) => Promise<void>;
  undoMoveFolderHandler: () => Promise<void>;
  markAsReadHandler: (activityId: string) => Promise<boolean>;
  markAsUnreadHandler: (activityIds: string[]) => Promise<void>;
  showUndoSnackbar: boolean;
  setShowUndoSnackbar: (show: boolean) => void;
  showMarkedAsUnreadSnackbar: boolean;
  setShowMarkAsUnreadSnackbar: (show: boolean) => void;
  moveSnackbarFolderAction: string;
}

/**
 * This component handles marking activities as read, unread, and moving folders
 * Only useable with UserActivities and SharedActivity
 */
const ActivitiesListController = ({
  activitiesListModel,
  activities,
  updateActivitiesInState,
  removeActivitiesFromState,
  unselectAllHandler,
  errorHandler,
}: ActivitiesListControllerProps): ActivitiesListControllerFields => {
  const [undoActivities, setUndoActivities] = useState<
    ActivityItem<Activity.UserActivity>[]
  >([]);
  const [showUndoSnackbar, setShowUndoSnackbar] = useState<boolean>(false);
  const [showMarkedAsUnreadSnackbar, setShowMarkAsUnreadSnackbar] =
    useState<boolean>(false);
  const [moveSnackbarFolderAction, setMoveSnackbarFolder] =
    useState<string>("");
  const [queueMarkAsRead, setQueueMarkAsRead] = useState<string>();

  // Marks activity as read. If it does not yet exist in the list state, then queue it to be marked as read when it is added.
  const markAsReadHandler = (activityId: string): Promise<boolean> => {
    if (activities[activityId] === undefined) {
      setQueueMarkAsRead(activityId);
      return Promise.resolve(false);
    } else {
      return activitiesListModel
        .updateDatabaseActivityRead({ ...activities[activityId] })
        .then((newActivity) => {
          logAnalyticsEvent(analyticsLogs.secureMessaging.selectActivity);
          updateActivitiesInState([newActivity]);
          return true;
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error updating message read status",
          });
          return false;
        });
    }
  };

  // Retry marking as read if it was queued once activities updates
  useEffect(() => {
    if (queueMarkAsRead !== undefined) {
      markAsReadHandler(queueMarkAsRead).then((updateSuccess) => {
        if (updateSuccess) setQueueMarkAsRead(undefined);
      });
    }
  }, [activities]);

  //Mark message as unread by current user in state and in the firestore
  const markAsUnreadHandler = (activityIds: string[]): Promise<void> => {
    return activitiesListModel
      .updateDatabaseActivityUnread(
        activityIds.map((activityId) => activities[activityId]),
      )
      .then((newActivities) => {
        logAnalyticsEvent(analyticsLogs.secureMessaging.markUnread);

        setShowMarkAsUnreadSnackbar(true);
        unselectAllHandler();
        updateActivitiesInState(newActivities);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error marking message as unread",
        });
      });
  };

  //Called when user presses archive button
  const moveFolderHandler = async (
    activityIds: string[],
    folder: Activity.UserActivityFolder,
  ): Promise<void> => {
    setShowUndoSnackbar(false);
    //This function modifies activities in-place
    try {
      const newUndoActivities =
        await activitiesListModel.updateDatabaseMoveFolder(
          activityIds.map((activityId) => activities[activityId]),
          folder,
        );
      switch (folder) {
        case "inbox":
          logAnalyticsEvent(analyticsLogs.secureMessaging.move.inbox);
          break;
        case "archive":
          logAnalyticsEvent(analyticsLogs.secureMessaging.move.archive);
          break;
      }

      setMoveSnackbarFolder(folder);
      setShowUndoSnackbar(true);

      removeActivitiesFromState(activityIds);

      setUndoActivities(newUndoActivities);
      unselectAllHandler();
    } catch (error) {
      errorHandler({
        error: error as Error | undefined,
        userMessage: `Error moving message to ${folder}`,
      });
    }
  };

  const undoMoveFolderHandler = () => {
    return activitiesListModel
      .updateDatabaseUndoMoveFolder(undoActivities)
      .then(() => {
        logAnalyticsEvent(analyticsLogs.secureMessaging.move.undo);
        setShowUndoSnackbar(false);
        updateActivitiesInState(undoActivities);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: `Error undoing the previous action`,
        });
      });
  };

  return {
    moveFolderHandler,
    undoMoveFolderHandler,
    markAsReadHandler,
    markAsUnreadHandler,

    showUndoSnackbar,
    setShowUndoSnackbar,
    showMarkedAsUnreadSnackbar,
    setShowMarkAsUnreadSnackbar,
    moveSnackbarFolderAction,
  };
};

export default ActivitiesListController;
