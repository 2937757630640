import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../types";
import FormProvinceSelect from "./FormProvinceSelect";

interface TypedFormProvinceSelectProps<T extends FieldValues>
  extends TypedControlledInputProps<T> {
  enabledOptions?: string[];
  sortByEnabled?: boolean;
}

function TypedFormProvinceSelect<T extends FieldValues>(
  props: TypedFormProvinceSelectProps<T>,
) {
  return (
    <FormProvinceSelect
      {...props}
      control={props.control as any}
      name={props.name as string}
    />
  );
}

export default TypedFormProvinceSelect;
