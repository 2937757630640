import { SidebarItem } from "@alethea-medical/alethea-components";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "src/AuthProvider";
import { routes } from "src/routes/routesData";

interface LogoutButtonProps {
  showText: boolean;
}
const LogoutButton: FC<LogoutButtonProps> = ({ showText }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const handleClick = () => {
    authContext.signOut().then(() => {
      navigate(routes.login.path);
    });
  };

  return (
    <SidebarItem
      label="Logout"
      showText={showText}
      icon={<ExitToAppIcon color="primary" />}
      onClick={handleClick}
      {...{ divider: true }}
    />
  );
};

export default LogoutButton;
