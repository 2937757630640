import { fbFunctions } from "../../../../firebase";
import { downloadBlob } from "../../../../utils/download-utils";

type ConsultDraftsCollectionType = "user_drafts" | "drafts_pending_approval";

const isConsultDraftsCollectionType = (
  value: any, // eslint-disable-line @typescript-eslint/no-explicit-any -- This is a type guard
): value is ConsultDraftsCollectionType => {
  return value === "user_drafts" || value === "drafts_pending_approval";
};

/* eslint-disable @typescript-eslint/no-explicit-any -- This uses a type guard */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const getAsConsultDraftsCollectionType = (
  value: any,
): ConsultDraftsCollectionType => {
  if (isConsultDraftsCollectionType(value)) {
    return value;
  }
  return "user_drafts";
};
/** eslint-enable @typescript-eslint/no-explicit-any */
/** eslint-enable @typescript-eslint/explicit-module-boundary-types */

async function exportPDF(activityId: string): Promise<void> {
  const result = await fbFunctions.httpsCallable(
    "secureMessaging-exportEconsultPdf",
  )({ activityId });
  downloadBlob(result.data, "econsult_export.pdf", "application/pdf");
}

export async function exportDraftPDF(
  consultDraftsCollectionType: ConsultDraftsCollectionType,
  draftId: string,
  subject: string,
): Promise<void> {
  const result = await fbFunctions.httpsCallable(
    "secureMessaging-exportEconsultDraftPdf",
  )({ consultDraftsCollectionType, draftId });
  downloadBlob(result.data, `draft_${subject}.pdf`, "application/pdf");
}

export default exportPDF;
