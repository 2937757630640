import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import queryString from "query-string";
import { FC, useEffect } from "react";
import { Control } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Activity } from "../../../../../shared/types";
import analyticsLogs from "../../../../analyticsLogs";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";
import formatPhone from "../../../../models/formatPhone";
import { PatientInfoWithFormattedDoB } from "../ConsultFormController";

interface AvaEconsultEndpointProps {
  control: Control<Activity.ConsultFormFields>;
  onAddEmrMedicalHistory: (medicalHistory: string) => void;
  onEmrPatientInfo: (newPatientInfo: PatientInfoWithFormattedDoB) => void;
  onEmrLink: (consultId: string, uuid: string) => void;
}

const AvaEconsultEndpoint: FC<AvaEconsultEndpointProps> = ({
  onAddEmrMedicalHistory,
  onEmrPatientInfo,
  onEmrLink,
}) => {
  const location = useLocation();

  const avaEconsultAuth = fbFunctions.httpsCallable("ava-econsultAuth");

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.jwt) {
      setProcessState(ProcessState.running);
      avaEconsultAuth({ jwt: params.jwt })
        // For testing
        // Promise.resolve({
        //     data: {
        //         patientInfo: {
        //             firstName: "Jeff",
        //             lastName: "Jefferson",
        //             email: "jeff@example.com",
        //             phone: "(403) 295-0000",
        //             phn: "000060002",
        //             dateOfBirth: "1999/10/25"
        //         },
        //         avaConsultId: "test_consult_id",
        //         avaUuid: "test_uuid",
        //         patientHistory: "Patient history blah\nblah\nblah"
        //     }
        // })
        .then(({ data }) => {
          logAnalyticsEvent(analyticsLogs.ava.consult.open);

          onEmrPatientInfo({
            firstName: data.patientInfo?.firstName ?? "",
            lastName: data.patientInfo?.lastName ?? "",
            email: data.patientInfo?.email ?? "",
            phone: formatPhone(data.patientInfo?.phone),
            phn: data.patientInfo?.phn ?? "",
            dateOfBirthYYYYMMDD: data.patientInfo?.dateOfBirth, // Pass formatted date of birth
          });
          onEmrLink(data.avaConsultId, data.avaUuid);

          onAddEmrMedicalHistory(data.patientHistory);

          setProcessState(ProcessState.success);
          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 2000);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error fetching AVA econsult info",
            analyticsLog: analyticsLogs.ava.consult.openFail,
          });
        });
    }
  }, [location.search]);

  return (
    <ProcessStatus
      state={processState}
      errorMessage={processErrorMessage}
      successMessage="Successfully fetched AVA econsult info"
      loadingMessage="Fetching AVA econsult info"
      hideProgressBar
      useSnackbar
    />
  );
};

export default AvaEconsultEndpoint;
