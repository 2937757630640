import { Grid2 as Grid, Paper, Theme, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import EarningTrends from "./EarningTrends";

export interface DatasetsChart {
  label: string;
  data: number[];
  fill?: boolean;
  borderColor?: string;
  backgroundColor: string[];
  type?: string;
  borderWidth?: number;
  order?: number;
}
export interface DataChart {
  labels: string[];
  datasets: DatasetsChart[];
}

interface ChartPanelProps {
  chartLabels: string[];
  chartEarningCommunications: number[];
  chartEarningEconsult: number[];
  chartEarningTotal: number[];
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
}));

export const ChartPanel: FC<ChartPanelProps> = ({
  chartLabels,
  chartEarningCommunications,
  chartEarningEconsult,
  chartEarningTotal,
}) => {
  const { classes } = useStyles();

  const [dataChart, setDataChart] = useState<DataChart>({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    getChartData();
  }, [
    chartLabels,
    chartEarningCommunications,
    chartEarningEconsult,
    chartEarningTotal,
  ]);

  const getChartData = () => {
    const chart: DataChart = {
      labels: chartLabels,
      datasets: [
        {
          label: "Consult Earnings",
          data: chartEarningEconsult,
          backgroundColor: ["#86d0c8"],
          borderWidth: 1,
          order: 1,
        },
        {
          label: "Patient Communications Earnings",
          data: chartEarningCommunications,

          backgroundColor: ["#05707d"],
          borderWidth: 1,
          order: 1,
        },
        {
          label: "Total Earning",
          data: chartEarningTotal,
          fill: false,
          borderColor: "#f36c24",
          backgroundColor: ["#f36c24"],
          type: "line",
          order: 0,
        },
      ],
    };
    setDataChart(chart);
  };

  return (
    <Paper className={classes.container} variant="outlined">
      <Grid container direction="column" alignItems="center" spacing={1}>
        <Grid>
          <Typography variant="h6" color="primary">
            Earning Trends
          </Typography>
        </Grid>
        <Grid>
          <EarningTrends data={dataChart} />
        </Grid>
      </Grid>
    </Paper>
  );
};
