import DraftsIcon from "@mui/icons-material/Drafts";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Fcm } from "../../../../../shared/types";
import MessageListItem from "../../../../components/MessageListItem";
import { formatTimeLong } from "../../../../models/formatTime";
import palette from "../../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  labelContainer: {
    padding: theme.spacing(1, 1, 0, 1),
  },
  iconRead: {
    color: palette.darkGreen,
  },
  iconUnread: {
    color: palette.orange,
  },
  timestamp: {
    whiteSpace: "nowrap",
  },
}));

interface NotificationListItemProps {
  openNotification: (id: string) => void;
  id: string;
  notification: Fcm.UserNotification;
}

const NotificationListItem: FC<NotificationListItemProps> = ({
  openNotification,
  id,
  notification,
}) => {
  const { classes, cx } = useStyles();

  return (
    <MessageListItem
      openMessage={openNotification}
      id={id}
      read={notification.read}
      labelComponent={
        <div className={classes.labelContainer}>
          {notification.read && (
            <DraftsIcon
              className={cx({
                [classes.iconRead]: notification.read,
                [classes.iconUnread]: !notification.read,
              })}
            />
          )}
          {!notification.read && (
            <MarkunreadIcon
              className={cx({
                [classes.iconRead]: notification.read,
                [classes.iconUnread]: !notification.read,
              })}
            />
          )}
        </div>
      }
      primary={notification.title}
    >
      <Typography className={classes.timestamp}>
        {" "}
        {formatTimeLong(notification.sentAt.toDate())}
      </Typography>
    </MessageListItem>
  );
};

export default NotificationListItem;
