import { SidebarItem } from "@alethea-medical/alethea-components";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { FC, useState } from "react";
import InviteAColleague from "./InviteAColleague";

interface InviteAColleagueButtonProps {
  showText: boolean;
}

const InviteAColleagueButton: FC<InviteAColleagueButtonProps> = ({
  showText,
}) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <SidebarItem
        label="Invite a Colleague"
        onClick={() => setShow(true)}
        showText={showText}
        icon={<EmojiPeopleIcon color="primary" />}
        {...{ divider: true }}
      />
      {show && (
        // Don't mount until show is true (saves on resources)
        <InviteAColleague show={show} setShow={setShow} />
      )}
    </>
  );
};

export default InviteAColleagueButton;
