import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { CSSProperties, FC, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  statusPaper: {
    color: palette.darkGreen,
    borderStyle: "solid",
    borderColor: palette.darkGreenLight,
    borderWidth: "1px",
    backgroundColor: "transparent",
    borderRadius: "4px",
    height: "36.02px",
    padding: theme.spacing(0, 1),
  },
  clickable: {
    cursor: "pointer",
  },
}));

interface StatusPaperProps {
  icon?: ReactNode;
  endIcon?: ReactNode;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}

const StatusPaper: FC<StatusPaperProps> = ({
  icon,
  endIcon,
  style,
  className,
  onClick,
  children,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Grid
      container
      className={cx(
        {
          [classes.clickable]: !!onClick,
        },
        className,
        classes.statusPaper,
      )}
      onClick={onClick}
      style={style}
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      {icon && <Grid>{icon}</Grid>}
      <Grid>{children}</Grid>
      {endIcon && (
        <Grid container alignItems="center">
          {endIcon}
        </Grid>
      )}
    </Grid>
  );
};

export default StatusPaper;
