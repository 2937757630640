import { useContext, useEffect } from "react";
import { FormTemplate } from "../../../../shared/types";
import { AuthContext } from "../../../AuthProvider";
import { fbFirestore } from "../../../firebase";
import { FormListItem } from "./FormsSelect";

/*a form has to be private and access must be granted in order for a user to be able to use and see it */
export function useAvailableForms(
  setValue: (name: string, value: any) => void,
  setFormList: (formList: FormListItem[]) => void,
) {
  const authContext = useContext(AuthContext);

  //Get forms from user profile
  useEffect(() => {
    //retrieve all non private forms that are viewable by everyone with no restrictions
    fbFirestore
      .collection("form_templates")
      .where("isPrivate", "==", false)
      .get()
      .then((snapshot) => {
        const newForms = snapshot.docs.map((doc) => {
          const form = doc.data();
          return {
            name: form.name,
            uid: doc.id,
            favoritedStatus: "Forms",
          };
        });
        //get the list that specifies which forms are private and viewable to the current user

        return fbFirestore
          .collection("form_users")
          .doc(authContext.uid)
          .get()
          .then((snapshot) => {
            if (snapshot.exists) {
              const restrictedUserForms = snapshot.data() as {
                privateForms: string[];
                favoriteForms: string[];
              };

              if (restrictedUserForms?.privateForms !== undefined) {
                const promises = restrictedUserForms.privateForms.map(
                  (formId: string) => {
                    return fbFirestore
                      .collection("form_templates")
                      .doc(formId)
                      .get()
                      .then((snapshot) => {
                        const form = snapshot.data() as FormTemplate;
                        return {
                          name: form.name,
                          uid: snapshot.id,
                          favoritedStatus: "Forms",
                        };
                      });
                  },
                );
                return Promise.all(promises);
              }
            }
            return [];
          })
          .then((promisedForms) => {
            const listOfForms = newForms.concat(promisedForms);
            const uniq = new Set(listOfForms.map((e) => JSON.stringify(e)));
            const accessableForms = Array.from(uniq).map((e) => JSON.parse(e));
            //set the forms that are accessable to the users given restrictions specified in db
            setFormList(accessableForms);
            setValue("selectedForm", undefined);
          });
      })

      .catch((error) => {
        console.log("Error getting document: ", error);
      });
  }, [authContext.uid]);
}
