import { UserProfile } from "@alethea-medical/aletheamd-types";
import { fbFirestore } from "../firebase";

const getProfile = (
  uid: string | undefined,
): Promise<UserProfile | undefined> => {
  if (uid === undefined) {
    return Promise.resolve(undefined);
  }
  return fbFirestore
    .collection("users")
    .doc(uid)
    .get()
    .then((doc) => {
      if (doc.exists && doc.data()) {
        return Promise.resolve(doc.data() as UserProfile);
      } else {
        Promise.reject("No such document!");
      }
    })
    .catch((error) => {
      console.log("Error getting document: ", error);
      return Promise.resolve(undefined);
    });
};
export default getProfile;
