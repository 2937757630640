import { PaperModal } from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
}));
interface ConfirmDeleteButtonProps {
  numToDelete: number;
  deleteHandler: () => void;
}

const ConfirmDeleteButton: FC<ConfirmDeleteButtonProps> = ({
  numToDelete,
  deleteHandler,
}) => {
  const { classes } = useStyles();

  const [show, setShow] = useState(false);

  const showConfirmationHandler = () => {
    setShow(true);
  };

  const handleDelete = () => {
    deleteHandler();
    setShow(false);
  };

  return (
    <>
      <PaperModal
        show={show}
        setShow={setShow}
        enablePadding
        flexHeight
        flexWidth
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Typography>
              Delete {numToDelete} file{numToDelete > 1 ? "s" : ""}?
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              variant="contained"
              className={classes.dangerButton}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </PaperModal>
      <Button
        variant="contained"
        className={classes.dangerButton}
        onClick={showConfirmationHandler}
      >
        Delete Selected
      </Button>
    </>
  );
};

export default ConfirmDeleteButton;
