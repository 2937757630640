import { ReactElement } from "react";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import PermissionRoute from "src/components/PermissionRoute";
import { type RouteName, routes } from "./routesData";
import { type Route } from "./types";

const LegacyRouteRedirect = ({ to }: { to: string }) => {
  const { search } = useLocation();
  return <Navigate replace to={`${to}${search}`} />;
};

// The reason for splitting up helpers into internalHelpers and helpers is to solve a circular dependency issue.

/**
 * Builds a list of routes from a redirect object to redirect legacy routes to new routes
 * @param routeRedirects Object where keys are the legacy route paths and values are the new route names
 * @returns List of routes to redirect legacy routes to new routes
 */
export function createLegacyRouteRedirects(routeRedirects: {
  [redirectFrom: string]: RouteName;
}): RouteObject[] {
  return Object.keys(routeRedirects).map((from) => {
    const to = routeRedirects[from];
    return {
      path: from,
      element: <LegacyRouteRedirect to={routes[to].path} />,
    };
  });
}

/**
 * Create new object from the childPathsObj with /${parentRoute}/ prepended to each path
 * @param childPathsObj Object of child paths to add parent path to
 * @param childPathNamesObj Object whose values are the keys of the childPathsObj for iteration
 * @param parentRoute Parent route to prepend to each child path
 */
export function addParentPathToChildPaths<T extends string>(
  childPathsObj: { [key in T]: Route },
  childPathNamesObj: { [key: string]: T },
  parentRoute: string,
): { [key in T]: Route } {
  // Remove leading and trailing slashes from a path
  function removeLeadingTrailingSlashes(path: string) {
    return path.replace(/^\/+|\/+$/g, "");
  }

  // Join two paths with a slashes
  // "/path1/path2"
  function joinPaths(path1: string, path2: string): string {
    const path1WithoutSlash = removeLeadingTrailingSlashes(path1);
    const path2WithoutSlash = removeLeadingTrailingSlashes(path2);
    if (path1WithoutSlash === "") {
      return `/${path2WithoutSlash}`;
    }
    return `/${path1WithoutSlash}/${path2WithoutSlash}`;
  }

  return Object.values(childPathNamesObj).reduce(
    (acc, key) => {
      const route = childPathsObj[key];
      acc[key] = {
        ...route,
        path: joinPaths(parentRoute, route.path),
      };
      return acc;
    },
    {} as {
      [key in T]: Route;
    },
  );
}

/**
 * Builds route object for a parent route with children
 * @param childPathsObj Object of child paths to add parent path to
 * @param childRouteElements Object of child route elements
 * @param childPathNamesObj Object whose values are the keys of the childPathsObj for iteration
 * @param parentPath Parent path for the route
 * @param {ReactElement} [parentPathElement] Optional element for the parent route
 * @param {boolean} [isPermissionControlled] Whether the parent route should be permission controlled (defaults to false)
 * @param {T} [redirectIndexPathName] Optional name of the child route to redirect to when the parent route is accessed
 */
export function buildRoute<T extends string>(
  childPathsObj: { [key in T]: Route },
  childRouteElements: { [key in T]: ReactElement },
  childPathNamesObj: { [key: string]: T },
  parentPath: string,
  parentPathElement?: ReactElement,
  isPermissionControlled: boolean = false,
  redirectIndexPathName?: T,
): RouteObject {
  const childrenPaths = [
    ...Object.values(childPathNamesObj).map((routeName) => {
      const route = childPathsObj[routeName];
      const element = childRouteElements[routeName];
      return {
        ...route,
        path: `${route.path}`,
        element: isPermissionControlled ? (
          <PermissionRoute route={route}>{element}</PermissionRoute>
        ) : (
          element
        ),
      };
    }),
  ];

  const indexPath = redirectIndexPathName && {
    index: true,
    element: (
      <Navigate replace to={`${childPathsObj[redirectIndexPathName].path}`} />
    ),
  };

  const children = indexPath ? [indexPath, ...childrenPaths] : childrenPaths;
  return {
    path: parentPath,
    element: parentPathElement,
    children,
  };
}
