import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  gridItem: {
    textAlign: "center",
  },
}));

type LoginLoadingPageProps = {
  message: string;
};

const LoginLoadingPage: FC<LoginLoadingPageProps> = ({ message }) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid size={{ xs: 6, md: 4 }} className={classes.gridItem}>
        <img
          src="/Alethea Logo.png"
          className={classes.imgFluid}
          alt="Alethea Logo"
        />
      </Grid>
      <Grid size={{ xs: 12 }} className={classes.gridItem}>
        <Typography variant="h5">{message}</Typography>
      </Grid>
      <Grid size={{ xs: 6 }} className={classes.gridItem}>
        <CircularProgress variant="indeterminate" />
      </Grid>
    </Grid>
  );
};

export default LoginLoadingPage;
