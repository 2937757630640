import { Capacitor } from "@capacitor/core";

/**
 * Returns true if application is running on web
 *
 * Will return true if platform is mobile, but in web browser
 */
function isWeb() {
  return Capacitor.getPlatform() === "web";
}

export default isWeb;
