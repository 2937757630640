import ArchiveIcon from "@mui/icons-material/Archive";
import AttachmentIcon from "@mui/icons-material/Attachment";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

import { useMobileSizes } from "@alethea-medical/alethea-components";
import SendIcon from "@mui/icons-material/Send";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { PatientMessageFolder } from "../../../../shared/types";
import ToggleButton from "../../../components/ToggleButton";

const useStyles = makeStyles()((theme: Theme) => ({
  iconButton: {
    marginRight: theme.spacing(1),
  },
}));

interface PatientMessageToolbarProps {
  folder: PatientMessageFolder;
  atLeastOneSelected: boolean;
  markAsUnread: () => void;
  moveFolder: (folder: PatientMessageFolder) => void;
  showAttachments?: boolean;
  onShowAttachments?: () => void;
}

const PatientMessageToolbar: FC<PatientMessageToolbarProps> = ({
  folder,
  atLeastOneSelected,
  markAsUnread,
  moveFolder,
  showAttachments,
  onShowAttachments,
}) => {
  const { classes, cx } = useStyles();
  const isMobileSize = useMobileSizes();

  return (
    <>
      <Collapse in={atLeastOneSelected} unmountOnExit>
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <Grid>
            <ButtonGroup>
              <Tooltip title="Mark as unread">
                <span>
                  {/* Buttons are wrapped in a span so the tooltip can always display, when when button is disabled */}
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={markAsUnread}
                    disabled={!atLeastOneSelected}
                  >
                    <MarkunreadMailboxIcon
                      className={cx({ [classes.iconButton]: !isMobileSize })}
                    />
                    {!isMobileSize && "Unread"}
                  </Button>
                </span>
              </Tooltip>
            </ButtonGroup>
          </Grid>
          <Grid>
            <ButtonGroup>
              <Tooltip title={"Move to Inbox"}>
                <span>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      moveFolder("inbox");
                    }}
                    disabled={folder === "inbox" || !atLeastOneSelected}
                  >
                    {
                      <MoveToInboxIcon
                        className={cx({
                          [classes.iconButton]: !isMobileSize,
                        })}
                      />
                    }
                    {!isMobileSize && "Inbox"}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={"Move to Sent"}>
                <span>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      moveFolder("sent");
                    }}
                    disabled={folder === "sent" || !atLeastOneSelected}
                  >
                    {
                      <SendIcon
                        className={cx({
                          [classes.iconButton]: !isMobileSize,
                        })}
                      />
                    }
                    {!isMobileSize && "Sent"}
                  </Button>
                </span>
              </Tooltip>
              <Tooltip title={"Move to Archive"}>
                <span>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => {
                      moveFolder("archive");
                    }}
                    disabled={folder === "archive" || !atLeastOneSelected}
                  >
                    {
                      <ArchiveIcon
                        className={cx({
                          [classes.iconButton]: !isMobileSize,
                        })}
                      />
                    }
                    {!isMobileSize && "Archive"}
                  </Button>
                </span>
              </Tooltip>
            </ButtonGroup>
          </Grid>
          {onShowAttachments &&
            showAttachments !== undefined &&
            isMobileSize && (
              <Grid>
                <ToggleButton
                  toggleState={showAttachments}
                  onClick={onShowAttachments}
                >
                  <AttachmentIcon className={classes.iconButton} />
                </ToggleButton>
              </Grid>
            )}
        </Grid>
      </Collapse>
    </>
  );
};

export default PatientMessageToolbar;
