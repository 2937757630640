import { PaperModal } from "@alethea-medical/alethea-components";
import PaymentIcon from "@mui/icons-material/Payment";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { DropdownMenuItem } from "../../../components/DropdownMenuButton/DropdownMenuButton";
import AveiroPaymentPage from "./AveiroPaymentPage";

const useStyles = makeStyles()(() => ({
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

interface AveiroPaymentModalProps {
  activityId: string;
  variant: "button" | "dropdown";
}

const AveiroPaymentButton: FC<AveiroPaymentModalProps> = ({
  activityId,
  variant,
}) => {
  const { classes } = useStyles();

  const [show, setShow] = useState(false);

  const openModalHandler = () => {
    setShow(true);
  };

  return (
    <>
      {variant === "button" && (
        <Tooltip title="Make a Payment">
          <Button
            onClick={openModalHandler}
            color="primary"
            variant="outlined"
            startIcon={<PaymentIcon />}
          >
            Payment
          </Button>
        </Tooltip>
      )}
      {variant === "dropdown" && (
        <DropdownMenuItem
          label="Payment"
          icon={<PaymentIcon color="primary" />}
          onClick={openModalHandler}
          color="primary"
        />
      )}

      <PaperModal
        className={classes.modal}
        show={show}
        setShow={setShow}
        enablePadding
        flexHeight
      >
        {show && <AveiroPaymentPage activityId={activityId} />}
      </PaperModal>
    </>
  );
};

export default AveiroPaymentButton;
