import Button from "@mui/material/Button";
import { FC } from "react";
import { ImageAnnotationSelectedDict } from "./EconsultImageAnnotationModel";

interface IgnoreImagesButtonProps {
  ignoreHandler: (items: ImageAnnotationSelectedDict) => void;
  unselectAllHandler: () => void;
  selectedMedia: ImageAnnotationSelectedDict;
}

const IgnoreImagesButton: FC<IgnoreImagesButtonProps> = ({
  ignoreHandler,
  unselectAllHandler,
  selectedMedia,
}) => {
  const handleIgnore = () => {
    ignoreHandler(selectedMedia);
    unselectAllHandler();
  };
  return (
    <Button
      fullWidth
      variant="contained"
      color="primary"
      onClick={handleIgnore}
      disabled={Object.keys(selectedMedia).length === 0}
    >
      Ignore
    </Button>
  );
};

export default IgnoreImagesButton;
