import { Add, Close } from "@mui/icons-material";
import {
  Button,
  Grid2 as Grid,
  IconButton,
  Paper,
  TextField,
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { FC, useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  addButton: {
    justifyContent: "flex-start",
  },
  newResourcePaper: {
    padding: theme.spacing(1),
  },
}));

interface AddResourceButtonProps {
  addResourceHandler: (name: string) => void;
}

const AddResourceButton: FC<AddResourceButtonProps> = ({
  addResourceHandler,
}) => {
  const { classes } = useStyles();
  const [resourceName, setResourceName] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const handleAddClicked = () => {
    setShowTextField(true);
  };

  // When enter is pressed or "create" is clicked
  const handleSubmit = () => {
    if (resourceName === "") return;

    addResourceHandler(resourceName);
    closeTextField();
  };

  // Listen for enter key press to submit
  const onKeyPress = (e: { keyCode: number }) => {
    if (e.keyCode == 13) {
      handleSubmit();
    }
  };

  const closeTextField = () => {
    setShowTextField(false);
    setResourceName("");
  };

  // Automatically focus textfield when textfield shows
  useEffect(() => {
    if (showTextField && textFieldRef.current !== null) {
      textFieldRef.current.focus();
    }
  }, [showTextField]);

  return (
    <>
      {!showTextField && (
        <Button
          className={classes.addButton}
          variant="text"
          color="primary"
          onClick={handleAddClicked}
          startIcon={<Add />}
          fullWidth
        >
          New Resource
        </Button>
      )}
      {showTextField && (
        <Paper className={classes.newResourcePaper}>
          <Grid container alignItems="center" spacing={1}>
            <Grid size={{ xs: "grow" }}>
              <TextField
                label="Resource Name"
                variant="outlined"
                fullWidth
                margin="dense"
                inputRef={(input) => {
                  textFieldRef.current = input;
                }}
                value={resourceName}
                onChange={(e) => setResourceName(e.target.value as string)}
                onKeyDown={onKeyPress}
              />
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={resourceName === ""}
              >
                Create
              </Button>
            </Grid>
            <Grid>
              <IconButton onClick={closeTextField}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default AddResourceButton;
