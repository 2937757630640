import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../palette";
import useConfirmDestructiveModal from "../useConfirmDestructiveModal";

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: palette.orange,
    color: "white",
  },
  white: {
    color: "white",
  },
}));

interface SaveDiscardToolbarProps {
  show: boolean;
  modalText: string;
  saveHandler: () => void;
  discardHandler: () => void;
  unsavedText?: string;
  className?: string;
}

const SaveDiscardToolbar: FC<SaveDiscardToolbarProps> = ({
  show,
  modalText,
  saveHandler,
  discardHandler,
  unsavedText = "Unsaved Changes",
  className,
}) => {
  const { classes, cx } = useStyles();
  const { ConfirmDestructiveModal, showConfirmDestructiveModal } =
    useConfirmDestructiveModal({
      confirmText: modalText,
      confirmButtonText: "Discard",
      action: discardHandler,
    });

  return (
    <Collapse in={show}>
      <Paper className={cx(className, classes.paper)}>
        <Grid container alignItems="center" spacing={1}>
          <Grid>
            <Typography>{unsavedText}</Typography>
          </Grid>
          <Grid>
            {/* Put in container so save and discard on same line when line breaks */}
            <Grid container alignItems="center" spacing={1}>
              <Grid>
                <Button
                  className={classes.white}
                  onClick={saveHandler}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Grid>
              <Grid>
                <Button
                  className={classes.white}
                  onClick={showConfirmDestructiveModal}
                  startIcon={<ClearIcon />}
                >
                  Discard
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      {ConfirmDestructiveModal}
    </Collapse>
  );
};

export default SaveDiscardToolbar;
