import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Service } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";
import { fetchServiceForm } from "../ConsultFormFunctions";

interface useServiceFormProps {
  serviceId: string;
}

const useServiceForm = ({ serviceId }: useServiceFormProps) => {
  const [serviceForm, setServiceForm] = useState<
    Service.ServiceForm | undefined
  >(undefined);
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  useEffect(() => {
    if (serviceId) {
      setProcessState(ProcessState.running);
      fetchServiceForm(serviceId)
        .then((serviceForm) => {
          setProcessState(ProcessState.idle);
          setServiceForm(serviceForm);
        })
        .catch((error: Error) => {
          setServiceForm(undefined);
          errorHandler({
            error,
            userMessage: "Error loading service form",
          });
        });
    } else {
      setServiceForm(undefined);
    }
  }, [serviceId]);

  return {
    serviceForm,
    serviceFormState: processState,
    serviceFormError: processErrorMessage,
  };
};

export default useServiceForm;
