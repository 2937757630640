import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import WebCamera from "./WebCamera";

const useStyles = makeStyles()(() => ({
  container: {
    overflowX: "hidden",
  },
}));

const Camera: FC = () => {
  const { classes } = useStyles();
  const { heightMinusAppBar } = useScreenSize({});

  return (
    <HeightLayout className={classes.container} height={heightMinusAppBar}>
      <HeightLayoutChild flexDriven>
        <WebCamera height={heightMinusAppBar} />
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default Camera;
