import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid2";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  breakWords: {
    whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
    wordWrap: "break-word", //break lines on word to fit
  },
}));

interface OutcomesInputProps {
  outcomes: string[];
  selectedOutcome: string;
  setSelectedOutcome: (selectedOutcome: string) => void;
  selectedDiagnosis: string;
  setSelectedDiagnosis: (selectedDiagnosis: string) => void;
  handleSubmitOutcomes?: () => void;
  diagnosisLabel?: string;
  diagnosisMinRows?: number;
}

const OutcomesInput: FC<OutcomesInputProps> = ({
  outcomes,
  selectedOutcome,
  setSelectedOutcome,
  selectedDiagnosis,
  setSelectedDiagnosis,
  handleSubmitOutcomes,
  diagnosisLabel = "Enter a Diagnosis",
  diagnosisMinRows = 1,
}) => {
  const { classes } = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <FormControl fullWidth margin="dense">
          <InputLabel id="select-an-outcome-label">
            Select an Outcome
          </InputLabel>
          <Select
            labelId="select-an-outcome-label"
            label="Select an Outcome"
            onChange={(e) => {
              setSelectedOutcome(e.target.value as string);
            }}
            value={selectedOutcome}
            classes={{
              root: classes.breakWords,
            }}
          >
            {outcomes.map((outcome, i) => (
              <MenuItem
                key={`outcome_${i}_${outcome}`}
                className={classes.breakWords}
                value={outcome}
              >
                {outcome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <InputLabel>{diagnosisLabel}</InputLabel>
        <TextField
          onChange={(e) => {
            setSelectedDiagnosis(e.target.value as string);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && handleSubmitOutcomes !== undefined) {
              handleSubmitOutcomes();
            }
          }}
          value={selectedDiagnosis}
          fullWidth
          margin="dense"
          multiline
          minRows={diagnosisMinRows}
        />
      </Grid>
    </Grid>
  );
};

export default OutcomesInput;
