import { useContext, useEffect, useState } from "react";
import { PermissionAppRoute } from "src/types/AppRoute";
import { AuthContext } from "../../AuthProvider";
import hasPermissions from "../../models/hasPermissions";
import isMobileDevice from "../../models/isMobileDevice";

const useRoutePermissions = (route?: PermissionAppRoute) => {
  const authContext = useContext(AuthContext);

  const [permissionGranted, setPermissionGranted] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkPermission = (route: PermissionAppRoute): Promise<boolean> => {
    if (isMobileDevice() && !route.availableOnMobile) {
      return Promise.resolve(false);
    } else if (authContext.profile === undefined) {
      return Promise.resolve(false);
    } else {
      return Promise.all(
        route.requiredResourcePermissions.map((resources) => {
          return Promise.all(
            resources.map((r) => {
              return hasPermissions(r, authContext.profile);
            }),
          ).then((results) => {
            return results.every((bool) => bool);
          });
        }),
      )
        .then((results) => {
          return results.some((bool) => bool);
        })
        .then((allow) => {
          return allow;
        });
    }
  };

  useEffect(() => {
    if (route !== undefined) {
      setLoading(true);
      if (!authContext.loading) {
        checkPermission(route).then((allow) => {
          setPermissionGranted(allow);
          setLoading(false);
        });
      }
    } else {
      setLoading(false);
    }
  }, [route, authContext.profile, authContext.loading]);

  return {
    permissionGranted,
    loading,
  };
};

export default useRoutePermissions;
