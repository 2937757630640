import { fbFunctions } from "../../../../firebase";

export function uploadFilesToAVA(selectedMediaIds: string[]): Promise<void> {
  return fbFunctions
    .httpsCallable("ava-uploadGalleryFiles")({
      galleryItemIds: selectedMediaIds,
    })
    .then(() => {
      return;
    });
}
