import {
  thinScrollbar,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useEffect, useRef } from "react";
import { ActivityUser } from "shared/types/dist/namespaces/Activity";
import { makeStyles } from "tss-react/mui";
import usePermissions from "../../../../components/usePermissions";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import MessageInput from "../MessageComponents/MessageInput";
import Messages, { MessagesElement } from "../MessageComponents/Messages";
import sharedStyles from "../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  ...sharedStyles(theme),
  messagesHeaderBox: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  messageInputBox: {
    padding: theme.spacing(1),
  },
  messageFileDropBox: {
    padding: theme.spacing(1),
  },
  hidden: {
    display: "none",
  },
  fullHeight: {
    height: "100%",
  },
}));

interface MOAMessageViewProps {
  goToEconsult: () => void;
  visible: boolean;
}

const MOAMessageView: FC<MOAMessageViewProps> = ({ goToEconsult, visible }) => {
  const { classes, cx } = useStyles();

  const activityContext = useContext(ActivityContext);
  const messagesRef = useRef<MessagesElement>(null);

  const scrollToMessagesBottom = () => {
    if (visible && messagesRef.current !== null) {
      messagesRef.current.scrollToBottom();
    }
  };

  //Update sizes when templates slide out
  useEffect(scrollToMessagesBottom, [visible]);

  const { heightMinusAppBar } = useScreenSize({});
  const isMobileSize = useMobileSizes();

  const { granted: hasSecureMessagingSendMessagesPermission } = usePermissions({
    resourceKey: resourceKeys.secureMessagingSendMessages,
  });

  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <Box
          borderBottom={1}
          className={cx(classes.messagesHeaderBox, classes.boxBorderColor)}
        >
          <Grid container justifyContent="flex-start" alignItems="center">
            {isMobileSize && (
              <Grid>
                <IconButton onClick={goToEconsult} size="large">
                  <ChevronLeftIcon />
                </IconButton>
              </Grid>
            )}
            <Grid>
              <Typography>
                {Object.values(activityContext.sharedActivity.profiles)
                  .filter(
                    (activityUser): activityUser is ActivityUser =>
                      activityUser != undefined,
                  )
                  .map((profile) => `${profile.firstName} ${profile.lastName}`)
                  .join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </HeightLayoutChild>
      <HeightLayoutChild
        flexDriven
        allowOverflowY
        className={cx(classes.boxPadding, classes.thinScrollbar)}
      >
        <Messages ref={messagesRef} />
      </HeightLayoutChild>
      {hasSecureMessagingSendMessagesPermission && (
        <HeightLayoutChild
          flexDriver
          maxHeight="50%"
          allowOverflowY
          className={classes.thinScrollbar}
        >
          <Box
            className={cx(classes.messageInputBox, classes.boxBorderColor)}
            borderTop={1}
          >
            <MessageInput viewType="moa" />
          </Box>
        </HeightLayoutChild>
      )}
    </HeightLayout>
  );
};

export default MOAMessageView;
