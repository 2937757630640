import {
  HeightLayout,
  HeightLayoutChild,
  PaperModal,
  ProcessState,
  ProcessStatus,
  thinScrollbar,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { aletheaMDCrypto } from "@alethea-medical/aletheamd-crypto";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import SendIcon from "@mui/icons-material/Send";
import { Theme } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import {
  Control,
  SubmitHandler,
  UseFormSetValue,
  UseFormTrigger,
  useForm,
} from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import { TypedFormTextArea } from "../../../../components/FormInputFields/FormTextArea";
import { TypedFormTextField } from "../../../../components/FormInputFields/FormTextField";
import {
  fbFirestore,
  fbFunctions,
  logAnalyticsEvent,
} from "../../../../firebase";
import { formatTimeLong } from "../../../../models/formatTime";
import ConsultFormCard from "../../Consult/ConsultFormCard";
import SpecialtySubsiteSpecialistSelect from "../../Consult/SpecialtyDropdown/SpecialtySubsiteSpecialistSelect";
import {
  SpecialtySubsiteSpecialistSelectControlType,
  emptySpecialist,
} from "../../Consult/SpecialtyDropdown/SpecialtySubsiteSpecialistSelectController";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import sharedStyles from "../sharedStyles";

interface ForwardEconsult {
  activityId: string;
  referingPhysicianUid: string;
  recipientSpecialistUId: string;
  newSpecialty: string;
  newSubSite: string;
  previousMessages?: PreviousMessage[];
  subject: string;
}
interface PreviousMessage {
  sentAt: string;
  sentBy: string;
  message: string;
}

/** Type for react hook form values */
interface ForwardConsultFormFields
  extends SpecialtySubsiteSpecialistSelectControlType {
  body: string;
  subject: string;
}

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  ...thinScrollbar,

  messagesBox: {
    padding: theme.spacing(1, 1, 3, 1),
    overflowY: "auto",
    overflowX: "hidden",
  },
}));

const ForwardSecureMessageButton: FC = () => {
  const authContext = useContext(AuthContext);
  const activityContext = useContext(ActivityContext);
  const { classes, cx } = useStyles();

  const [show, setShow] = useState<boolean>(false);

  const getForwardFields = (
    data: ForwardConsultFormFields | undefined,
    uid: string,
  ): ForwardConsultFormFields => {
    return {
      specialty: data?.specialty ?? "",
      specialist: data?.specialist ?? emptySpecialist,
      subsite: data?.subsite ?? "",
      subject: activityContext.sharedActivity.subject,
      activityType: "econsult",
      locationIdx:
        activityContext.sharedActivity.profiles[uid]?.location.locationIndex ??
        0,
      body: "",
    };
  };

  const { handleSubmit, control, reset, watch, getValues, setValue, trigger } =
    useForm({
      mode: "onTouched",
      defaultValues: useMemo(
        () => getForwardFields(undefined, authContext.uid),
        [authContext.uid],
      ),
    });
  const specialty = watch("specialty");
  const subsite = watch("subsite");

  const {
    processState,
    setProcessState,
    processErrorMessage,
    setProcessErrorMessage,
    errorHandler,
  } = useProcessState({ logAnalyticsEvent });
  const createNewEconsult = fbFunctions.httpsCallable(
    "secureMessaging-forwardEconsult",
  );

  useEffect(() => {
    getPreviousMessages();
  }, [show]);

  //change the subject so it matches new information
  useEffect(() => {
    const phn = activityContext.econsult.phn;
    if (specialty !== "" && subsite !== "")
      setValue("subject", `FWD: ${specialty} - ${subsite}, ${phn}`);
  }, [specialty, subsite]);

  //retrieve and objectify previous messages to be formatted later
  const getPreviousMessages = () => {
    fbFirestore
      .collection(dbNames.activities)
      .doc(activityContext.activityId)
      .collection(dbNames.activities_messages)
      .orderBy("sentAt", "asc")
      .get()
      .then((snapshot) => {
        const prevMessages: PreviousMessage[] = []; //messages from previously created econsult
        const newMessages = snapshot.docs.map((doc) => {
          return doc.data() as Activity.Message;
        });
        return aletheaMDCrypto
          .encryptDecryptMessages(
            newMessages,
            fbFirestore
              .collection(dbNames.system)
              .doc("keystore")
              .collection("keys")
              .doc("firestoreData"),
            { decrypt: true },
          )
          .then(() => {
            newMessages.forEach((message) => {
              const userSentBy: Activity.ActivityUser | undefined =
                activityContext.sharedActivity.profiles[message.sentBy];
              if (!userSentBy) {
                throw new Error(
                  `UID not found on activityContext:\n Activity ID: ${activityContext.activityId}, Message Sent By: ${message.sentBy}`,
                );
              }
              const dateAt = formatTimeLong(message.sentAt.toDate());
              const messageStr = `${message.message}\n`;
              prevMessages.push({
                sentBy: `${userSentBy.firstName} ${userSentBy.lastName}`,
                sentAt: dateAt,
                message: messageStr,
              });
            });
            setValue("body", displayMessages(prevMessages));
          })
          .catch((error: Error) => {
            console.log(error);
          });
      });
  };

  //messages are formated here, changes made here will change how messages appear in the forward econsult modal
  const displayMessages = (prevMessages: PreviousMessage[]) => {
    let stringMessage = "";

    prevMessages.forEach((message) => {
      stringMessage =
        stringMessage +
        `Message sent at ${message.sentAt} by ${message.sentBy}: \n - ${message.message} ` +
        `\n\n`;
    });
    //this is a quick fix for nested econsult Forwarding TECH DEBT ew
    return activityContext.sharedActivity.forwardedEconsult &&
      activityContext.sharedActivity.forwardedEconsult !== undefined
      ? `Previously Forwarded eConsult Messages:\n\n${stringMessage}`
      : `Previous eConsult Messages:\n\n${stringMessage}`;
  };

  const onSubmit: SubmitHandler<ForwardConsultFormFields> = (data) => {
    setProcessState(ProcessState.running);

    const fwdEconsult: ForwardEconsult = {
      activityId: activityContext.activityId,
      referingPhysicianUid: authContext.uid,
      recipientSpecialistUId: data.specialist.uid,
      newSpecialty: data.specialty,
      newSubSite: data.subsite,
      previousMessages: data.body as unknown as PreviousMessage[], // this is a hack to get around the type error, not sure how its working now??
      subject: data.subject,
    };

    logAnalyticsEvent("secure_messaging_forward_to_specialist_start");
    createNewEconsult(fwdEconsult)
      .then(() => {
        logAnalyticsEvent("secure_messaging_forward_to_specialist_success");

        setProcessState(ProcessState.success);
        setTimeout(() => {
          setProcessState(ProcessState.idle);
          setShow(false);
        }, 1000);
        resetForm();
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error forwarding econsult to specialist",
          analyticsLog: "secure_messaging_forward_to_specialist_failed",
        });
      });
  };

  const resetForm = () => {
    const data = getValues();
    reset(getForwardFields(data, authContext.uid));
  };

  const onError = () => {
    setProcessErrorMessage("Check form for errors.");
    setProcessState(ProcessState.error);
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  return (
    <>
      <DropdownMenuItem
        label="Forward to Specialist"
        icon={<SendIcon color="primary" />}
        onClick={() => {
          setShow(true);
        }}
        color="primary"
      />
      <PaperModal show={show} setShow={setShow} flexWidth enablePadding>
        <HeightLayout>
          <HeightLayoutChild
            flexDriven
            allowOverflowY
            className={cx(classes.messagesBox, classes.thinScrollbar)}
          >
            <Grid container>
              <Grid size={{ xs: 12 }}>
                <Typography variant="h5">
                  Forward to specialist as a new eConsult
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Typography variant="subtitle1">
                  Securely forward this eConsult, and attachments to a different
                  specialist.
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <form
                  onSubmit={handleSubmit(onSubmit, onError)}
                  autoComplete="off"
                  aria-autocomplete="none"
                >
                  <fieldset disabled={isDisabled()} aria-autocomplete="none">
                    <Grid container spacing={1}>
                      <Grid size={{ xs: 12 }}>
                        <ConsultFormCard title="Specialty">
                          <SpecialtySubsiteSpecialistSelect
                            control={
                              control as unknown as Control<SpecialtySubsiteSpecialistSelectControlType>
                            }
                            trigger={
                              trigger as unknown as UseFormTrigger<SpecialtySubsiteSpecialistSelectControlType>
                            }
                            setValue={
                              setValue as unknown as UseFormSetValue<SpecialtySubsiteSpecialistSelectControlType>
                            }
                            isDisabled={isDisabled()}
                          />
                        </ConsultFormCard>
                        {/* Specialties and Subsites*/}
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <TypedFormTextField
                          name="subject"
                          control={control}
                          label="Subject"
                          disabled={isDisabled()}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <TypedFormTextArea
                          name="body"
                          label="Previous eConsult Messages"
                          control={control}
                          initRows={3}
                          disabled={isDisabled()}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <ProcessStatus
                          state={processState}
                          setState={setProcessState}
                          errorMessage={processErrorMessage}
                          successMessage={"Message sent."}
                        />
                      </Grid>
                      <Grid>
                        <Button
                          type="submit"
                          color="primary"
                          disabled={isDisabled()}
                          startIcon={<SendIcon color="primary" />}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </fieldset>
                </form>
              </Grid>
            </Grid>
          </HeightLayoutChild>
        </HeightLayout>
      </PaperModal>
    </>
  );
};

export default ForwardSecureMessageButton;
