import { Search as SearchIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Grid2 as Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import isWeb from "../../models/isWeb";

interface SearchBarProps {
  enableSearch: boolean;
  runSearch: (searchTerms: string[]) => void;
  clearSearch: () => void;
  maxSearchTerms?: number;
  placeholderText: string;
}

export const SearchBar: FC<SearchBarProps> = ({
  enableSearch,
  runSearch,
  clearSearch,
  maxSearchTerms = 10,
  placeholderText,
}) => {
  const [searchParams, setSearchParams] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");

  const handleSearch = () => {
    if (searchParams.length > 0) {
      runSearch(searchParams);
    }
  };

  const handleClearSearch = () => {
    setSearchParams([]);
    clearSearch();
  };

  useEffect(() => {
    if (searchParams.length === 0 && enableSearch) {
      clearSearch();
    }
  }, [searchParams]);

  const getHelperText = () => {
    if (inputValue.length > 0 || searchParams.length > 0) {
      return "Press enter to add multiple search terms";
    }
    return undefined;
  };

  return (
    <Grid container direction="column" spacing={1} width={"100%"}>
      <Grid container size={12} spacing={2} alignItems={"center"}>
        <Grid size={{ xs: 12, md: "grow" }}>
          <Autocomplete
            multiple
            fullWidth
            freeSolo
            options={[]}
            value={searchParams}
            filterSelectedOptions
            autoSelect
            limitTags={maxSearchTerms}
            onChange={(_, value) => {
              setSearchParams(
                (value as string[]).map((str) => {
                  try {
                    return str.trim();
                  } catch (e) {
                    return str;
                  }
                }),
              );
            }}
            onInputChange={(e, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholderText}
                variant="outlined"
                margin="none"
                slotProps={{
                  input: {
                    ...params.InputProps,
                    style: { paddingTop: 0, paddingBottom: 0 },
                  },
                  htmlInput: {
                    ...params.inputProps,
                    style: { padding: "10px 4px" },
                    enterKeyHint: "done",
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid size="auto">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
          >
            Search
          </Button>
        </Grid>
        {enableSearch && (
          <Grid size="auto">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClearSearch}
            >
              Clear
            </Button>
          </Grid>
        )}
      </Grid>
      {isWeb() && getHelperText() && (
        <Grid>
          <Typography variant="caption" sx={{ ml: 1.5 }}>
            {getHelperText()}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
