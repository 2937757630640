import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { FC } from "react";
import { SpecialistTemplates } from "../../../../shared/types";

interface InsertTemplateButtonProps {
  item: SpecialistTemplates.Item;
  templateInsertHandler: (item: SpecialistTemplates.Item) => void;
}

const InsertTemplateButton: FC<InsertTemplateButtonProps> = ({
  item,
  templateInsertHandler,
}) => {
  const handleTemplateInsert = () => {
    templateInsertHandler(item);
  };

  return (
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={handleTemplateInsert}
    >
      Insert Text
    </Button>
  );
};

export default InsertTemplateButton;
