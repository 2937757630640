import Help from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import { FC } from "react";
import isNativeMobile from "src/models/isNativeMobile";
import { makeStyles } from "tss-react/mui";

const getFractionalSize = (size: string, fraction: number) => {
  return `${parseFloat(size) * fraction}em`;
};

// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (for example `(props) => ({...})` instead of `({color}) => ({...})`).
// TODO jss-to-tss-react codemod: Unable to handle style definition reliably. Unsupported arrow function syntax.
// Arrow function has parameter type of Identifier instead of ObjectPattern (for example `(props) => ({...})` instead of `({color}) => ({...})`).
const useStyles = makeStyles<{
  size?: string;
  mobileSize?: string;
  questionMarkFraction?: number;
  mobileQuestionMarkFraction?: number;
}>()(
  (
    theme,
    { size, mobileSize, questionMarkFraction, mobileQuestionMarkFraction },
  ) => ({
    iconContainer: {
      position: "relative",
    },
    stethoscope: {
      right: -5,
    },
    icon: {
      width: size || "2em",
      height: size || "2em",
      [theme.breakpoints.down("md")]: {
        width: mobileSize || "2em",
        height: mobileSize || "2em",
      },
    },
    questionMark: {
      position: "absolute",
      width: getFractionalSize(size || "2em", questionMarkFraction || 0.3),
      height: getFractionalSize(size || "2em", questionMarkFraction || 0.3),
      top: 0,
      right: -10,
      zIndex: 1,
      color: theme.palette.primary.main,
      [theme.breakpoints.down("md")]: {
        width: getFractionalSize(
          mobileSize || "2em",
          mobileQuestionMarkFraction || 0.5,
        ),
        height: getFractionalSize(
          mobileSize || "2em",
          mobileQuestionMarkFraction || 0.5,
        ),
      },
    },
  }),
);

interface AcceptanceFlagsProps {
  acceptanceFlags?: {
    acceptAll?: boolean;
    acceptExisting?: boolean;
    acceptReferral?: boolean;
  };
  size?: string;
  mobileSize?: string;
  questionMarkFraction?: number; // How much smaller the questionMark should be
  mobileQuestionMarkFraction?: number; // How much smaller the questionMark should be on mobile
}

const AcceptanceFlags: FC<AcceptanceFlagsProps> = ({
  acceptanceFlags,
  size,
  mobileSize,
  questionMarkFraction,
  mobileQuestionMarkFraction,
}) => {
  const { classes } = useStyles({
    size,
    mobileSize,
    questionMarkFraction,
    mobileQuestionMarkFraction,
  });

  return (
    <>
      {/* Hide accept All logo for now. May add back in the future. */}
      {/* {acceptanceFlags?.acceptAll && (
        <Grid>
          <Tooltip title="Will accept eConsults for all patients">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-green-chat.svg"
                alt="Accept All"
                className={classes.icon}
              />
              <Help
                className={classes.questionMark}
                style={!isNativeMobile() ? { right: "-16px" } : {}}
              />
            </div>
          </Tooltip>
        </Grid>
      )} */}
      {acceptanceFlags?.acceptExisting && (
        <Grid>
          <Tooltip title="Will only accept eConsults for existing patients">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-orange-chat.svg"
                alt="Accept Existing"
                className={classes.icon}
              />
              <Help
                className={classes.questionMark}
                style={!isNativeMobile() ? { right: "-16px" } : {}}
              />
            </div>
          </Tooltip>
        </Grid>
      )}
      {acceptanceFlags?.acceptReferral && (
        <Grid>
          <Tooltip title="Will accept in-person referrals if necessary through Alethea eConsults">
            <div className={classes.iconContainer}>
              <img
                src="/acceptance-icons/icon-stethoscope.svg"
                alt="acceptReferral"
                className={classes.icon}
              />
              <Help
                className={`${classes.questionMark} ${classes.stethoscope}`}
              />
            </div>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

export default AcceptanceFlags;
