import { Utilities } from "@alethea-medical/aletheamd-types";
import { useEffect, useState } from "react";

type StringDict<T> = Utilities.StringDict<T>;

type ListItemSelectControllerReturn = {
  selectedItems: string[];
  allSelected: boolean;
  selectAllHandler: (selectAll: boolean) => void;
  listItemSelectHandler: (id: string, checked: boolean) => void;
  unselectAllHandler: () => void;
};

interface ListItemSelectControllerProps<T> {
  listItemDict: StringDict<T>;
}

/**
 * Generic controller for selecting items in a list
 */
function ListItemSelectController<T>({
  listItemDict,
}: ListItemSelectControllerProps<T>): ListItemSelectControllerReturn {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const selectAllHandler = (selectAll: boolean) => {
    if (selectAll) {
      const newSelectedItems = [...selectedItems];
      Object.keys(listItemDict).forEach((id) => {
        if (!newSelectedItems.includes(id)) {
          newSelectedItems.push(id);
        }
      });
      setSelectedItems(newSelectedItems);
    } else {
      setSelectedItems([]);
    }
  };

  const listItemSelectHandler = (id: string, checked: boolean) => {
    if (checked) {
      if (!selectedItems.includes(id)) {
        const newSelectedItems = [...selectedItems];
        newSelectedItems.push(id);
        setSelectedItems(newSelectedItems);
      }
    } else {
      const idxToRemove = selectedItems.indexOf(id);
      if (idxToRemove !== -1) {
        const newSelectedItems = [...selectedItems];
        newSelectedItems.splice(idxToRemove, 1);
        setSelectedItems(newSelectedItems);
      }
    }
  };

  const unselectAllHandler = () => {
    setSelectedItems([]);
  };

  useEffect(() => {
    if (selectedItems.length > 0) {
      setAllSelected(
        Object.keys(listItemDict).every((id) => {
          return selectedItems.includes(id);
        }),
      );
    } else {
      setAllSelected(false);
    }
  }, [selectedItems]);

  return {
    selectedItems,
    allSelected,
    selectAllHandler,
    listItemSelectHandler,
    unselectAllHandler,
  };
}

export default ListItemSelectController;
