import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";

const FormCheckbox = ({
  name,
  control,
  label,
  defaultValue,
  onChange,
  ...rest
}: ControlledInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue !== undefined ? defaultValue : false}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          {...field}
          checked={field.value === true}
          control={
            <Checkbox
              color="primary"
              {...rest}
              onChange={(e) => {
                field.onChange(e); // Call field's onChange
                if (onChange) {
                  onChange(e); // Call custom onChange if provided
                }
              }}
              disableRipple
            />
          }
        />
      )}
    />
  );
};

export default FormCheckbox;
