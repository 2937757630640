import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Resources } from "../../../../shared/types";

interface InsertResourceButtonProps {
  resource: Resources.Resource;
  resourceInsertHandler: (resource: Resources.Resource) => void;
  disabled?: boolean;
}

const InsertResourceButton = ({
  resource,
  resourceInsertHandler,
  disabled = false,
}: InsertResourceButtonProps) => {
  const handleResourceInsert = () => {
    resourceInsertHandler(resource);
  };

  return (
    <Button
      variant="outlined"
      startIcon={<AddIcon />}
      onClick={handleResourceInsert}
      disabled={disabled}
    >
      Insert Resource
    </Button>
  );
};

export default InsertResourceButton;
