import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import firebase from "firebase/compat/app";
import { FC, ReactNode, createContext, useEffect, useState } from "react";
import { UserClaims } from "../../shared/types";
import { fbAuth, fbFunctions } from "../firebase";
import globalStrings from "../globalStrings";

interface ContextProps {
  userClaims?: UserClaims;
  fetchClaims: (user: firebase.User) => void;
  processState: ProcessState;
  processErrorMessage: string;
}

export const ClaimsContext = createContext<ContextProps>({
  fetchClaims: () => {
    console.log("fetchClaims is not yet initialized");
  },
  processState: ProcessState.idle,
  processErrorMessage: "",
});

type ClaimsProviderProps = {
  children: ReactNode;
};

const ClaimsProvider: FC<ClaimsProviderProps> = ({ children }) => {
  const setCustomClaims = fbFunctions.httpsCallable("login-setCustomClaims_v1");
  const [userClaims, setUserClaims] = useState<UserClaims | undefined>(
    undefined,
  );
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  // Remove userClaims when user logs out
  useEffect(() => {
    const unsubscribe = fbAuth.onAuthStateChanged(
      (newUser: firebase.User | null) => {
        if (newUser === null) {
          setUserClaims(undefined);
          setProcessState(ProcessState.idle);
        }
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const fetchClaims = (user: firebase.User) => {
    setProcessState(ProcessState.running);

    let claimsPromise = Promise.resolve(userClaims);

    if (userClaims === undefined) {
      claimsPromise = setCustomClaims().then(
        (response) => response.data as UserClaims,
      );
    }
    console.log("Refreshing claims");
    claimsPromise
      .then((claims) => {
        // Generate a new token
        return user.getIdToken(true).then(() => {
          // Update userClaims
          setUserClaims(claims);
          setProcessState(ProcessState.success);
        });
      })
      .catch((error) => {
        errorHandler({
          error,
          userMessage: `Unable to load claims data; some features may not work as expected. Please logout and log back in. If you continue to receive this error please contact us at ${globalStrings.supportEmail} for assistance.`,
          hideErrorMessage: true,
        });
      });
  };

  return (
    <ClaimsContext.Provider
      value={{
        userClaims,
        fetchClaims,
        processState,
        processErrorMessage,
      }}
    >
      {children}
    </ClaimsContext.Provider>
  );
};

export default ClaimsProvider;
