import { DatePicker } from "@mui/x-date-pickers";
import { FC } from "react";

interface VacationDatePickerProps {
  value: Date;
  label: string;
  dateChangeHandler: (newDate: Date | null) => void;
}

const VacationDatePicker: FC<VacationDatePickerProps> = ({
  value,
  label,
  dateChangeHandler,
}: VacationDatePickerProps) => {
  return (
    <DatePicker
      label={label}
      disableOpenPicker={false}
      format="yyyy-MM-dd"
      value={value}
      onChange={dateChangeHandler}
      sx={{ width: "100%" }}
    />
  );
};

export default VacationDatePicker;
