import React from "react";

import { makeStyles } from "tss-react/mui";

export const timeRowHeight = 15;

const useStyles = makeStyles()(() => ({
  timeRowMajor: {
    borderTop: "1px solid #ddd",
  },
  timeRow: {
    height: `${timeRowHeight}px`,
  },
}));

export function isTimeRowMajor(index: number): boolean {
  return index % 3 === 0;
}

interface TimeRowProps {
  time: string;
  index: number;
  children: React.ReactNode;
}

const TimeRow: React.FC<TimeRowProps> = ({ index, children }) => {
  const { classes, cx } = useStyles();
  return (
    <div
      className={cx(
        {
          [classes.timeRowMajor]: isTimeRowMajor(index),
        },
        classes.timeRow,
      )}
    >
      {children}
    </div>
  );
};

export default TimeRow;
