import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import { Control, useWatch } from "react-hook-form";
import { Activity } from "../../../../../shared/types";
import { TypedFormCheckbox } from "../../../../components/FormInputFields/FormCheckbox";
import HelpModal from "../../../../components/HelpModal";
import usePermissions from "../../../../components/usePermissions";
import globalStrings from "../../../../globalStrings";

interface ConsultEmailPatientCheckboxProps {
  control: Control<Activity.ConsultFormFields>;
  disabled: boolean;
}

const patientCCMessageBillable = [
  "The patient will receive a copy of their consult tomorrow at 7:00 AM through the Alethea.Care portal. Physician contact information will not be included.",
  "This patient communication will be recorded for billing tomorrow at 7:00 AM.",
];
const patientCCMessageNotBillable = [
  "The patient will receive a copy of their consult tomorrow at 7:00 AM through the Alethea.Care portal. Physician contact information will not be included.",
  "If you are an Alethea Plus user, this patient communication will be recorded for billing tomorrow at 7:00 AM.",
  `To learn more about Alethea Plus, please contact ${globalStrings.salesEmail}.`,
];

export const ConsultEmailPatientCheckbox: FC<
  ConsultEmailPatientCheckboxProps
> = ({ control, disabled }) => {
  const patientEmail = useWatch({ control, name: "patientInfo.email" });

  const { granted: hasPatientCCPermissions } = usePermissions({
    resourceKey: resourceKeys.patientCC,
  });
  const { granted: hasBillingPermissions } = usePermissions({
    resourceKey: resourceKeys.billing,
  });

  return (
    <>
      {hasPatientCCPermissions && (
        <Grid container alignItems="center" style={{ marginTop: "7px" }}>
          <Grid>
            <TypedFormCheckbox
              name="emailPatient"
              control={control}
              label={`Send eConsult copy to patient${patientEmail ? "" : " (Email Required)"}`}
              disabled={disabled || !patientEmail}
            />
          </Grid>
          <Grid size={{ xs: "grow" }}>
            <HelpModal
              modalTitle="About sending a copy of the eConsult to the patient"
              helpText={
                hasBillingPermissions
                  ? patientCCMessageBillable
                  : patientCCMessageNotBillable
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};
