import firebase from "firebase/compat";
import { config } from "../firebase";
import devConsole from "./devConsole";
import isNativeMobile from "./isNativeMobile";

// TODO make this a meaningful variable (we should have a variable for this fetch URL maybe?) and we should have a separate env for local dev
const isLocal = import.meta.env.VITE_FIREBASE_USE_FUNCTIONS_EMULATOR === "true";

// Returns the Url to fetch from, depending on the environment and device
const getFetchUrl = (endpoint: string) => {
  if (isLocal)
    return `http://localhost:5001/${config.projectId}/us-central1/${serverAPIString}/${serverAPIString}/${endpoint}`;

  if (isNativeMobile())
    return `https://us-central1-${config.projectId}.cloudfunctions.net/${serverAPIString}/${serverAPIString}/${endpoint}`;

  return `/${serverAPIString}/${endpoint}`;
};

const serverAPIString = "serverV5";
//Function to handle sending authorized requests to the backend. Handles https errors and server errors for you, and returns as standard promise errors.
const serverRequest = async (
  user: firebase.User | null | undefined,
  headers: any,
  body: any,
  endpoint: string,
) => {
  try {
    // Get the user's ID token
    const token = user ? await user.getIdToken(true) : undefined;

    // If token exists, add it to headers
    if (token !== undefined) {
      headers.Authentication = `Bearer ${token}`;
    }

    const requestOptions = {
      method: "POST",
      headers,
      body,
    };

    //Call function directly if running in android/iOS app
    //serverApiString is put in here twice because the hosting rewrite needs to rewrite the route serverV4 to the function serverV4.
    const fetchUrl = getFetchUrl(endpoint);

    const response = await fetch(fetchUrl, requestOptions);
    devConsole.log("Server response status", response.status);

    if (response.status === 404) {
      throw new Error(
        `${response.status} ${response.statusText}: Server may be down, or check your internet connection.`,
      );
    } else if (response.status < 400) {
      // Try to parse JSON, otherwise return text
      try {
        return await response.clone().json();
      } catch {
        return await response.clone().text();
      }
    } else {
      // Handle error responses
      let error;
      try {
        error = await response.clone().json();
      } catch {
        console.error("Error parsing JSON, falling back on text");
        error = await response.clone().text();
      }
      throw new Error(error?.error ? error.error : JSON.stringify(error));
    }
  } catch (error) {
    console.error("Authorized Server Request: Error caught => ", error);
    throw error;
  }
};

export default serverRequest;
