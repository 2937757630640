import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../shared/sharedStyles";
import { ControlledInputProps } from "../types";

const useStyles = makeStyles()(() => {
  const shared = sharedStyles();
  return {
    ...shared,
  };
});

const FormTextField = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  disabled,
  errorTextPadding,
  className,
  ...rest
}: ControlledInputProps) => {
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={rules}
      render={({ field, fieldState }) => (
        <TextField
          fullWidth
          {...field}
          {...rest}
          disabled={disabled}
          className={`${classes.canDisable} ${className ?? ""}`}
          label={label}
          error={fieldState.error !== undefined}
          helperText={
            errorTextPadding
              ? fieldState.error?.message
                ? fieldState.error.message
                : " "
              : fieldState.error?.message
          }
          margin="dense"
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            disableAnimation: true,
          }}
          inputProps={{
            "aria-autocomplete": "none",
            list: "autocompleteOff",
          }}
        />
      )}
    />
  );
};

export default FormTextField;
