import { ListItemButton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  thread: {
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.main,
      borderWidth: theme.spacing(0.5),
    },
    padding: 0,
    margin: 0,
  },
  threadText: {
    padding: theme.spacing(1),
    margin: 0,
    overflowX: "hidden",
    whiteSpace: "pre-line",
    wordWrap: "break-word", //break lines on word to fit
  },
  unreadText: {
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  readText: {
    color: theme.palette.text.secondary,
  },
  readThread: {
    background: theme.palette.grey[200],
  },
  unreadThread: {
    //white
  },
  unreadIcon: {
    marginRight: theme.spacing(2),
  },
  selectedThread: {
    background: theme.palette.secondary.light,
  },
  checkbox: {
    height: "100%",
    margin: theme.spacing(0),
    padding: theme.spacing(0, 1),
  },
  childrenContainer: {
    marginRight: theme.spacing(1),
  },
}));

interface MessageListItemProps {
  selected?: boolean;
  setSelected?: (id: string, checked: boolean) => void;
  openMessage: (id: string) => void;
  id: string;
  read?: boolean;
  primary: React.ReactChild;
  secondary?: React.ReactChild;
  /** Component to render to the right of the checkbox */
  labelComponent?: React.ReactChild;
  className?: string;
  children?: React.ReactNode;
}

const MessageListItem: React.FC<MessageListItemProps> = ({
  selected,
  setSelected,
  openMessage,
  id,
  read,
  primary,
  secondary,
  labelComponent,
  className,
  children,
}) => {
  const { classes, cx } = useStyles();

  const changeMessage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    openMessage(id);
  };

  return (
    <ListItemButton
      className={cx(
        {
          [classes.selectedThread]: selected,
          [classes.readThread]: !selected && read,
          [classes.unreadThread]: !selected && !read,
        },
        className,
        classes.thread,
      )}
      divider
    >
      {setSelected !== undefined && (
        <Checkbox
          sx={{ mx: 1 }}
          checked={selected}
          onChange={(e) => {
            e.stopPropagation();
            setSelected(id, e.target.checked);
          }}
        />
      )}
      {labelComponent}
      <ListItemText
        onClick={changeMessage}
        className={`${classes.threadText}`}
        primary={primary}
        primaryTypographyProps={{
          className: `${read ? classes.readText : classes.unreadText}`,
        }}
        secondary={secondary}
        secondaryTypographyProps={{
          component: "span",
        }}
      />
      <div onClick={changeMessage} className={classes.childrenContainer}>
        {children}
      </div>
    </ListItemButton>
  );
};

export default MessageListItem;
