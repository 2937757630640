import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

/**
 * Textfield that only updates on blur
 * Handles its internal state itself
 * Always uses outlined (can't figure out how to use other props for different variants)
 */

interface InternalStateTextFieldProps extends OutlinedTextFieldProps {
  setValue: (newValue: string) => void;
  /** Rules function. If error return error message. If no error return undefined. Defaults to always return undefined if no function provided */
  rules?: (value: string) => string | undefined;
}

const InternalStateTextField = ({
  value: externalValue,
  setValue: setExternalValue,
  rules = () => undefined,
  onChange,
  onBlur,
  ...rest
}: InternalStateTextFieldProps) => {
  // State for internal value, equal to external value by default
  const [internalValue, setInternalValue] = useState(externalValue);
  const [error, setError] = useState<string | undefined>();

  // Update internal value whenever external value changes
  useEffect(() => {
    setInternalValue(externalValue);
  }, [externalValue]);

  const changeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = e.target.value as string;

    // Update error text
    //If rules not provided, default return value is undefined
    const newError = rules(value);
    setError(newError);

    setInternalValue(value);

    if (onChange) onChange(e);
  };

  const blurHandler = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    const value = e.target.value as string;
    //Don't update external value if it hasn't changed
    if (externalValue === value) return;

    // If there is an error, don't update external value
    // Reset internal value to external value
    if (rules(value) !== undefined) {
      setError(undefined);
      setInternalValue(externalValue);
    } else {
      setExternalValue(value);
    }

    if (onBlur) onBlur(e);
  };

  return (
    <TextField
      {...rest}
      value={internalValue}
      onChange={changeHandler}
      onBlur={blurHandler}
      error={error !== undefined}
      helperText={error}
      autoComplete="off"
      inputProps={{
        "aria-autocomplete": "none",
        list: "autocompleteOff",
      }}
    />
  );
};

export default InternalStateTextField;
