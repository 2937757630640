import { LinearProgress } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import RoundedButton from "../../../../../components/RoundedButton";
import palette from "../../../../../palette";
import sharedStyles from "../../../../../sharedStyles";
import strings from "../../Common/strings";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  container: {
    paddingTop: theme.spacing(7),
  },
  title: {
    color: palette.darkGreen,
    fontSize: "2em",
    textAlign: "center",
  },
  center: {
    textAlign: "center",
  },
}));
interface SignupSuccessPageProps {
  forwardingToOnboarding: boolean;
  forwardToOnboardingManually: () => void;
}

const SignupSuccessPage: FC<SignupSuccessPageProps> = ({
  forwardingToOnboarding,
  forwardToOnboardingManually,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const onClickLogin = () => {
    // If login is not working for some reason, just forward to the desired location
    if (forwardingToOnboarding) forwardToOnboardingManually();
    else navigate("/login");
  };

  return (
    <Grid
      className={classes.container}
      container
      spacing={2}
      justifyContent="center"
    >
      <Grid size={{ xs: 3, md: 1 }} className={classes.center}>
        <img src="/logo_big.png" className={classes.imgFluid} />
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography className={classes.title}>
          {strings.signupSuccess}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }} className={classes.center}>
        <Typography>
          Logging you in...{" "}
          {forwardingToOnboarding
            ? "You will be redirected to schedule an onboarding session shortly."
            : ""}
        </Typography>
      </Grid>
      <Grid size={{ xs: 10, md: 3 }}>
        <LinearProgress />
      </Grid>
      <Grid size={{ xs: 12 }} className={classes.center}>
        <RoundedButton onClick={onClickLogin}>
          {strings.autoLoginFail}
        </RoundedButton>
      </Grid>
    </Grid>
  );
};

export default SignupSuccessPage;
