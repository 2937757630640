import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import React, { FC, useContext, useEffect } from "react";
import { AuthContext } from "../../AuthProvider";
import usePermissions from "../../components/usePermissions";
import { ClaimsContext } from "../../config/ClaimsProvider";
import { fbFunctions } from "../../firebase";
import { TypedFormSelect } from "../FormInputFields/FormSelect";

export interface SendingOnBehalfOfOption {
  label: string;
  isClinic: boolean;
  id: string;
  firstName?: string;
  lastName?: string;
}

interface SelectSendOnBehalfOfProps {
  control: any;
}

const SelectSendOnBehalfOf: FC<SelectSendOnBehalfOfProps> = ({ control }) => {
  const getUsersForClinic = fbFunctions.httpsCallable(
    "clinic-getClinicInformation_v1",
  );
  const [onBehalfOfDropdownOptions, setOnBehalfOfDropdownOptions] =
    React.useState([] as SendingOnBehalfOfOption[]);
  const authContext = useContext(AuthContext);
  const {
    userClaims,
    fetchClaims,
    processState: claimsProcess,
    processErrorMessage: claimsError,
  } = useContext(ClaimsContext);
  const { granted: sendMsgOnBehalfOfPhysicianOrClinic } = usePermissions({
    resourceKey: resourceKeys.sendMsgOnBehalfOfPhysicianOrClinic,
  });
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const SetAuthContextInformation = () => {
    // Are you properly authenticated?
    if (!authContext.user) return false;

    // Can they send on behalf of a physician? (necessary permissions found in Specialist MOA role)
    if (!sendMsgOnBehalfOfPhysicianOrClinic) return false;

    // Get their Clinic ID
    fetchClaims(authContext.user);
  };

  useEffect(() => {
    SetAuthContextInformation();
  }, [authContext.user, sendMsgOnBehalfOfPhysicianOrClinic]);

  useEffect(() => {
    const clinicId = userClaims?.clinicId;
    if (clinicId) SetClinicInformation(clinicId);
  }, [userClaims?.clinicId]);

  const SetClinicInformation = async (clinicId: string) => {
    setProcessState(ProcessState.running);

    // Get the clinic users
    getUsersForClinic({ clinicId })
      .then((clinicInformationResponse) => {
        const clinicInformation = clinicInformationResponse.data;

        const dropdownOptions: SendingOnBehalfOfOption[] = [];
        if (!authContext.profile) return; // This should never happen, but if it does, we need to handle it
        dropdownOptions.push({
          id: clinicId,
          label: authContext.profile.locations[0].clinicName,
          isClinic: true,
        }); // first option is the clinic

        // Add the physicians
        clinicInformation.physicians.forEach((physician: any) => {
          dropdownOptions.push({
            id: physician.uid,
            firstName: physician.firstName,
            lastName: physician.lastName,
            label: `Dr. ${physician.firstName} ${physician.lastName}`,
            isClinic: false,
          });
        });

        setProcessState(ProcessState.idle);
        setOnBehalfOfDropdownOptions(dropdownOptions);
      })
      .catch((error) => {
        errorHandler({
          error,
          userMessage: "Error getting clinic information",
        });
      });
  };

  return (
    <>
      {sendMsgOnBehalfOfPhysicianOrClinic && (
        <>
          {processState === ProcessState.idle &&
            claimsProcess === ProcessState.success && (
              <TypedFormSelect
                control={control}
                name="sendingOnBehalfOfOption"
                label="Send on Behalf of:"
                options={onBehalfOfDropdownOptions}
                getOptionLabel={(dropdownOption: SendingOnBehalfOfOption) => {
                  return dropdownOption.label;
                }}
              />
            )}
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            loadingMessage="Loading Clinic Information"
          />
          <ProcessStatus
            state={claimsProcess}
            errorMessage={claimsError}
            loadingMessage="Loading User Information"
          />
        </>
      )}
    </>
  );
};

export default SelectSendOnBehalfOf;
