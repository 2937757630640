import { FC, useEffect } from "react";
import { Control, UseFormTrigger, useWatch } from "react-hook-form";
import { Activity } from "../../../../../shared/types";
import { TypedEmailInput } from "../../../../components/FormInputFields/EmailInput";

interface ConsultEmailProps {
  control: Control<Activity.ConsultFormFields>;
  trigger: UseFormTrigger<Activity.ConsultFormFields>;
  disabled: boolean;
}

export const ConsultEmail: FC<ConsultEmailProps> = ({
  control,
  trigger,
  disabled,
}) => {
  const emailPatient = useWatch({ control, name: "emailPatient" });

  useEffect(() => {
    trigger("patientInfo.email");
  }, [emailPatient]);

  return (
    <TypedEmailInput
      name="patientInfo.email"
      label="Patient Email"
      control={control}
      rules={{
        required: { value: emailPatient, message: "Email is required" },
      }}
      disabled={disabled}
    />
  );
};
