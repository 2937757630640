import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC, ReactElement } from "react";
import { TimeBinnedMedia, UserMediaMetadataItem } from "../Models/GalleryModel";
import GalleryGrid from "./GalleryGrid";

interface BinProps {
  timestamp: string;
  items: UserMediaMetadataItem[];
  galleryRenderer: ({ index, key, photo }: any) => ReactElement;
}

const Bin: FC<BinProps> = ({ items, timestamp, galleryRenderer }) => {
  return (
    <Grid size={{ xs: 12 }} key={`gallery_${timestamp}`}>
      <Typography variant="subtitle1">
        {`${new Date(parseInt(timestamp)).toDateString()}`}
      </Typography>
      <GalleryGrid photos={items} galleryRenderer={galleryRenderer} />
      <Divider />
    </Grid>
  );
};

interface GalleryTimestampBinsProps {
  binnedMediaItems: TimeBinnedMedia | undefined;
  galleryRenderer: ({ index, key, photo }: any) => ReactElement;
}

const GalleryTimestampBins: FC<GalleryTimestampBinsProps> = ({
  binnedMediaItems,
  galleryRenderer,
}) => {
  return (
    <>
      {binnedMediaItems !== undefined ? (
        <Grid container spacing={2}>
          {Object.keys(binnedMediaItems).map((timestamp) => (
            <Grid size={{ xs: 12 }} key={`gallery_grid_${timestamp}`}>
              <Bin
                items={binnedMediaItems[timestamp]}
                timestamp={timestamp}
                galleryRenderer={galleryRenderer}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default GalleryTimestampBins;
