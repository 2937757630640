import { FC } from "react";
import { Control } from "react-hook-form";
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";
import { SpecialtySubsiteSpecialistSelectControlType } from "./SpecialtySubsiteSpecialistSelectController";

interface SubsiteSelectProps {
  control: Control<SpecialtySubsiteSpecialistSelectControlType>;
  subsites: string[];
  disabled: boolean;
}

const SubsiteSelect: FC<SubsiteSelectProps> = ({
  control,
  subsites,
  disabled,
}) => {
  return (
    <TypedFormSelect
      name="subsite"
      control={control}
      label="Subsite"
      options={subsites}
      disabled={disabled}
      rules={{ required: { value: true, message: "Please select a subsite" } }}
    />
  );
};

export default SubsiteSelect;
