import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import GetAppIcon from "@mui/icons-material/GetApp";
import IconButton from "@mui/material/IconButton";
import { FC } from "react";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import downloadBlob from "../../../../models/downloadBlob";
import isAndroid from "../../../../models/isAndroid";
import isIOS from "../../../../models/isIOS";
import { UserMediaMetadataItem } from "../../Models/GalleryModel";

interface MediaDownloadButtonProps {
  mediaItem: UserMediaMetadataItem;
}

const MediaDownloadButton: FC<MediaDownloadButtonProps> = ({ mediaItem }) => {
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const downloadMediaItem = () => {
    if (mediaItem.data.fileDownloadUrl !== undefined) {
      setProcessState(ProcessState.running);
      fetch(mediaItem.data.fileDownloadUrl)
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          setProcessState(ProcessState.idle);
          const filename = mediaItem.data.filePath.replace(/^.*[\\/]/, ""); //Get filename from path
          logAnalyticsEvent(analyticsLogs.gallery.downloadFile);
          return downloadBlob(blob, filename).then((filePath) => {
            if (filePath !== undefined) {
              if (isAndroid()) alert(`File downloaded to: ${filePath}`);
              else if (isIOS())
                alert("File downloaded. View in the Files app.");
            }
          });
        })
        .catch((error) => {
          errorHandler({
            error: error,
            userMessage: "Error downloading file",
          });
        });
    }
  };

  return (
    <>
      <IconButton onClick={downloadMediaItem} size="large">
        <GetAppIcon />
      </IconButton>
      <ProcessStatus state={processState} errorMessage={processErrorMessage} />
    </>
  );
};

export default MediaDownloadButton;
