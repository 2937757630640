import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { ReactElement } from "react";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import exportPDF from "./exportPDF";

type useExportPDFReturn = {
  exportPdfState: ProcessState;
  exportPdfErrorMessage: string;
  setExportPdfState: (state: ProcessState) => void;
  exportPDFHandler: () => void;
  renderExportPdfButton: () => ReactElement;
};

interface useExportPDFProps {
  activityId: string;
}

const useExportPDF = ({
  activityId,
}: useExportPDFProps): useExportPDFReturn => {
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const exportPDFHandler = () => {
    setProcessState(ProcessState.running);
    logAnalyticsEvent(analyticsLogs.secureMessaging.exportPdf.start);
    return exportPDF(activityId)
      .then(() => {
        logAnalyticsEvent(analyticsLogs.secureMessaging.exportPdf.success);
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error exporting PDF",
          analyticsLog: analyticsLogs.secureMessaging.exportPdf.fail,
        });
      });
  };

  return {
    exportPdfState: processState,
    exportPdfErrorMessage: processErrorMessage,
    setExportPdfState: setProcessState,
    exportPDFHandler,
    renderExportPdfButton: () => (
      <Tooltip title="Export eConsult as PDF">
        <Button
          color="primary"
          variant="outlined"
          startIcon={<PictureAsPdfIcon />}
          onClick={exportPDFHandler}
        >
          Export
        </Button>
      </Tooltip>
    ),
  };
};

export default useExportPDF;
