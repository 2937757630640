import { fbFunctions } from "../firebase";

type TypesenseCollectionName = "services" | "users";

const getTypesenseApiKey = (
  collectionName: TypesenseCollectionName,
): Promise<string> => {
  // Make the backend function call
  return fbFunctions
    .httpsCallable("typesense-getTypesenseApiKey")({ collectionName })
    .then((result) => {
      return Promise.resolve(result.data.apiKey);
    });
};

export default getTypesenseApiKey;
