import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import CircularProgress from "@mui/material/CircularProgress";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import CircularProgressWithLabel from "../CircularProgressWithLabel";

const useStyles = makeStyles()(() => ({
  loading: {
    marginTop: "50px",
  },
}));

interface LoadingPageProps {
  label: string;
  value?: number;
}

const LoadingPage: FC<LoadingPageProps> = ({ label, value }) => {
  const { classes } = useStyles();

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <div className={classes.loading}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
              {value !== undefined && (
                <CircularProgressWithLabel value={value} />
              )}
              {value === undefined && <CircularProgress />}
            </Grid>
            <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
              <Typography>{label}</Typography>
            </Grid>
          </Grid>
        </div>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default LoadingPage;
