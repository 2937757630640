import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { PopoverOrigin } from "@mui/material/Popover";
import {
  CSSProperties,
  FC,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  useState,
} from "react";

interface DropdownMenuButtonProps {
  /** Text to provide the button component. If undefined, the button will be an IconButton */
  text?: string;
  /** Start Icon to provide the button component. If text is undefined, this will be used as the IconButton icon */
  buttonIcon?: ReactNode;
  /** End icon to provide the button component */
  dropdownIcon?: ReactNode;
  /** Optionally provide a custom button to render. text, buttonIcon, and dropdownIcon will be ignored */
  customButton?: ReactNode;
  /** Origin for the popup menu*/
  transformOrigin?: PopoverOrigin;
  /** Origin for where the popup menu will be anchored to the button */
  anchorOrigin?: PopoverOrigin;
  /** If true, popup will automatically close once an option is selected. Default true */
  autoClose?: boolean;
  style?: CSSProperties;
  children: ReactNode;
}

const DropdownMenuButton: FC<DropdownMenuButtonProps> = ({
  text,
  buttonIcon,
  dropdownIcon = <ArrowDownIcon />,
  customButton,
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  transformOrigin = { vertical: "top", horizontal: "center" },
  autoClose = true,
  style,
  children,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {customButton &&
        cloneElement(customButton as ReactElement, {
          onClick: handleClick,
          style,
        })}
      {!customButton && (
        <>
          {/* Pass rest to button so it can render properly in buttonGroups */}
          {text === undefined ? (
            <IconButton
              {...rest}
              style={style}
              onClick={handleClick}
              size="large"
            >
              {buttonIcon}
            </IconButton>
          ) : (
            <Button
              {...rest}
              color="primary"
              variant="outlined"
              startIcon={buttonIcon}
              onClick={handleClick}
              endIcon={dropdownIcon}
              style={style}
            >
              {text}
            </Button>
          )}
        </>
      )}
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={autoClose ? handleClose : undefined}
      >
        {children}
      </Menu>
    </>
  );
};

interface DropdownMenuItemProps {
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: (e: MouseEvent<HTMLLIElement>) => void;
  color?:
    | "primary"
    | "secondary"
    | "inherit"
    | "textPrimary"
    | "textSecondary"
    | "error";
  className?: string;
  style?: CSSProperties;
}

export const DropdownMenuItem = forwardRef<
  HTMLLIElement,
  PropsWithChildren<DropdownMenuItemProps>
>(
  (
    { label, icon, disabled, onClick, color, className, style, children },
    ref,
  ) => {
    return (
      <MenuItem
        ref={ref}
        onClick={onClick}
        disabled={disabled}
        style={style}
        className={className}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          primary={label}
          primaryTypographyProps={{ color: color }}
        />
        {children}
      </MenuItem>
    );
  },
);

DropdownMenuItem.displayName = "DropdownMenuItem";

export default DropdownMenuButton;
