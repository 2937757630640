import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import queryString from "query-string";
import analyticsLogs from "../../../../analyticsLogs";
import { logAnalyticsEvent } from "../../../../firebase";
import {
  exportDraftPDF,
  getAsConsultDraftsCollectionType,
} from "../../SecureMessaging/Utilities/exportPDF";

interface useExportDraftPdfProps {
  draftId: string | undefined;
  subject: string;
  isDirty: boolean;
}

const useExportDraftPdf = ({
  draftId,
  subject,
  isDirty,
}: useExportDraftPdfProps) => {
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const params = queryString.parse(location.search);
  const draftCollection = getAsConsultDraftsCollectionType(params.collection);

  const exportPDFHandler = () => {
    if (draftId === undefined) return;
    if (isDirty) {
      errorHandler({
        userMessage: "Save your draft before exporting.",
      });
      return;
    }

    setProcessState(ProcessState.running);
    logAnalyticsEvent(analyticsLogs.consultDraft.export);
    return exportDraftPDF(draftCollection, draftId, subject)
      .then(() => {
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error exporting PDF",
          analyticsLog: analyticsLogs.consultDraft.exportFail,
        });
      });
  };

  return {
    exportPdfState: processState,
    exportPdfErrorMessage: processErrorMessage,
    setExportPdfState: setProcessState,
    renderExportPdfButton: () => (
      <Tooltip title="Export Draft as PDF">
        <Button
          color="primary"
          startIcon={<PictureAsPdfIcon />}
          fullWidth
          onClick={exportPDFHandler}
        >
          Export
        </Button>
      </Tooltip>
    ),
  };
};

export default useExportDraftPdf;
