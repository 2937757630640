import { Theme } from "@mui/material/styles";
import { useRoutePermissions } from "../../../components/PermissionRoute";
import { orangeButton } from "../../../sharedStyles";
import AletheaSidebarItem, {
  AletheaSidebarItemProps,
} from "./components/AletheaSidebarItem";

import { PermissionAppRoute } from "src/types/AppRoute";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    color: "white",
    minWidth: "45px",
  },
  text: {
    color: "white",
  },
  consultButtonClosed: {
    paddingLeft: theme.spacing(1),
    height: "40px",
    width: "40px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  consultButtonOpen: {
    width: "auto",
    margin: theme.spacing(1),
  },
  consultButton: {
    ...orangeButton,
    borderRadius: "10px",

    padding: theme.spacing(1),
  },
}));

interface ConsultButtonProps extends AletheaSidebarItemProps {
  route: PermissionAppRoute;
}

const ConsultButton = (props: ConsultButtonProps) => {
  const { classes, cx } = useStyles();
  const { permissionGranted } = useRoutePermissions(props.route);
  return (
    <AletheaSidebarItem
      {...props}
      label="New Consult"
      enabled={permissionGranted}
      textClass={classes.text}
      classes={{
        drawerButton: cx(
          {
            [classes.consultButtonClosed]: !props.showText,
            [classes.consultButtonOpen]: props.showText,
          },
          classes.consultButton,
        ),
        icon: classes.icon,
      }}
      icon={props.icon}
    />
  );
};

export default ConsultButton;
