import React, { FC, PropsWithChildren } from "react";

import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Theme } from "@mui/material/styles";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    height: "100%",
    padding: theme.spacing(1),
    position: "relative",
  },
  content: {
    color: theme.palette.text.secondary,
    whiteSpace: "pre-line", //Show newlines
    wordWrap: "break-word", //break lines on word to fit,
  },
}));

interface EconsultCardProps {
  title?: string;
  titleComponent?: React.ReactNode;
}

const EconsultCard: FC<PropsWithChildren<EconsultCardProps>> = ({
  title,
  titleComponent,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <Paper variant="outlined" className={classes.container}>
      {title !== undefined && <Typography gutterBottom>{title}</Typography>}
      {titleComponent !== undefined && <>{titleComponent}</>}
      <div className={classes.content}>{children}</div>
    </Paper>
  );
};

export default EconsultCard;
