import Typography from "@mui/material/Typography";
import { formatTimeslot } from "../../Models/SpecialistScheduleModel";

import { FC } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  timeslot: {
    fontSize: "0.9em",
    // Disable text select
    "-webkit-user-select": "none" /* Safari */,
    "-ms-user-select": "none" /* IE 10 and IE 11 */,
    "user-select": "none" /* Standard syntax */,
  },
}));
interface TimeslotRangeDisplayProps {
  start: string;
  end: string;
  className?: string;
}

const TimeslotRangeDisplay: FC<TimeslotRangeDisplayProps> = ({
  start,
  end,
  className,
}) => {
  const { classes, cx } = useStyles();
  return (
    <Typography className={cx(className, classes.timeslot)}>
      {formatTimeslot(start)} - {formatTimeslot(end)}
    </Typography>
  );
};

export default TimeslotRangeDisplay;
