import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { FC } from "react";

interface RemovePatientButtonProps {
  index: number;
  remove: (index?: number | number[] | undefined) => void;
  disabled: boolean;
}

const RemovePatientButton: FC<RemovePatientButtonProps> = ({
  index,
  remove,
  disabled,
}) => {
  const handleClick = () => {
    remove(index);
  };

  return (
    <IconButton onClick={handleClick} disabled={disabled} size="large">
      <CloseIcon color="primary" />
    </IconButton>
  );
};

export default RemovePatientButton;
