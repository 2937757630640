import { FieldValues } from "react-hook-form";
import { TypedControlledInputProps } from "../types";
import FormNumberField from "./FormNumberField";

function TypedFormNumberField<T extends FieldValues>(
  props: TypedControlledInputProps<T>,
) {
  return (
    <FormNumberField
      {...props}
      control={props.control as any}
      name={props.name as string}
    />
  );
}

export default TypedFormNumberField;
