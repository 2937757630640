import {
  PaperModal,
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import InputContextOutcomesSetter from "../InputContextOutcomesSetter";
import OutcomesController from "../OutcomesController";
import OutcomesInput from "../OutcomesInput";

const useStyles = makeStyles()(() => ({
  header: {
    fontSize: "1.3em",
  },
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

export const outcomes = [
  "Patient OAT candidate",
  "Patient NOT an OAT candidate",
  "Patient waiting for dental visit",
  "Dental work required (external)",
  "Dental work required (internal)",
  "OAT ordered",
  "OAT fitted",
  "Patient rejected treatment",
];

interface DentistOutcomesModalProps {
  showOutcomesMenu: boolean;
  setShowOutcomesMenu: (show: boolean) => void;
}

/**
 * Modal to show econsult outcomes
 */
const DentistOutcomesModal: FC<DentistOutcomesModalProps> = ({
  showOutcomesMenu,
  setShowOutcomesMenu,
}) => {
  const { classes } = useStyles();

  const {
    selectedOutcome,
    setSelectedOutcome,
    selectedDiagnosis,
    setSelectedDiagnosis,
    handleSubmitOutcomes,
    processState,
    processErrorMessage,
  } = OutcomesController({});

  InputContextOutcomesSetter({
    setSelectedOutcome,
    setSelectedDiagnosis,
    setShowOutcomesMenu,
  });

  const onSubmit = () => {
    handleSubmitOutcomes().then((success) => {
      if (success) setShowOutcomesMenu(false);
    });
  };

  const isDisabled = (): boolean => {
    return processState === ProcessState.running;
  };

  return (
    <PaperModal
      className={classes.modal}
      show={showOutcomesMenu}
      setShow={setShowOutcomesMenu}
      flexWidth
      flexHeight
      enablePadding
    >
      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Typography className={classes.header}>
            Please confirm the eConsult outcome and diagnosis are correct
          </Typography>
        </Grid>
        <Grid size={{ xs: 12 }}>
          <OutcomesInput
            outcomes={outcomes}
            selectedOutcome={selectedOutcome}
            setSelectedOutcome={setSelectedOutcome}
            selectedDiagnosis={selectedDiagnosis}
            setSelectedDiagnosis={setSelectedDiagnosis}
            diagnosisLabel="Enter a Diagnosis, treatment AHI, and treatment SPO2"
            diagnosisMinRows={3}
          />
        </Grid>
        <Grid size={{ xs: 12 }}>
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            successMessage="Outcomes saved."
          />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              setShowOutcomesMenu(false);
            }}
            disabled={isDisabled()}
          >
            Skip
          </Button>
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onSubmit}
            disabled={isDisabled()}
          >
            Save and Close
          </Button>
        </Grid>
      </Grid>
    </PaperModal>
  );
};

export default DentistOutcomesModal;
