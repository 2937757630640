import {
  HeightLayout,
  HeightLayoutChild,
  useMobileSizes,
  useScreenSize,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Link, useNavigate } from "react-router-dom";
import { RouteNames, getRouteFromRouteName } from "src/routes";
import ConsultButton from "../../../Dashboard/Sidebar/ConsultButton";

import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { Activity } from "../../../../../shared/types";
import useMultiPermissions from "../../../../components/usePermissions/useMultiPermissions";
import NotificationCenter from "../NotificationCenter";
import UnreadNotificationsCounter from "../NotificationCenter/UnreadNotificationsCounter";
import isNativeMobile from "./../../../../models/isNativeMobile";
import DraftsListView from "./DraftsListView";
import InboxView from "./InboxView";
import UnreadApprovalsCounter from "./UnreadApprovalsCounter";

function isUserActivityFolder(tab: string): tab is Activity.UserActivityFolder {
  return tab === "inbox" || tab === "archive";
}

export type MessagingTab =
  | "inbox"
  | "archive"
  | "drafts"
  | "notifications"
  | "approvals";

const SecureMessaging: FC = () => {
  const navigate = useNavigate();
  const [loadingPermissions, setLoadingPermissions] = useState(true);
  const { permissionDict } = useMultiPermissions({
    resourceKeys: [
      resourceKeys.econsultAndSecureMessaging,
      resourceKeys.forwardEconsult,
      resourceKeys.approveEconsult,
    ],
    setLoadingPermissions,
  });
  const granted = Object.values(permissionDict).some((value) => value === true);
  const isApprover = permissionDict[resourceKeys.approveEconsult] === true;
  const [tab, setTab] = useState<MessagingTab>("inbox");

  const [showTabs, setShowTabs] = useState(true);
  const isMobileSize = useMobileSizes();

  useEffect(() => {
    const tab = queryString.parse(location.search).tab;
    if (
      tab &&
      (tab === "inbox" ||
        tab === "archive" ||
        tab === "drafts" ||
        tab === "notifications" ||
        tab === "approvals")
    ) {
      setTab(tab);
    }
  }, [location.search]);

  const handleTab = (_: unknown, newTab: MessagingTab) => {
    // Set tab in search params if user presses back to go to this page the tab is correct
    navigate({
      search: `?tab=${newTab}`,
    });
  };

  const { heightMinusAppBar } = useScreenSize({});
  const { sizeRef, height, updateSize } = useSizeManager();

  useEffect(() => {
    updateSize();
  }, [showTabs, tab]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.tab === undefined) {
      setTab(granted ? "inbox" : "notifications");
    }
    updateSize();
  }, [granted]);

  return (
    <HeightLayout height={heightMinusAppBar}>
      {showTabs && !loadingPermissions && (
        <HeightLayoutChild flexDriver>
          {isMobileSize && (
            <ConsultButton
              route={getRouteFromRouteName(RouteNames.consult)}
              showText={true}
              icon={<AddCircleIcon />}
              linkComponent={Link}
              {...{ disableGutters: true }}
            />
          )}

          <Grid container sx={{ maxWidth: "100vw" }}>
            <Grid size="grow">
              <Tabs
                style={{ maxWidth: "100vw" }}
                value={tab}
                onChange={handleTab}
                orientation={"horizontal"}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                {granted && <Tab value="inbox" label="Inbox" />}
                {granted && <Tab value="archive" label="Archive" />}
                {granted && <Tab value="drafts" label="Drafts" />}
                {isApprover && <Tab value="approvals" label="Approvals" />}
                {isApprover && (
                  <UnreadApprovalsCounter
                    sx={{
                      top: 15,
                      right: isNativeMobile() ? 10 : 25,
                    }}
                  />
                )}
                <Tab value="notifications" label="Alethea News" />
                <UnreadNotificationsCounter
                  sx={{
                    top: 15,
                    right: 10,
                  }}
                />
              </Tabs>
            </Grid>
            {!isMobileSize && (
              <Grid size="auto">
                <ConsultButton
                  route={getRouteFromRouteName(RouteNames.consult)}
                  showText={true}
                  icon={<AddCircleIcon />}
                  linkComponent={Link}
                />
              </Grid>
            )}
          </Grid>
        </HeightLayoutChild>
      )}
      {isUserActivityFolder(tab) && granted && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <InboxView height={height} folder={tab} setShowTabs={setShowTabs} />
        </HeightLayoutChild>
      )}
      {tab === "drafts" && granted && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <DraftsListView height={height} />
        </HeightLayoutChild>
      )}
      {tab === "notifications" && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <NotificationCenter height={height} setShowTabs={setShowTabs} />
        </HeightLayoutChild>
      )}
      {tab === "approvals" && isApprover && (
        <HeightLayoutChild flexDriven ref={sizeRef}>
          <DraftsListView height={height} approvalsList />
        </HeightLayoutChild>
      )}
    </HeightLayout>
  );
};

export default SecureMessaging;
