import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import { FC, useContext, useState } from "react";

import { ProcessStatus } from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import usePermissions from "../../../../components/usePermissions";
import isNativeMobile from "../../../../models/isNativeMobile";
import AveiroInvoiceButton from "../../Aveiro/AveiroInvoiceButton";
import AveiroPaymentButton from "../../Aveiro/AveiroPaymentButton";
import { ActivityContext } from "../Inbox/Contexts/ActivityProvider";
import AddConsultantButton from "../Toolbar/AddConsultantButton";
import ConsultStatusDropdownButton from "../Toolbar/ConsultStatusDropdownButton";
import useExportPDF from "../Utilities/useExportPDF";
import ViewSecureMessageNavigation from "../ViewSecureMessageNavigation";
import sharedStyles from "../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
}));

interface MOAViewSecureMessageToolbarProps {
  goToMessages: () => void;
}

const MOAViewSecureMessageToolbar: FC<MOAViewSecureMessageToolbarProps> = ({
  goToMessages,
}) => {
  const { classes, cx } = useStyles();
  const activityContext = useContext(ActivityContext);

  const [showToolbar, setShowToolbar] = useState(true);
  const {
    exportPdfState,
    exportPdfErrorMessage,
    setExportPdfState,
    renderExportPdfButton,
  } = useExportPDF({ activityId: activityContext.activityId });
  const { granted: hasAddSpecialistPermission } = usePermissions({
    resourceKey: resourceKeys.addUserToActivity,
  });
  const { granted: hasAveiroPaymentPermission } = usePermissions({
    resourceKey: resourceKeys.createAveiroCharge,
  });

  return (
    <>
      <Box
        className={cx(classes.boxBorderColor, classes.boxPadding)}
        borderBottom={1}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <ViewSecureMessageNavigation
            showToolbar={showToolbar}
            setShowToolbar={setShowToolbar}
            goBack={activityContext.closeActivity}
            goToMessages={goToMessages}
          />
          <Grid>
            <Collapse in={showToolbar}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                {!isNativeMobile() && <Grid>{renderExportPdfButton()}</Grid>}
                {hasAveiroPaymentPermission && (
                  <Grid>
                    <AveiroPaymentButton
                      variant="button"
                      activityId={activityContext.activityId}
                    />
                  </Grid>
                )}
                {hasAveiroPaymentPermission && (
                  <Grid>
                    <AveiroInvoiceButton
                      variant="button"
                      activityId={activityContext.activityId}
                      econsult={activityContext.econsult}
                    />
                  </Grid>
                )}
                {hasAddSpecialistPermission && (
                  <Grid>
                    <AddConsultantButton
                      viewType="moa"
                      variant="button"
                      activityId={activityContext.activityId}
                      sharedActivity={activityContext.sharedActivity}
                    />
                  </Grid>
                )}
                <Grid>
                  <ConsultStatusDropdownButton viewerRole={"moa"} />
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
      <ProcessStatus
        state={exportPdfState}
        setState={setExportPdfState}
        errorMessage={exportPdfErrorMessage}
        useSnackbar={true}
      />
    </>
  );
};

export default MOAViewSecureMessageToolbar;
