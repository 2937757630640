import { thinScrollbar } from "@alethea-medical/alethea-components";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { Theme } from "@mui/material/styles";
import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { makeStyles } from "tss-react/mui";
import { logAnalyticsEvent } from "../../firebase";
import isMobileDevice from "../../models/isMobileDevice";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  container: {
    height: "100%",
    position: "relative",
  },
  viewer: {
    overflowY: "scroll",
  },
  border: {
    borderColor: theme.palette.grey[300],
  },
  buttonFloat: {
    top: 0,
    left: 0,
  },
  controls: {
    zIndex: 10,
    position: "absolute",
  },
  loadingFloat: {
    position: "absolute",
    top: 10,
    left: 10,
    zIndex: 10,
  },
  alert: {
    margin: theme.spacing(1),
  },
  noData: {
    margin: theme.spacing(3),
  },
  grabbable: {
    cursor: "grab",
  },
  pdfPage: {
    height: "100%",
    "& canvas": {
      //Center canvas on page
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
}));

interface PDFViewerProps {
  pdfURL?: string;
  noDataText?: string | React.ReactChild;
  height?: number;
  width?: number;
  disableAnalytics?: boolean;
}

const PDFViewer = ({
  pdfURL,
  noDataText,
  height,
  width,
  disableAnalytics = false,
}: PDFViewerProps) => {
  const { classes, cx } = useStyles();

  const [numPages, setNumPages] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1); //Mult by width

  const loadSuccessHandler = (pdf: any) => {
    setNumPages(pdf.numPages);
  };

  const pdfOpened = () => {
    if (!disableAnalytics) {
      logAnalyticsEvent("message_pdf_open");
    }
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.25);
  };
  const handleZoomOut = () => {
    setZoomLevel(Math.max(zoomLevel - 0.25, 0.25));
  };

  return (
    <div className={classes.container}>
      <>
        {pdfURL !== undefined && (
          <div className={cx(classes.controls, classes.buttonFloat)}>
            <Grid container direction="column">
              <Grid>
                <a href={pdfURL} target="_blank" rel="noopener noreferrer">
                  <IconButton onClick={pdfOpened} size="large">
                    {<OpenInNewIcon color="primary" />}
                  </IconButton>
                </a>
              </Grid>
              {/* Only show on non-mobile devices */}
              {!isMobileDevice() && (
                <>
                  <Grid>
                    <IconButton onClick={handleZoomIn} size="large">
                      <ZoomInIcon color="primary" />
                    </IconButton>
                  </Grid>
                  <Grid>
                    <IconButton onClick={handleZoomOut} size="large">
                      <ZoomOutIcon color="primary" />
                    </IconButton>
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        )}
        <Box
          className={cx({
            [classes.viewer]: !isMobileDevice(),
            [classes.thinScrollbar]: !isMobileDevice(),
            [classes.border]: !isMobileDevice(),
            [classes.grabbable]: isMobileDevice(),
          })}
          border={1}
          width={width}
          height={height}
        >
          {/* Show draggable PDF on mobile devices (native or web) */}
          {isMobileDevice() && (
            <TransformWrapper
              minScale={0.5}
              limitToBounds={false}
              wheel={{ step: 1 }}
            >
              <TransformComponent>
                <Document
                  file={pdfURL}
                  noData={
                    <div className={classes.noData}>
                      {noDataText ? noDataText : ""}
                    </div>
                  }
                  onLoadSuccess={loadSuccessHandler}
                  loading={<LinearProgress />}
                  error={
                    <MuiAlert className={classes.alert} severity="error">
                      Error loading PDF
                    </MuiAlert>
                  }
                >
                  {[...Array(numPages)].map((_, index) => (
                    <Page
                      key={`page_${index}`}
                      pageIndex={index}
                      width={width}
                    />
                  ))}
                </Document>
              </TransformComponent>
            </TransformWrapper>
          )}
          {/* Show default viewer on desktop */}
          {!isMobileDevice() && (
            <Document
              file={pdfURL}
              noData={
                <div className={classes.noData}>
                  {noDataText ? noDataText : ""}
                </div>
              }
              onLoadSuccess={loadSuccessHandler}
              loading={<LinearProgress />}
              error={
                <MuiAlert className={classes.alert} severity="error">
                  Error loading PDF
                </MuiAlert>
              }
            >
              {[...Array(numPages)].map((_, index) => (
                <Page
                  className={classes.pdfPage}
                  key={`page_${index}`}
                  pageIndex={index}
                  scale={zoomLevel}
                  width={width}
                />
              ))}
            </Document>
          )}
        </Box>
      </>
    </div>
  );
};

export default PDFViewer;
