import { PaperModal } from "@alethea-medical/alethea-components";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC, useState } from "react";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
}));

interface MediaEditDeleteProps {
  mediaDelete: () => void;
}

const MediaEditDelete: FC<MediaEditDeleteProps> = ({ mediaDelete }) => {
  const { classes } = useStyles();
  const [show, setShow] = useState(false);

  const showConfirmationHandler = () => {
    setShow(true);
  };

  const handleDelete = () => {
    mediaDelete();
    setShow(false);
  };

  return (
    <>
      <PaperModal
        show={show}
        setShow={setShow}
        enablePadding
        flexHeight
        flexWidth
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid size={{ xs: 12 }}>
            <Typography>Are you sure?</Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Button
              variant="contained"
              className={classes.dangerButton}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </PaperModal>
      <Grid container spacing={1} alignItems="center">
        <Grid size={{ xs: 1 }}>
          <IconButton onClick={showConfirmationHandler} size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};

export default MediaEditDelete;
