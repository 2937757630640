import {
  HeightLayout,
  HeightLayoutChild,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import { Grid2 as Grid, Theme, Typography } from "@mui/material";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import LeavePagePrompt from "../../../../components/LeavePagePrompt";
import SaveDiscardToolbar from "../../../../components/SaveDiscardToolbar";
import AddCategoryButton from "../../SpecialistTemplates/AddCategoryButton"; // Component is the same, its okay to import from SpecialistTemplates
import useEditButtons from "../../SpecialistTemplates/useEditButtons"; // Component is the same, its okay to import from SpecialistTemplates
import ResourcesController from "../ResourcesController";
import ResourcesInputController from "../ResourcesInputController";
import Category from "./Category";

const useStyles = makeStyles()((theme: Theme) => ({
  toolbar: {
    padding: theme.spacing(1),
  },
}));

interface ResourcesPageViewProps {
  /** If true, edit will be enabled by default and edit button will not show */
  alwaysEnableEditing?: boolean;
  /** Optional height value to pass to HeightLayout  */
  height?: number | string;
  /** If true, drag handle is hidden even when editing */
  disableDrag?: boolean;
}

const ResourcesPageView: FC<ResourcesPageViewProps> = ({
  alwaysEnableEditing,
  height,
  disableDrag,
}) => {
  const { classes } = useStyles();

  const controller = ResourcesController();
  const { processState, setProcessState } = useProcessState({});

  const modifyProcessState = (newState: ProcessState) => {
    setProcessState(newState);
  };

  const inputController = ResourcesInputController({ modifyProcessState });
  const { isEditing, EditButton } = useEditButtons({
    unsavedChanges: controller.unsavedChanges,
    alwaysEnableEditing,
  });

  return (
    <>
      <HeightLayout height={height}>
        {!alwaysEnableEditing && (
          <Typography variant="h4"> Resources </Typography>
        )}
        <HeightLayoutChild flexDriver className={classes.toolbar}>
          {!alwaysEnableEditing && EditButton}
          {(alwaysEnableEditing || isEditing) && (
            <SaveDiscardToolbar
              show={controller.unsavedChanges}
              modalText={"Discard changes to your resources?"}
              saveHandler={controller.saveTemplates}
              discardHandler={controller.discardChanges}
            />
          )}
        </HeightLayoutChild>
        <HeightLayoutChild flexDriven allowOverflowY>
          <DragDropContext onDragEnd={controller.onDragEndHandler}>
            <Droppable droppableId={"category"} type="category">
              {(provided) => (
                <Grid
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  container
                  direction={"column"}
                  spacing={2}
                  sx={{ padding: 1 }}
                >
                  {controller.categoryOrder.map((categoryId, index) => {
                    if (controller.categories[categoryId] === undefined)
                      return null;
                    const id = `category_${categoryId}`;
                    return (
                      <Grid size="grow" key={id}>
                        <Category
                          index={index}
                          category={controller.categories[categoryId]}
                          deleteCategoryHandler={
                            controller.deleteCategoryHandler
                          }
                          updateCategoryHandler={
                            controller.updateCategoryHandler
                          }
                          resources={controller.resources}
                          addResourceHandler={controller.addResourceHandler}
                          deleteResourceHandler={
                            controller.deleteResourceHandler
                          }
                          updateResourceHandler={
                            controller.updateResourceHandler
                          }
                          isEditing={isEditing}
                          disableDrag={disableDrag}
                          resourceInsertHandler={
                            inputController.resourceInsertHandler
                          }
                        />
                      </Grid>
                    );
                  })}
                  {provided.placeholder}
                  {isEditing && (
                    <Grid size="grow">
                      <AddCategoryButton
                        addCategoryHandler={controller.addCategoryHandler}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </HeightLayoutChild>
        <ProcessStatus
          state={processState}
          successMessage="Resource shared successfully"
          hideProgressBar
        />
      </HeightLayout>
      <LeavePagePrompt isDirty={controller.unsavedChanges} />
    </>
  );
};

export default ResourcesPageView;
