import { MaskitoMask } from "@maskito/core";
import { Province } from "shared/types";

/**
 * Masks for each province's PHN.
 * FOR USE IN THIS FILE ONLY. If needed elsewhere, use one of the functions or create one instead.
 */
const ProvincePHNMasks: {
  [key in Province | "default"]: MaskitoMask;
} = {
  AB: [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
  BC: [/\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/],
  MB: [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/],
  NB: [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/],
  NL: [
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
  ],
  NS: [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/],
  NT: ["N", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  NU: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  ON: [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /[A-Z]/,
    /[A-Z]/,
  ],
  PE: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  QC: [
    /[A-Z]/,
    /[A-Z]/,
    /[A-Z]/,
    /[A-Z]/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ],
  SK: [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/],
  YT: [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
  default: /.*/,
} as const;

/**
 * Gets the mask for a PHN based on the province, or defaults to Alberta if it is undefined.
 * FOR INTERNAL USE ONLY UNTIL OTHER PHNs ARE VERIFIED
 * @param maybeProvince
 * @returns MaskitoMask
 */
const getLikelyPHNMaskFromProvince = (maybeProvince?: Province) =>
  maybeProvince ? ProvincePHNMasks[maybeProvince] : ProvincePHNMasks.AB;

/**
 * Gets the mask for a PHN based on the province, or defaults to accept any input.
 * Currently only AB and BC have specific masks.
 * @param maybeProvince
 * @returns MaskitoMask
 */
export const getPHNMaskFromProvince = (maybeProvince?: Province) => {
  if (maybeProvince === "AB" || maybeProvince === "BC") {
    return getLikelyPHNMaskFromProvince(maybeProvince);
  }
  return ProvincePHNMasks.default;
};
/**
 * Gets the length of the PHN of the given province, or defaults to Alberta if it is undefined.
 * @param maybeProvince
 * @returns number
 */
export const getPHNLength = (maybeProvince?: Province) => {
  const mask = getLikelyPHNMaskFromProvince(maybeProvince);
  if (Array.isArray(mask)) {
    return mask.length;
  }
  return 9;
};

/**
 * Gets an example PHN for the given province, or defaults to Alberta if it is undefined.
 * @param maybeProvince
 * @returns number
 */
export const getExamplePHN = (maybeProvince?: Province) => {
  const mask = getLikelyPHNMaskFromProvince(maybeProvince);
  if (Array.isArray(mask)) {
    return mask
      .map((maskSegment) => {
        if (maskSegment instanceof RegExp) {
          if (String(maskSegment) === "/[A-Z]/") return "A";
          return "0";
        }
        return maskSegment;
      })
      .join("");
  }
  return "PHN";
};

/**
 * Removes all non-alphanumeric characters and lowercase letters from a PHN.
 * @param phn string
 * @returns string
 */
export const stripPHN = (phn?: string) =>
  phn?.replaceAll(/[^\dA-Z]/g, "") ?? "";
