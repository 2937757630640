import {
  ProcessState,
  ProcessStatus,
  useMobileSizes,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Resources } from "@alethea-medical/aletheamd-types";
import { ExpandLess, ExpandMore, Menu } from "@mui/icons-material";
import Description from "@mui/icons-material/Description";
import {
  Collapse,
  FormHelperText,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";

import { Draggable } from "@hello-pangea/dnd";
import { FC, useContext, useRef, useState } from "react";
import { FileRejection } from "react-dropzone";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../../AuthProvider";
import InternalStateTextField from "../../../../components/InternalStateTextField";
import useFileDrop from "../../../../components/useFileDrop";
import { FileDropReturnTypes } from "../../../../components/useFileDrop/useFileDrop";
import { fbStorage, logAnalyticsEvent } from "../../../../firebase";
import isMobileDevice from "../../../../models/isMobileDevice";
import DeleteButton from "../DeleteButton";
import InsertResourceButton from "../InsertResourceButton";

const useStyles = makeStyles()((theme: Theme) => ({
  resourceEditing: {
    padding: theme.spacing(1),
  },
  resourceNotEditing: {
    padding: theme.spacing(1),
  },
  expandArea: {
    cursor: "pointer",
  },
  resourceText: {
    marginTop: theme.spacing(1),
    fontSize: "1.1em",
  },
  breakWords: {
    whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
    wordWrap: "break-word", //break lines on word to fit
  },
  dragging: {
    backgroundColor: theme.palette.grey[300],
  },
}));

interface ResourceProps {
  index: number;
  resource: Resources.Resource;
  deleteResourceHandler: (resourceId: string, index: number) => void;
  updateResourceHandler: (newResource: Resources.Resource) => void;
  isEditing: boolean;
  resourceInsertHandler: (resource: Resources.Resource) => void;
  defaultExpand?: boolean;
  disableDrag?: boolean;
}

type FileExtension = "jpg" | "jpeg" | "png" | "pdf" | "docx" | "txt"; // Acceptable file types
function isFileExtension(ext: string): ext is FileExtension {
  return ["jpg", "jpeg", "png", "pdf", "docx", "txt"].includes(ext);
}

const Resource: FC<ResourceProps> = ({
  index,
  resource,
  deleteResourceHandler,
  updateResourceHandler,
  isEditing,
  resourceInsertHandler,
  defaultExpand,
  disableDrag,
}) => {
  const { classes, cx } = useStyles();
  const [expand, setExpand] = useState(defaultExpand);
  const isMobileSize = useMobileSizes();
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const prevFileNameTimestampRef = useRef<number | null>(null);
  const authContext = useContext(AuthContext);

  function createStoragePath(fileExt: FileExtension) {
    let timestamp = new Date().getTime();

    // See useUploadMediaFile.tsx for the logic & reasoning behind this
    if (prevFileNameTimestampRef.current !== null) {
      if (
        timestamp === prevFileNameTimestampRef.current ||
        timestamp < prevFileNameTimestampRef.current
      ) {
        timestamp = prevFileNameTimestampRef.current + 1;
      }
    }

    prevFileNameTimestampRef.current = timestamp;

    return `resources/${authContext.uid}/${timestamp}.${fileExt}`;
  }

  const uploadFile = async (
    blob: Blob,
    fileName: string,
    fileExt: FileExtension,
  ) => {
    if (authContext.uid === "")
      return Promise.reject(
        new Error("You must be signed in to upload images or videos"),
      );

    logAnalyticsEvent(`uploading_resource_${fileExt}_file_start`);

    return fbStorage
      .ref(createStoragePath(fileExt))
      .put(blob)
      .then(async (result) => {
        logAnalyticsEvent(`uploading_resource_${fileExt}_file_success`);

        const url = await fbStorage.ref(result.ref.fullPath).getDownloadURL();

        const metadata = {
          fileName: fileName,
          filePath: result.ref.fullPath,
          fileDownloadUrl: url,
        };

        return metadata;
      });
  };

  const dropRejectedHandler = (fileRejections: FileRejection[]) => {
    errorHandler({
      userMessage: fileRejections[0].errors[0].message,
    });
  };

  const {
    files,
    removeFiles,
    createFileList,
    createFileThumbs,
    createDropzone,
  }: FileDropReturnTypes = useFileDrop({
    disabled: processState === ProcessState.running,
    maxFiles: 1,
    onDropRejected: dropRejectedHandler,
    acceptedFileTypes: {
      "image/jpg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "text/plain": [".txt"],
    },
  });

  const handleSubmit = () => {
    setProcessState(ProcessState.running);
    let fileExtensionFailure = false;

    if (Object.keys(files).length > 0) {
      const uploadPromises = Object.keys(files).map((key: string) => {
        const file = files[key].file;
        const fileName = file.name.split(".")[0];
        const fileExtension = file.name.split(".")[1];

        if (!isFileExtension(fileExtension)) {
          fileExtensionFailure = true;
          return Promise.resolve({ success: false, key: "" });
        }

        return uploadFile(file, fileName, fileExtension)
          .then((metadata) => {
            // Save the file's location for retrieval later
            newFileUploadHandler(
              metadata.fileDownloadUrl,
              metadata.fileName,
              metadata.filePath,
            );

            return {
              name: file.path,
              key: key,
              success: true,
            };
          })
          .catch((error: Error) => {
            console.error(error);
            return {
              name: file.path,
              key: key,
              success: false,
            };
          });
      });

      const successFileUploadKeys: string[] = [];

      Promise.all(uploadPromises).then((results) => {
        let oneFailed = false;
        results.forEach((result) => {
          if (!result.success) {
            oneFailed = true;
          } else {
            successFileUploadKeys.push(result.key);
          }
        });
        removeFiles(successFileUploadKeys);

        if (oneFailed) {
          errorHandler({
            userMessage: fileExtensionFailure
              ? "Only jpg, jpeg, png, pdf, and docx files are allowed."
              : "The remaining files failed to upload.",
          });

          // Clear the images
          removeFiles(Object.keys(files));
        } else {
          setProcessState(ProcessState.success);

          setTimeout(() => {
            setProcessState(ProcessState.idle);
          }, 1000);
        }
      });
    } else {
      errorHandler({
        userMessage: "Select at least one file.",
      });
      setTimeout(() => {
        setProcessState(ProcessState.idle);
      }, 1000);
    }
  };

  const resourceNameChangeHandler = (resourceName: string) => {
    const newResource = { ...resource };
    newResource.resourceName = resourceName;
    updateResourceHandler(newResource);
  };

  const newFileUploadHandler = (
    downloadUrl: string,
    fileName: string,
    filePath: string,
  ) => {
    const newResource = { ...resource };
    newResource.downloadUrl = downloadUrl;
    newResource.fileName = fileName;
    newResource.filePath = filePath;
    updateResourceHandler(newResource);
  };

  return (
    <Draggable
      draggableId={resource.id}
      index={index}
      isDragDisabled={!isEditing}
    >
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={cx({
            [classes.resourceEditing]: isEditing,
            [classes.resourceNotEditing]: !isEditing,
            [classes.dragging]: snapshot.isDragging,
          })}
        >
          <Grid container spacing={1} alignItems="center">
            <Grid {...provided.dragHandleProps}>
              {isEditing && !disableDrag && <Menu />}
            </Grid>
            <Grid size={{ xs: 6 }}>
              {isEditing ? (
                <InternalStateTextField
                  value={resource.resourceName}
                  setValue={resourceNameChangeHandler}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  rules={(value: string) =>
                    value === "" ? "Template name cannot be empty." : undefined
                  }
                />
              ) : (
                <Typography>{resource.resourceName}</Typography>
              )}
            </Grid>
            {/* If editing, or an image exists, can expand drop down to upload. Otherwise have empty grid */}
            {resource.downloadUrl || isEditing ? (
              <Grid
                size={{
                  xs: "grow",
                }}
                className={classes.expandArea}
                onClick={() => {
                  setExpand(!expand);
                }}
              >
                {expand ? <ExpandLess /> : <ExpandMore />}
              </Grid>
            ) : (
              <Grid size={{ xs: "grow" }} className={classes.expandArea}></Grid>
            )}

            {isEditing && (
              <Grid>
                <DeleteButton
                  deleteHandler={deleteResourceHandler}
                  id={resource.id}
                  index={index}
                  name={resource.resourceName}
                  type="resource"
                />
              </Grid>
            )}
            {!isEditing && (
              <Grid>
                <InsertResourceButton
                  resource={resource}
                  resourceInsertHandler={resourceInsertHandler}
                  disabled={!resource.downloadUrl}
                />
              </Grid>
            )}
            <Grid size={{ xs: 12 }}>
              <Collapse in={expand}>
                {isEditing ? (
                  <Grid size={{ xs: 12 }}>
                    {/* This element is to download & view the already existing file IF it exists */}
                    {resource.downloadUrl && (
                      <>
                        <Typography
                          style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                        >
                          Current Resource
                        </Typography>

                        <Button
                          variant="outlined"
                          color="primary"
                          startIcon={<Description />}
                          href={resource.downloadUrl ?? ""}
                          target="_blank"
                        >
                          {resource.fileName}
                        </Button>
                      </>
                    )}

                    <Typography
                      style={{ marginTop: "0.5em", marginBottom: "0.5em" }}
                    >
                      Upload New Resource
                    </Typography>

                    {createDropzone(
                      isMobileDevice()
                        ? "Tap to Upload"
                        : "Click Here or Drag and Drop to Upload",
                      isMobileSize ? "100px" : undefined,
                    )}
                    <FormHelperText>You can only upload 1 file</FormHelperText>

                    <Grid size={{ xs: 12 }}>
                      <ProcessStatus
                        state={processState}
                        successMessage="File uploaded successfully"
                        errorMessage={processErrorMessage}
                      />
                    </Grid>

                    <Grid size={{ xs: 12 }}>{createFileList()}</Grid>
                    <Grid size={{ xs: 12 }}>{createFileThumbs()}</Grid>
                    <Grid>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={
                          processState === ProcessState.running ||
                          Object.keys(files).length <= 0
                        }
                      >
                        Upload File
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid size={{ xs: 12 }}>
                    {resource.downloadUrl && (
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<Description />}
                        href={resource.downloadUrl ?? ""}
                        target="_blank"
                      >
                        {resource.fileName}
                      </Button>
                    )}
                  </Grid>
                )}
              </Collapse>
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

export default Resource;
