import MessageIcon from "@mui/icons-material/Message";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import usePushNotifications from "./usePushNotifications";

//This is a component so that it can live within the react router scope
const PushNotifications: FC = () => {
  const { androidMsg, setAndroidMsg, route, setRoute } = usePushNotifications();
  const navigate = useNavigate();
  const handleClose = () => {
    setAndroidMsg(undefined);
    setRoute(undefined);
  };

  const handleClick = () => {
    if (route !== undefined) {
      navigate(route);
      handleClose();
    }
  };
  return (
    <Snackbar
      open={androidMsg !== undefined}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity="info"
        style={{ width: "100%" }}
        icon={<MessageIcon />}
      >
        <div onClick={handleClick}>{androidMsg}</div>
      </Alert>
    </Snackbar>
  );
};

export default PushNotifications;
