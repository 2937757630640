import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { FC, useCallback, useEffect, useMemo } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";

interface LeavePagePromptProps {
  isDirty: boolean;
  message?: string;
  title?: string;
}

const handleRouteChange = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = true;
  return true;
};

// TODO we should get rid of this and save state to session storage

const LeavePagePrompt: FC<LeavePagePromptProps> = ({
  isDirty,
  message = "You have unsaved changes, are you sure you want to leave?",
  title = "Unsaved Changes",
}) => {
  const shouldBlock: BlockerFunction = useCallback(
    ({ currentLocation, nextLocation }) =>
      isDirty &&
      (currentLocation.pathname !== nextLocation.pathname ||
        currentLocation.search !== nextLocation.search),
    [isDirty],
  );
  const blocker = useBlocker(shouldBlock);
  const isBlocked = useMemo(() => blocker.state === "blocked", [blocker.state]);

  useEffect(() => {
    if (isDirty) {
      window.addEventListener("beforeunload", handleRouteChange);
    }
    return () => {
      window.removeEventListener("beforeunload", handleRouteChange);
    };
  }, [blocker.reset, isDirty]);

  return (
    <Dialog open={isBlocked}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button onClick={blocker.reset}>Cancel</Button>
        <Button onClick={blocker.proceed}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default LeavePagePrompt;
