import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  draftSubjectTextbox: {
    padding: theme.spacing(1),
    backgroundColor: palette.darkGreen,
    color: "white",
  },
}));
interface ConsultDraftSubjectProps {
  draftSubject: string;
}

const ConsultDraftSubject: FC<ConsultDraftSubjectProps> = ({
  draftSubject,
}) => {
  const { classes } = useStyles();
  return (
    <Paper className={classes.draftSubjectTextbox}>
      <Typography>
        Draft: {draftSubject !== "" ? draftSubject : "No Subject"}
      </Typography>
    </Paper>
  );
};

export default ConsultDraftSubject;
