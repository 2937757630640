import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import { Role } from "@alethea-medical/aletheamd-types";
import { Theme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FC, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { makeStyles } from "tss-react/mui";
import { fbStorage } from "../../../../../firebase";
import globalStrings from "../../../../../globalStrings";
import ordinalSuffixOf from "../../../../../models/ordinalSuffixOf";
import useCms from "../../../../../utils/useCms";
import AgreementContainer from "./AgreementContainer";
import SignatureCard from "./SignatureCard";

const RoleContractNames: { [key in Role.Role]: string } = {
  [Role.Roles.Doctor]: "mdContractMarkdown",
  [Role.Roles.Resident]: "mdContractMarkdown",
  [Role.Roles.NursePractitioner]: "npContractMarkdown",
};

const useStyles = makeStyles()((theme: Theme) => ({
  signatureContainer: {
    marginTop: theme.spacing(2),
  },
}));

export interface PlatformUseAgreementProps {
  selectedRole: Role.Role;
  requiredUserInfo: {
    firstName: string;
    lastName: string;
    email: string;
    city: string;
    province: string;
  };
  setBlob: (blob: Blob) => void;
  savedSignature: string | undefined;
  setSavedSignature: (value: string | undefined) => void;
  onSave?: () => void;
}

const error_loadContract = `An error was encountered while loading the contract. Please contact us at ${globalStrings.salesEmail} for help signing up. Error message`;

const PlatformUseAgreement: FC<PlatformUseAgreementProps> = ({
  selectedRole,
  requiredUserInfo,
  setBlob,
  savedSignature,
  setSavedSignature,
  onSave,
}) => {
  const { firstName, lastName, email, city, province } = requiredUserInfo;
  const { classes } = useStyles();

  const content = useCms({ cmsId: "contracts" });
  const [parsedContract, setParsedContract] = useState("");
  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({});

  const insertValues = (text: string, valueId: string, value: string) => {
    // Replace all by setting global flag. replaceAll is not supported in all browsers
    const re = new RegExp(`\\$\\{${valueId}\\}`, "g");
    return text.replace(re, value);
  };

  useEffect(() => {
    setProcessState(ProcessState.running);
    if (!content.isLoaded()) return;

    const contractName = RoleContractNames[selectedRole];
    const markdown = content.getValue<string>(contractName);

    if (markdown === undefined) {
      errorHandler({
        error: new Error(`${contractName} not found in CMS`),
        userMessage: error_loadContract,
      });
      return;
    }

    const today = new Date();
    let processed = markdown as string; // Guaranteed to be defined if content is loaded and no error was thrown
    processed = insertValues(
      processed,
      "string-day",
      ordinalSuffixOf(today.getDate()),
    );
    processed = insertValues(
      processed,
      "string-month",
      today.toLocaleString("en-us", { month: "long" }),
    );
    processed = insertValues(
      processed,
      "string-year",
      today.getFullYear().toString(),
    );
    processed = insertValues(
      processed,
      "string-user-name",
      `${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
    );
    processed = insertValues(
      processed,
      "string-signing-name",
      `${firstName.toUpperCase()} ${lastName.toUpperCase()}`,
    );
    processed = insertValues(processed, "string-user-email", email);
    processed = insertValues(processed, "string-city", city);
    processed = insertValues(processed, "string-province", province);
    Promise.all([
      fbStorage.ref("Agreement_pdfs/Steven_witness_sig.png").getDownloadURL(),
      fbStorage.ref("Agreement_pdfs/Alana_witness_sig.png").getDownloadURL(),
    ])
      .then(([ws1, ws2]) => {
        processed = insertValues(
          processed,
          "element-ws1",
          `<img style="height: 75px; visibility: hidden;" id="ws1" src=${ws1} />`,
        );
        processed = insertValues(
          processed,
          "element-ws2",
          `<img style="height: 75px; visibility: hidden;" id="ws2" src=${ws2} />`,
        );
        setParsedContract(processed);
        setProcessState(ProcessState.success);
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: error_loadContract,
        });
      });
  }, [content]);

  //Don't allow contract to be signed until this information is filled in
  //Province defaults to AB so don't need to check
  const isDoctorInfoNotEmpty = () => {
    return firstName !== "" && lastName !== "" && email !== "" && city !== "";
  };

  return (
    <AgreementContainer
      setBlob={setBlob}
      onSavedAnalyticsLog="signup_contract_signed"
      isSigned={savedSignature !== undefined}
      isMissingInfo={!isDoctorInfoNotEmpty()}
      onSave={onSave}
    >
      <ProcessStatus
        state={processState}
        errorMessage={processErrorMessage}
        loadingMessage="Loading..."
      />
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>
        {parsedContract}
      </ReactMarkdown>
      {processState === ProcessState.success && (
        <Grid
          container
          justifyContent="center"
          className={classes.signatureContainer}
        >
          <Grid size={{ xs: 12, md: 8, lg: 4 }}>
            <SignatureCard
              savedSignature={savedSignature}
              setSavedSignature={setSavedSignature}
            />
          </Grid>
        </Grid>
      )}
    </AgreementContainer>
  );
};

export default PlatformUseAgreement;
