import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { fbAuth, logAnalyticsEvent } from "../../firebase";
import ColumnViewContainer from "../ColumnViewContainer";
import FormNewPassword from "../FormInputFields/FormNewPassword";

const useStyles = makeStyles()((theme: Theme) => ({
  form: {
    width: "100%",
  },
  header: {
    textAlign: "center",
    marginBottom: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  typography: {
    textAlign: "center",
  },
  status: {
    marginTop: theme.spacing(2),
  },
}));

const ResetPassword: FC = () => {
  const location = useLocation();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { handleSubmit, control, trigger } = useForm({ mode: "onTouched" });

  const [oobCode, setOobCode] = useState<string>("");
  const [validated, setValidated] = useState<boolean | undefined>(undefined);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.oobCode != null && typeof params.oobCode === "string") {
      setOobCode(params.oobCode);
      fbAuth
        .verifyPasswordResetCode(params.oobCode)
        .then(() => {
          setValidated(true);
        })
        .catch((error: Error) => {
          console.error(error);
          setValidated(false);
        });
    } else {
      setValidated(false);
    }
  }, [location, queryString, setOobCode, fbAuth, setValidated, validated]);

  const onSubmit = (data: any) => {
    setProcessState(ProcessState.running);
    logAnalyticsEvent("reset_password_start");
    fbAuth
      .confirmPasswordReset(oobCode, data.password)
      .then(() => {
        logAnalyticsEvent("reset_password_success");
        setProcessState(ProcessState.success);
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "An error occurred while resetting your password",
          analyticsLog: "reset_password_failed",
        });
      });
  };

  const isDisabled = () => {
    return (
      processState === ProcessState.running ||
      processState === ProcessState.success
    );
  };

  return (
    <ColumnViewContainer>
      {validated === true && (
        <>
          <Typography component="h1" variant="h5" className={classes.header}>
            Reset Password
          </Typography>
          <Typography variant="subtitle1" className={classes.typography}>
            Enter your new password below.
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <FormNewPassword control={control} trigger={trigger} />
            <Grid size={{ xs: 12 }}>
              <Button
                className={classes.submit}
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                disabled={isDisabled()}
              >
                Reset Password
              </Button>
            </Grid>
            <Grid size={{ xs: 12 }} className={classes.status}>
              <ProcessStatus
                state={processState}
                errorMessage={processErrorMessage}
                successMessage={"Password changed"}
              />
            </Grid>
          </form>
          {processState === ProcessState.success && (
            <Button
              className={classes.submit}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate("/auth/login")}
            >
              Continue to Login
            </Button>
          )}
        </>
      )}
      {validated === false && (
        <Typography variant="subtitle1" className={classes.typography}>
          The link to reset your password is invalid or has expired.
        </Typography>
      )}
    </ColumnViewContainer>
  );
};

export default ResetPassword;
