import { PaperModal } from "@alethea-medical/alethea-components";
import Typography from "@mui/material/Typography";
import { FC } from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()(() => ({
  text: {
    textAlign: "center",
  },
}));
interface SuccessModalProps {
  text: string;
  show: boolean;
}

const SuccessModal: FC<SuccessModalProps> = ({ text, show }) => {
  const { classes } = useStyles();
  return (
    <PaperModal show={show} flexWidth flexHeight enablePadding>
      <Typography className={classes.text}>{text}</Typography>
    </PaperModal>
  );
};

export default SuccessModal;
