import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import HelpModal from "../../../../../components/HelpModal";
import SaveDiscardToolbar from "../../../../../components/SaveDiscardToolbar";
import SpecialistVacationController from "../../Controllers/SpecialistVacationController";
import VacationRow from "./VacationRow";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  containerChild: {
    margin: theme.spacing(1),
  },
  helpIcon: {
    marginLeft: theme.spacing(1),
  },
}));

interface SpecialistVacationViewProps {
  hasPhoneConsultPermission: boolean;
  height: number | string;
}

const helpText1 =
  "Family physicians will not be able to send new consults to you starting at 12:00 AM on the start date until 11:59 PM on the end date.";
const helpText2 =
  "You can choose to be unavailable for only eConsults, only Phone Consults, or both.";
const helpText3 =
  "If you choose to be unavailable for phone consults, your phone consult schedule will be overridden for the dates you select.";
const helpText4 =
  "These dates can be changed or deleted at any time. Once saved, any changes made are implemented immediately.";

const helpTextPhoneConsult = [helpText1, helpText2, helpText3, helpText4];
const helpTextNoPhoneConsult = [helpText1, helpText4];

const SpecialistVacationView: FC<SpecialistVacationViewProps> = ({
  hasPhoneConsultPermission,
  height,
}) => {
  const { classes } = useStyles();
  const {
    vacation,
    isDirty,
    addRowHandler,
    updateRowHandler,
    removeRowHandler,
    saveHandler,
    discardHandler,
    processState,
    processErrorMessage,
  } = SpecialistVacationController();

  return (
    <HeightLayout height={height}>
      <HeightLayoutChild flexDriver>
        <SaveDiscardToolbar
          className={classes.containerChild}
          show={isDirty}
          modalText={"Discard changes to your vacation settings?"}
          saveHandler={saveHandler}
          discardHandler={discardHandler}
          unsavedText="Unsaved Vacation Settings"
        />
      </HeightLayoutChild>
      <HeightLayoutChild flexDriver>
        <ProcessStatus
          className={classes.containerChild}
          state={processState}
          errorMessage={processErrorMessage}
        />
      </HeightLayoutChild>
      <HeightLayoutChild flexDriver className={classes.container}>
        <Grid container alignItems="center">
          <Grid>
            <Typography>
              Set yourself as unavailable to receive eConsults{" "}
              {hasPhoneConsultPermission && "or Phone Consults "}for a period of
              time.
            </Typography>
          </Grid>
          <Grid className={classes.helpIcon}>
            <HelpModal
              helpText={
                hasPhoneConsultPermission
                  ? helpTextPhoneConsult
                  : helpTextNoPhoneConsult
              }
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <Typography variant="subtitle1">
              Dates are shown in your current timezone.
            </Typography>
          </Grid>
        </Grid>
      </HeightLayoutChild>
      <HeightLayoutChild flexDriver className={classes.container}>
        <Button startIcon={<AddIcon />} onClick={addRowHandler}>
          Add Vacation
        </Button>
      </HeightLayoutChild>
      <HeightLayoutChild
        flexDriven
        allowOverflowY
        className={classes.container}
      >
        <Grid container spacing={1}>
          {vacation.map((v, i) => (
            <Grid size={{ xs: 12 }} key={`vacation_${v.id}_${i}`}>
              <VacationRow
                index={i}
                vacationPeriod={v.data}
                removeRowHandler={removeRowHandler}
                updateRowHandler={updateRowHandler}
                hasPhoneConsultPermission={hasPhoneConsultPermission}
              />
            </Grid>
          ))}
          <Grid>
            <Button startIcon={<AddIcon />} onClick={addRowHandler}>
              Add Vacation
            </Button>
          </Grid>
        </Grid>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default SpecialistVacationView;
