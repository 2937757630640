import {
  HeightLayout,
  HeightLayoutChild,
  useMobileSizes,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import ReactPlayer from "react-player";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import {
  UserMediaMetadataItem,
  getMediaDisplayName,
} from "../../Models/GalleryModel";
import AIPredictionsView from "../AIPredictionsView";
import MediaDownloadButton from "./MediaDownloadButton";
import MediaEditView from "./MediaEditView";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  fullHeight: {
    height: "100%",
  },
  imageContainer: {
    padding: theme.spacing(1),
  },
  video: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  image: {
    //Center image
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "100%", //Make sure image doesn't overflow horizontally
    height: "100%", //Make image take up full space given to it in heightLayout
    objectFit: "contain", //Force maintain aspect ratio even if screen gets too small
  },
  predictionsContainer: {
    padding: theme.spacing(1),
  },
  mediaContainer: {
    height: "100%", //Force height of container to 100%, otherwise on iOS images will have a huge amount of vertical whitespace
    minHeight: "200px", //If page gets too small, make page scroll instead of continually shrinking the image
  },
  mediaEditContainer: {
    padding: theme.spacing(3, 1),
  },
}));

interface MediaViewerProps {
  mediaItem: UserMediaMetadataItem;
  height?: number;
  closeMediaItem: () => void;
  /** Handler to edit media in state. If provided, the media viewer will show the option to edit */
  modifyItemHandler?: (id: string, newItem: UserMediaMetadataItem) => void;
  /** Handler to remove media in state. If provided, the media viewer will show the option to delete */
  removeMediaItems?: (ids: string[]) => void;
  /** Use full width for media edit view and ai predictions */
  fullWidth?: boolean;
  /** Labels provided by doctor to be passed to ai predictions view */
  doctorLabels?: string[];
  /** Allow AI labels to be cached in the database. Disable if using mock media items. Default true */
  allowAIDatabaseCaching?: boolean;
}

const MediaViewer = ({
  mediaItem,
  height,
  closeMediaItem,
  modifyItemHandler,
  removeMediaItems,
  fullWidth,
  doctorLabels,
  allowAIDatabaseCaching = true,
}: MediaViewerProps) => {
  const { classes } = useStyles();
  const isMobileSize = useMobileSizes();
  const { sizeRef, width } = useSizeManager();
  return (
    <>
      <HeightLayout ref={sizeRef} height={height} className={classes.root}>
        <HeightLayoutChild flexDriver>
          <Grid container justifyContent="space-evenly" alignItems="center">
            <Grid>
              <IconButton onClick={closeMediaItem} size="large">
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="subtitle1">
                {getMediaDisplayName(mediaItem.data)}
              </Typography>
            </Grid>
            <Grid></Grid>
          </Grid>
        </HeightLayoutChild>
        <HeightLayoutChild flexDriver className={classes.predictionsContainer}>
          <AIPredictionsView
            mediaItem={mediaItem}
            fullWidth={fullWidth}
            doctorLabels={doctorLabels}
            modifyItem={modifyItemHandler}
            allowAIDatabaseCaching={allowAIDatabaseCaching}
          />
        </HeightLayoutChild>
        <HeightLayoutChild
          flexDriven
          allowOverflowY
          className={classes.mediaContainer}
        >
          {mediaItem.data.fileType === "video" && (
            <ReactPlayer
              className={classes.video}
              url={mediaItem.data.fileDownloadUrl}
              width={isMobileSize ? width : undefined}
              controls
            />
          )}
          {mediaItem.data.fileType === "image" && (
            <img
              className={classes.image}
              src={mediaItem.data.fileDownloadUrl}
            />
          )}
        </HeightLayoutChild>
        {modifyItemHandler !== undefined && (
          <HeightLayoutChild flexDriver className={classes.mediaEditContainer}>
            <MediaEditView
              mediaItem={mediaItem}
              closeMediaItem={closeMediaItem}
              modifyItemHandler={modifyItemHandler}
              removeMediaItems={removeMediaItems}
              fullWidth={fullWidth}
            />
          </HeightLayoutChild>
        )}
        {/* If not showing edit controls, show download button individually */}
        {modifyItemHandler === undefined && (
          <HeightLayoutChild flexDriver className={classes.mediaEditContainer}>
            <MediaDownloadButton mediaItem={mediaItem} />
          </HeightLayoutChild>
        )}
      </HeightLayout>
    </>
  );
};

export default MediaViewer;
