import { useScreenSize } from "@alethea-medical/alethea-components";
import { FC } from "react";
import ResourcesView from "./ResourcesView";

const EditResourcesPage: FC = () => {
  const { heightMinusAppBar } = useScreenSize({});
  return (
    <ResourcesView height={heightMinusAppBar} alwaysEnableEditing={true} />
  );
};

export default EditResourcesPage;
