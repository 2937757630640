import Badge from "@mui/material/Badge";
import { SxProps } from "@mui/system";
import { FC, ReactNode } from "react";
import DraftsListController from "./Controllers/DraftsListController";

type UnreadApprovalsCounterProps = {
  children?: ReactNode;
  sx?: SxProps;
};

const UnreadApprovalsCounter: FC<UnreadApprovalsCounterProps> = ({
  children,
  sx,
}: UnreadApprovalsCounterProps) => {
  const { unreadApprovals } = DraftsListController("drafts_pending_approval");

  return (
    <Badge
      badgeContent={unreadApprovals}
      color="error"
      max={99}
      overlap="rectangular"
      sx={sx}
    >
      {children}
    </Badge>
  );
};

export default UnreadApprovalsCounter;
