import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import palette from "../../palette";

const RoundedButton = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "30px",
    textTransform: "none",
    padding: theme.spacing(1, 4),
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: palette.orange,
    },
    "&:disabled": {
      backgroundColor: palette.disabledBGColor,
    },
  };
});

export default RoundedButton;
