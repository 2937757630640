import {
  AuthChildRouteName,
  AuthRouteNames,
  authChildrenRoutes,
} from "src/routes/auth/authRoutesData";
import {
  AvaRouteName,
  AvaRouteNames,
  avaChildrenRoutes,
} from "src/routes/ava/avaRoutesData";
import {
  CareFormsRouteName,
  CareFormsRouteNames,
  careFormsChildrenRoutes,
} from "src/routes/careForms/careFormsRoutesData";
import {
  DashboardChildRouteName,
  DashboardRouteNames,
  dashboardChildrenRoutes,
} from "src/routes/dashboard/dashboardRoutesData";
import { type Route } from "src/routes/types";

/**
 * Type of all keys for routes in the application.
 */
export type RouteName =
  | DashboardChildRouteName
  | AuthChildRouteName
  | AvaRouteName
  | CareFormsRouteName;

/**
 * Enum of all keys for routes in the application.
 */
export const RouteNames = {
  ...DashboardRouteNames,
  ...AuthRouteNames,
  ...AvaRouteNames,
  ...CareFormsRouteNames,
} as const;

/**
 * List of all routes in the application.
 * NOTE: USE RouteNames AS THE KEY TO ACCESS THIS OBJECT TO ENSURE TYPE SAFETY AND EASY REFACTORS.
 * See addParentPathstoChildPaths in internalHelpers.tsx for an example.
 */

export const routes: { [key in RouteName]: Route } = {
  ...dashboardChildrenRoutes,
  ...authChildrenRoutes,
  ...avaChildrenRoutes,
  ...careFormsChildrenRoutes,
} as const;
