import { SignupInfo } from "@alethea-medical/aletheamd-types";
import Grid from "@mui/material/Grid2";
import { FC, useEffect } from "react";
import { Control, UseFormTrigger, useWatch } from "react-hook-form";
import { TypedFormTextField } from "../../../../../components/FormInputFields/FormTextField";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";

interface PasswordInputProps {
  control: Control<SignupInfo.SignupInfo>;
  trigger: UseFormTrigger<SignupInfo.SignupInfo>;
  disabled: boolean;
}

const PasswordInput: FC<PasswordInputProps> = ({
  control,
  trigger,
  disabled,
}) => {
  const passwordWatch = useWatch({
    control,
    name: "password",
    defaultValue: "",
  });
  const confirmPasswordWatch = useWatch({
    control,
    name: "confirmPassword",
    defaultValue: "",
  });

  useEffect(() => {
    if (confirmPasswordWatch !== "") trigger("confirmPassword");
  }, [passwordWatch]);

  const doPasswordsMatch = (confirmPassword: string) => {
    return confirmPassword === passwordWatch
      ? undefined
      : "Passwords do not match";
  };

  const isPasswordValid = (password: string) => {
    return password.length >= 6
      ? undefined
      : "Password must be 6 or more characters";
  };

  return (
    <>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>Password</FormattedInputLabel>
        <TypedFormTextField
          name="password"
          control={control}
          disabled={disabled}
          errorTextPadding
          rules={{
            required: { value: true, message: "Password is required" },
            validate: {
              isPasswordValid: isPasswordValid,
            },
          }}
          {...{
            type: "password",
            inputProps: {
              autoComplete: "new-password",
              form: { autocomplete: "new-password" },
            },
          }}
        />
      </Grid>
      <Grid size={{ xs: 12, md: 6 }}>
        <FormattedInputLabel>Confirm Password</FormattedInputLabel>
        <TypedFormTextField
          name="confirmPassword"
          control={control}
          disabled={disabled}
          errorTextPadding
          rules={{
            required: { value: true, message: "Passwords do not match" },
            validate: {
              doPasswordsMatch,
            },
          }}
          {...{
            type: "password",
            inputProps: {
              autoComplete: "new-password",
              form: { autocomplete: "new-password" },
            },
          }}
        />
      </Grid>
    </>
  );
};

export default PasswordInput;
