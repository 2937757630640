import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import { FC, useState } from "react";

interface SearchBarProps {
  enableSearch: boolean;
  runSearch: (params: string[]) => void;
  clearSearch: () => void;
  placeholderText: string;
}

const DraftSearchBar: FC<SearchBarProps> = ({
  enableSearch,
  runSearch,
  clearSearch,
  placeholderText,
}: SearchBarProps) => {
  const [searchSubject, setSearchSubject] = useState<string>("");

  const handleSearch = () => {
    runSearch([searchSubject]);
  };

  const handleClearSearch = () => {
    setSearchSubject("");
    clearSearch();
  };

  return (
    <Grid container spacing={1} sx={{ width: "100%" }} alignItems="center">
      <Grid size="grow">
        <TextField
          value={searchSubject}
          onChange={(e) => {
            setSearchSubject(e.target.value);
          }}
          placeholder={placeholderText}
          variant="outlined"
          size="small"
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
      </Grid>
      <Grid container size="auto" spacing={1}>
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
        {enableSearch && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearSearch}
          >
            Clear
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default DraftSearchBar;
