import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";

import { FC } from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  noSelect: {
    WebkitUserSelect: "none" /* Safari */,
    msUserSelect: "none" /* IE 10 and IE 11 */,
    userSelect: "none" /* Standard syntax */,
  },
  expandButton: {
    padding: theme.spacing(1),
  },
}));

interface EconsultCardExpandHeaderProps {
  title: string;
  expand: boolean;
  setExpand: (expand: boolean) => void;
}

const EconsultCardExpandHeader: FC<EconsultCardExpandHeaderProps> = ({
  title,
  expand,
  setExpand,
}) => {
  const { classes } = useStyles();
  return (
    <Typography
      className={classes.noSelect}
      onClick={() => {
        setExpand(!expand);
      }}
    >
      {title}
      <IconButton className={classes.expandButton} size="large">
        {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Typography>
  );
};

export default EconsultCardExpandHeader;
