import {
  HeightLayout,
  HeightLayoutChild,
  useScreenSize,
} from "@alethea-medical/alethea-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { Fcm } from "../../../../../../shared/types";
import AddLinks from "../../../../../components/AddLinks";
import { formatTimeLong } from "../../../../../models/formatTime";

const useStyles = makeStyles()((theme: Theme) => ({
  notifContainer: {
    padding: theme.spacing(3),
    margin: theme.spacing(1),
  },
  body: {
    whiteSpace: "pre-wrap", //Show newlines, and don't collapse sequential spaces
  },
}));

interface NotificationViewProps {
  notification: Fcm.UserNotification;
  goBack: () => void;
}

const NotificationView: FC<NotificationViewProps> = ({
  notification,
  goBack,
}) => {
  const { classes } = useStyles();

  const { heightMinusAppBar } = useScreenSize({});

  return (
    <HeightLayout height={heightMinusAppBar}>
      <HeightLayoutChild flexDriver>
        <IconButton onClick={goBack} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </HeightLayoutChild>
      <HeightLayoutChild flexDriven allowOverflowY>
        <Paper className={classes.notifContainer}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid>
              <Typography variant="h5">{notification.title}</Typography>
            </Grid>
            <Grid>
              <Typography>
                {formatTimeLong(notification.sentAt.toDate())}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Divider />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Typography className={classes.body}>
                <AddLinks text={notification.body} />
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default NotificationView;
