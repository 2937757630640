import { PaperModal } from "@alethea-medical/alethea-components";
import { AletheaMedicalCapacitorFirebase } from "@alethea-medical/capacitor-firebase";
import { LinearProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../AuthProvider";
import { logAnalyticsEvent } from "../firebase";
import isAndroid from "../models/isAndroid";
import versionStrings from "../versionStrings";

interface ContextProps {
  updateAvailable: boolean;
  checkForUpdate: () => void;
}

export const AndroidAPKUpdateContext = React.createContext<ContextProps>({
  updateAvailable: false,
  checkForUpdate: () => {
    console.log("checkForUpdate is not yet initialized");
    return Promise.resolve();
  },
});

type AndroidAPKUpdateProviderProps = {
  children: React.ReactNode;
};

//A provider is used, so that the version can be checked at the top level on first render, and then it doesn't have to run again
//Then any component within the app can get the update status
const AndroidAPKUpdateProvider: React.FC<AndroidAPKUpdateProviderProps> = ({
  children,
}) => {
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [updating, setUpdating] = useState(false);
  const authContext = useContext(AuthContext);

  const checkForUpdate = () => {
    if (isAndroid()) {
      AletheaMedicalCapacitorFirebase.checkForNewAndroidVersion({
        currentVersionString: versionStrings.version,
      })
        .then(({ updateAvailable, newVersionString }) => {
          setUpdateAvailable(updateAvailable);
          if (newVersionString !== undefined) {
            if (
              window.confirm(
                `Version ${newVersionString} is available. Update now?`,
              )
            ) {
              setUpdating(true);
              logAnalyticsEvent(`android_install_new_version`, {
                version: newVersionString,
              });
              AletheaMedicalCapacitorFirebase.installNewAndroidVersion({
                newVersionString,
              })
                .then(() => {
                  console.log("Update complete!");
                  setUpdateAvailable(false);
                })
                .catch((error: Error) => {
                  alert(`An error occurred while updating: ${error.message}`);
                })
                .finally(() => {
                  setUpdating(false);
                });
            }
          }
        })
        .catch((error: Error) => {
          alert(
            `An error occurred while checking for an update: ${error.message}`,
          );
        });
    }
  };

  useEffect(() => {
    //Check for update after logging in
    if (authContext.authenticated) {
      //Don't check for update if we've already checked before
      if (!updateAvailable) {
        checkForUpdate();
      }
    }
  }, [authContext.authenticated]);

  return (
    <AndroidAPKUpdateContext.Provider
      value={{
        updateAvailable,
        checkForUpdate,
      }}
    >
      <PaperModal show={updating} enablePadding flexHeight>
        Downloading update...
        <LinearProgress />
      </PaperModal>
      {children}
    </AndroidAPKUpdateContext.Provider>
  );
};

export default AndroidAPKUpdateProvider;
