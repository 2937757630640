import {
  HeightLayout,
  HeightLayoutChild,
  useMobileSizes,
  useSizeManager,
} from "@alethea-medical/alethea-components";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import { FC, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import { ImageAnnotationItem } from "../../../../views/Pages/SecureMessaging/SpecialistTools/EconsultImageAnnotation/EconsultImageAnnotationModel";
import {
  UserMediaMetadataItem,
  getMediaDisplayName,
} from "../../Models/GalleryModel";
import AIPredictionsView from "../AIPredictionsView";
import MediaDownloadButton from "./MediaDownloadButton";
import MediaEditView from "./MediaEditView";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ZoomIn from "@mui/icons-material/ZoomIn";
import ZoomOut from "@mui/icons-material/ZoomOut";
import useMediaQuery from "@mui/material/useMediaQuery";
import palette from "./../../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  fullHeight: {
    height: "100%",
  },
  video: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  image: {
    //Center image
    display: "block",
    maxHeight: "100%",
    maxWidth: "100%", //Make sure image doesn't overflow horizontally
    //? Removed because after zooming in there was a large amount of whitespace over the image in mobile (should be tested further)
    // height: "100%", //Make image take up full space given to it in heightLayout
    // objectFit: "contain", //Force maintain aspect ratio even if screen gets too small
  },
  predictionsContainer: {
    padding: theme.spacing(1),
  },
  mediaContainer: {
    height: "100%", //Force height of container to 100%, otherwise on iOS images will have a huge amount of vertical whitespace
    minHeight: "200px", //If page gets too small, make page scroll instead of continually shrinking the image
    overflowX: "auto",
    // Manually created scrollbar:
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: palette.darkGreen,
    },
    // Firefox scrollbar styles:
    scrollbarWidth: "thin",
    scrollbarColor: `${palette.darkGreen} auto`, // Adjust according to your theme structure
  },
  mediaEditContainer: {
    padding: theme.spacing(3, 1),
  },
  imageManipulateContainer: {
    width: "100%",
    justifyContent: "center",
    padding: theme.spacing(1),
    display: "flex",
  },
}));

interface MultiMediaViewerProps {
  mediaItems: UserMediaMetadataItem[];
  currentMediaItem: UserMediaMetadataItem;
  height?: number;
  closeMediaItem: () => void;
  /** Handler to edit media in state. If provided, the media viewer will show the option to edit */
  openMediaItem: (item: UserMediaMetadataItem | ImageAnnotationItem) => void;
  modifyItemHandler?: (id: string, newItem: UserMediaMetadataItem) => void;
  /** Handler to remove media in state. If provided, the media viewer will show the option to delete */
  removeMediaItems?: (ids: string[]) => void;
  /** Use full width for media edit view and ai predictions */
  fullWidth?: boolean;
  /** Labels provided by doctor to be passed to ai predictions view */
  doctorLabels?: string[];
  /** Allow AI labels to be cached in the database. Disable if using mock media items. Default true */
  allowAIDatabaseCaching?: boolean;
  /** Hide AI predictions view */
  hideAi?: boolean;
}

const MultiMediaViewer: FC<MultiMediaViewerProps> = ({
  mediaItems,
  currentMediaItem,
  height,
  closeMediaItem,
  openMediaItem,
  modifyItemHandler,
  removeMediaItems,
  fullWidth,
  doctorLabels,
  hideAi,
  allowAIDatabaseCaching = true,
}) => {
  const { classes } = useStyles();
  const isMobileSize = useMobileSizes();
  const { sizeRef, width } = useSizeManager();
  const matchesCustomBreakpoint = useMediaQuery("(min-width:380px)"); // This is true when screen size >=380px

  // If index is negative, or over the limit loop around
  const changeItem = (change: number) => {
    setImageZoom(1); // Reset zoom
    const currentIndex = currentMediaItem?.id;
    if (currentIndex) {
      const currentIndexNumber = mediaItems.findIndex(
        (item) => item.id === currentIndex,
      );
      // add mediaItems.length to ensure positive number
      const newIndex =
        (currentIndexNumber + change + mediaItems.length) % mediaItems.length;
      const newMediaItem = mediaItems[newIndex];
      openMediaItem(newMediaItem);
    }
  };

  const goToPreviousMediaItem = () => {
    changeItem(-1);
  };
  const goToNextMediaItem = () => {
    changeItem(1);
  };
  //* ************************

  //* ZOOM
  const maxZoom = 4;
  const minZoom = 1;
  const zoomAmount = 0.5;
  const [imageZoom, setImageZoom] = useState(minZoom);
  const parentImageContainerRef = useRef<HTMLDivElement>(null);
  const childImageContainerRef = useRef<HTMLDivElement>(null);

  const updateScrollbars = () => {
    if (!parentImageContainerRef.current || !childImageContainerRef.current)
      return;

    const parentHeight =
      parentImageContainerRef.current.getBoundingClientRect().height;
    const childHeight =
      childImageContainerRef.current.getBoundingClientRect().height;
    const parentWidth =
      parentImageContainerRef.current.getBoundingClientRect().width;
    const childWidth =
      childImageContainerRef.current.getBoundingClientRect().width;

    const verticalValue = (childHeight - parentHeight) / 2;
    const horizontalValue = (childWidth - parentWidth) / 2;

    parentImageContainerRef.current.scrollTo({
      top: verticalValue,
      left: horizontalValue,
    });
  };

  const zoomIn = () => {
    setImageZoom((prev) =>
      prev + zoomAmount > maxZoom ? maxZoom : prev + zoomAmount,
    );

    requestAnimationFrame(() => updateScrollbars());
  };

  const zoomOut = () => {
    setImageZoom((prev) =>
      prev - zoomAmount < minZoom ? minZoom : prev - zoomAmount,
    );

    requestAnimationFrame(() => updateScrollbars());
  };

  //* ************************

  return (
    <HeightLayout ref={sizeRef} height={height} className={classes.root}>
      <HeightLayoutChild flexDriver>
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid>
            <IconButton onClick={closeMediaItem} size="large">
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid>
            <Typography variant="subtitle1">
              {getMediaDisplayName(currentMediaItem?.data)}
            </Typography>
          </Grid>
          <Grid></Grid>
        </Grid>
      </HeightLayoutChild>
      {!hideAi && (
        <HeightLayoutChild flexDriver className={classes.predictionsContainer}>
          <AIPredictionsView
            mediaItem={currentMediaItem}
            fullWidth={fullWidth}
            doctorLabels={doctorLabels}
            modifyItem={modifyItemHandler}
            allowAIDatabaseCaching={allowAIDatabaseCaching}
          />
        </HeightLayoutChild>
      )}
      <HeightLayoutChild
        ref={parentImageContainerRef}
        allowOverflowY
        flexDriven
        className={classes.mediaContainer}
      >
        {currentMediaItem.data.fileType === "video" && (
          <ReactPlayer
            className={classes.video}
            url={currentMediaItem.data.fileDownloadUrl}
            width={isMobileSize ? width : undefined}
            controls
          />
        )}
        {currentMediaItem.data.fileType === "image" && (
          <div
            ref={childImageContainerRef}
            style={{
              transform: `scale(${imageZoom})`,
              transformOrigin: "top left", // Fix the image to the top/left corner so that after zoom only scroll towards right/bottom is needed
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              className={classes.image}
              src={currentMediaItem.data.fileDownloadUrl}
            />
          </div>
        )}
      </HeightLayoutChild>

      {modifyItemHandler !== undefined && (
        <HeightLayoutChild flexDriver className={classes.mediaEditContainer}>
          <MediaEditView
            mediaItem={currentMediaItem}
            closeMediaItem={closeMediaItem}
            modifyItemHandler={modifyItemHandler}
            removeMediaItems={removeMediaItems}
            fullWidth={fullWidth}
          />
        </HeightLayoutChild>
      )}

      {/* Change image buttons */}
      <HeightLayoutChild
        flexDriver
        className={classes.imageManipulateContainer}
      >
        <Grid container sx={{ width: "100%" }}>
          <Grid size={{ xs: 2, md: 2 }}> </Grid>

          <Grid size={{ xs: matchesCustomBreakpoint ? 8 : 12, sm: 8, md: 8 }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid>
                <IconButton onClick={goToPreviousMediaItem} size="large">
                  <ArrowBackIcon />
                </IconButton>
              </Grid>

              <Grid>
                <IconButton
                  onClick={zoomOut}
                  disabled={imageZoom == minZoom}
                  size="large"
                >
                  <ZoomOut />
                </IconButton>
              </Grid>

              <Grid>
                <IconButton
                  onClick={zoomIn}
                  disabled={imageZoom == maxZoom}
                  size="large"
                >
                  <ZoomIn />
                </IconButton>
              </Grid>

              <Grid>
                <IconButton onClick={goToNextMediaItem} size="large">
                  <ArrowForwardIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            size={{
              xs: matchesCustomBreakpoint ? 2 : 12,
              sm: 2,
              md: 2,
            }}
            style={{
              display: "flex", // Makes the Grid a flex container
              justifyContent: "flex-end", // Aligns children (the button) to the right (so the button appears to the right)
            }}
          >
            {/* If not showing edit controls, show download button individually */}
            {modifyItemHandler === undefined && (
              <MediaDownloadButton mediaItem={currentMediaItem} />
            )}
          </Grid>
        </Grid>
      </HeightLayoutChild>
    </HeightLayout>
  );
};

export default MultiMediaViewer;
