import {
  Activity,
  ConsultFormSpecialty,
} from "@alethea-medical/aletheamd-types";
import { Service } from "@alethea-medical/aletheamd-types";
import firebase from "firebase/compat/app";
import { UserMediaMetadataSelectedDict } from "../../../components/Gallery/Controllers/SelectGalleryController";
import { fbFunctions } from "../../../firebase";

export type SpecialtiesFormDict = {
  [specialty: string]: ConsultFormSpecialty;
};

/**
 * Get specialty forms from firestore
 * @returns Specialty forms dictionary. Key is the specialty. Value is a dictionary where key is subsite, and value is the form template
 */
export function fetchServiceForm(
  serviceId: string,
): Promise<Service.ServiceForm> {
  return fbFunctions
    .httpsCallable("services-getServiceForm")({ serviceId })
    .then(({ data }) => {
      return data;
    });
}

/**
 * Recursively decodes all object keys from base64 into normal text (not in-place).
 * Base64 encoding keys is necessary because react-hook-form cannot use keys that contain special characters
 *
 * @param subObj Object to decode
 * @returns Decoded object
 */
export function decodeFormObjectKeys(subObj: any): any {
  // Check if subObj is an object. If it is an object, then decode each key
  if (subObj.constructor === {}.constructor) {
    const newObj: any = {};
    Object.keys(subObj).forEach((key) => {
      const newKey = atob(key); //decode base64
      newObj[newKey] = decodeFormObjectKeys(subObj[key]);
    });
    return newObj;
  } else {
    //If no an object, return the value
    return subObj;
  }
}

/**
 * Submit Consult to the server API
 *
 * Calls serverV{version}/econsult or serverV{version}/phoneConsult depending on data.activityType
 * @param user Firebase user object to pass to serverRequest. Server request gets auth token from user. This user's UID is also used as the physicianUid on the consult
 * @param data Data passed from ConsultForm react-hook-form values
 * @param selectedMedia Selected gallery items
 * @param files Uploaded files
 * @returns
 */
export function submitConsult(
  user: firebase.User,
  data: Activity.ConsultFormFields,
  selectedMedia: UserMediaMetadataSelectedDict, // "Gallery Attachments"
  attachmentPaths: string[], // "Other Attachments"
  econsultForwarder?: Activity.EconsultForwarder,
): Promise<any> {
  const activityType = data.activityType as Activity.ActivityType;

  const inputFields: Activity.EconsultAPIFields = {
    gpUid: user.uid,
    phn: data.patientInfo.phn,
    oop: data.oop,
    locationIdx: data.locationIdx,
    galleryMediaURIs: Object.values(selectedMedia)
      .map((item) => {
        return item.data.filePath;
      })
      .concat(attachmentPaths), // Append attachment paths to referral image URIs so they will be copied over along with referral image uris
    patientFirstName: data.patientInfo.firstName,
    patientLastName: data.patientInfo.lastName,
    patientPhone: data.patientInfo.phone,
    patientEmail: data.patientInfo.email,
    patientDateOfBirth: data.patientInfo.dateOfBirth,
    emailPatient: data.emailPatient,
    specialty: data.specialty,
    subsite: data.subsite,
    serviceId: data.serviceId,
    /* Symptoms are encoded in base64 so we need to decode to get the readable text. 
    Base 64 encoding is necessary to make the form keys compatible with react-hook-form */
    symptoms: data.symptoms ? decodeFormObjectKeys(data.symptoms) : {},
  };

  if (data.billingCode) {
    inputFields.billingCode = data.billingCode;
  }

  if (inputFields.oop === true && data.oopData) {
    inputFields.oopData = {
      ...data.oopData,
      //Character length limit is handled on the admin portal side
      firstName: data.patientInfo.firstName,
      surname: data.patientInfo.lastName,
      //Format as YYYYMMDD (HLink wants it this way)
      birthDate: data.patientInfo.dateOfBirth.replace(/\//g, ""),
      provinceCode: data.patientProvince,
    };
  }

  if (data.avaData) {
    inputFields.avaData = data.avaData;
  }

  if (econsultForwarder) {
    inputFields.econsultForwarder = econsultForwarder;
  }

  // Add activity type specific fields to input fields
  switch (activityType) {
    case "econsult":
      break;
    case "phoneConsult":
      if (
        data.phoneConsultData.startTime != null &&
        data.phoneConsultData.endTime != null
      ) {
        (inputFields as Activity.PhoneConsultAPIFields).phoneConsultData = {
          startTime: data.phoneConsultData.startTime,
          endTime: data.phoneConsultData.endTime,
          physicianCalendarEmail: data.phoneConsultData.physicianCalendarEmail,
          physicianPhoneNumber: data.phoneConsultData.physicianPhoneNumber,
        };
      }
      break;
    default:
      break;
  }

  return fbFunctions.httpsCallable("consult-sendConsult")({ inputFields });
}

export function getActivityName(activityType: Activity.ActivityType): string {
  switch (activityType) {
    case "econsult":
      return "eConsult";
    case "phoneConsult":
      return "Phone Consult";
  }
}
