import { type FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logAnalyticsEvent } from "../firebase";

const Analytics: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location !== undefined) {
      logAnalyticsEvent("screen_view", {
        screen_name: window.location.pathname,
      });
    }
  }, [location]);

  return <></>;
};

export default Analytics;
