import { thinScrollbar } from "@alethea-medical/alethea-components";
import { TextField, Typography } from "@mui/material";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { TypedFormTextField } from "../../../components/FormInputFields/FormTextField";

const useStyles = makeStyles()(() => ({
  ...thinScrollbar,
  billingCodeInputBox: {
    marginTop: "0px",
    paddingRight: "5px",
  },
  inputProps: {
    minHeight: "20px",
  },
}));

interface BillingCodeTextFieldProps {
  control?: any;
  disabled: boolean;
  value: string;
  defaultValue?: string;
  onChange: (value: string) => void;
}

const BillingCodeTextField: FC<BillingCodeTextFieldProps> = ({
  control,
  disabled,
  value,
  defaultValue,
  onChange,
}) => {
  const { classes, cx } = useStyles();

  return (
    <>
      <Typography
        variant="caption"
        style={{
          color: "gray",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "20%",
        }}
      >
        Billing Code
      </Typography>

      {control ? (
        <TypedFormTextField
          name={`billingCode`}
          control={control}
          disabled={disabled}
          rules={{
            required: { value: true, message: "Billing code is required" },
            // TODO: Rules for billing codes, we know "1X" is a valid code so who knows...
            // minLength: {
            //   value: 4,
            //   message: "Billing code must be at least 4 characters long",
            // },
          }}
        />
      ) : (
        <TextField
          className={cx(classes.thinScrollbar, classes.billingCodeInputBox)}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          variant="outlined"
          fullWidth
          margin="dense"
          disabled={disabled}
          placeholder={defaultValue}
          InputProps={{
            classes: { input: classes.inputProps },
          }}
        />
      )}
    </>
  );
};

export default BillingCodeTextField;
