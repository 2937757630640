import { useEffect } from "react";
import { Control, UseFormTrigger, useWatch } from "react-hook-form";
import { Activity, PatientInfo } from "../../../../../shared/types";
import PHNInputLookup from "../../../../components/FormInputFields/PHNInput";

interface ConsultPHNProps {
  control: Control<Activity.ConsultFormFields>;
  trigger: UseFormTrigger<Activity.ConsultFormFields>;
  disabled: boolean;
  onSelectPatient: (patientInfo: PatientInfo) => void;
}

export const ConsultPHN = ({
  control,
  trigger,
  disabled,
  onSelectPatient,
}: ConsultPHNProps) => {
  const patientProvince = useWatch({ control, name: "patientProvince" });
  const phn = useWatch({ control, name: "patientInfo.phn" });

  useEffect(() => {
    if (phn) trigger("patientInfo");
  }, [patientProvince]);

  return (
    <PHNInputLookup
      name="patientInfo.phn"
      control={control}
      label="PHN"
      required
      handlePatientInfo={onSelectPatient}
      ignoreChecksum={patientProvince !== "AB" && patientProvince !== "BC"} //When we add more provinces, put this directly into PHNInputLookup.
      disabled={disabled}
      province={patientProvince}
      autocomplete
    />
  );
};
