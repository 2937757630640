const salesEmail = "sales@aletheamedical.com";
const globalStrings = {
  salesEmail: salesEmail,
  salesEmailAsLink: (
    <a href={`mailto:${salesEmail}`} target="_blank" rel="noreferrer noopener">
      {salesEmail}
    </a>
  ),
  salesPhone: "1 (587) 480-7392",
  supportEmail: "support@aletheamedical.com",
};

export default globalStrings;
