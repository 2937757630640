import { BillingRecord, Status } from "@alethea-medical/admin-types";
import {
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Theme,
} from "@mui/material";
import { endOfMonth, startOfMonth, subMonths } from "date-fns";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";
import MonthPicker from "../MonthlyBillingTable/MonthPicker";
import {
  getComparator,
  parseRecordFromAPI,
  stableSort,
} from "../billingFunctions";
import { HeadCell, Order, SortableKeys } from "../types";
import BillingTableHead from "./BillingTableHead";
import BillingTableRow from "./BillingTableRow";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  title: {
    textAlign: "center",
  },
  table: {
    overflow: "visible",
    marginTop: theme.spacing(1),
  },
  loadingStatus: {
    marginTop: theme.spacing(1),
  },
  pdfableTable: {
    width: "100%",
  },
  process: {
    marginTop: theme.spacing(1),
  },
}));

interface BillingTableProps {
  headCells: HeadCell[];
  fetchBy: "hlinkResultReceivedAt" | "status" | "created";
  statuses?: Status[];
  tableKey: string;
  defaultSort?: SortableKeys;
}

const useBillingTable = ({
  headCells,
  fetchBy,
  statuses = [],
  tableKey,
  defaultSort = "created",
}: BillingTableProps) => {
  const { classes } = useStyles();
  const rowsPerPageOptions = [5, 10, 25, 50];

  const [fromDate, setFromDate] = useState<Date>(
    subMonths(startOfMonth(new Date()), 1),
  );
  const [toDate, setToDate] = useState<Date>(
    subMonths(endOfMonth(new Date()), 1),
  );

  const createBillingReport = fbFunctions.httpsCallable(
    "reporting-createBillingReport",
  );

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  const [billingData, setBillingData] = useState<BillingRecord[]>([]);

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<SortableKeys>(defaultSort);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: SortableKeys,
  ) => {
    const isAsc = orderBy === property && order === "desc";
    setOrder(isAsc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setPage(0);
  }, []);

  const getBillingData = () => {
    setProcessState(ProcessState.running);
    let fetchOptions = {};
    switch (fetchBy) {
      case "hlinkResultReceivedAt":
        fetchOptions = {
          fetchByHlinkResultReceivedAt: {
            startDate: fromDate.getTime(),
            endDate: toDate.getTime(),
          },
        };
        break;
      case "status":
        fetchOptions = {
          fetchByStatus: {
            statuses,
          },
        };
        break;
      case "created":
        fetchOptions = {
          fetchByCreated: {
            startDate: fromDate.getTime(),
            endDate: toDate.getTime(),
            statuses,
          },
        };
        break;
    }
    createBillingReport(fetchOptions)
      .then((result) => {
        setProcessState(ProcessState.success);
        setBillingData(
          result.data.map((record: BillingRecord) => {
            return parseRecordFromAPI(record);
          }),
        );
      })
      .catch((error: Error) => {
        errorHandler({
          error: error,
          userMessage: "Error fetching billing records.",
        });
      });
  };

  useEffect(() => {
    getBillingData();
  }, [fromDate, toDate]);

  const handleMonthChange = (from: Date, to: Date) => {
    setFromDate(from);
    setToDate(to);
  };

  const createMonthPicker = () => {
    return (
      <MonthPicker
        label="Billing Period"
        fromDate={fromDate}
        setMonth={handleMonthChange}
      />
    );
  };

  const createBillingTable = () => {
    return (
      <>
        <ProcessStatus
          className={classes.process}
          state={processState}
          errorMessage={processErrorMessage}
        />
        <TableContainer>
          <Table className={classes.table} size={"small"} stickyHeader>
            <BillingTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {billingData?.length > 0 &&
                stableSort(billingData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data, index) => (
                    <BillingTableRow
                      key={`${tableKey}_table_row_${index}`}
                      index={index}
                      headCells={headCells}
                      data={data}
                    />
                  ))}

              {billingData.length === 0 && (
                <TableRow tabIndex={-1}>
                  <TableCell>No records found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component={"div"}
          count={billingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  };

  return {
    createBillingTable,
    createMonthPicker,
    billingData,
    order,
    orderBy,
    fromDate,
    toDate,
  };
};

export default useBillingTable;
