import { MaskitoMask, maskitoTransform } from "@maskito/core";
import { ElementState } from "@maskito/core/src/lib/types";
import { useMaskito } from "@maskito/react";
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";

// #region Helper Functions
/**
 * Given a string, returns whether it is a letter.
 * @param char string
 * @returns boolean
 */
const isLetter = (char: string): boolean =>
  char.length === 1 && char.toLowerCase() !== char.toUpperCase();

/**
 * Given a letter, returns string in the opposite case.
 * @param char string
 * @returns string
 */
const swapCase = (char: string) => {
  if (!isLetter(char)) {
    return char;
  }

  return char === char.toLowerCase() ? char.toUpperCase() : char.toLowerCase();
};

/**
 * Given a mask, a data string to be entered, and a state describing the current state of the text
 * and where data is to be entered, returns whether the data would be masked out.
 * @param mask MaskitoMask
 * @param data string
 * @param elementState ElementState
 * @returns boolean
 */
const dataWouldBeMaskedOut = (
  mask: MaskitoMask,
  data: string,
  elementState: ElementState,
) => {
  const currentValue = elementState.value;
  const selectionFrom = elementState.selection[0];
  const selectionTo = elementState.selection[1];
  const hypotheticalValueUnmasked = `${currentValue.slice(
    0,
    selectionFrom,
  )}${data}${currentValue.slice(selectionTo)}`;
  const hypotheticalValue = maskitoTransform(hypotheticalValueUnmasked, {
    mask,
  });

  return elementState.value === hypotheticalValue;
};
// #endregion

// #region Component
export interface MaskedInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  mask?: MaskitoMask;
  value?: string;
}

/**
 * A masked input field that uses Maskito to format the input value.
 * @param props MaskedInputProps
 * @param ref Ref<HTMLInputElement>
 * @returns JSX.Element
 */
const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(
  ({ onChange, mask, value, ...rest }: MaskedInputProps, inputRef) => {
    // Need to use this value to reformat the value when the mask changes or the value is change programatically
    const [inputValue, setInputValue] = useState(value);
    // Need this to ensure value is only reformat when the mask changes
    const [inputMask, setInputMask] = useState<MaskitoMask | undefined>(mask);
    const ref = useMaskito(
      inputMask
        ? {
            options: {
              mask: inputMask,
              preprocessors: [
                ({ elementState, data }, actionType) => {
                  if (actionType !== "insert") {
                    return { elementState, data };
                  }

                  // Make the input accept both upper and lower case letters
                  // and transform them to what the mask will accept
                  if (
                    isLetter(data) &&
                    dataWouldBeMaskedOut(inputMask, data, elementState)
                  ) {
                    return { elementState, data: swapCase(data) };
                  }

                  return { elementState, data };
                },
              ],
            },
          }
        : undefined,
    );

    // Updates the value to use the correct formatting when the mask or value props change
    useEffect(() => {
      if (
        value === undefined ||
        mask === undefined ||
        (mask === inputMask && inputValue === maskitoTransform(value, { mask }))
      )
        return;
      setInputMask(mask);
      setInputValue(maskitoTransform(value, { mask }));
    }, [value, mask, inputMask, setInputMask, setInputValue]);

    return (
      <input
        // Maskito needs a ref, but so does MUI when this is used there.
        // This allows multiple references (one for Maskito, one for MUI)
        ref={(el) => {
          if (inputRef) {
            if (typeof inputRef === "function") inputRef(el);
            else inputRef.current = el;
          }
          ref(el);
        }}
        // Maskito prevents onChange from firing, so onInput is used instead
        onInput={(e) => {
          // Sends out an onChange event to match expected React behaviour
          setInputValue(e.currentTarget.value);
          if (onChange)
            onChange({
              ...e,
              target: { ...e.currentTarget },
            });
        }}
        value={inputValue}
        {...rest}
      />
    );
  },
);

MaskedInput.displayName = "MaskedInput";

// #endregion

export default MaskedInput;
