import { useMobileSizes } from "@alethea-medical/alethea-components";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "tss-react/mui";
import SectionHeader from "../Styling/section/SectionHeader";
import SectionNumber from "../Styling/section/SectionNumber";

const useStyles = makeStyles()((theme: Theme) => ({
  contentContainerWeb: {
    paddingLeft: theme.spacing(9),
  },
}));

interface SectionContainerProps {
  title?: string;
  number?: number;
  children: React.ReactNode;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  title,
  number,
  children,
}) => {
  const { classes, cx } = useStyles();

  const isMobileSize = useMobileSizes();

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
      {number !== undefined && (
        <Grid>
          <SectionNumber number={number} />
        </Grid>
      )}
      {title !== undefined && (
        <Grid>
          <SectionHeader title={title} />
        </Grid>
      )}
      <Grid size={{ xs: 12 }}>
        <Grid
          className={cx({
            [classes.contentContainerWeb]: !isMobileSize,
          })}
          container
          spacing={1}
        >
          <Grid size={{ xs: 12 }}>{children}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionContainer;
