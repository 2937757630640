import { ProcessStatus } from "@alethea-medical/alethea-components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import LabelIcon from "@mui/icons-material/Label";
import NotesIcon from "@mui/icons-material/Notes";
import SaveIcon from "@mui/icons-material/Save";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import { FC, useState } from "react";
import MediaEditController from "../../Controllers/MediaEditController";
import { UserMediaMetadataItem } from "../../Models/GalleryModel";
import MediaDownloadButton from "./MediaDownloadButton";
import MediaEditDelete from "./MediaEditDelete";
import MediaEditNotes from "./MediaEditNotes";
import MediaEditTags from "./MediaEditTags";

interface MediaEditViewProps {
  mediaItem: UserMediaMetadataItem;
  closeMediaItem: () => void;
  modifyItemHandler: (id: string, newItem: UserMediaMetadataItem) => void;
  removeMediaItems?: (ids: string[]) => void;
  fullWidth?: boolean;
}

const MediaEditView: FC<MediaEditViewProps> = ({
  mediaItem,
  closeMediaItem,
  modifyItemHandler,
  removeMediaItems,
  fullWidth,
}) => {
  const {
    // states
    tags,
    notes,
    // handlers
    tagsChangedHandler,
    notesChangedHandler,
    mediaDeleteHandler,
    deleteState,
    deleteError,
  } = MediaEditController({
    mediaItem,
    closeMediaItem,
    modifyItemHandler,
    removeMediaItems,
  });

  const [showEdit, setShowEdit] = useState(false);

  const showEditHandler = () => {
    setShowEdit(!showEdit);
  };

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid
        size={{
          lg: fullWidth ? 12 : 6,
          md: fullWidth ? 12 : 8,
          sm: fullWidth ? 12 : 10,
          xs: 12,
        }}
      >
        <Grid spacing={1}>
          <Collapse in={showEdit}>
            <Grid container spacing={1} size={{ xs: 12 }}>
              <Grid container spacing={1} alignItems="center" size={{ xs: 12 }}>
                <Grid size={{ xs: 1 }}>
                  <LabelIcon color="primary" />
                </Grid>
                <Grid size={{ xs: 11 }}>
                  <MediaEditTags
                    tags={tags}
                    tagsChanged={tagsChangedHandler}
                    placeholder="Add Tags"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} size={{ xs: 12 }} alignItems="center">
                <Grid size={{ xs: 1 }}>
                  <NotesIcon color="primary" />
                </Grid>
                <Grid size={{ xs: 11 }}>
                  <MediaEditNotes
                    notes={notes}
                    notesChanged={notesChangedHandler}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
          <Grid
            container
            spacing={5}
            size={{ xs: 12 }}
            alignItems="center"
            justifyContent="center"
          >
            <Grid>
              <IconButton onClick={showEditHandler} size="large">
                {showEdit ? <SaveIcon color="primary" /> : <EditOutlinedIcon />}
              </IconButton>
            </Grid>
            <Grid>
              <MediaDownloadButton mediaItem={mediaItem} />
            </Grid>
            {removeMediaItems !== undefined && (
              <Grid>
                <MediaEditDelete mediaDelete={mediaDeleteHandler} />
                <ProcessStatus state={deleteState} errorMessage={deleteError} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MediaEditView;
