import TextField from "@mui/material/TextField";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../shared/sharedStyles";
import { ControlledInputProps } from "../types";
import isValidPhoneInput from "./isPhone";

const useStyles = makeStyles()(() => {
  const shared = sharedStyles();
  return {
    ...shared,
  };
});

// Helper function to strip non-numeric characters and remove country code
const formatPhoneNumber = (value: string) => {
  // Remove all non-numeric characters
  const numbers = value.replace(/\D/g, "");
  // Remove country code if present (assuming max 3 digits for country code)
  return numbers.length > 10 ? numbers.slice(-10) : numbers;
};

//Formats input as phone number with format (403) 123-4567
const PhoneInput: FC<ControlledInputProps> = ({
  name,
  control,
  defaultValue,
  rules,
  disabled,
  label,
  errorTextPadding,
  ...rest
}: ControlledInputProps) => {
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={Object.assign(rules ? rules : {}, {
        validate: {
          isPhone: isValidPhoneInput,
        },
      })}
      render={({ field, fieldState }) => {
        const { ref, ...restField } = field;
        return (
          <PatternFormat
            {...restField}
            inputRef={ref}
            {...rest}
            format="(###) ###-####"
            mask="_"
            customInput={TextField}
            label={label}
            disabled={disabled}
            className={classes.canDisable}
            error={fieldState.error !== undefined}
            helperText={
              errorTextPadding
                ? fieldState.error?.message
                  ? fieldState.error.message
                  : " "
                : fieldState.error?.message
            }
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="off"
            onValueChange={(values) => {
              field.onChange(values.value);
            }}
            onPaste={(e: any) => {
              e.preventDefault();
              const pastedValue = e.clipboardData.getData("text");
              const formattedValue = formatPhoneNumber(pastedValue);
              field.onChange(formattedValue);
            }}
            InputLabelProps={{
              disableAnimation: true,
            }}
            inputProps={{
              "aria-autocomplete": "none",
              list: "autocompleteOff",
            }}
          />
        );
      }}
    />
  );
};

export default PhoneInput;
