import RefreshIcon from "@mui/icons-material/Refresh";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import React from "react";

/** Summary
 * Renders buttons for controlling selections, and editing
 */

interface GalleryControlsProps {
  refresh: () => void;
  editing?: boolean;
  setEditing?: (value: boolean) => void;
  setShowUploadModal?: (value: boolean) => void;
  children?: React.ReactNode;
}

const GalleryControls: React.FC<GalleryControlsProps> = ({
  refresh,
  editing,
  setEditing,
  setShowUploadModal,
  children,
}) => {
  const selectHandler = () => {
    if (editing !== undefined && setEditing !== undefined) setEditing(!editing);
  };

  const uploadHandler = () => {
    if (setShowUploadModal !== undefined) setShowUploadModal(true);
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid>
        <IconButton onClick={refresh} size="large">
          <RefreshIcon />
        </IconButton>
      </Grid>
      {editing !== undefined && setEditing !== undefined && (
        <Grid>
          <Button
            variant={editing ? "outlined" : "contained"}
            color="primary"
            onClick={selectHandler}
          >
            {editing ? "Cancel" : "Select"}
          </Button>
        </Grid>
      )}
      {setShowUploadModal !== undefined && (
        <Grid>
          <Button variant={"contained"} color="primary" onClick={uploadHandler}>
            Upload Files
          </Button>
        </Grid>
      )}
      {children}
    </Grid>
  );
};

export default GalleryControls;
