import { getDocumentData } from "@alethea-medical/utilities";
import Favorite from "@mui/icons-material/Favorite";
import Unfavorite from "@mui/icons-material/FavoriteBorder";
import Button from "@mui/material/Button";
import React, { FC, useContext, useState } from "react";
import { useEffect } from "react";
import { Control, useWatch } from "react-hook-form";
import { AuthContext } from "../../../AuthProvider";
import { fbFirestore } from "../../../firebase";
import { FormListItem } from "./FormsSelect";
import { sortForms } from "./sortForms";
import { useFavorites } from "./useFavorites";

interface AddFavoriteProps {
  control: Control;

  disabled?: boolean;
  isLoading: boolean;
  formList: FormListItem[];
  setFormList: React.Dispatch<React.SetStateAction<FormListItem[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddFavoriteButton: FC<AddFavoriteProps> = ({
  control,
  disabled,
  formList,
  setFormList,
  isLoading,
  setLoading,
}) => {
  const selectedForm = useWatch({
    control,
    name: "selectedForm",
    defaultValue: undefined,
  });
  const [favorites, setFavorites] = useState<any[]>([]);
  const authContext = useContext(AuthContext);

  //pull from firestore the doc containing list of favorites
  useEffect(() => {
    fbFirestore
      .collection("form_users")
      .doc(authContext.uid)
      .get()
      .then(getDocumentData)
      .then((prefs: { privateForms: string[]; favoritedForms: string[] }) => {
        if (prefs.favoritedForms !== undefined)
          setFavorites(prefs.favoritedForms);
      })
      .catch((error) => {
        console.log("Error getting document: ", error);
      });
  }, [authContext.uid]);

  //ensure that formList has been populated so that initial load of favorites works
  useEffect(() => {
    if (formList.length > 0) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [formList.length]);

  //apply any changes made to the favorites
  useEffect(() => {
    if (!isLoading && favorites !== undefined) {
      const tempFormList = [...formList];
      favorites.map((favoritedItem: string) => {
        if (formList !== undefined) {
          const favoriteIndex = formList.findIndex(
            (x) => x.uid === favoritedItem,
          );
          if (favoriteIndex !== -1) {
            const tempFormItem = formList[favoriteIndex];
            tempFormItem.favoritedStatus = "Favorites";
            tempFormList[favoriteIndex] = tempFormItem;
          }
        }
      });
      setFormList(tempFormList);
      sortForms(formList);
    }
  }, [favorites, isLoading]);

  //sort the forms based on whether they are favorited or not and then alphabetically
  useEffect(() => {
    sortForms(formList);
  }, [formList]);

  //update db
  useFavorites(favorites, selectedForm);

  //favorite button press
  const handleClick = () => {
    if (selectedForm !== undefined) {
      if (favorites.includes(selectedForm.uid)) {
        //remove the favorited item from the users favorites preferences
        const index = formList.findIndex(
          (item) => item.uid === selectedForm.uid,
        );
        const tempFormList = [...formList];
        const tempFormItem = formList[index];
        tempFormItem.favoritedStatus = "Forms";
        tempFormList[index] = tempFormItem;
        setFormList(tempFormList);
        setFavorites(
          (favorites) =>
            (favorites = favorites.filter((item) => item !== selectedForm.uid)),
        );
      } else {
        //add favorited item to preferences
        setFavorites(
          (favorites) => (favorites = [...favorites, selectedForm.uid]),
        );
      }

      sortForms(formList);
    }
  };

  return (
    <Button
      startIcon={
        selectedForm?.uid !== undefined &&
        (favorites.includes(selectedForm.uid) ? (
          <Favorite color="primary" />
        ) : (
          <Unfavorite color="primary" />
        ))
      }
      onClick={handleClick}
      color="primary"
      disabled={disabled}
    >
      {selectedForm?.uid !== undefined &&
        (favorites.includes(selectedForm.uid) ? (
          <span>Remove from Favourites</span>
        ) : (
          <span>Add to Favourites</span>
        ))}
    </Button>
  );
};

export default AddFavoriteButton;
