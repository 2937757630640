import { FC, ReactNode } from "react";
import { PermissionAppRoute } from "src/types/AppRoute";
import LoginGuardedRoute from "../ShowOrRedirectToLogin/ShowOrRedirectToLogin";
import NoPermissionsPage from "./NoPermissionspage";
import useRoutePermissions from "./useRoutePermissions";

interface PermissionRouteProps {
  route: PermissionAppRoute;
  children: ReactNode;
}

// Check whether or not to allow route based on the route's permissions (set in routes.ts)

const PermissionRoute: FC<PermissionRouteProps> = ({ route, children }) => {
  const { permissionGranted, loading } = useRoutePermissions(route);

  if (permissionGranted) {
    return children;
  }

  if (loading) {
    return null;
  }

  return (
    <LoginGuardedRoute>
      <NoPermissionsPage />
    </LoginGuardedRoute>
  );
};

export default PermissionRoute;
