import { FC } from "react";

type SpacerProps = {
  height?: string;
};

const Spacer: FC<SpacerProps> = ({ height = "20px" }) => {
  return <div style={{ height: height }} />;
};

export default Spacer;
