import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Grid from "@mui/material/Grid2";
import { Theme } from "@mui/material/styles";
import React, { FC } from "react";
import ReactPlayer from "react-player";
import { makeStyles } from "tss-react/mui";
import Label from "../../Label";
import { UserMediaMetadataItem } from "../Models/GalleryModel";

/** Summary
 * Renderer function passed to GalleryGrid
 * Each image is rendered using the GalleryRenderer component
 */

const useStyles = makeStyles()((theme: Theme) => ({
  mediaContainer: {
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  },
  image: {
    width: "100%",
    objectFit: "cover",
  },
  imageSquare: {
    aspectRatio: "1", //Not supported in some older browsers. Images will not be square if not supported
  },
  videoContainer: {
    paddingTop: "100%",
    position: "relative",
    width: "100%",
    height: "100%",
  },
  video: {
    position: "absolute",
    top: "-50%",
    left: "-50%",
  },
  mediaSelected: {
    transition:
      "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
  },
  mediaUnselected: {
    transition:
      "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
  },
  selectableHover: {
    "&:hover": {
      outline: "4px solid",
      outlineColor: theme.palette.primary.main,
    },
  },
  selectedOrEditing: {
    transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
  },
  moreInfo: {
    right: "4px",
    top: "4px",
    position: "absolute",
    zIndex: 1,
    color: theme.palette.primary.main,
  },
  checkmark: {
    fill: theme.palette.primary.main,
    position: "absolute",
    left: theme.spacing(0.25),
    top: theme.spacing(0.25),
    zIndex: 1,
  },
  checkmarkCircleOuter: {
    fill: theme.palette.primary.main,
    position: "absolute",
  },
  whiteSvgAbsolute: {
    fill: "white",
    position: "absolute",
  },
  aiLabel: {
    fontSize: "0.8em",
  },
}));

interface CheckmarkProps {
  selected?: boolean;
}

const Checkmark: FC<CheckmarkProps> = ({ selected }) => {
  const { classes } = useStyles();
  const checkmarkPosition = {
    cx: 12.2,
    cy: 12.2,
  };

  return (
    <div className={classes.checkmark}>
      {!selected && (
        <svg>
          {/* Circle */}
          <circle
            className={classes.checkmarkCircleOuter}
            {...checkmarkPosition}
            r="9"
          />
          <circle
            className={classes.whiteSvgAbsolute}
            {...checkmarkPosition}
            r="7.5"
          />
        </svg>
      )}

      {selected && (
        <svg>
          {/* Checkmark */}
          <circle
            className={classes.whiteSvgAbsolute}
            {...checkmarkPosition}
            r="7.5"
          />
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
        </svg>
      )}
    </div>
  );
};

interface InfoButtonProps {
  item: UserMediaMetadataItem;
  handleShowModal?: (item: UserMediaMetadataItem) => void;
  /** Icon width */
  width: number;
  /** Icon height */
  height: number;
  /** Whether to use circle or rect for bg */
  shape: "circle" | "rect";
  /** x position of bg */
  x?: number;
  /** y position of bg */
  y?: number;
  /** x side length of rect bg */
  sx?: number;
  /** y side length of rect bg */
  sy?: number;
  /** radius of bg. Corner radius if using rect */
  r?: number;
  children: React.ReactNode;
}

const InfoButton: React.FunctionComponent<InfoButtonProps> = ({
  item,
  handleShowModal,
  width,
  height,
  shape,
  x,
  y,
  sx,
  sy,
  r,
  children,
}) => {
  const { classes } = useStyles();

  return (
    <div className={classes.moreInfo}>
      <svg
        width={`${width}px`}
        height={`${height}px`}
        onClick={(e) => {
          e.stopPropagation();
          if (handleShowModal !== undefined) handleShowModal(item);
        }}
      >
        {shape === "circle" && (
          <circle
            className={classes.whiteSvgAbsolute}
            cx={x ?? width / 2}
            cy={y ?? height / 2}
            r={r ?? width / 2}
          />
        )}
        {shape === "rect" && (
          <rect
            className={classes.whiteSvgAbsolute}
            height={sy}
            width={sx}
            x={x}
            y={y}
            rx={r}
            ry={r}
          />
        )}
        {children}
      </svg>
    </div>
  );
};

interface GalleryRendererProps {
  item: UserMediaMetadataItem;
  /** Is image selected or not */
  selected?: boolean;
  /** Is selection allowed for image (clicking image will select instead of showing modal)*/
  enableSelect: boolean;
  /** Is editing enabled (will cause image to shrink slightly to show edit mode) */
  editing?: boolean;
  /** Handler when mouse enters the media container */
  onMouseEnter?: (item: UserMediaMetadataItem) => void;
  /** Handler when mouse leaves the media container */
  onMouseLeave?: (item: UserMediaMetadataItem) => void;
  /** Handler when media is selected */
  select?: (item: UserMediaMetadataItem) => void;
  /** Handler when media is unselected */
  unselect?: (id: string) => void;
  /** Handler when media is opened */
  handleOpenMedia?: (item: UserMediaMetadataItem) => void;
  /** If provided, labels will show below the media */
  labels?: string[];
  /** Type of label to show icon and colour appropriately. Default "Doctor" */
  labelType?: "AI" | "Doctor";
  /** If true, image will be shown as its original aspect ratio. Otherwise it will be square */
  useOriginalAspectRatio?: boolean;
}

const GalleryRenderer = ({
  item,
  selected,
  enableSelect,
  editing,
  select,
  unselect,
  handleOpenMedia,
  onMouseEnter,
  onMouseLeave,
  labels,
  labelType = "Doctor",
  useOriginalAspectRatio,
}: GalleryRendererProps) => {
  const { classes, cx } = useStyles();

  const handleClick = () => {
    if (!enableSelect && !editing && handleOpenMedia !== undefined) {
      //If selection disabled and not editing, show modal when image is clicked
      handleOpenMedia(item);
    } else if (unselect !== undefined && select !== undefined) {
      //Otherwise select file
      if (selected) {
        unselect(item.id);
      } else {
        select(item);
      }
    }
  };

  const displayMedia = (item: UserMediaMetadataItem) => {
    if (item.data.thumbnailDownloadUrl !== undefined)
      return (
        <img
          className={cx(
            {
              [classes.imageSquare]: !useOriginalAspectRatio,
            },
            classes.image,
          )}
          src={item.data.thumbnailDownloadUrl}
        />
      );
    else if (item.data.fileType === "image")
      return (
        <img
          className={cx(
            {
              [classes.imageSquare]: !useOriginalAspectRatio,
            },
            classes.image,
          )}
          src={item.data.fileDownloadUrl}
        />
      );
    else
      return (
        <div className={classes.videoContainer}>
          <ReactPlayer
            className={classes.video}
            url={item.data.fileDownloadUrl}
          />
        </div>
      );
  };

  return (
    <div
      className={cx(
        {
          [classes.mediaSelected]: selected,
          [classes.mediaUnselected]: !selected,
          [classes.selectableHover]: enableSelect,
          [classes.selectedOrEditing]: selected || editing,
        },
        classes.mediaContainer,
      )}
      onClick={handleClick}
      onMouseEnter={() => {
        onMouseEnter?.(item);
      }}
      onMouseLeave={() => {
        onMouseLeave?.(item);
      }}
    >
      {enableSelect && <Checkmark selected={selected} />}
      {displayMedia(item)}
      {handleOpenMedia !== undefined &&
        enableSelect &&
        item.data.fileType === "image" && (
          <InfoButton
            item={item}
            handleShowModal={handleOpenMedia}
            width={30}
            height={30}
            sx={21}
            sy={21}
            x={3}
            y={5}
            shape={"rect"}
            r={6}
          >
            <ImageSearchIcon />
          </InfoButton>
        )}
      {item.data.fileType === "video" && (
        <InfoButton
          item={item}
          handleShowModal={handleOpenMedia}
          width={24}
          height={24}
          r={9}
          shape={"circle"}
        >
          <PlayCircleFilledIcon />
        </InfoButton>
      )}
      {labels !== undefined && (
        <Grid container spacing={1}>
          {labels.map((label) => {
            return (
              <Grid size={{ xs: 12 }} key={`label_${label}`}>
                <Label
                  text={label}
                  color={labelType === "AI" ? "orange" : "dark"}
                  startIcon={
                    labelType === "AI" && <b className={classes.aiLabel}>AI</b>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};

export default GalleryRenderer;
