import {
  ErrorHandlerOptions,
  ProcessState,
} from "@alethea-medical/alethea-components";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import analyticsLogs from "../../../analyticsLogs";
import { fbFunctions, logAnalyticsEvent } from "../../../firebase";

interface AveiroExternalPaymentFormProps {
  visible: boolean;
  activityId: string;
  itemCode: string | undefined;
  refetchPayments: () => void;
  setProcessState: (state: ProcessState) => void;
  processState: ProcessState;
  errorHandler: (options: ErrorHandlerOptions) => void;
}

// Payment form that will record a external payment. Used for payments that are to be manually recorded by Aveiro
const AveiroExternalPaymentForm: FC<AveiroExternalPaymentFormProps> = ({
  visible,
  activityId,
  itemCode,
  refetchPayments,
  setProcessState,
  processState,
  errorHandler,
}) => {
  const submitOatPayment = fbFunctions.httpsCallable("aveiro-submitOatPayment");

  const handleRetry = () => {
    if (itemCode === undefined) return;

    setProcessState(ProcessState.running);
    return submitOatPayment({
      itemCode: itemCode,
      activityId: activityId,
      externalPayment: true,
    })
      .then((result) => {
        if (result.data.ok) {
          // Show success page
          logAnalyticsEvent(analyticsLogs.aveiro.paymentSubmit.success);
          setProcessState(ProcessState.success);
        } else {
          errorHandler({
            error: new Error(result.data.error),
            userMessage: "Could not record payment. Reason",
            analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail,
          });
        }
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error recording payment",
          analyticsLog: analyticsLogs.aveiro.paymentSubmit.fail,
        });
      })
      .finally(() => {
        // Refetch payments after recording
        refetchPayments();
      });
  };

  return (
    <Collapse in={visible}>
      <Grid container spacing={2} justifyContent="center">
        <Grid size={{ xs: 12 }}>
          <Typography>
            Record this payment that was made outside of Alethea
          </Typography>
        </Grid>
        <Grid size={{ xs: 4 }}>
          <Button
            onClick={handleRetry}
            variant="contained"
            color="secondary"
            disabled={processState === ProcessState.running}
            fullWidth
          >
            Record Payment
          </Button>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default AveiroExternalPaymentForm;
