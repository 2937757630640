import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import React from "react";

import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import globalStrings from "../../../globalStrings";
import palette from "../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  title: {
    color: palette.darkGreen,
    fontSize: "1.5em",
  },
}));

const ValidationRequired: React.FC = () => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid>
        <Typography className={classes.title}>
          Before you can get started with Alethea, we need to verify your
          Practitioner Identification Number (Prac ID).
        </Typography>
        <Typography>
          Please contact us at {globalStrings.salesEmail} or{" "}
          {globalStrings.salesPhone} to provide us with your correct Prac ID.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ValidationRequired;
