import {
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import { AIType } from "../../../../shared/types";
import { projectEnv } from "../../../firebase";
import palette from "../../../palette";
import Label from "../../Label";
import AIPredictionsController, {
  AIPredictionsControllerProps,
} from "../Controllers/AIPredictionsController";
import { getAITypeDisplayString } from "../Models/AIPredictionsModel";

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    fontWeight: 600,
    color: palette.orange,
  },
  cyaText: {
    fontSize: "0.7em",
  },
  paper: {
    padding: theme.spacing(1),
  },
  center: {
    //Center in grid
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  placeholder: {
    color: "#999",
  },
}));

const VIDEO_PREDICTION_TEXT = "AI prediction is not available for video files.";
const cyaStr =
  "This is not a diagnosis. Our AI is a visual prediction tool intended for educational purposes only.";

interface AIPredictionsViewProps extends AIPredictionsControllerProps {
  fullWidth?: boolean;
}

const AIPredictionsView: FC<AIPredictionsViewProps> = ({
  mediaItem,
  doctorLabels,
  modifyItem,
  allowAIDatabaseCaching,
  fullWidth,
}) => {
  const { classes, cx } = useStyles();

  const isMobileSize = useMobileSizes();

  const {
    //states
    predictions,
    labelType,
    predictionType,
    //handlers
    predictionTypeChangeHandler,
    clearPredictions,
    //process
    aiState: processState,
    aiError: processErrorMessage,
  } = AIPredictionsController({
    mediaItem,
    doctorLabels,
    modifyItem,
    allowAIDatabaseCaching,
  });

  const renderLabels = () => (
    <Grid container alignItems="center" justifyContent={"center"} spacing={1}>
      {predictions.map((prediction: string) => {
        if (prediction !== "") {
          return (
            <Grid key={`ai_label_${prediction}`}>
              <Label
                text={prediction}
                color={labelType === "AI" ? "orange" : "dark"}
              />
            </Grid>
          );
        } else {
          return null;
        }
      })}
    </Grid>
  );

  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid
        size={{
          lg: fullWidth ? 12 : 6,
          md: fullWidth ? 12 : 8,
          sm: fullWidth ? 12 : 10,
          xs: 12,
        }}
      >
        {mediaItem.data.fileType === "image" && (
          <>
            {labelType === "AI" && (
              <Grid container spacing={1} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Typography className={classes.header}>AI Support</Typography>
                </Grid>
                {!isMobileSize && <Grid size={{ xs: 6 }}>{/* Empty */}</Grid>}
                <Grid size={{ xs: 12, md: 6 }}>
                  <Select
                    value={predictionType}
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    displayEmpty
                    renderValue={(value: "" | AIType | "lvl1") =>
                      value === "" ? (
                        <Typography className={classes.placeholder}>
                          Select a Diagnostic Category
                        </Typography>
                      ) : (
                        getAITypeDisplayString(value)
                      )
                    }
                    onChange={predictionTypeChangeHandler}
                    endAdornment={
                      predictionType === "" ? undefined : (
                        <IconButton onClick={clearPredictions} size="large">
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                  >
                    <MenuItem value={"ear"}>
                      {getAITypeDisplayString("ear")}
                    </MenuItem>
                    <MenuItem value={"nasal"}>
                      {getAITypeDisplayString("nasal")}
                    </MenuItem>
                    <MenuItem value={"derm"}>
                      {getAITypeDisplayString("derm")}
                    </MenuItem>
                    {projectEnv === "dev" || projectEnv === "local" ? (
                      <MenuItem value={"lvl1"}>
                        {getAITypeDisplayString("lvl1")}
                      </MenuItem>
                    ) : null}
                  </Select>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Collapse in={predictions.length > 0}>
                    {renderLabels()}
                  </Collapse>
                </Grid>
                {predictions.length > 0 && (
                  <Grid size={{ xs: 12 }}>
                    <Typography
                      variant="subtitle1"
                      className={cx(classes.cyaText, classes.centerText)}
                    >
                      {cyaStr}
                    </Typography>
                  </Grid>
                )}
                <Grid size={{ xs: 12 }}>
                  <ProcessStatus
                    state={processState}
                    errorMessage={processErrorMessage}
                  />
                </Grid>
              </Grid>
            )}
            {labelType === "Doctor" && (
              <Grid container spacing={1} justifyContent="center">
                <Grid size={{ xs: 12 }}>
                  <Typography className={classes.centerText}>
                    {"Specialist Labels"}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12 }}>{renderLabels()}</Grid>
              </Grid>
            )}
          </>
        )}
        {mediaItem.data.fileType === "video" && (
          <Typography variant="subtitle1" className={classes.centerText}>
            {VIDEO_PREDICTION_TEXT}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AIPredictionsView;
