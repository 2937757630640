import {
  ProcessState,
  useProcessState,
} from "@alethea-medical/alethea-components";
import firebase from "firebase/compat/app";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Activity } from "../../../../../../shared/types";
import { AuthContext } from "../../../../../AuthProvider";
import analyticsLogs from "../../../../../analyticsLogs";
import {
  fbFirestore,
  fbFunctions,
  logAnalyticsEvent,
} from "../../../../../firebase";
import {
  ConsultDraftsCollectionType,
  discardDraft,
} from "../../../Consult/Draft/ConsultDraftFunctions";
import ListItemSelectController from "./ListItemSelectController";

import { dbNames, resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import hasPermissions from "./../../../../../models/hasPermissions";

type DraftsListControllerReturn = {
  drafts: ConsultDraftDict;
  searchDrafts: ConsultDraftDict;
  unreadApprovals: number;
  selectedItems: string[];
  allSelected: boolean;
  selectAllHandler: (selectAll: boolean) => void;
  listItemSelectHandler: (id: string, checked: boolean) => void;
  unselectAllHandler: () => void;
  openApprovalHandler: (draftId: string) => void;
  openDraftHandler: (draftId: string) => void;
  discardHandler: () => void;
  enableSearch: boolean;
  clearSearch: () => void;
  runSearch: (searchParams: string[]) => void;
  processState: ProcessState;
  processErrorMessage: string;
  setProcessState: (state: ProcessState) => void;
};

export interface ConsultDraftDict {
  [draftId: string]: Activity.ConsultDraft;
}

const DraftsListController = (
  collection: ConsultDraftsCollectionType,
): DraftsListControllerReturn => {
  const [drafts, setDrafts] = useState<ConsultDraftDict>({});
  const [unreadApprovals, setUnreadApprovals] = useState<number>(0);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });

  useEffect(() => {
    if (authContext.uid !== "") {
      return fbFirestore
        .collection(dbNames.consultDrafts)
        .doc(authContext.uid)
        .onSnapshot((snapshot) => {
          if (!snapshot.exists) return;
          const notificationsProfile = snapshot.data();
          if (!notificationsProfile) return;
          if (typeof notificationsProfile.numUnreadApprovals !== "number")
            return;
          setUnreadApprovals(
            Math.max(notificationsProfile.numUnreadApprovals, 0),
          );
        });
    }
  }, [authContext.uid]);

  useEffect(() => {
    loadDrafts();
  }, [unreadApprovals]);

  const openApprovalHandler = (draftId: string) => {
    navigate(
      `/dashboard/consult?consultDraftId=${draftId}&collection=drafts_pending_approval`,
    );
  };

  const openDraftHandler = (draftId: string) => {
    navigate(
      `/dashboard/consult?consultDraftId=${draftId}&collection=user_drafts`,
    );
  };

  const loadDrafts = () => {
    if (collection === "drafts_pending_approval") {
      return hasPermissions(
        resourceKeys.approveEconsult,
        authContext.profile,
      ).then((hasPermission) => {
        if (hasPermission) {
          return fbFunctions
            .httpsCallable("consultDraft-listDrafts")({
              consultDraftsCollectionType: "drafts_pending_approval",
            })
            .then((data) => {
              // For each draft, convert the JavaScript date object to a Firebase Timestamp object
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              Object.entries(data.data).forEach(([, draft]: [string, any]) => {
                draft.created = new firebase.firestore.Timestamp(
                  draft.created._seconds,
                  draft.created._nanoseconds,
                );
              });

              setDrafts(data.data);
            });
        }
      });
    }

    return fbFunctions
      .httpsCallable("consultDraft-listDrafts")({
        consultDraftsCollectionType: "user_drafts",
      })
      .then((data) => {
        // For each draft, convert the JavaScript date object to a Firebase Timestamp object
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        Object.entries(data.data).forEach(([, draft]: [string, any]) => {
          draft.created = new firebase.firestore.Timestamp(
            draft.created._seconds,
            draft.created._nanoseconds,
          );
        });

        setDrafts(data.data);
      });
  };

  useEffect(() => {
    if (authContext.uid !== "") {
      setProcessState(ProcessState.running);
      loadDrafts()
        .then(() => {
          setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
          errorHandler({
            error,
            userMessage: "Error fetching your drafts",
          });
        });
    }
  }, [authContext.uid]);

  const {
    selectedItems,
    allSelected,
    selectAllHandler,
    listItemSelectHandler,
    unselectAllHandler,
  } = ListItemSelectController({ listItemDict: drafts });

  const [enableSearch, setEnableSearch] = useState(false);
  const [searchDrafts, setSearchDrafts] = useState<ConsultDraftDict>({});

  const clearSearch = () => {
    setEnableSearch(false);
  };

  const runSearch = (searchParams: string[]) => {
    setEnableSearch(true);

    const newSearchDrafts: ConsultDraftDict = {};
    // Filter out draft entries that don't match params, then populate new dictionary
    Object.entries(drafts)
      .filter(([, draft]) => {
        return searchParams.every((param) =>
          draft.subject.toLowerCase().includes(param.toLowerCase()),
        );
      })
      .forEach(([draftId, draft]) => {
        newSearchDrafts[draftId] = draft;
      });
    setSearchDrafts(newSearchDrafts);
  };

  const discardHandler = () => {
    if (authContext.uid !== "") {
      setProcessState(ProcessState.running);
      Promise.all(
        selectedItems.map((draftId) => {
          return discardDraft(collection, draftId);
        }),
      )
        .then(() => {
          logAnalyticsEvent(analyticsLogs.consultDraft.discard);

          setProcessState(ProcessState.idle);
        })
        .catch((error: Error) => {
          errorHandler({
            error: error,
            userMessage: "Error discarding some drafts",
            analyticsLog: analyticsLogs.consultDraft.discardFail,
          });
        })
        .finally(() => {
          unselectAllHandler();
          return loadDrafts();
        });
    }
  };

  return {
    drafts,
    searchDrafts,
    unreadApprovals,
    selectedItems,
    allSelected,
    selectAllHandler,
    listItemSelectHandler,
    unselectAllHandler,
    openApprovalHandler,
    openDraftHandler,
    discardHandler,
    enableSearch,
    clearSearch,
    runSearch,
    processState,
    processErrorMessage,
    setProcessState,
  };
};

export default DraftsListController;
