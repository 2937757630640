import {
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import Collapse from "@mui/material/Collapse";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import usePermissions from "src/components/usePermissions";
import useConsultFormStyles from "../useConsultFormStyles";
import ServiceDirectory from "./Directory/ServiceDirectory";
import SelectedService from "./SelectedService";
import ServiceSearch from "./ServiceSearch";
import ServiceSearchController, {
  ServiceSearchControllerProps,
} from "./ServiceSearchController";
import ServiceSearchResults from "./ServiceSearchResults";

const ServiceSearchContainer = (props: ServiceSearchControllerProps) => {
  const { classes } = useConsultFormStyles();

  const {
    searchText,
    handleSearchText,
    selectedService,
    control,
    searchResults,
    processState,
    processErrorMessage,
    tab,
    handleChangeTab,
    handleStringSearch,
    handleRestartSearchResults,
    handleDirectorySearch,
    handleSelectService,
    handleResetSubsite,
    handleDeselectService,
  } = ServiceSearchController(props);

  const { granted: hasSearchBarPermissions } = usePermissions({
    resourceKey: resourceKeys.consultSearchBar,
  });

  return (
    <>
      <Collapse in={!selectedService || tab === "directory"}>
        {/* Show tab selector and search results when no service is selected */}
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          orientation={"horizontal"}
          variant="fullWidth"
        >
          <Tab value="directory" label="Directory" />
          {hasSearchBarPermissions && <Tab value="search" label="Search" />}
        </Tabs>
        {tab === "directory" && (
          <div className={classes.spacing}>
            <ServiceDirectory
              searchResults={searchResults}
              selectedService={selectedService}
              searchDirectory={handleDirectorySearch}
              selectService={handleSelectService}
              deselectService={handleDeselectService}
              searchProcessState={processState}
              control={control}
              resetSubsite={handleResetSubsite}
            />
          </div>
        )}
        {tab === "search" && hasSearchBarPermissions && (
          <div className={classes.spacing}>
            <ServiceSearch
              searchText={searchText}
              handleSearchText={handleSearchText}
              onStringSearch={handleStringSearch}
              handleRestartSearchResults={handleRestartSearchResults}
            />
            <ServiceSearchResults
              searchResults={searchResults}
              noResults={
                searchText.length > 0 && processState === ProcessState.idle
              }
              selectService={handleSelectService}
            />
          </div>
        )}
        <div className={classes.spacing}>
          <ProcessStatus
            state={processState}
            errorMessage={processErrorMessage}
            loadingMessage="Searching..."
          />
        </div>
      </Collapse>

      {/* When service is selected, show service and back button */}
      {selectedService && tab === "search" && (
        <SelectedService
          selectedService={selectedService}
          goBackToResults={handleDeselectService}
        />
      )}
    </>
  );
};

export default ServiceSearchContainer;
