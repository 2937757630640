import {
  PaperModal,
  ProcessState,
  ProcessStatus,
  useProcessState,
} from "@alethea-medical/alethea-components";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import MuiAlert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { FC, useContext, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import { fbFunctions, logAnalyticsEvent } from "../../../../firebase";

const useStyles = makeStyles()(() => ({
  header: {
    fontSize: "1.3em",
  },
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
    maxWidth: "1000px",
    minHeight: "200px",
  },
  menuItemTextWrap: {
    whiteSpace: "normal",
    overflow: "hidden",
    maxWidth: "100%",
    wordWrap: "break-word",
  },
}));

interface AddConsultantButtonProps {
  viewType: "md" | "moa";
  variant: "button" | "dropdown";
  activityId: string;
  sharedActivity: Activity.Activity;
  onConsultantAdded?: () => void;
}

const AddConsultantButton: FC<AddConsultantButtonProps> = ({
  viewType,
  variant,
  activityId,
  sharedActivity,
  onConsultantAdded,
}) => {
  const { classes } = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [availableUsers, setAvailableUsers] = useState<
    { label: string; uid: string }[]
  >([]);
  const [selectedUserId, setSelectedUserId] = useState<string>("");
  const authContext = useContext(AuthContext);

  const addConsultantToActivity = fbFunctions.httpsCallable(
    "secureMessaging-addUserToActivity",
  );
  const addSelfToActivityAsMoa = fbFunctions.httpsCallable(
    "activity-addSelfToActivityAsMoa_v1",
  );
  const getConsultantsAvailableToUser = fbFunctions.httpsCallable(
    "secureMessaging-getConsultantsAvailableToUser",
  );

  const { processState, setProcessState, processErrorMessage, errorHandler } =
    useProcessState({ logAnalyticsEvent });
  const isDisabled = () => {
    return processState === ProcessState.running || availableUsers.length === 0;
  };

  const isAddButtonDisabled = () => {
    return isDisabled() || selectedUserId === "";
  };

  const addConsultantHandler = () => {
    setProcessState(ProcessState.running);

    let addUserPromise = Promise.resolve();
    // If user is not on activity as MOA, then add them to the activity
    if (viewType === "moa") {
      if (!sharedActivity.users.includes(authContext.uid)) {
        addUserPromise = addSelfToActivityAsMoa({ activityId }).then(() => {
          return Promise.resolve();
        });
      }
    }

    return addUserPromise
      .then(() => {
        return addConsultantToActivity({
          activityId,
          newUserId: selectedUserId,
          activityRole: "consultant",
        });
      })
      .then(() => {
        setProcessState(ProcessState.idle);
        setShowModal(false);
        if (onConsultantAdded !== undefined) onConsultantAdded();
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error adding user",
        });
      });
  };

  const openModalHandler = () => {
    setShowModal(true);
    setProcessState(ProcessState.running);
    return getConsultantsAvailableToUser()
      .then((result) => {
        setAvailableUsers(result.data);
        if (result.data.length > 0) trySelectUserId(result.data[0].uid);
        setProcessState(ProcessState.idle);
      })
      .catch((error: Error) => {
        errorHandler({
          error,
          userMessage: "Error retrieving available users",
        });
      });
  };

  const trySelectUserId = (uid: string) => {
    if (sharedActivity.users.includes(uid)) return;
    setSelectedUserId(uid);
  };

  return (
    <>
      {variant === "button" && (
        <Button
          color="primary"
          variant="outlined"
          startIcon={<PersonAddIcon />}
          onClick={openModalHandler}
        >
          Add Consultant
        </Button>
      )}
      {variant === "dropdown" && (
        <DropdownMenuItem
          label="Add Consultant"
          icon={<PersonAddIcon color="primary" />}
          onClick={openModalHandler}
          color="primary"
        />
      )}
      <PaperModal
        className={classes.modal}
        show={showModal}
        setShow={setShowModal}
        flexHeight
        enablePadding
      >
        <Grid container spacing={2}>
          <Grid size={{ xs: 12 }}>
            <ProcessStatus
              state={processState}
              errorMessage={processErrorMessage}
            />
          </Grid>
          <Grid size={{ xs: 12 }}>
            <TextField
              label="Choose a Consultant to Add"
              value={selectedUserId}
              onChange={(e) => {
                trySelectUserId(e.target.value as string);
              }}
              variant="outlined"
              margin="dense"
              fullWidth
              select
              disabled={isDisabled()}
            >
              {availableUsers.length > 0 ? (
                availableUsers.map((user) => {
                  const alreadyAdded = sharedActivity.users.includes(user.uid);
                  return (
                    <MenuItem
                      key={`available_user_to_add_${user.uid}`}
                      value={user.uid}
                      disabled={alreadyAdded}
                      className={classes.menuItemTextWrap}
                    >
                      {user.label}
                      {alreadyAdded ? " (Added)" : ""}
                    </MenuItem>
                  );
                })
              ) : (
                <div />
              )}
            </TextField>
            {availableUsers.length === 0 &&
              processState !== ProcessState.running && (
                <MuiAlert severity="warning">No consultants available</MuiAlert>
              )}
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => {
                setShowModal(false);
              }}
              disabled={isDisabled()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid size={{ xs: 6 }}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => {
                addConsultantHandler();
              }}
              disabled={isAddButtonDisabled()}
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </PaperModal>
    </>
  );
};

export default AddConsultantButton;
