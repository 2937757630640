import TextField from "@mui/material/TextField";
import { Controller } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { ControlledInputProps } from "../types";
import isValidEmailInput from "./isEmail";

const errorTextOffset = 27;

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useOffsetEmailInputErrorTextStyles = makeStyles()(() => ({
  flexAlignedEnd: {
    marginBottom: errorTextOffset,
  },
  flexAlignedStart: {
    marginTop: errorTextOffset,
  },
}));

const EmailInput = ({
  name,
  control,
  defaultValue,
  rules,
  label,
  errorTextPadding,
  ...rest
}: ControlledInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={Object.assign(rules ? rules : {}, {
        validate: {
          isEmail: isValidEmailInput,
        },
      })}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...rest}
          label={label}
          error={fieldState.error !== undefined}
          helperText={
            errorTextPadding
              ? fieldState.error?.message
                ? fieldState.error.message
                : " "
              : fieldState.error?.message
          }
          margin="dense"
          fullWidth
          variant="outlined"
          autoComplete="off"
          InputLabelProps={{
            disableAnimation: true,
          }}
          inputProps={{
            "aria-autocomplete": "none",
            list: "autocompleteOff",
          }}
        />
      )}
    />
  );
};

export default EmailInput;
