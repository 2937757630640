import { ProcessStatus } from "@alethea-medical/alethea-components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { FC, ReactNode } from "react";
import usePDFCreator from "../../../../components/usePDFCreator";

interface ExportBillingReportPDFProps {
  pages: ReactNode[];
}

const ExportBillingReportPDF: FC<ExportBillingReportPDFProps> = ({ pages }) => {
  const { savePDF, createPDFContainer, pdfProcessState, pdfErrorMessage } =
    usePDFCreator({
      pdfKey: "billing_report_pdf",
      pages: pages,
      analyticsLog: "billing_report_export_pdf",
      download: true,
      hide: true,
    });

  return (
    <div>
      <Tooltip title="Export billing records as a PDF">
        <Button
          variant="contained"
          color="primary"
          startIcon={<PictureAsPdfIcon />}
          onClick={savePDF}
        >
          Export as PDF
        </Button>
      </Tooltip>
      {/* hidden */}
      {createPDFContainer()}
      <ProcessStatus state={pdfProcessState} errorMessage={pdfErrorMessage} />
    </div>
  );
};

export default ExportBillingReportPDF;
