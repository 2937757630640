import { FC } from "react";
import isMobileDevice from "../../../../models/isMobileDevice";
import ResourcesView from "../../Resources/Views/ResourcesView";

const ResourcesPullout: FC = () => {
  return (
    <ResourcesView alwaysEnableEditing={false} disableDrag={isMobileDevice()} />
  );
};

export default ResourcesPullout;
