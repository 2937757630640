import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import React, { FC } from "react";
import { makeStyles } from "tss-react/mui";
import RoundedButton from "../../../../../components/RoundedButton";
import palette from "../../../../../palette";
import sharedStyles from "../../../../../sharedStyles";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
  title: {
    color: palette.darkGreen,
    fontSize: "2em",
  },
}));

interface NoPlanErrorPageProps {
  title: React.ReactNode;
}

const NoPlanErrorPage: FC<NoPlanErrorPageProps> = ({ title }) => {
  const { classes } = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <a
          className={classes.unstyledLink}
          href="https://aletheamedical.com/sign-up"
          rel="noreferrer noopener"
        >
          <RoundedButton>Pick a Plan</RoundedButton>
        </a>
      </Grid>
    </Grid>
  );
};

export default NoPlanErrorPage;
