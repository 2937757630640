import { ProcessStatus } from "@alethea-medical/alethea-components";
import { resourceKeys } from "@alethea-medical/aletheamd-db-keys";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import { FC, useContext, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { AuthContext } from "../../../../AuthProvider";
import DropdownMenuButton from "../../../../components/DropdownMenuButton";
import { DropdownMenuItem } from "../../../../components/DropdownMenuButton/DropdownMenuButton";
import usePermissions from "../../../../components/usePermissions";
import isNativeMobile from "../../../../models/isNativeMobile";
import AveiroInvoiceButton from "../../Aveiro/AveiroInvoiceButton";
import AveiroPaymentButton from "../../Aveiro/AveiroPaymentButton";
import { UserActivityContext } from "../Inbox/Contexts/ActivityProvider";
import CallCompleteButton from "../PhoneConsultComponents/CallCompleteButton";
import useExportPDF from "../Utilities/useExportPDF";
import ViewSecureMessageNavigation from "../ViewSecureMessageNavigation";
import sharedStyles from "../sharedStyles";
import AddConsultantButton from "./AddConsultantButton";
import ConsultStatusDropdownButton from "./ConsultStatusDropdownButton";
import ForwardSecureMessageButton from "./ForwardSecureMessageButton";
import MOAMessageButton from "./MOAMessageButton";
import SecureMessagingToolbar from "./SecureMessagingToolbar";

const useStyles = makeStyles()((theme: Theme) => ({
  ...sharedStyles(theme),
}));

interface ViewSecureMessageToolbarProps {
  goToMessages: () => void;
  refillActivities: () => void;
}

const ViewSecureMessageToolbar: FC<ViewSecureMessageToolbarProps> = ({
  goToMessages,
  refillActivities,
}) => {
  const { classes, cx } = useStyles();
  const authContext = useContext(AuthContext);
  const activityContext = useContext(UserActivityContext);

  const [showToolbar, setShowToolbar] = useState(true);

  const {
    exportPdfState,
    exportPdfErrorMessage,
    setExportPdfState,
    exportPDFHandler,
  } = useExportPDF({ activityId: activityContext.activityId });

  const { granted: hasAddSpecialistPermission } = usePermissions({
    resourceKey: resourceKeys.addUserToActivity,
  });
  const { granted: hasMOAMessagingPermission } = usePermissions({
    resourceKey: resourceKeys.moaMessaging,
  });
  const { granted: hasAveiroPaymentPermission } = usePermissions({
    resourceKey: resourceKeys.createAveiroCharge,
  });

  const showForwardToSpecialistButton =
    activityContext.sharedActivity.profiles[authContext.uid]?.activityRole ===
      "referrer" &&
    activityContext.sharedActivity.activityType !== "phoneConsult";

  const toggleMuteNotifications = () => {
    activityContext.updateMetadataActivity({
      muted: !activityContext.metadataActivity.muted,
    });
  };

  const activityRole =
    activityContext.sharedActivity.profiles[authContext.uid]?.activityRole;

  return (
    <>
      <Box
        className={cx(classes.boxBorderColor, classes.boxPadding)}
        borderBottom={1}
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
        >
          <ViewSecureMessageNavigation
            showToolbar={showToolbar}
            setShowToolbar={setShowToolbar}
            goBack={activityContext.closeActivity}
            goToMessages={goToMessages}
          />
          <Grid>
            <Collapse in={showToolbar}>
              <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid>
                  <SecureMessagingToolbar
                    folder={activityContext.metadataActivity.folder}
                    atLeastOneSelected={true}
                    markAsUnread={activityContext.markAsUnread}
                    moveFolder={async (folder) => {
                      await activityContext.moveFolder(folder);
                      refillActivities();
                    }}
                  />
                </Grid>
                <Grid>
                  <ButtonGroup>
                    <Tooltip
                      title={
                        activityContext.metadataActivity.muted
                          ? "Click to Turn Notifications ON"
                          : "Click to Turn Notifications OFF"
                      }
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={toggleMuteNotifications}
                        startIcon={
                          activityContext.metadataActivity.muted ? (
                            <NotificationsOffIcon />
                          ) : (
                            <NotificationsIcon />
                          )
                        }
                      >
                        {activityContext.metadataActivity.muted
                          ? "Muted"
                          : "Unmuted"}
                      </Button>
                    </Tooltip>
                    <DropdownMenuButton
                      text="Actions"
                      buttonIcon={<MoreHorizIcon />}
                    >
                      {!isNativeMobile() && (
                        <DropdownMenuItem
                          label="Export PDF"
                          icon={<PictureAsPdfIcon color="primary" />}
                          onClick={exportPDFHandler}
                          color="primary"
                        />
                      )}
                      {hasMOAMessagingPermission && (
                        <MOAMessageButton
                          activityId={activityContext.activityId}
                          defaultSubject={
                            activityContext.sharedActivity.subject
                          }
                        />
                      )}
                      {showForwardToSpecialistButton && (
                        <ForwardSecureMessageButton />
                      )}
                      {hasAddSpecialistPermission && (
                        <AddConsultantButton
                          viewType="md"
                          variant="dropdown"
                          activityId={activityContext.activityId}
                          sharedActivity={activityContext.sharedActivity}
                        />
                      )}
                      {hasAveiroPaymentPermission && (
                        <AveiroPaymentButton
                          variant="dropdown"
                          activityId={activityContext.activityId}
                        />
                      )}
                      {hasAveiroPaymentPermission && (
                        <AveiroInvoiceButton
                          variant="dropdown"
                          activityId={activityContext.activityId}
                          econsult={activityContext.econsult}
                        />
                      )}
                    </DropdownMenuButton>
                  </ButtonGroup>
                </Grid>
                <Grid>
                  {activityRole && (
                    <ConsultStatusDropdownButton viewerRole={activityRole} />
                  )}
                </Grid>
                <Grid>
                  {activityContext.sharedActivity.activityType ===
                    "phoneConsult" &&
                    activityContext.sharedActivity.phoneConsultData !==
                      undefined &&
                    activityRole && (
                      <CallCompleteButton
                        activityId={activityContext.activityId}
                        callComplete={
                          activityContext.sharedActivity.phoneConsultData
                            .callComplete
                        }
                        userActivityRole={activityRole}
                      />
                    )}
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Box>
      <ProcessStatus
        state={exportPdfState}
        setState={setExportPdfState}
        errorMessage={exportPdfErrorMessage}
        useSnackbar={true}
      />
    </>
  );
};

export default ViewSecureMessageToolbar;
