import { TextField } from "@mui/material";
import { FC } from "react";
import { Controller } from "react-hook-form";
import { NumberFormatValues, PatternFormat } from "react-number-format";
import { makeStyles } from "tss-react/mui";
import sharedStyles from "../../shared/sharedStyles";
import { ControlledInputProps } from "../types";
import isValidPracIDInput from "./isPracID";

const useStyles = makeStyles()(() => {
  const shared = sharedStyles();
  return {
    ...shared,
  };
});

// Formats input as prac-id with format 1234-56789
const PracIDInput: FC<ControlledInputProps> = ({
  name,
  control,
  defaultValue,
  rules,
  disabled,
  label,
  errorTextPadding,
  ...rest
}) => {
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : ""}
      rules={{
        ...rules,
        validate: {
          isPracID: (ID: string) =>
            isValidPracIDInput(ID, control._formValues.province),
        },
      }}
      render={({ field, fieldState }) => {
        const handleChange = (values: NumberFormatValues) => {
          field.onChange(values.value);
        };

        const getPracIDFormat = (province: string): string => {
          switch (province) {
            case "AB":
              return "####-#####";
            case "BC":
              return "J####";
            default:
              return "";
          }
        };

        const isBC = control._formValues.province === "BC";

        return isBC ? (
          <TextField
            {...field}
            {...rest}
            label={label}
            disabled={disabled}
            className={classes.canDisable}
            error={Boolean(fieldState.error)}
            helperText={
              errorTextPadding
                ? fieldState.error?.message || " "
                : fieldState.error?.message
            }
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="off"
            InputLabelProps={{
              disableAnimation: true,
            }}
            inputProps={{
              "aria-autocomplete": "none",
              list: "autocompleteOff",
              maxLength: 5,
            }}
          />
        ) : (
          <PatternFormat
            {...field}
            {...rest}
            format={getPracIDFormat(control._formValues.province)}
            mask="_"
            customInput={TextField}
            label={label}
            disabled={disabled}
            className={classes.canDisable}
            error={Boolean(fieldState.error)}
            helperText={
              errorTextPadding
                ? fieldState.error?.message || " "
                : fieldState.error?.message
            }
            variant="outlined"
            fullWidth
            margin="dense"
            autoComplete="off"
            slotProps={{
              inputLabel: {
                disableAnimation: true,
              },
              htmlInput: {
                "aria-autocomplete": "none",
                list: "autocompleteOff",
              },
            }}
            onValueChange={handleChange}
            // Override default onChange, because it saves the formatted value, not the raw value
            onChange={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
          />
        );
      }}
    />
  );
};

export default PracIDInput;
