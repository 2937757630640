import Avatar from "@mui/material/Avatar";
import { Theme } from "@mui/material/styles";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import palette from "../../../../../../palette";

const useStyles = makeStyles()((theme: Theme) => ({
  numberCircle: {
    fontWeight: "bold",
    fontSize: "2.0em",
    color: "white",
    backgroundColor: palette.orange,
    padding: theme.spacing(3),
  },
}));
interface SectionNumberProps {
  number: number;
}

const SectionNumber: FC<SectionNumberProps> = ({ number }) => {
  const { classes } = useStyles();

  return <Avatar className={classes.numberCircle}>{number}</Avatar>;
};

export default SectionNumber;
