import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { Control, useWatch } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { Activity } from "../../../../../shared/types";
import { TypedFormSelect } from "../../../../components/FormInputFields/FormSelect";
import { TypedFormTextField } from "../../../../components/FormInputFields/FormTextField";

const useStyles = makeStyles()(() => ({
  helperText: {
    fontSize: "1.0em",
  },
}));

interface FormOOPInputProps {
  control: Control<Activity.ConsultFormFields>;
  disabled: boolean;
}

export const getStringLengthValidator: (
  maxLen: number,
) => (str: string) => string | undefined = (maxLen: number) => (str: string) =>
  str.length > maxLen
    ? `This field must be no more than ${maxLen} characters long.`
    : undefined;

const ConsultOOPInput: FC<FormOOPInputProps> = ({ control, disabled }) => {
  const { classes } = useStyles();

  const oop = useWatch({ control, name: "oop" });

  return (
    <Collapse in={oop} unmountOnExit>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12 }}>
          <Typography variant="subtitle1">
            Since the patient&apos;s province does not match the family
            physician&apos;s or specialist&apos;s province, the patient will be
            billed as out of province. Please fill out the extra fields below.
          </Typography>
        </Grid>
        {/* genderCode */}
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <TypedFormSelect
            control={control}
            name="oopData.genderCode"
            label="Sex"
            disabled={disabled}
            options={["M", "F"]}
            defaultEmpty
            rules={{
              required: { value: true, message: "Patient's sex is required." },
            }}
          />
        </Grid>
        {/* addressLine1 */}
        <Grid size={{ xs: 12 }}>
          <TypedFormTextField
            control={control}
            name="oopData.addressLine1"
            label="Address Line 1"
            disabled={disabled}
            rules={{
              required: { value: true, message: "Address line 1 is required." },
              validate: { valid: getStringLengthValidator(25) },
            }}
          />
          <Typography className={classes.helperText} variant="subtitle1">
            Address line 1 should contain non-address data (e.g. company name)
            if applicable, otherwise the street or mailing address should be
            here. The apartment or unit number is to be placed at the end of the
            street address. No symbols (#,-) are to be placed before the number.
          </Typography>
        </Grid>
        {/* addressLine2 */}
        <Grid size={{ xs: 12 }}>
          <TypedFormTextField
            control={control}
            name="oopData.addressLine2"
            label="Address Line 2"
            disabled={disabled}
          />
          <Typography className={classes.helperText} variant="subtitle1">
            If address line 1 is used for non-address data, address line 2
            should contain street or mailing address. Otherwise address line 2
            is optional.
          </Typography>
        </Grid>
        {/* addressLine3 */}
        <Grid size={{ xs: 12 }}>
          <TypedFormTextField
            control={control}
            name="oopData.addressLine3"
            label="Address Line 3"
            disabled={disabled}
          />
          <Typography className={classes.helperText} variant="subtitle1">
            Address line 3 is optional.
          </Typography>
        </Grid>
        {/* cityName */}
        <Grid size={{ xs: 12 }}>
          <TypedFormTextField
            control={control}
            name="oopData.cityName"
            label="City Name"
            disabled={disabled}
            rules={{
              required: { value: true, message: "City name is required." },
              validate: { valid: getStringLengthValidator(30) },
            }}
          />
        </Grid>
        {/* postalCode */}
        <Grid size={{ xs: 12 }}>
          <TypedFormTextField
            control={control}
            name="oopData.postalCode"
            label="Postal Code"
            disabled={disabled}
            rules={{
              required: { value: true, message: "Postal code is required." },
              validate: {
                valid: (str: string) => {
                  return /^[A-Z]\d[A-Z]\d[A-Z]\d$/.test(str)
                    ? undefined
                    : "Postal is invalid. Do not include any spaces, and use capital letters (ex. A1A1A1).";
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default ConsultOOPInput;
