import { type Route } from "src/routes/types";
import { addParentPathToChildPaths } from "../internalHelpers";

// #region Types

/**
 * List of all route keys for AvaChildrenRoutes as an enum object
 */
export const AvaRouteNames = {
  linkAccount: "linkAccount",
} as const;

/**
 * Type of the keys of all of the routes for AvaChildrenRoutes (e.g. to ensure that all routes are covered for display)
 */
export type AvaChildRouteName =
  (typeof AvaRouteNames)[keyof typeof AvaRouteNames];

// #endregion

// #region Constants

/**
 * The parent route for all dashboard routes
 */
export const AVA_ROUTE = "/ava";

/**
 * All route metadata for all children routes of Ava without the ava route prepended
 */
export type AvaRouteName = (typeof AvaRouteNames)[keyof typeof AvaRouteNames];

const AVA_CHILDREN_PARTIAL_ROUTES: {
  [key in AvaRouteName]: Route;
} = {
  // Full route: /${AVA_ROUTE}/link-account
  [AvaRouteNames.linkAccount]: {
    path: "/link-account",
    availableOnMobile: true,
    isValidHomepage: false,
    requiredResourcePermissions: [[]],
    name: "Link Account",
  },
} as const;

// #endregion

// #region Exports

/**
 * Create new object from AVA_CHILDREN_PARTIAL_ROUTES with /${DASHBOARD_ROUTE}/ prepended to each path
 */
export const avaChildrenRoutes: {
  [key in AvaChildRouteName]: Route;
} = addParentPathToChildPaths(
  AVA_CHILDREN_PARTIAL_ROUTES,
  AvaRouteNames,
  AVA_ROUTE,
);

// #endregion

export default avaChildrenRoutes;
