import { Plan, Role, SignupInfo } from "@alethea-medical/aletheamd-types";
import { Grid2 as Grid } from "@mui/material";
import { FC } from "react";
import { Control } from "react-hook-form";
import { TypedFormSelect } from "src/components/FormInputFields/FormSelect";
import FormattedInputLabel from "src/components/FormattedInputLabel";
import { ROLE_WATCH_KEY } from "../../Signup";

type SelectRoleDropdownProps = {
  control: Control<SignupInfo.SignupInfo>;
  plan: Plan.Plan;
};

const SelectRoleDropdown: FC<SelectRoleDropdownProps> = ({ control, plan }) => (
  <Grid size={{ xs: 12, md: 4, lg: 3 }} spacing={3}>
    <FormattedInputLabel>Select a Role</FormattedInputLabel>
    <TypedFormSelect
      control={control}
      name={ROLE_WATCH_KEY}
      options={Object.values(Role.Roles)}
      rules={{
        required: { value: true, message: "Role is required" },
        validate: {
          isRoleAndPlanValid: (role: Role.Role) => {
            if (!Role.isEligibleForPlan(role, plan))
              return `${Role.getLongDisplayName(role)}s cannot sign up for Alethea plus at this time.`;
            return undefined;
          },
        },
      }}
      getOptionLabel={(option: Role.Role) => Role.getLongDisplayName(option)}
    />
  </Grid>
);

export default SelectRoleDropdown;
