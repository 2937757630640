import { Theme } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";
import ActivityListItem from "./ListItem/ActivityListItem";

import { ArrowDownward, Tune } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";

import { thinScrollbar } from "@alethea-medical/alethea-components";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid2";
import SnackbarMessage from "../../../../components/SnackbarMessage";
import SecureMessagingToolbar from "../Toolbar/SecureMessagingToolbar";
import ViewSecureMessage from "../ViewSecureMessage";

import { FC, useEffect, useState } from "react";

import {
  HeightLayout,
  HeightLayoutChild,
  ProcessStatus,
  useMobileSizes,
} from "@alethea-medical/alethea-components";
import { dbNames } from "@alethea-medical/aletheamd-db-keys";
import { useContext } from "react";
import { Activity } from "../../../../../shared/types";
import { AuthContext } from "../../../../AuthProvider";
import { SpecialistProvider } from "../Utilities/SpecialistProvider";
import ConsultSearchBar from "./ConsultSearchBar";
import ActivityProvider from "./Contexts/ActivityProvider";
import ActivitiesListController from "./Controllers/ActivitiesListController";
import InboxController from "./Controllers/InboxController";
import ActivitiesListModel from "./Models/ActivitiesListModel";
import InboxModel from "./Models/InboxModel";

import LabelContainer from "./ListItem/LabelContainer";

const useStyles = makeStyles()((theme: Theme) => ({
  ...thinScrollbar,
  box: {
    borderColor: theme.palette.grey[300],
  },
  columnHeader: {
    fontWeight: "bold",
    whiteSpace: "normal",
    wordWrap: "break-word",
  },
  threadSidebar: {
    height: "100%",
    padding: 0,
  },
  threadListItem: {
    padding: 0,
  },
  threadList: {
    padding: 0,
  },
  hidden: {
    display: "none",
  },
  hiddenIcon: {
    visibility: "hidden",
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
  },
  inboxControlsBar: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  tabSwitcher: {
    paddingLeft: theme.spacing(1),
  },
}));

interface InboxViewProps {
  height: number;
  folder: Activity.UserActivityFolder;
  setShowTabs: (show: boolean) => void;
}

const InboxAndConsultDetailsView: FC<InboxViewProps> = ({
  height,
  folder,
  setShowTabs,
}: InboxViewProps) => {
  const { classes, cx } = useStyles();

  const authContext = useContext(AuthContext);

  const {
    sortedActivities,
    sortedEconsults,
    activities,
    updateActivitiesInState,
    removeActivitiesFromState,
    refillActivities,
    loadMoreHandler,
    disableLoadMoreLoading,
    disableLoadMoreEndOfResults,
    calculateGridSize,

    isActivityOpen,
    openActivityHandler,

    // Permissions
    permissions,

    // Select
    selectedActivities,
    allSelected,
    selectAllHandler,
    activitySelectHandler,
    unselectAllHandler,

    // Search
    searchParams,
    updateSearchParams,
    enableSearch,
    runSearch,
    clearSearch,
    saveSubjectLineFilters,
    fetchSubjectLineFilters,

    processState,
    setProcessState,
    processErrorMessage,
    errorHandler,
  } = InboxController<Activity.UserActivity>({
    inboxModel: new InboxModel(
      authContext.uid,
      dbNames.activities,
      dbNames.userActivities,
      dbNames.userActivities_activities,
      "lastMessageReceivedAt",
    ),
    folder,
    metadataFieldToSortBy: "lastMessageReceivedAt",
    setShowTabs,
  });

  const activitiesListController = ActivitiesListController({
    activitiesListModel: new ActivitiesListModel(authContext.uid),
    activities,
    updateActivitiesInState,
    removeActivitiesFromState,
    unselectAllHandler,
    errorHandler,
  });

  // To show the column headers
  const columnHeaders = [
    "Specialty",
    "Subsite",
    "PHN",
    "Patient Name",
    "Referrer Name",
    "Consultant Name",
  ];

  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [showColumnHeaders, setShowColumnHeaders] = useState(true);
  const [toolbarAnimationComplete, setToolbarAnimationComplete] =
    useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const isMobileSize = useMobileSizes("lg");
  const gridSizes = calculateGridSize(selectedOptions);

  useEffect(() => {
    if (toolbarAnimationComplete) {
      setShowColumnHeaders(true);
    }
  }, [toolbarAnimationComplete]);

  const handleSelectAll = (checked: boolean) => {
    selectAllHandler(checked);
    setShowColumnHeaders(false);
    setToolbarAnimationComplete(false);
  };

  const handleActivitySelect = (id: string, checked: boolean) => {
    activitySelectHandler(id, checked);
    setShowColumnHeaders(selectedActivities.length === 0 && !checked);
    setToolbarAnimationComplete(false);
  };

  return (
    <SpecialistProvider>
      {!isActivityOpen && (
        <Box display="flex" flexDirection="column" height="100%">
          <HeightLayoutChild flexDriver>
            <ListItem divider>
              <ConsultSearchBar
                folder={folder}
                searchParams={searchParams}
                updateSearchParams={updateSearchParams}
                enableSearch={enableSearch}
                runSearch={runSearch}
                clearSearch={clearSearch}
                saveSubjectLineFilters={saveSubjectLineFilters}
                fetchSubjectLineFilters={fetchSubjectLineFilters}
                placeholderText={
                  "PHN, Patient Name, Specialty, Subsite, Referring Provider/Consultant name"
                }
                maxSearchTerms={10}
                onSelectedOptionsChange={setSelectedOptions}
                showOptions={showOptions}
                setShowOptions={setShowOptions}
              />
            </ListItem>
            <ProcessStatus
              state={processState}
              setState={setProcessState}
              errorMessage={processErrorMessage}
              useSnackbar={true}
            />
            <ListItem className={classes.toolbar} divider>
              <Grid
                container
                justifyContent={isMobileSize ? "space-between" : "flex-start"}
                alignItems="center"
                size="grow"
              >
                <Grid>
                  <Checkbox
                    checked={allSelected}
                    onChange={(e) => {
                      handleSelectAll(e.target.checked);
                    }}
                  />
                </Grid>
                <Grid className={classes.hiddenIcon} style={{ padding: "8px" }}>
                  {sortedActivities.length > 0 && (
                    <LabelContainer
                      activity={sortedActivities[0]}
                      read={true}
                    />
                  )}
                </Grid>
                <Grid>
                  <SecureMessagingToolbar
                    folder={folder}
                    atLeastOneSelected={selectedActivities.length > 0}
                    markAsUnread={() => {
                      activitiesListController.markAsUnreadHandler(
                        selectedActivities,
                      );
                    }}
                    moveFolder={async (folder) => {
                      const loadedActivitiesCount = sortedActivities.length;
                      await activitiesListController.moveFolderHandler(
                        selectedActivities,
                        folder,
                      );
                      refillActivities(loadedActivitiesCount);
                    }}
                    onAnimationEnd={() => {
                      setToolbarAnimationComplete(true);
                    }}
                  />
                </Grid>
                <Grid
                  container
                  size={{ xs: "grow" }}
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  {!isMobileSize && showColumnHeaders && (
                    <>
                      <Grid
                        className={cx(
                          {
                            [classes.hiddenIcon]:
                              !permissions.hasEditStatusPermissions,
                          },
                          classes.columnHeader,
                        )}
                        size={{
                          xs: gridSizes.Status,
                        }}
                      />
                      {columnHeaders.map(
                        (header) =>
                          selectedOptions.includes(header) && (
                            <Grid
                              key={header}
                              className={classes.columnHeader}
                              size={{
                                xs: gridSizes[header],
                              }}
                            >
                              {header}
                            </Grid>
                          ),
                      )}
                    </>
                  )}
                  <Grid
                    size={{ xs: "grow" }}
                    container
                    justifyContent="flex-end"
                  >
                    <Tooltip title="Edit Subject Line">
                      <IconButton onClick={() => setShowOptions(!showOptions)}>
                        <Tune />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          </HeightLayoutChild>
          <HeightLayoutChild
            flexDriven
            allowOverflowY
            className={classes.thinScrollbar}
          >
            {sortedActivities.map((a, index) => (
              <ActivityListItem
                key={`activity_list_item_${a.id}`}
                activity={a}
                econsult={sortedEconsults[index]}
                openActivity={openActivityHandler}
                selected={selectedActivities.includes(a.id)}
                setSelected={handleActivitySelect}
                filters={selectedOptions}
                calculateGridSize={calculateGridSize}
                permissions={permissions}
                processState={processState}
              />
            ))}
            <ListItemButton
              onClick={loadMoreHandler}
              disabled={disableLoadMoreLoading || disableLoadMoreEndOfResults}
              alignItems="center"
              divider
            >
              <ListItemIcon>
                <ArrowDownward color="primary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  disableLoadMoreLoading
                    ? "Loading"
                    : disableLoadMoreEndOfResults
                      ? "End of Consults"
                      : enableSearch
                        ? "Load More Search Results"
                        : "Load More"
                }
              />
            </ListItemButton>
          </HeightLayoutChild>
        </Box>
      )}
      <ActivityProvider
        updateActivitiesInListState={updateActivitiesInState}
        activitiesListController={activitiesListController}
        viewType="md"
        metadataRefId={authContext.uid}
        metadataCollection={dbNames.userActivities}
        metadataSubCollection={dbNames.userActivities_activities}
      >
        <ViewSecureMessage
          refillActivities={() => {
            const loadedActivitiesCount = sortedActivities.length;
            refillActivities(loadedActivitiesCount);
          }}
        />
      </ActivityProvider>
      <SnackbarMessage
        message={`Moved to ${activitiesListController.moveSnackbarFolderAction}`}
        show={activitiesListController.showUndoSnackbar}
        setShow={activitiesListController.setShowUndoSnackbar}
        onClick={activitiesListController.undoMoveFolderHandler}
        useButton
        buttonText="UNDO"
      />
      <SnackbarMessage
        message={"Marked as unread"}
        show={activitiesListController.showMarkedAsUnreadSnackbar}
        setShow={activitiesListController.setShowMarkAsUnreadSnackbar}
      />
    </SpecialistProvider>
  );
};

export default InboxAndConsultDetailsView;
