import {
  HeightLayout,
  PaperModal,
  ProcessState,
  ProcessStatus,
} from "@alethea-medical/alethea-components";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import { FC, useCallback, useMemo, useState } from "react";
import MultiMediaViewer from "src/components/Gallery/Views/MediaViewer/MultiMediaViewer";
import SelectGalleryController from "../../../../../components/Gallery/Controllers/SelectGalleryController";
import GalleryGrid from "../../../../../components/Gallery/Views/GalleryGrid";
import GalleryRenderer from "../../../../../components/Gallery/Views/GalleryRenderer";
import useAITranslation from "../../../../../components/useAITranslation";
import AddLabelsDropdown from "./AddLabelsDropdown";
import ConfirmAIPredictionButton from "./ConfirmAIPredictionButton";
import EconsultImageAnnotationController from "./EconsultImageAnnotationController";
import {
  ImageAnnotationItem,
  ImageAnnotationSelectedDict,
} from "./EconsultImageAnnotationModel";
import IgnoreImagesButton from "./IgnoreImagesButton";
import StartStopLabellingButton from "./StartStopLabellingButton";

const EconsultImageAnnotationView: FC = () => {
  const controller = EconsultImageAnnotationController();

  const mediaSortedById = useMemo(
    () =>
      controller.imagesToLabel.sort(
        (a, b) => a.data.created.toMillis() - b.data.created.toMillis(),
      ),
    [controller.imagesToLabel],
  );
  const { aiToSp } = useAITranslation({ useSpToAi: true });
  const [modalImage, setModalImage] = useState<ImageAnnotationItem>();
  const {
    // data
    selectedMedia,
    // handlers
    selectHandler,
    unselectHandler,
    unselectAllHandler,
  } = SelectGalleryController({});

  const galleryRenderer = useCallback(
    (photo: ImageAnnotationItem) => (
      <GalleryRenderer
        item={photo}
        selected={selectedMedia[photo.id] !== undefined}
        handleOpenMedia={(item) => {
          setModalImage(item as ImageAnnotationItem);
        }}
        select={selectHandler}
        unselect={unselectHandler}
        labels={(photo.doctorLabels ?? photo.aiPrediction).map((ai) =>
          aiToSp(ai, true),
        )}
        labelType={photo.doctorLabels ? "Doctor" : "AI"}
        enableSelect
        useOriginalAspectRatio
      />
    ),
    [aiToSp],
  );

  return (
    <>
      {controller.showAnnotation && (
        <>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12 }}>
              <StartStopLabellingButton
                show={controller.expandAnnotation}
                startLabellingHandler={controller.startLabellingHandler}
                stopLabellingHandler={controller.stopLabellingHandler}
                disabled={controller.annotationProcess === ProcessState.running}
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <ProcessStatus
                state={controller.annotationProcess}
                errorMessage={controller.annotationError}
                loadingMessage="Saving labels..."
              />
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Collapse in={controller.expandAnnotation}>
                <Grid container spacing={1}>
                  <Grid size={{ xs: 12 }}>
                    <AddLabelsDropdown
                      specialty={controller.specialty}
                      selectedMedia={
                        selectedMedia as ImageAnnotationSelectedDict
                      }
                      labelHandler={controller.labelImagesHandler}
                      unselectAllHandler={unselectAllHandler}
                    />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <ConfirmAIPredictionButton
                      selectedMedia={
                        selectedMedia as ImageAnnotationSelectedDict
                      }
                      confirmHandler={controller.confirmAIPredictionsHandler}
                      unselectAllHandler={unselectAllHandler}
                    />
                  </Grid>
                  <Grid size={{ xs: 6 }}>
                    <IgnoreImagesButton
                      selectedMedia={
                        selectedMedia as ImageAnnotationSelectedDict
                      }
                      ignoreHandler={controller.ignoreImagesHandler}
                      unselectAllHandler={unselectAllHandler}
                    />
                  </Grid>
                  <Grid size={{ xs: 12 }}>
                    <GalleryGrid
                      photos={mediaSortedById}
                      breakpoints={{ xs: 6, sm: 4, md: 4, lg: 4 }}
                      galleryRenderer={galleryRenderer}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
          </Grid>
          {modalImage && (
            <PaperModal
              show={modalImage !== undefined}
              setShow={(show) => {
                if (!show) setModalImage(undefined);
              }}
              enablePadding
            >
              <HeightLayout>
                <MultiMediaViewer
                  currentMediaItem={modalImage}
                  mediaItems={mediaSortedById}
                  closeMediaItem={() => setModalImage(undefined)}
                  openMediaItem={(item) =>
                    setModalImage(item as ImageAnnotationItem)
                  }
                  hideAi
                />
              </HeightLayout>
            </PaperModal>
          )}
        </>
      )}
    </>
  );
};

export default EconsultImageAnnotationView;
