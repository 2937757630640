import { PaperModal, ProcessStatus } from "@alethea-medical/alethea-components";
import DoneIcon from "@mui/icons-material/Done";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import SettingsPhoneIcon from "@mui/icons-material/SettingsPhone";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { FC } from "react";
import { makeStyles } from "tss-react/mui";
import StatusPaper from "../../../../components/StatusPaper";
import { orangeButton } from "../../../../sharedStyles";
import { outcomes } from "../SpecialistTools/Outcomes/EconsultOutcomes/EconsultOutcomesModal";
import OutcomesInput from "../SpecialistTools/Outcomes/OutcomesInput";
import CallCompleteController, { billingCodes } from "./CallCompleteController";

const useStyles = makeStyles()(() => ({
  incomplete: orangeButton,
  modal: {
    maxHeight: "90vh",
    overflowY: "auto",
  },
}));

interface CallCompleteButtonProps {
  activityId: string;
  callComplete: boolean;
  userActivityRole: string;
}

const CallCompleteButton: FC<CallCompleteButtonProps> = ({
  activityId,
  callComplete,
  userActivityRole,
}) => {
  const { classes } = useStyles();

  const {
    callCompleteHandler,
    showModal,
    setShowModal,
    enableButtonControl,
    processState,
    processErrorMessage,
    outcomesEmpty,
    disableSubmit,
    disableCancel,
    billingCodeKey,
    setBillingCodeKey,
    selectedOutcome,
    setSelectedOutcome,
    selectedDiagnosis,
    setSelectedDiagnosis,
  } = CallCompleteController({ activityId, userActivityRole });

  const showModalHandler = () => {
    setShowModal(true);
  };

  const hideModalHandler = () => {
    setShowModal(false);
  };

  return (
    <>
      {enableButtonControl === true && (
        <>
          {callComplete ? (
            <StatusPaper icon={<DoneIcon color="primary" />}>
              Call Complete
            </StatusPaper>
          ) : (
            <Button
              className={classes.incomplete}
              variant="contained"
              startIcon={<PhoneInTalkIcon />}
              onClick={showModalHandler}
            >
              Mark Call as Complete
            </Button>
          )}
        </>
      )}
      {enableButtonControl === false && (
        <>
          {callComplete ? (
            <StatusPaper icon={<DoneIcon color="primary" />}>
              Call Complete
            </StatusPaper>
          ) : (
            <StatusPaper icon={<SettingsPhoneIcon color="primary" />}>
              Waiting for Call
            </StatusPaper>
          )}
        </>
      )}
      <PaperModal
        className={classes.modal}
        show={showModal}
        flexWidth
        flexHeight
        enablePadding
        setShow={disableCancel ? undefined : setShowModal}
      >
        <Grid container justifyContent="center" spacing={2}>
          <Grid size={{ xs: 12 }}>
            <Typography>
              Mark call as complete and bill the phone consult for you and the
              referring physician?
            </Typography>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <OutcomesInput
              outcomes={outcomes}
              selectedOutcome={selectedOutcome}
              setSelectedOutcome={setSelectedOutcome}
              selectedDiagnosis={selectedDiagnosis}
              setSelectedDiagnosis={setSelectedDiagnosis}
            />
          </Grid>
          {outcomesEmpty && (
            <Grid size={{ xs: 12 }}>
              <Typography>
                Please fill out the Outcome before marking as complete
              </Typography>
            </Grid>
          )}
          <Grid size={{ xs: 12 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Select the Billing Code that is Appropriate for this Consult
              </FormLabel>
              <RadioGroup
                aria-label="billing-code"
                value={billingCodeKey}
                onChange={(e) => {
                  setBillingCodeKey(e.target.value);
                }}
              >
                {Object.keys(billingCodes).map((key) => (
                  <FormControlLabel
                    key={`call_complete_billing_code_${key}`}
                    value={key}
                    control={<Radio />}
                    label={`${billingCodes[key].description} (${billingCodes[key].code})`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={hideModalHandler}
              fullWidth
              disabled={disableCancel}
            >
              Cancel
            </Button>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              className={classes.incomplete}
              variant="contained"
              onClick={callCompleteHandler}
              fullWidth
              disabled={disableSubmit}
            >
              Mark as Complete and Bill
            </Button>
          </Grid>
          <Grid size={{ xs: 12 }}>
            <ProcessStatus
              state={processState}
              errorMessage={processErrorMessage}
            />
          </Grid>
        </Grid>
      </PaperModal>
    </>
  );
};

export default CallCompleteButton;
