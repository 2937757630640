import { SignupInfo } from "@alethea-medical/aletheamd-types";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FC } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { TypedEmailInput } from "src/components/FormInputFields/EmailInput";
import { useOffsetEmailInputErrorTextStyles } from "src/components/FormInputFields/EmailInput/EmailInput";
import RoundedButton from "src/components/RoundedButton";
import { TypedFormTextField } from "../../../../../components/FormInputFields/FormTextField";
import FormattedInputLabel from "../../../../../components/FormattedInputLabel";
import SectionContainer from "../../Helpers/Container/SectionContainer";

interface PreceptorInformationProps {
  control: Control<SignupInfo.SignupInfo>;
  disabled?: boolean;
  number: number;
}

const PreceptorInformation: FC<PreceptorInformationProps> = ({
  control,
  disabled,
  number,
}) => {
  const { classes } = useOffsetEmailInputErrorTextStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "preceptorInformation",
  });

  const addRowHandler = () => {
    append({ preceptorName: "", preceptorEmail: "" });
  };

  const removeRowHandler = (i: number) => {
    remove(i);
  };

  return (
    <SectionContainer title="Preceptor Information" number={number}>
      <Grid container spacing={4}>
        {fields.map((field, index) => (
          <Grid container spacing={2} alignItems="flex-end" key={field.id}>
            <Grid size={{ xs: 12, md: 6, lg: 5 }}>
              <FormattedInputLabel>Preceptor Name</FormattedInputLabel>
              <TypedFormTextField
                className={classes.flexAlignedEnd}
                control={control}
                name={`preceptorInformation.${index}.preceptorName`}
                disabled={disabled}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6, lg: 5 }}>
              <FormattedInputLabel>
                Preceptor E-Mail Address
              </FormattedInputLabel>
              <TypedEmailInput
                control={control}
                name={`preceptorInformation.${index}.preceptorEmail`}
                disabled={disabled}
                errorTextPadding
              />
            </Grid>
            <Grid size={{ xs: 12, lg: 2 }}>
              <RoundedButton
                style={{ width: "100%" }}
                className={classes.flexAlignedEnd}
                startIcon={<RemoveIcon />}
                onClick={() => removeRowHandler(index)}
                size="medium"
              >
                Remove
              </RoundedButton>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Divider variant="middle" />
            </Grid>
          </Grid>
        ))}
        <Grid size={{ xs: 12, sm: 5, md: 4, lg: 3, xl: 2 }}>
          <RoundedButton
            style={{ width: "100%" }}
            startIcon={<AddIcon />}
            onClick={addRowHandler}
          >
            Add Preceptor
          </RoundedButton>
        </Grid>
      </Grid>
    </SectionContainer>
  );
};
export default PreceptorInformation;
