import ListItem from "@mui/material/ListItem";
import { Theme } from "@mui/material/styles";
import React from "react";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  header: {
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  item: {
    paddingLeft: theme.spacing(7),
    backgroundColor: theme.palette.secondary.light,
  },
}));

interface LinkPreviewerContainerProps {
  header?: boolean;
  item?: boolean;
  children: React.ReactNode;
}

const LinkPreviewerContainer: React.FC<LinkPreviewerContainerProps> = ({
  header,
  item,
  children,
}) => {
  const { classes, cx } = useStyles();

  return (
    <ListItem
      className={cx(classes.container, {
        [classes.item]: item,
        [classes.header]: header,
      })}
    >
      {children}
    </ListItem>
  );
};

export default LinkPreviewerContainer;
