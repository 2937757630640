const formatPhone = (unformatted: string | undefined): string => {
  if (unformatted === undefined) return "";

  const cleaned = ("" + unformatted).replace(/\D/g, "");
  const cleanedAndTrimmed = cleaned.substr(0, 10);
  const match = cleanedAndTrimmed.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return "";
};

export default formatPhone;
